import React from 'react';
import ContentLoader from '../../components/Loader/contentLoader';
import Chart from 'react-apexcharts';

type ChartComponentProps = {
  title: string;
  options: any;
  series: any;
  loading: boolean;
};
const chartComponent: React.FC<ChartComponentProps> = ({
  title,
  options,
  series,
  loading,
}) => {
  if (!options || !series) {
    console.error('Invalid options or series:', { options, series });
    return null;
  }
  return (
    <>
      {/* <h4 className="margin-top">{title}</h4> */}
      {!loading ? (
        <Chart
          options={options}
          series={series}
          type="pie"
          width={'100%'}
          height={300}
        />
      ) : (
        <div className="loading-container-dashboard">
          <ContentLoader />
          <span>Updating Chart...</span>
        </div>
      )}
    </>
  );
};

export default chartComponent;
