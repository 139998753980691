import React, { useState, useEffect } from 'react';
import { FaEdit } from 'react-icons/fa';
import {
  Modal,
  Button,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AddEditCopilotPrompts.css';
import axios from 'axios';

interface AddEditCopilotPromptsProps {
  btnText: string;
  id?: string; // Make id optional
  sendMessageToParent: (message: string) => void;
  data: {
    id?: number;
    name: string;
    prompt: string;
    functionname: string;
    active: boolean;
    disabled?: boolean;
  };
}

const AddEditCopilotPrompts: React.FC<AddEditCopilotPromptsProps> = (props) => {
  const [name, setName] = useState('');
  const [prompt, setPrompt] = useState('');
  const [functionName, setFunctionName] = useState('');
  const [activeFlag, setActiveFlag] = useState(false);
  const [show, setShow] = useState(false);
  const [functions, setFunctions] = useState<{ id: number; name: string }[]>(
    []
  );

  // Add useEffect to listen for changes in functionName
  useEffect(() => {
    if (functionName) {
      fetchFunctions();
    }
  }, [functionName]);

  // Update the fetchFunctions function to handle the API call
  const fetchFunctions = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_COPILOT_BACKEND_DOMAIN}/api/functions/getActiveEngines`,
        { copilot_instance_id: props.id }
      );
      //console.log('Functions response:', response.data);
      setFunctions(response.data);
    } catch (error) {
      console.error('Error fetching functions:', error);
    }
  };

  const handleClose = () => {
    setName('');
    setPrompt('');
    setFunctionName('');
    setActiveFlag(false);
    props.sendMessageToParent('success');
    setShow(false);
    toast.dismiss();
  };

  const handleShow = async () => {
    await fetchFunctions();
    setName(props.data.name);
    setPrompt(props.data.prompt);
    setFunctionName(props.data.functionname);
    setActiveFlag(props.data.active);
    setShow(true);
  };

  const onChangeCopilotPrompt = (event: any) => {
    if (event.target.name === 'name') {
      setName(event.target.value);
    } else if (event.target.name === 'prompt') {
      setPrompt(event.target.value);
    } else if (event.target.name === 'functionName') {
      setFunctionName(event.target.value);
    } else if (event.target.name === 'active') {
      setActiveFlag(event.target.checked);
    }
  };

  const createPrompt = async () => {
    try {
      if (!name || !prompt || !functionName) {
        toast.error('Name, Prompt, and Function Name are mandatory fields.', {
          onClick: () => toast.dismiss(),
          autoClose: 1000,
        });
        return;
      }

      if (!props.id) {
        toast.error('Instance ID is required to create a prompt.', {
          onClick: () => toast.dismiss(),
          autoClose: 1000,
        });
        return;
      }

      const copilot_params = {
        name: name,
        prompt: prompt,
        active: activeFlag,
        copilotFunctionId: functions.find((func) => func.name === functionName)
          ?.id,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_COPILOT_BACKEND_DOMAIN}/api/prompts/createPrompt/${props.id}`,
        copilot_params
      );

      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      handleClose();
    } catch (error) {
      toast.error(
        'An error has occurred. Please reach out to the administrator',
        {
          onClick: () => toast.dismiss(),
          autoClose: 1000,
        }
      );
    }
  };

  const editPrompt = async () => {
    try {
      if (!name || !prompt || !functionName) {
        toast.error('Name, Prompt, and Function Name are mandatory fields.', {
          onClick: () => toast.dismiss(),
          autoClose: 1000,
        });
        return;
      }

      const copilot_params = {
        name: name,
        prompt: prompt,
        active: activeFlag,
        copilotFunctionId: functions.find((func) => func.name === functionName)
          ?.id,
      };

      const response = await axios.put(
        `${process.env.REACT_APP_COPILOT_BACKEND_DOMAIN}/api/prompts/updatePrompt/${props.data.id}`,
        copilot_params
      );

      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      handleClose();
    } catch (error) {
      toast.error(
        'An error has occurred. Please reach out to the administrator',
        {
          onClick: () => toast.dismiss(),
          autoClose: 1000,
        }
      );
    }
  };

  return (
    <>
      <ToastContainer
        toastClassName="custom-toast-error custom-toast-container"
        closeButton={false}
        hideProgressBar={true}
      />
      <div>
        {props.btnText !== 'Edit' && (
          <Button className="btn btn-primary" onClick={handleShow}>
            {props.btnText}
          </Button>
        )}
        {props.btnText === 'Edit' && (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Edit Prompt</Tooltip>}
          >
            <Button className="edit-btn " onClick={handleShow}>
              <FaEdit></FaEdit>
            </Button>
          </OverlayTrigger>
        )}
      </div>
      <ToastContainer closeOnClick autoClose={false} />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="custom-modal-header">
          <div>
            {props.btnText !== 'Edit' && (
              <Modal.Title>{props.btnText}</Modal.Title>
            )}
            {props.btnText === 'Edit' && (
              <Modal.Title>{props.btnText} Prompt</Modal.Title>
            )}
          </div>
          <div className="custom-close-button-container">
            <button className="custom-close-button" onClick={handleClose}>
              &times;
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="container-box">
          <div className="my-3">
            <form className="container">
              <Row>
                <Col xs={12} md={12}>
                  <div>
                    <strong>
                      Name
                      <span className="required-asterisk">*</span>
                      <OverlayTrigger
                        placement="top-end"
                        overlay={
                          <Tooltip id="tooltip-name">
                            Define the name of the prompt
                          </Tooltip>
                        }
                      >
                        <span style={{ display: 'inline-block' }}>
                          <FaInfoCircle className="info-icon" />
                        </span>
                      </OverlayTrigger>
                    </strong>
                    <input
                      type="text"
                      className="form-control my-2"
                      name="name"
                      onChange={onChangeCopilotPrompt}
                      value={name}
                      required
                      placeholder="Enter name"
                    />
                  </div>
                  <div className="my-3">
                    <strong>
                      Prompt
                      <span className="required-asterisk">*</span>
                      <OverlayTrigger
                        placement="top-end"
                        overlay={
                          <Tooltip id="tooltip-prompt">
                            Define the prompt
                          </Tooltip>
                        }
                      >
                        <span style={{ display: 'inline-block' }}>
                          <FaInfoCircle className="info-icon" />
                        </span>
                      </OverlayTrigger>
                    </strong>
                    <textarea
                      name="prompt"
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows={5}
                      value={prompt}
                      onChange={onChangeCopilotPrompt}
                      required={true}
                      placeholder="Enter prompt"
                    ></textarea>
                  </div>
                  <div className="my-3">
                    <strong>
                      Function Name
                      <span className="required-asterisk">*</span>
                      <OverlayTrigger
                        placement="top-end"
                        overlay={
                          <Tooltip id="tooltip-function-name">
                            Provide the function name associated with the prompt
                          </Tooltip>
                        }
                      >
                        <span style={{ display: 'inline-block' }}>
                          <FaInfoCircle className="info-icon" />
                        </span>
                      </OverlayTrigger>
                    </strong>
                    <select
                      className="form-control my-2 custom-select"
                      name="functionName"
                      onChange={onChangeCopilotPrompt}
                      value={functionName}
                      required
                    >
                      <option value="">Select Function</option>
                      {functions.map((func) => (
                        <option key={func.id} value={func.name}>
                          {func.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="md-3">
                    <strong className="my-3">
                      Active{' '}
                      <OverlayTrigger
                        placement="top-end"
                        overlay={
                          <Tooltip id="tooltip-active">
                            Check to make it active
                          </Tooltip>
                        }
                      >
                        <span style={{ display: 'inline-block' }}>
                          <FaInfoCircle className="info-icon" />
                        </span>
                      </OverlayTrigger>
                    </strong>
                    <label className="switch">
                      <input
                        type="checkbox"
                        id="flexCheckChecked"
                        onChange={onChangeCopilotPrompt}
                        checked={activeFlag}
                        name="active"
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </Col>
              </Row>
            </form>
          </div>
          <div className="container container-flex">
            {props.btnText === 'Edit' && (
              <button className="save-button" onClick={editPrompt}>
                <strong>Save</strong>
              </button>
            )}
            {props.btnText !== 'Edit' && (
              <button className="save-button" onClick={createPrompt}>
                <strong>Save</strong>
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

// Set default props for AddEditCopilotPrompts
AddEditCopilotPrompts.defaultProps = {
  data: {
    name: '',
    prompt: '',
    functionname: '',
    active: false,
    disabled: false,
  },
};

export default AddEditCopilotPrompts;
