import React from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AddCopilotInstancs.css';

interface AddCopilotInstanceProps {
  sendMessageToParent: (message: string) => void;
}

const AddCopilotInstance: React.FC<AddCopilotInstanceProps> = (props) => {
  const [copilotInstanceName, setcopilotInstanceName] = React.useState('');
  const [description, setdescription] = React.useState('');
  const [show, setShow] = React.useState(false);

  const handleClose = () => {
    props.sendMessageToParent('hello parent');
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const setCopilotInstanceValue = (event: any) => {
    setcopilotInstanceName(event.target.value);
  };
  const setCopilotDescription = (event: any) => {
    setdescription(event.target.value);
  };

  //fetching instaces from DB
  const createNewInstance = async () => {
    try {
      if (!copilotInstanceName) {
        // Show an error message or alert to the user indicating that name and description are mandatory.
        toast.error('Instance Name is required', {
          onClick: () => toast.dismiss(),
          autoClose: 1000,
        });
        return;
      }

      const copilot_params = {
        copilotInstanceName: copilotInstanceName,
        description: description,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_COPILOT_BACKEND_DOMAIN}/api/instances/createInstances`,
        copilot_params
      );

      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // const data = await response.json();
      const data = response.data;
      // Handle the response data directly here
      handleClose();
    } catch (error) {
      // Handle errors
      console.error('Error:', error);
    }
  };

  return (
    <>
      <a href="javascript:void(0);" onClick={handleShow}>
        Create new Instance
      </a>

      <ToastContainer></ToastContainer>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="custom-modal-header">
          <div>
            <Modal.Title>Create new Instance</Modal.Title>
          </div>
          <div className="custom-close-button-container">
            <button className="custom-close-button" onClick={handleClose}>
              &times;
            </button>
          </div>
        </Modal.Header>

        <Modal.Body className="container-background">
          {/* <div className="my-3">
            <form className="container" >
              <Row>
                <Col xs={12} md={12}>
                  <div>
                    <strong>Copilot Instance Name:</strong>
                    <input
                      type="text"
                      className="form-control my-2"
                      value={copilotInstanceName}
                      name="copilotInstanceName"
                      onChange={setCopilotInstanceValue}
                    />
                  </div>
                  <div className="my-3">
                    <strong>Description:</strong>

                    <textarea name="description" className="form-control my-2" rows={5} value={description} onChange={setCopilotDescription}></textarea>
                  </div>
                </Col>
              </Row>
            </form>
          </div > */}

          <div className="my-3">
            <form className="container">
              <Row>
                <Col xs={12} md={12}>
                  <div>
                    <strong>
                      Copilot Instance Name
                      <span className="required-asterisk">*</span>
                      <OverlayTrigger
                        placement="top-end"
                        overlay={
                          <Tooltip id="tooltip-copilot-name">
                            Mention the name of the copilot instance
                          </Tooltip>
                        }
                      >
                        <span style={{ display: 'inline-block' }}>
                          <FaInfoCircle className="info-icon" />
                        </span>
                      </OverlayTrigger>
                    </strong>
                    <input
                      type="text"
                      className="form-control my-2"
                      value={copilotInstanceName}
                      name="copilotInstanceName"
                      onChange={setCopilotInstanceValue}
                    />
                  </div>
                  <div className="my-3">
                    <strong>
                      Description{' '}
                      <OverlayTrigger
                        placement="top-end"
                        overlay={
                          <Tooltip id="tooltip-description">
                            Define a brief description for the copilot instance
                          </Tooltip>
                        }
                      >
                        <span style={{ display: 'inline-block' }}>
                          <FaInfoCircle className="info-icon" />
                        </span>
                      </OverlayTrigger>
                    </strong>
                    <textarea
                      name="description"
                      className="form-control my-2"
                      rows={5}
                      value={description}
                      onChange={setCopilotDescription}
                    />
                  </div>
                </Col>
              </Row>
            </form>
          </div>
          <div className="container container-flex">
            {/* <Button className="btn btn-primary"  style={{float:'right'}}  onClick={createNewInstance}>Save</Button> */}
            {/* <Button className="btn btn-primary" onClick={createNewInstance}>Save</Button> */}
            <button className="save-button" onClick={createNewInstance}>
              <strong>Save</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddCopilotInstance;
