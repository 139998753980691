/* eslint-disable react-hooks/exhaustive-deps */

import axios from 'axios';
import { useState, useRef, useEffect, useContext } from 'react';
import { Form, Button, Modal, Badge } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import './VideoAnalytics.css';
import Chatbox from '../../components/Chatbox';
import { ReactComponent as BackIcon } from '../../assets/arrows/back-arrow.svg';
import { Appl, ChatboxInterface } from '../../interfaces';
import UserContext from '../../context/UserContext';
import ContentLoader from '../../components/Loader/contentLoader';
import { FaEdit, FaTrash } from 'react-icons/fa';
import VideoViewer from '../../components/VideoViewer';
import { ADVANCED_ERROR_MESSAGE, NORMAL } from '../../utils/constants';

const VisionVideoAnalytics = () => {
  const { projectId, appUrl } = useParams();
  const apps = JSON.parse(sessionStorage.getItem('apps') as string) as Appl[];
  const [outputData, setOutputData] = useState<string>('');
  const chatbox = useRef<ChatboxInterface>(null);
  const overlayContainerRef = useRef<HTMLDivElement | null>(null);
  const [showErrMsg, setShowErrMsg] = useState<string>('');
  const currentApp = apps.find((app) => app.url === appUrl);
  const [showNewDomainModal, setShowNewDomainModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<FileData[]>([]);
  const [domainName, setDomainName] = useState<string>('');
  const [file, setFile] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isSelect, setIsSelect] = useState(false);
  const [selectedCorpus, setselectedCorpus] = useState('');
  const [isUploadDomainmsg, setIsUploadDomainMsg] = useState(false);
  const [selectedImageBackup, setSelectedImagebackUp] = useState<string[]>([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isFinishLoader, setIsFinishLoader] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [selectedImageNames, setSelectedImageNames] = useState<string[]>([]);
  const [isCancel, setIsCancel] = useState(false);
  const [isEditEnabled, setIsEditEnabled] = useState(true);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [isDeleteEnabled, setIsDeleteEnabled] = useState(true);
  const [isDeleteLoader, setIsDeleteLoader] = useState(false);
  const [removeFileName, setRemoveFileName] = useState('');
  const [removeFileindex, setRemoveFileIndex] = useState(-1);
  const [corpusList, setCorpusList] = useState<string[]>([]);
  const [queries, setQueries] = useState<any[]>([]);
  const [feedbackproperties, setFeedBackProporties] = useState<any>([]);
  const [imgUploadError, setImgUploadError] = useState(false);
  const [editCorpusSuccessMsg, setEditCorpusSuccessMsg] = useState(false);
  const [uploadCheckLinks, setUploadCheckLinks] = useState<string[]>([]);
  const [instanceVideoLinks, setinstanceVideoLinks] = useState<any>({});
  const [editFinishButton, setEditFinishButton] = useState(true);
  // const [imageUploaadResponse, setImageUploadResponse] = useState<string[]>([]);
  const [removeFileKey, setRemoveFileKey] = useState<string>('');
  const [instanceButtons, setinstanceButtons] = useState(false);
  const [uploadTaskId, setUploadTaskId] = useState<string>('');
  const [showDeleteCorpusPopup, setShowDeleteCorpusPopup] = useState(false);
  const [selectedImageKeys, setSelectedImageKeys] = useState<string[]>([]);
  const [isUploadingVideo, setIsUploadingVideo] = useState<boolean>(false);
  const [isUploaded, setIsUploaded] = useState<boolean>(false);

  const userContext = useContext(UserContext) || {
    selectedInstance: '',
    useAdvancedSettings: false,
    userInput: '',
    setUserInput: () => {},
    isLoading: false,
    setIsLoading: () => {},
    showAdvancedSettings: false,
    setShowAdvancedSettings: () => {},
    showAdvancedSettingsAlert: false,
    setShowAdvancedSettingsAlert: () => {},
    chatContents: [],
    setChatContents: () => [],
    advancedSettings: {
      mode: 'autocut',
      autocut: 1,
      query_param: undefined,
      fusion_type: undefined,
    },
    localSelectedModel: '',
    setIsActivityActive: () => {},
    isActivityActive: false,
    showToast: false,
    setShowToast: () => {},
  };

  const {
    useAdvancedSettings,
    advancedSettings,
    userInput,
    setUserInput,
    setShowAdvancedSettingsAlert,
    showAdvancedSettingsAlert,
    setShowAdvancedSettings,
    setIsLoading,
    chatContents,
    setChatContents,
    localSelectedModel,
    showToast,
    setShowToast,
  } = userContext;

  useEffect(() => {
    getCorpusList(projectId, currentApp?.id);
  }, []);
  const handleSelectChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    getCorpusList(projectId, currentApp?.id);
    setIsUploadDomainMsg(false);
    setEditCorpusSuccessMsg(false);
    chatClearCallback();
    const selectedCorpus = event.target.value;
    setselectedCorpus(selectedCorpus);
    setQueries([]);
    setFeedBackProporties([]);
    setSelectedImageNames([]);
    setSelectedImagebackUp([]);
    setinstanceButtons(false);

    const params: Record<string, string | number> = {
      corpus_name: selectedCorpus,
    };

    if (projectId) {
      params.project_id = Number(projectId);
    }
    if (currentApp?.id) {
      params.application_id = Number(currentApp.id);
    }
    const queryparam = new URLSearchParams(
      params as Record<string, string>
    ).toString();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/video-qa/get-all-videos?${queryparam}`
      );
      if (response.status === 200 || response.status === 201) {
        const responsebody = response.data;
        if (responsebody.data && responsebody.status === 'success') {
          let response_links: string[] = [];
          setinstanceVideoLinks(response.data.data.video_links);
          const entries = Object.entries(response.data.data.video_links);
          for (const [key, value] of entries) {
            response_links.push(`${value}`);
          }
          setSelectedImages(response_links);
          setIsSelect(true);
        }
      }
    } catch (error) {
      console.error(error); // Handle errors
    }
  };

  //To Ask Query
  const submitPrompt = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsLoading(true);
    setShowToast(false);
    handlePromptSubmit();
  };

  const handleCheckboxChange = (imageName: any, index: number) => {
    setSelectedImageNames((prevSelectedImageNames) => {
      if (prevSelectedImageNames.includes(imageName)) {
        return prevSelectedImageNames.filter((name) => name !== imageName);
      } else {
        return [...prevSelectedImageNames, imageName];
      }
    });
  };

  //To ask query
  const handlePromptSubmit = async () => {
    queries.push({ role: 'user', content: userInput });
    setIsLoading(true);
    const size = queries.length;
    setShowToast(false);
    feedbackproperties.push({
      projectId: projectId,
      applicationId: currentApp?.id,
      corpusname: selectedCorpus,
      query: queries[size - 1].content,
    });
    const payloadData = {
      query: queries,
      project_id: projectId,
      application_id: currentApp?.id,
      corpus_name: selectedCorpus,
      selected_videos: selectedImageKeys,
    };
    if (
      useAdvancedSettings &&
      advancedSettings.mode !== NORMAL &&
      advancedSettings.query_param === ''
    ) {
      setShowAdvancedSettingsAlert(true);
    } else {
      setChatContents([
        ...chatContents,
        { role: 'user', content: userInput } as Chat,
        { role: 'assistant', content: '' } as Chat,
      ]);
      setUserInput('');
      setOutputData('');
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/video-qa/query`,
          payloadData,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        if (response.status === 200 || response.status === 201) {
          if (response.data.data && response.data.status === 'success') {
            setIsLoading(false);
            queries.push({
              role: 'assistant',
              content: response.data.data.response,
            });
            setOutputData((prev) => {
              setChatContents((oldChatContents) =>
                oldChatContents.map((chatContent, index, array) => {
                  if (index + 1 === array.length) {
                    return {
                      ...chatContent,
                      content: `${prev}${response?.data?.data?.response}`,
                      images: response?.data?.data?.images,
                      feedback: response?.data?.data?.feedback,
                    };
                  } else {
                    return chatContent;
                  }
                })
              );
              return `${prev}${response?.data?.data?.response}`;
            });
          } else {
            setIsLoading(false);
            setShowToast(true);
            console.error(`Error submitting file`);
          }
        }
      } catch (error) {
        console.error(error); // Handle errors
        setIsLoading(false);
        setShowToast(true);
        if (error) {
          setShowErrMsg('Please Try Again');
        } else {
          console.error('Error message not available');
        }
      }
    }
  };
  interface Chat {
    role: string;
    content: string;
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | React.MouseEvent) => {
      if (
        overlayContainerRef.current &&
        !overlayContainerRef.current.contains(event.target as Node)
      ) {
        setShowAdvancedSettings(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const chatClearCallback = () => {
    setShowToast(false);
    setinstanceButtons(true);
    setChatContents([]);
    setSelectedImages([]);
    setselectedCorpus(' ');
  };

  const openNewInstanceModal = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    setIsEditMode(false);
    setIsSelect(false);
    setShowNewDomainModal(true);
    setIsEditMode(false);
    setShowToast(false);
    setDomainName('');
    setSelectedFiles([]);
    setUploadedFiles([]);
    setIsEditMode(false);
    setSelectedImagebackUp(selectedImages);
    setSelectedImages([]);
  };
  const handleDomainNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDomainName(event.target.value);
  };
  interface FileData {
    name: string;
    size: number;
    type: string;
  }

  const handleFileInputChange = async (e: any) => {
    // setIsUploadLoading(true);
    // setIsUploadResponse(false);
    setOutputData('');
    const files = e.target.files;
    setUploadedFiles(Array.from(files));
    if (!files) return;

    for (const file of files) {
      const reader = new FileReader();

      reader.onload = () => {
        const imageDataUrl = reader.result as string;
        setSelectedImages((prevImages) => [...prevImages, imageDataUrl]);
      };

      reader.onerror = (error) => {
        console.error(`Error reading file: ${error}`);
      };

      reader.readAsDataURL(file);
    }

    const newFiles: any = Array.from(e.target.files);
    file.push(newFiles);
    setSelectedFiles((prevFiles) => {
      const newFiles: FileData[] = [];
      // Concatenate previous files with newly selected files
      for (let i = 0; i < prevFiles.length; i++) {
        newFiles.push(prevFiles[i]);
      }
      for (let i = 0; i < files.length; i++) {
        newFiles.push(files[i]);
      }
      return newFiles;
    });

    const formData = new FormData();
    newFiles.forEach((file: any) => {
      formData.append('files', file);
    });
  };
  const UploadVideos = async () => {
    setIsUploadingVideo(true);
    const formData = new FormData();
    selectedFiles.forEach((file: any) => {
      formData.append('files', file);
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/video-qa/upload-videos`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        // setIsUploadLoading(false);
        // setIsUploadResponse(true);
        // imageUploaadResponse.push(...response.data);
        // setImageString(response.data);
        // setUploadedFiles([]);
        setUploadTaskId(response.data.taskid);
        // const params: Record<string, string | number> = {
        //   task_id: response.data.taskid,
        // };

        // const queryparam = new URLSearchParams(
        //   params as Record<string, string>
        // ).toString();
        fileUploadCheck(0, response.data.taskid);
      } else {
        setShowToast(true);
      }
      // setIsUploadLoading(false);
    } catch (error) {
      console.error(error);
      // setIsUploadLoading(false);
      // setIsUploadResponse(false);
    }
  };
  const fileUploadCheck = async (retry: number, taskid: string) => {
    const params: Record<string, string | number> = {
      task_id: taskid,
    };

    const queryparam = new URLSearchParams(
      params as Record<string, string>
    ).toString();
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/video-qa/check-upload-status?${queryparam}`,

      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    if (response.data.ready === true && response.data.successful === true) {
      setUploadCheckLinks(response.data.value.links);
      setIsUploaded(true);
      setIsUploadingVideo(false);
    } else {
      if (
        retry < 20 &&
        response.data.ready !== true &&
        response.data.successful !== true
      ) {
        fileUploadCheck(retry + 1, taskid);
        // setTimeout(() => {
        //   fileUploadCheck(retry + 1, taskid)
        // }, 1000);
      }
    }
  };
  const handleRemoveFile = async (fileName: string, index: number) => {
    setShowDeleteWarning(true);
    const entries = Object.entries(instanceVideoLinks);
    for (const [key, value] of entries) {
      if (value === fileName) {
        setRemoveFileKey(key);
      }
    }
    setRemoveFileName(fileName);
    setRemoveFileIndex(index);
  };
  //To delete image from corpus
  const deleteimage = async (fileName: string, index: number) => {
    // const removedFile = selectedImages[index];
    setIsDeleteLoader(true);
    const updatedImages = selectedImages.filter((_, i) => i !== index);
    const updatedFiles = uploadedFiles.filter((_, i) => i !== index);
    setSelectedImages(updatedImages);
    setUploadedFiles(updatedFiles);
    if (!fileName.includes('https')) {
      setShowDeleteWarning(false);
      setIsDeleteLoader(false);
    }
    if (fileName.includes('https')) {
      try {
        const params: Record<string, string | number> = {
          corpus_name: domainName,
        };

        if (projectId) {
          params.project_id = Number(projectId);
        }

        if (currentApp?.id) {
          params.application_id = Number(currentApp.id);
        }

        if (removeFileKey.length > 0) {
          params.selected_video = removeFileKey;
        }

        const formData = new FormData();
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/video-qa/delete-video`,
          formData,
          {
            params: params,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        if (response.status === 200 || response.status === 201) {
          if (response.data.status === 'success') {
            setShowDeleteWarning(false);
            setIsDeleteLoader(false);
            setIsUploaded(true);
          }
        }
      } catch (error) {
        setIsLoading(false);
        console.error(error); // Handle errors
      }
    }
  };

  //To maintain state
  // useEffect(() => {
  // }, [uploadedFiles, removeFileName, removeFileindex, selectedImageNames, selectedImages]);

  //To create new corpus
  const handlePostData = async () => {
    const formData = uploadCheckLinks;

    setIsDisabled(true);
    setIsFinishLoader(true);
    setShowToast(false);
    const params: Record<string, string | number> = {
      corpus_name: domainName,
    };
    if (projectId) {
      params.project_id = Number(projectId);
    }
    if (currentApp?.id) {
      params.application_id = Number(currentApp.id);
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/video-qa/create-corpus`,
        formData,
        {
          params: params,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        const createCorpusResponse = response.data;
        if (createCorpusResponse.status === 'success') {
          setIsCancel(false);
          setShowNewDomainModal(false);
          setIsUploadDomainMsg(true);
          getCorpusList(projectId, currentApp?.id);
        } else {
          if (createCorpusResponse.error.code === 429) {
            setImgUploadError(true);
          } else {
            setShowToast(true);
          }
        }
        setIsFinishLoader(false);
      } else {
        setIsFinishLoader(false);
        setShowToast(true);
        console.error(`Error submitting file`);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };
  const hideimguploaderror = () => {
    setImgUploadError(false);
  };

  //To get all corpus
  const getCorpusList = async (projectId: any, appId: any) => {
    const params: Record<string, string | number> = {};

    if (projectId) {
      params.project_id = Number(projectId);
    }
    if (currentApp?.id) {
      params.application_id = Number(currentApp.id);
    }
    const queryparam = new URLSearchParams(
      params as Record<string, string>
    ).toString();
    if (projectId && appId) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/video-qa/get-all-corpus?${queryparam}`
        );
        if (response.status === 200 || response.status === 201) {
          const responsebody = response.data;
          if (responsebody.data && responsebody.status === 'success') {
            setCorpusList(responsebody.data);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const editButton = () => {
    setIsEditMode(true);
    setShowNewDomainModal(true);
    setSelectedImagebackUp([...selectedImages]);
    setIsSelect(true);
    setIsCancel(true);
    setDomainName(selectedCorpus);
    setSelectedImageNames([]);
    selectcallback();
  };

  //To Update Corpus
  const handlePdfileInputChange = (e: any) => {
    const files = e.target.files;
    setUploadedFiles(Array.from(files));
    if (!files) return;
    setSelectedFiles((prevFiles) => {
      const newFiles: FileData[] = [];
      // Concatenate previous files with newly selected files
      for (let i = 0; i < prevFiles.length; i++) {
        newFiles.push(prevFiles[i]);
      }
      for (let i = 0; i < files.length; i++) {
        newFiles.push(files[i]);
      }
      return newFiles;
    });
  };
  const handleUpdateData = async () => {
    const formData = [...uploadCheckLinks];
    setIsDisabled(true);
    setIsFinishLoader(true);
    setShowToast(false);
    const params: Record<string, string | number> = {
      corpus_name: domainName,
    };
    if (projectId) {
      params.project_id = projectId;
    }
    if (currentApp?.id) {
      params.application_id = currentApp?.id;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/video-qa/update-corpus`,
        formData,
        {
          params: params,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        setUploadedFiles([]);
        setIsFinishLoader(false);
        if (response.data.status === 'success') {
          setShowNewDomainModal(false);
          setSelectedImagebackUp([...selectedImages]);
          getCorpusList(projectId, currentApp?.id);
          setselectedCorpus('');
          setSelectedImages([]);
          setEditFinishButton(true);
          setEditCorpusSuccessMsg(true);
        }
        setIsCancel(false);
      }
    } catch (error) {
      setIsFinishLoader(false);
      setShowToast(true);
      console.error(error); // Handle errors
    }
  };
  const selectcallback = () => {
    if (!editButton) {
      setIsSelect(true);
    }
  };
  const deleteIcon = () => {
    setShowDeleteCorpusPopup(true);
  };
  const canceldeleteIcon = () => {
    setShowDeleteCorpusPopup(false);
  };

  //To delete corpus
  const deleteInstance = async () => {
    const params: Record<string, string | number> = {
      corpus_name: selectedCorpus,
    };
    if (projectId) {
      params.project_id = Number(projectId);
    }
    if (currentApp?.id) {
      params.application_id = Number(currentApp.id);
    }
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/video-qa/delete-corpus`,
        {
          params: params,
        }
      );
      if (response.status === 200 || response.status === 201) {
        if (response.data.status === 'success') setinstanceButtons(true);
        setShowDeleteCorpusPopup(false);
        chatClearCallback();
        getCorpusList(projectId, currentApp?.id);
        setIsEditMode(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const removedeleteWarning = () => {
    setShowDeleteWarning(false);
  };
  useEffect(() => {
    if (selectedImageNames.length === 0) {
      setSelectedImageKeys([]);
    }
    const entries = Object.entries(instanceVideoLinks);
    for (const [key, value] of entries) {
      if (value === selectedImageNames[selectedImageNames.length - 1]) {
        selectedImageKeys.push(key);
      }
    }
  }, [selectedImageNames]);
  return (
    <>
      <div className="application-page-container">
        <div className="title">{currentApp?.name}</div>
        <div className="back-button-container">
          <Link to={`/project/${projectId}`}>
            <BackIcon /> Back
          </Link>
          {/* <div ref={overlayContainerRef}>
            <AdvancedSettings
              chatbox={chatbox}
              overlayContainerRef={overlayContainerRef}
            />
          </div> */}
        </div>
        <div className="single-app-container">
          <Form.Group
            className="user-input"
            controlId="exampleForm.instancePicker"
          >
            <Form.Label>Select a Instance</Form.Label>
            <div className="icon-dropdown-wrapper">
              <Form.Select
                id="mySelect"
                aria-label="Select an instance"
                className="instance-picker"
                value={selectedCorpus}
                onChange={handleSelectChange}
                // onClick={getCorpusList(projectId,currentApp?.id)}
              >
                <option value="">Select a Instance</option>
                {corpusList.map((corpus, index) => (
                  <option key={index} value={corpus}>
                    {corpus}
                  </option>
                ))}
                ;
              </Form.Select>
              <div className="edit-div">
                {isEditEnabled && (
                  // <div className='edit-div'>
                  <Button
                    className="icon-edit-button"
                    onClick={editButton}
                    disabled={!selectedCorpus || instanceButtons}
                  >
                    <FaEdit className="icon-edit" />
                  </Button>
                )}
                {isDeleteEnabled && (
                  <Button
                    className="icon-edit-button"
                    onClick={deleteIcon}
                    disabled={!selectedCorpus || instanceButtons}
                  >
                    <FaTrash className="icon-delete" />
                  </Button>
                )}
              </div>
            </div>
            <Form.Label className="create-new-instance-link">
              <a href="javascript:void(0);" onClick={openNewInstanceModal}>
                Create New Instance
              </a>
            </Form.Label>
            <VideoViewer
              selectedImages={selectedFiles}
              onRemoveImage={handleRemoveFile}
              isSelect={isSelect}
              displayImages={selectedImages}
              onSelectImage={handleCheckboxChange}
              selectedImageNames={selectedImageNames}
              isCancel={isCancel}
            />
          </Form.Group>
          {showDeleteCorpusPopup && (
            <div className="clear-chat-warning-container">
              <Modal
                show={showDeleteCorpusPopup}
                onHide={canceldeleteIcon}
                backdrop="static"
                keyboard={true}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Delete Instance</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="delete-warning">
                    <p>
                      This Action will delete the selected instance. Are you
                      sure you want to delete it?
                    </p>
                  </div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="danger" onClick={deleteInstance}>
                    Delete
                  </Button>
                  <Button variant="secondary" onClick={canceldeleteIcon}>
                    Cancel
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          )}

          {showNewDomainModal && (
            <Modal
              show={showNewDomainModal}
              onHide={() => {
                setShowNewDomainModal(false);
                setDomainName('');
                setIsSelect(true);
                setIsFinishLoader(false);
                setSelectedFiles([]);
                if (isEditMode && isUploaded === false) {
                  setselectedCorpus('');
                }
                setSelectedImages(selectedImageBackup);
                if (!isEditMode) {
                  setSelectedImages(selectedImageBackup);
                }
                setIsDeleteLoader(false);

                setSelectedImageNames([]);
                setUploadedFiles([]);
                setShowToast(false);
                setIsUploadDomainMsg(false);
                setEditFinishButton(true);
                setIsCancel(false);
              }}
            >
              <Modal.Header closeButton>
                {isEditMode ? 'Edit Instance' : 'Create New Instance'}
              </Modal.Header>
              <Modal.Body>
                <>
                  <div className="basic-details-container inline-display">
                    <div className="basic-details-wrapper">
                      <Form.Group
                        className="file-input"
                        controlId="exampleForm.fileInput"
                      >
                        <Form.Label className="label-text">
                          Upload Videos
                        </Form.Label>
                        <Form.Control
                          type="file"
                          name="datafile"
                          accept=".mp4,.mov"
                          multiple
                          onChange={handleFileInputChange}
                          ref={fileInputRef}
                        />
                        {!isEditMode && (
                          <>
                            <Form.Label className="label-text margin-top">
                              Upload Pdf
                            </Form.Label>
                            <Form.Control
                              type="file"
                              name="datafile"
                              accept=".pdf"
                              multiple
                              onChange={(e) => handlePdfileInputChange(e)}
                              ref={fileInputRef}
                            />
                          </>
                        )}
                        {isUploadingVideo ? (
                          <Button
                            variant="primary"
                            className="margin-top user-story-generator-submit is-loading-btn"
                          >
                            <ContentLoader />
                          </Button>
                        ) : (
                          <Button
                            className="margin-top"
                            variant="primary"
                            onClick={UploadVideos}
                            disabled={selectedFiles.length === 0}
                          >
                            Upload
                          </Button>
                        )}
                      </Form.Group>
                      <Form.Label className="label-text margin-top">
                        Instance Name
                      </Form.Label>
                      <Form.Control
                        as="input"
                        type="text"
                        value={domainName}
                        onChange={handleDomainNameChange}
                        placeholder="Enter Instance Name"
                        className="text-input"
                        disabled={isEditMode}
                      />
                    </div>
                    {selectedImages.length > 0 && (
                      <div className="inline-display display-layout">
                        <VideoViewer
                          selectedImages={selectedFiles}
                          onRemoveImage={handleRemoveFile}
                          isSelect={false}
                          displayImages={selectedImages}
                          onSelectImage={handleCheckboxChange}
                          selectedImageNames={selectedImageNames}
                          isCancel={true}
                        />
                      </div>
                    )}
                  </div>
                  <div className="finish-button">
                    {isFinishLoader ? (
                      <Button
                        className="user-story-generator-submit is-loading-btn"
                        variant="success"
                        disabled={true}
                      >
                        <ContentLoader></ContentLoader>
                      </Button>
                    ) : isEditMode ? (
                      <Button
                        variant="success"
                        disabled={!isUploaded}
                        onClick={handleUpdateData}
                      >
                        Finish
                      </Button>
                    ) : (
                      <Button
                        variant="success"
                        disabled={
                          !domainName ||
                          selectedImages.length === 0 ||
                          !isUploaded
                        }
                        onClick={handlePostData}
                      >
                        Finish
                      </Button>
                    )}
                  </div>
                </>
              </Modal.Body>
              {imgUploadError && (
                <div>
                  <Modal
                    show={imgUploadError}
                    onHide={hideimguploaderror}
                    backdrop="static"
                    keyboard={true}
                  >
                    <Modal.Header className="img-upload-error" closeButton>
                      <Modal.Title className="img-upload-error-title">
                        <h3>Oh snap! You got an error!</h3>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="img-upload-error">
                      <div>
                        <h5>
                          Image upload count exceeded.Can only upload upto 10
                          images!!
                        </h5>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              )}
              {showDeleteWarning && (
                <div className="clear-chat-warning-container">
                  <Modal
                    show={showDeleteWarning}
                    onHide={removedeleteWarning}
                    backdrop="static"
                    keyboard={true}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Clear Image?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {isEditMode ? (
                        <div className="delete-warning">
                          <p>
                            This Action will remove video from selected
                            instance. Are you sure you want to remove video?
                          </p>
                        </div>
                      ) : (
                        <div className="delete-warning">
                          <p>
                            This Action will remove video. Are you sure you want
                            to remove video?
                          </p>
                        </div>
                      )}
                    </Modal.Body>

                    <Modal.Footer>
                      {isDeleteLoader ? (
                        <Button
                          className="user-story-generator-submit is-loading-btn"
                          variant="danger"
                          disabled={true}
                        >
                          <ContentLoader></ContentLoader>
                        </Button>
                      ) : (
                        <Button
                          variant="danger"
                          onClick={() =>
                            deleteimage(removeFileName, removeFileindex)
                          }
                        >
                          Delete
                        </Button>
                      )}
                      <Button variant="secondary" onClick={removedeleteWarning}>
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              )}
              {showToast && (
                <div className="show-toast">
                  <Alert
                    variant="danger"
                    onClose={() => setShowToast(false)}
                    dismissible
                  >
                    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                    <p>
                      {showErrMsg
                        ? showErrMsg
                        : `Something went wrong. Please try again`}
                    </p>
                  </Alert>
                </div>
              )}
            </Modal>
          )}
          <hr style={{ color: 'gray' }}></hr>
          {editCorpusSuccessMsg && (
            <div className="upload-msg">
              <Badge bg="success">
                Instance Updated Successfully - The instance is currently being
                provisioned. Proceed with Q&A session once it's up.
              </Badge>
            </div>
          )}
          {isUploadDomainmsg && (
            <div className="upload-msg">
              <Badge bg="success">
                Instance Created Successfully - The instance is currently being
                provisioned. Proceed with Q&A session once it's up.
              </Badge>
            </div>
          )}

          <Chatbox
            submitPrompt={submitPrompt}
            chatClearCallback={chatClearCallback}
            ref={chatbox}
            selectedModel={localSelectedModel}
            isMultiplePrompts={true}
            feedBackProperties={feedbackproperties.slice(-1)}
          />

          {showToast && (
            <div style={{ paddingTop: '80px' }}>
              <Alert
                variant="danger"
                onClose={() => setShowToast(false)}
                dismissible
              >
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <p>
                  {showErrMsg
                    ? showErrMsg
                    : `Something went wrong. Please try again`}
                </p>
              </Alert>
            </div>
          )}
          {showAdvancedSettingsAlert && (
            <Alert
              variant="danger"
              onClose={() => setShowAdvancedSettingsAlert(false)}
              dismissible
            >
              <Alert.Heading>Advanced Settings</Alert.Heading>
              <p>{ADVANCED_ERROR_MESSAGE}</p>
            </Alert>
          )}
        </div>
      </div>
    </>
  );
};

export default VisionVideoAnalytics;
