/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, Overlay, Popover } from 'react-bootstrap';
import { FiSettings } from 'react-icons/fi';
import { AdvanceSettingProps } from '../../types';
import UserContext, { UserContextType } from '../../context/UserContext';
import './AdvancedSettings.css';
import {
  CASE_COPILOT_GENERATOR,
  NORMAL,
  PGVECTOR,
  SUPERADMIN,
  VISION_QA_ENGINE,
} from '../../utils/constants';
import { useParams } from 'react-router-dom';
import { Appl } from '../../interfaces';

const AdvancedSettings: React.FC<AdvanceSettingProps> = ({
  chatbox,
  overlayContainerRef,
  vectorDb,
}) => {
  const [overlayTarget, setOverlayTarget] = useState<HTMLElement | null>(null);
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const { appUrl } = useParams();
  const apps = JSON.parse(sessionStorage.getItem('apps') as string) as Appl[];
  const currentApp = apps.find((app) => app.url === appUrl);
  const userRole = JSON.parse(sessionStorage.getItem('role') || '{}');

  const userContext = useContext(UserContext) || {
    useAdvancedSettings: false,
    setUseAdvancedSettings: () => {},
    chatContents: [],
    showAdvancedSettingsAlert: false,
    setShowAdvancedSettingsAlert: () => {},
    showAdvancedSettings: false,
    setShowAdvancedSettings: () => {},
    setAdvancedSettings: () => {},
    advancedSettings: {
      mode: 'autocut',
      autocut: 1,
      query_param: undefined,
      fusion_type: undefined,
      enableChatRegeneration: false,
      debugMode: false,
    },
    llmCloud: '',
    selectedInstance: '',
    enableChatRegeneration: false,
    setEnableChatRegeneration: () => {},
    debugMode: false,
    setDebugMode: () => {},
  };

  const {
    useAdvancedSettings,
    setUseAdvancedSettings,
    setAdvancedSettings,
    advancedSettings,
    chatContents,
    setShowAdvancedSettingsAlert,
    showAdvancedSettings,
    setShowAdvancedSettings,
    llmCloud,
    selectedInstance,
    enableChatRegeneration,
    setEnableChatRegeneration,
    debugMode,
    setDebugMode,
  } = userContext as UserContextType;

  useEffect(() => {
    if (typeof advancedSettings.autocut === 'string') {
      setAdvancedSettings({
        ...advancedSettings,
        autocut: parseInt(advancedSettings.autocut) as 1 | 2 | 3,
      });
    }
    localStorage.setItem('advancedSettings', JSON.stringify(advancedSettings));
    setShowAdvancedSettingsAlert(false);
  }, [advancedSettings]);

  useEffect(() => {
    localStorage.setItem(
      'useAdvancedSettings',
      JSON.stringify(useAdvancedSettings)
    );
  }, [useAdvancedSettings]);

  const toggleAdvancedSettings = (event: any) => {
    setShowAdvancedSettings(!showAdvancedSettings);
    setOverlayTarget(event?.currentTarget as HTMLElement);
  };

  const updateAdvancedSettings = (event: any) => {
    let currentAdvancedSettings = { ...advancedSettings };
    if (event?.target?.id === 'mode' && event?.target?.value === 'autocut') {
      currentAdvancedSettings = {
        ...currentAdvancedSettings,
        query_param: undefined,
        fusion_type: undefined,
      };
    } else {
      if (event?.target?.id === 'mode' && event?.target?.value === 'hybrid') {
        currentAdvancedSettings = {
          ...currentAdvancedSettings,
          query_param: currentAdvancedSettings.query_param ?? '',
          fusion_type:
            currentAdvancedSettings.fusion_type ?? 'relativeScoreFusion',
        };
      }
    }
    setAdvancedSettings({
      ...currentAdvancedSettings,
      [event?.target?.id]: event?.target?.value,
    });
  };

  const handleSettingChange = (event: any) => {
    setUseAdvancedSettings(event.target.checked);
    setEnableChatRegeneration(false);
    setDebugMode(false);
    setAdvancedSettings((prev) => ({
      ...prev,
      enableChatRegeneration: false,
      debugMode: false,
    }));
    if (chatContents.length !== 0) {
      chatbox?.current?.initiateChatWarningModal({
        callback: handleSettingChange,
        prop: event,
      });
    }
  };

  const handleEnableChatRegeneration = (event: any) => {
    const newValue = event.target.checked;
    setEnableChatRegeneration(newValue);
    setAdvancedSettings((prev) => ({
      ...prev,
      enableChatRegeneration: newValue,
    }));
  };

  const handleDebugMode = (event: any) => {
    const newValue = event.target.checked;
    setDebugMode(newValue);
    setAdvancedSettings((prev) => ({
      ...prev,
      debugMode: newValue,
    }));
  };

  return (
    <>
      <Button
        className={`advanced-settins-submit ${useAdvancedSettings ? 'enabled-btn' : 'disabled-btn'}`}
        type="submit"
        onClick={toggleAdvancedSettings}
      >
        <FiSettings />
        {selectedInstance && (
          <span
            style={{
              borderLeft: '1px solid #FFF',
              padding: '0 0 0 1rem',
              fontWeight: 'lighter',
              marginLeft: '0.6rem',
            }}
          >
            {llmCloud === 'gcp' ? 'GCP' : llmCloud === 'aws' ? 'AWS' : 'Azure'}
          </span>
        )}
      </Button>
      <Overlay
        show={showAdvancedSettings}
        target={overlayTarget}
        placement="bottom"
        container={overlayContainerRef}
        containerPadding={20}
        ref={overlayRef}
      >
        <Popover id="popover-contained" style={{ zIndex: 1 }}>
          <Popover.Header>
            <Form.Check
              type="switch"
              id="advanced-settings-switch"
              label=" Advanced Settings"
              defaultChecked={useAdvancedSettings}
              onChange={handleSettingChange}
              checked={useAdvancedSettings}
            />
          </Popover.Header>
          <Popover.Body>
            <Form.Group className="user-input" controlId="overlayForm.mode">
              <Form.Label>Mode</Form.Label>
              <Form.Select
                aria-label="Choose Mode"
                id="mode"
                className="mode-picker"
                value={advancedSettings.mode}
                onChange={updateAdvancedSettings}
                disabled={!useAdvancedSettings || vectorDb === PGVECTOR}
              >
                <option value={NORMAL}>{NORMAL}</option>
                <option value="autocut">Autocut</option>
                {llmCloud !== 'aws' && <option value="hybrid">Hybrid</option>}
              </Form.Select>
            </Form.Group>
            {advancedSettings.mode !== NORMAL && (
              <Form.Group
                className="user-input"
                controlId="overlayForm.autocutThreshold"
              >
                <Form.Label>
                  Autocut Threshold: {advancedSettings.autocut}
                </Form.Label>
                <Form.Range
                  value={advancedSettings.autocut}
                  id="autocut"
                  min={1}
                  max={3}
                  step={1}
                  className="custom-slider"
                  onChange={updateAdvancedSettings}
                  disabled={!useAdvancedSettings || vectorDb === PGVECTOR}
                />
              </Form.Group>
            )}
            {advancedSettings.mode === 'hybrid' && (
              <>
                <Form.Group
                  className="user-input"
                  controlId="overlayForm.queryParam"
                >
                  <Form.Control
                    as="input"
                    id="query_param"
                    placeholder="Query Parameter"
                    className="user-query-parameter"
                    value={advancedSettings.query_param}
                    onChange={updateAdvancedSettings}
                    disabled={!useAdvancedSettings || vectorDb === PGVECTOR}
                  />
                </Form.Group>
                <Form.Group
                  className="user-input"
                  controlId="overlayForm.fusionType"
                >
                  <Form.Label>Fusion Type</Form.Label>
                  <Form.Select
                    aria-label="Choose Fusion Type"
                    id="fusion_type"
                    className="fusion-type-picker"
                    value={advancedSettings.fusion_type}
                    onChange={updateAdvancedSettings}
                    disabled={!useAdvancedSettings || vectorDb === PGVECTOR}
                  >
                    <option value="relativeScoreFusion">
                      Relative Score Fusion
                    </option>
                    <option value="rankedFusion">Ranked Fusion</option>
                  </Form.Select>
                </Form.Group>
              </>
            )}
            {currentApp?.name !== CASE_COPILOT_GENERATOR &&
              currentApp?.name !== VISION_QA_ENGINE && (
                <>
                  <hr />
                  <Form.Check
                    type="switch"
                    id="enable-chat-regeneration-switch"
                    label=" Response Re-generation"
                    defaultChecked={false}
                    checked={enableChatRegeneration}
                    onChange={handleEnableChatRegeneration}
                    disabled={!useAdvancedSettings}
                  />
                </>
              )}
            {userRole && userRole === SUPERADMIN && (
              <>
                <hr />
                <Form.Check
                  type="switch"
                  id="debug-mode-switch"
                  label="Debug Mode"
                  defaultChecked={false}
                  checked={debugMode}
                  onChange={handleDebugMode}
                  disabled={!useAdvancedSettings}
                />
              </>
            )}
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
};

export default AdvancedSettings;
