import React, { useState, useRef, ChangeEvent } from 'react';
import './AddCopilotActions.css';
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import axios from 'axios';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { SlArrowDownCircle, SlArrowUpCircle } from 'react-icons/sl';
import ConfirmationBox from '../../ConfirmationBox';
import { copilotFunctions, copilotSubActions } from '../../../interfaces';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  faUserCircle,
  faDownload,
  faUpload,
  faFolderPlus,
  faPlus,
  faPaperclip,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './AddCopilotActions.css';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import 'react-toastify/dist/ReactToastify.css';

interface AddCopilotActionProps {
  copilotInstanceId: string;
  btnText: string;
  sendMessageToParent: (message: string) => void;
  copilotActionId: string;
}

interface dataDic {
  ObjectName: string;
  ObjDescription: string;
  dataDic: any[];
}
interface Sheet {
  sheetName: string;
  sheetData: any[];
}

const AddCopilotActions: React.FC<AddCopilotActionProps> = (props) => {
  const [show, setShow] = React.useState(false);
  const [isImport, setIsImport] = React.useState(false);
  const [fileIndex, setFileIndex] = React.useState(Number);
  const [subActionIndex, setsubActionIndex] = React.useState(Number);
  const [copilotSubActionMetaDetaIndex, setcopilotSubActionMetaDetaIndex] =
    React.useState(Number);
  const [isBtnTag, setIsBtnTag] = React.useState(String);
  const [workbook, setWorkbook] = useState<XLSX.WorkBook | null>(null);
  const [copilotSubAction, setcopilotSubAction] = useState<copilotSubActions[]>(
    []
  );
  const [localDataDic, setLocalDataDic] = useState<dataDic[]>([]);
  const [actionName, setActionName] = React.useState('');
  const [actionDescription, setActionDescription] = React.useState('');
  const [activeFlag, setActiveFlag] = React.useState(false);
  const [mappedTo, SetMappedTo] = React.useState('');
  const [functionData, setFunctionData] = useState<copilotFunctions[]>([]);
  const [objFileContent, setObjFileContent] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
  const [isFileSelected, setIsFileSelected] = useState<boolean>(false);
  const [isSupportedFileType, setIsSupportedFileType] = useState(true);

  const type = ['Application', 'Service'];
  const sub_type = ['Conversations', 'Query Metadata'];

  const handleClose = () => {
    setcopilotSubAction([]);
    setActionName('');
    setActionDescription('');
    setActiveFlag(false);
    SetMappedTo('');
    props.sendMessageToParent('success');
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
    fetchFunctions(props.copilotInstanceId, props.copilotActionId);
    if (props.copilotActionId !== '')
      fetchActionsAndSubactions(props.copilotActionId);
    else pushSubAction();
  };

  const handleTypeChange = (index: any, selectedType: string) => {
    const updatedSubAction = [...copilotSubAction];
    updatedSubAction[index] = {
      ...updatedSubAction[index],
      type: selectedType,
    };
    setcopilotSubAction(updatedSubAction);
  };

  const handleInstanceIdChange = (index: any, newValue: string) => {
    const updatedSubAction = [...copilotSubAction];
    updatedSubAction[index] = {
      ...updatedSubAction[index],
      genaie_instance_id: newValue,
    };
    setcopilotSubAction(updatedSubAction);
  };

  const handleSystemPromptChange = (index: any, newValue: string) => {
    const updatedSubAction = [...copilotSubAction];
    updatedSubAction[index] = {
      ...updatedSubAction[index],
      system_prompt: newValue,
    };
    setcopilotSubAction(updatedSubAction);
  };

  const handleSubTypeChange = (index: any, selectedType: string) => {
    const updatedSubAction = [...copilotSubAction];
    updatedSubAction[index] = {
      ...updatedSubAction[index],
      sub_type: selectedType.trim(),
    };
    setcopilotSubAction(updatedSubAction);
  };

  const handleObjectNameChange = (
    index: any,
    objName: string,
    itemIndex: number
  ) => {
    const updatedSubAction = [...copilotSubAction];
    const localDataDic = updatedSubAction[index].objectMetaDeta;
    localDataDic[itemIndex] = {
      ...localDataDic[itemIndex],
      ObjectName: objName,
    };
    updatedSubAction[index] = {
      ...updatedSubAction[index],
      objectMetaDeta: localDataDic,
    };
    setcopilotSubAction(updatedSubAction);
  };

  const handleObjectDescriptionChange = (
    index: any,
    objDes: string,
    itemIndex: number
  ) => {
    const updatedSubAction = [...copilotSubAction];
    const localDataDic = updatedSubAction[index].objectMetaDeta;
    localDataDic[itemIndex] = {
      ...localDataDic[itemIndex],
      ObjDescription: objDes,
    };
    updatedSubAction[index] = {
      ...updatedSubAction[index],
      objectMetaDeta: localDataDic,
    };
    setcopilotSubAction(updatedSubAction);
  };

  const onchangeCopilotAction = (event: any) => {
    if (event.target.name === 'actionName') {
      setActionName(event.target.value);
    } else if (event.target.name === 'actionDescription') {
      setActionDescription(event.target.value);
    } else if (event.target.name === 'active') {
      setActiveFlag(event.target.checked);
    } else if (event.target.name === 'mappedTo') {
      SetMappedTo(event.target.value);
    }
  };

  const pushSubAction = () => {
    setSelectedFileName(null);
    setIsFileSelected(false);
    const newSubActionobj = {
      id: '',
      type: '',
      genaie_application_name: '',
      genaie_instance_id: '',
      genaie_service_name: '',
      system_prompt: '',
      sub_type: '',
      objectMetaDeta: [],
    };
    setcopilotSubAction((prevCopilotSubAction: any) => [
      ...prevCopilotSubAction,
      newSubActionobj,
    ]);
  };

  //fetching actions & subaction using action id
  const fetchActionsAndSubactions = async (param: string) => {
    try {
      const copilot_params = {
        copilot_action_id: param,
      };
      setCurrentCopilotActionId(param);
      const response = await axios.post(
        `${process.env.REACT_APP_COPILOT_BACKEND_DOMAIN}/api/actions/getActionsAndSubAction`,
        copilot_params
      );
      const data = response.data;
      setcopilotSubAction(data.data[0].subactions);
      setActionName(data.data[0].copilot_action_name);
      setActionDescription(data.data[0].copilot_action_description);
      setActiveFlag(data.data[0].copilot_action_active);
      SetMappedTo(data.data[0].copilot_function_id);
    } catch (error) {
      // Handle errors
      console.error('Error:', error);
    }
  };

  const [showSubActionConfirmation, setShowSubActionConfirmation] =
    useState(false);

  const [itemSubActionId, setSubActionId] = useState<string | null>(null);
  const [isToggled, setIsToggled] = useState(false);

  const handleToggleChange = () => {
    setIsToggled(!isToggled);
  };

  const handleCancelDelete = () => {
    setShowSubActionConfirmation(false);
  };

  const handleDeleteConfirmation = (e: any, itemSubActionId: string) => {
    e.preventDefault();
    setSubActionId(itemSubActionId);
    setShowSubActionConfirmation(true);
  };

  const removeSubAction = async (copilot_action_id: string, id: string) => {
    try {
      // Check if it's the first subaction, don't delete
      if (copilotSubAction.length > 1) {
        const delete_params = {
          data: {
            copilot_action_id,
            id,
          },
        };

        const response = await axios.delete(
          `${process.env.REACT_APP_COPILOT_BACKEND_DOMAIN}/api/subActions/deleteSubAction`,
          delete_params
        );
        if (response.status !== 200) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        fetchActionsAndSubactions(copilot_action_id);
      } else {
        // Display a toast message
        toast.error('Cannot delete the last subaction', {
          onClick: () => toast.dismiss(),
          autoClose: 3000,
        });
        console.error('Cannot delete the last subaction');
      }
    } catch (error) {
      console.error('Error deleting sub-action:', error);
      throw error; // rethrow the error to propagate it to the caller
    }
  };

  const [currentCopilotActionId, setCurrentCopilotActionId] = useState<
    string | null
  >(null);

  const handleDeleteSubAction = async () => {
    console.error(
      'itemSubActionId before calling removeSubAction:',
      itemSubActionId
    );

    try {
      // Use the current copilot_action_id from state
      const actionId = currentCopilotActionId || '';
      await removeSubAction(actionId, itemSubActionId!);
    } catch (error: any) {
      console.error('Error deleting sub-action:', error.message);
      // Display an error message to the user or handle it appropriately
    }

    handleCancelDelete();
  };

  const saveActions = async () => {
    try {
      if (!mappedTo) {
        toast.error('Function is a mandatory field', {
          onClick: () => toast.dismiss(),
          autoClose: 1000,
        });
        return;
      }
      if (isValidSubActions()) {
        const action_params = {
          name: actionName,
          description: actionDescription,
          active: activeFlag,
          mappedTo: mappedTo,
          copilot_instance_id: props.copilotInstanceId,
          subActions: copilotSubAction,
        };
        const response = await axios.post(
          `${process.env.REACT_APP_COPILOT_BACKEND_DOMAIN}/api/actions/createCopilotActions`,
          action_params
        );

        if (response.status !== 200) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = response.data;
        handleClose();
      }
    } catch (error) {
      // Handle errors
      console.error('Error:', error);
    }
  };

  const showErrorToast = (message: string) => {
    toast.error(message, {
      onClick: () => toast.dismiss(),
      autoClose: 5000,
    });
  };

  const isValidSubActions = (): boolean => {
    const errorMessages: string[] = [];

    const pushErrorMsg = (message: string) => {
      errorMessages.push(message);
    };

    let sequence = 0;
    copilotSubAction.forEach((subAction, index) => {
      sequence++;
      if (subAction.type === '' || subAction.type === 'Select Type') {
        pushErrorMsg('Type is a mandatory field for Sequence ' + sequence);
      } else if (
        subAction.type === 'Application' &&
        subAction.genaie_instance_id === ''
      ) {
        pushErrorMsg(
          'Instance id is a mandatory field for Sequence ' + sequence
        );
      } else if (subAction.type === 'Service') {
        if (
          subAction.sub_type === '' ||
          subAction.sub_type === 'Select SubType'
        ) {
          pushErrorMsg(
            'Sub Type is a mandatory field for Sequence ' + sequence
          );
        }
        if (
          subAction.sub_type === 'Conversations' &&
          subAction.system_prompt === ''
        ) {
          pushErrorMsg(
            'System Prompt is a mandatory field for Sequence ' + sequence
          );
        }
        if (
          subAction.sub_type === 'Query Metadata' &&
          (subAction.objectMetaDeta == null ||
            subAction.objectMetaDeta.length === 0)
        ) {
          pushErrorMsg(
            'Atleast one record is required in the data model defination for SubAction ' +
              sequence
          );
        }
      }
    });

    // Throw each error one by one
    errorMessages.forEach((message) => {
      showErrorToast(message);
    });

    // Return true if there are no errors, false otherwise
    return errorMessages.length === 0;
  };

  const importFile = async (
    flag: string,
    fileindex: number,
    metaDetaindex: number
  ) => {
    if (flag === 'upload') {
      setFileIndex(metaDetaindex); //index of subactionmetadeta
      setsubActionIndex(fileindex);
    } else {
      setcopilotSubActionMetaDetaIndex(fileindex);
    }

    setIsBtnTag(flag);
    setIsImport(true);
  };

  const closeImportModal = async () => {
    setIsImport(false);
    setIsFileSelected(false);
    setSelectedFileName(null);
  };

  const editAction = async () => {
    try {
      if (!mappedTo) {
        toast.error('Function is a mandatory field', {
          onClick: () => toast.dismiss(),
          autoClose: 1000,
        });
        return;
      }

      if (isValidSubActions()) {
        const action_params = {
          actionId: props.copilotActionId,
          name: actionName,
          description: actionDescription,
          active: activeFlag,
          mappedTo: mappedTo,
          copilot_instance_id: props.copilotInstanceId,
          subActions: copilotSubAction,
        };
        const response = await axios.post(
          `${process.env.REACT_APP_COPILOT_BACKEND_DOMAIN}/api/actions/updateCopilotAction`,
          action_params
        );

        if (response.status !== 200) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = response.data;
        handleClose();
      }
    } catch (error) {
      // Handle errors
      console.error('Error:', error);
    }
  };

  //fetching functions from DB
  const fetchFunctions = async (param: string, action_id: string) => {
    try {
      const copilot_params = {
        copilot_instance_id: param,
        copilot_action_id: action_id,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_COPILOT_BACKEND_DOMAIN}/api/functions/getAllUnassignedFunctions`,
        copilot_params
      );

      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = response.data;
      setFunctionData(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const uploadFile = (event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    const allowedFileTypes = ['text/plain'];
    if (event.target.files && event.target.files.length > 0) {
      const fileInput = event.target;
      const file = fileInput.files && fileInput.files[0];

      if (file) {
        if (!allowedFileTypes.includes(file.type)) {
          toast.error('Unsupported file type');
          return;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
          const content = e.target?.result as string;
          setObjFileContent(content);
          setSelectedFileName(file.name);
          setIsFileSelected(true);
        };
        reader.readAsText(file);
      }
    }
  };

  const handleUpload = async () => {
    if (!isFileSelected || !selectedFileName) {
      toast.error('Please select a file before uploading.', {
        autoClose: 3000,
      });
      return;
    }

    try {
      const updatedSubAction = [...copilotSubAction];
      const localDataDic = updatedSubAction[subActionIndex].objectMetaDeta;
      localDataDic[fileIndex] = {
        ...localDataDic[fileIndex],
        dataDic: JSON.parse(objFileContent || '[]'), // Parsing objFileContent to an array
      };
      updatedSubAction[subActionIndex] = {
        ...updatedSubAction[subActionIndex],
        objectMetaDeta: localDataDic,
      };
      setcopilotSubAction(updatedSubAction);

      toast.success('File imported successfully', {
        autoClose: 2000,
      });
      closeImportModal();
    } catch (error) {
      toast.error('Error importing file. Please try again later.', {
        autoClose: 3000,
      });
      console.error('Error importing file', error);
    }
  };

  const handleFileImport = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const maxSize = 5 * 1024 * 1024; // 5MB
    const allowedFileTypes = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];

    if (event.target.files && event.target.files.length > 0) {
      const fileInput = event.target;
      const file = fileInput.files && fileInput.files[0];

      if (file) {
        if (!allowedFileTypes.includes(file.type)) {
          toast.error('Unsupported file type');
          return;
        }

        if (file.size > maxSize) {
          toast.error('File size exceeds 5MB limit');
          return;
        }

        setSelectedFileName(file.name);
        setIsFileSelected(true);
        const reader = new FileReader();
        reader.onload = (e) => {
          const content = e.target?.result;

          const arrayBuffer = e.target?.result as ArrayBuffer;

          const data = new Uint8Array(arrayBuffer);
          const parsedWorkbook = XLSX.read(data, { type: 'array' });
          setWorkbook(parsedWorkbook);
        };

        reader.readAsArrayBuffer(file);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    } else {
      setSelectedFileName(null);
      setIsFileSelected(false); // No file selected
    }
  };
  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    fileInputRef.current?.click();
  };

  const handleImport = async () => {
    if (!isFileSelected || !selectedFileName || !workbook) {
      toast.error('Please select a file before importing.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    let data: any = [];
    workbook?.SheetNames.forEach((sheetName: string) => {
      const sheet = workbook.Sheets[sheetName];
      const sheetData: any[] = XLSX.utils.sheet_to_json(sheet) as any[];

      const sheetObj: Sheet = {
        sheetName,
        sheetData,
      };
      data.push(sheetObj);
    });
    let finalData: any = [];
    for (let metaData = 0; metaData < data[0].sheetData.length; metaData++) {
      if (data[metaData + 1] !== undefined) {
        const obj = {
          ObjectName: data[0].sheetData[metaData].ObjectName,
          ObjDescription: data[0].sheetData[metaData].ObjDescription,
          dataDic: data[metaData + 1].sheetData,
        };
        finalData.push(obj);
      } else {
        const obj = {
          ObjectName: data[0].sheetData[metaData].ObjectName,
          ObjDescription: data[0].sheetData[metaData].ObjDescription,
          dataDic: null,
        };
        finalData.push(obj);
      }
    }
    toast.success('File imported successfully', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    //  setLocalDataDic(finalData);

    const updatedSubAction = [...copilotSubAction];
    updatedSubAction[copilotSubActionMetaDetaIndex] = {
      ...updatedSubAction[copilotSubActionMetaDetaIndex],
      objectMetaDeta: finalData,
    };
    setcopilotSubAction(updatedSubAction);
    closeImportModal();
  };

  const pushDataDic = (index: number) => {
    const newDataDicObj: dataDic = {
      ObjectName: '',
      ObjDescription: '',
      dataDic: [],
    };
    const updatedSubAction = [...copilotSubAction];
    updatedSubAction[index].objectMetaDeta.unshift(newDataDicObj);
    setcopilotSubAction(updatedSubAction);
  };

  const handleDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
    // Specify the path to your Excel file within the public folder
    const filePath = '/Sample_data.xlsx';

    // Build the full URL
    const fileUrl = process.env.PUBLIC_URL + filePath; //process.env.PUBLIC_URL +

    // Trigger the download
    saveAs(fileUrl, 'data.xlsx');
    event.preventDefault();
  };

  const removeDataDic = (index: number, itemIndex: number) => {
    const updatedSubAction = [...copilotSubAction];
    const localDataDic = updatedSubAction[index].objectMetaDeta;
    const updatedDataDic = localDataDic.filter((_, i) => i !== itemIndex);
    updatedSubAction[index] = {
      ...updatedSubAction[index],
      objectMetaDeta: updatedDataDic,
    };
    setcopilotSubAction(updatedSubAction);
  };

  const DownloadMetaDetaFile = (index: number, itemIndex: number) => {
    const updatedSubAction = [...copilotSubAction];
    const localDataDic = updatedSubAction[index].objectMetaDeta;
    const metaData = localDataDic[itemIndex].dataDic;
    let fileName = localDataDic[itemIndex].ObjectName;
    // Convert JSON object to a string
    const jsonString = JSON.stringify(metaData, null, 2); // The third parameter specifies the number of spaces for indentation (optional)

    // Create a Blob with the JSON string
    const blob = new Blob([jsonString], { type: 'text/plain' });

    // Create a download link
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  return (
    <>
      <div>
        {props.btnText !== 'Edit' && (
          <Button className="btn btn-primary" onClick={handleShow}>
            {props.btnText}
          </Button>
        )}
        {props.btnText === 'Edit' && (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Edit Action</Tooltip>}
          >
            <Button className="edit-btn " onClick={handleShow}>
              <FaEdit></FaEdit>
            </Button>
          </OverlayTrigger>
        )}
        <ToastContainer />
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="custom-modal-header">
          <div className="modal-title-container">
            {props.btnText === 'Edit' ? (
              <Modal.Title>Edit Actions & SubActions</Modal.Title>
            ) : (
              <Modal.Title>Define Actions & SubActions</Modal.Title>
            )}
          </div>
          <div className="custom-close-button-container">
            <button className="custom-close-button" onClick={handleClose}>
              &times;
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="my-3">
            <form className="container light-grey-background">
              <Row>
                <Col xs={12} md={6}>
                  <div>
                    <strong>
                      Name{' '}
                      <OverlayTrigger
                        placement="top-end"
                        overlay={
                          <Tooltip id="tooltip-copilot-name">
                            Display the name of the Action
                          </Tooltip>
                        }
                      >
                        <span style={{ display: 'inline-block' }}>
                          <FaInfoCircle className="info-icon" />
                        </span>
                      </OverlayTrigger>
                    </strong>
                    <input
                      type="text"
                      className="form-control my-2 form-style"
                      name="actionName"
                      value={actionName}
                      onChange={onchangeCopilotAction}
                      required
                    />
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div>
                    <strong>
                      Function <span className="required-asterisk">*</span>
                      <OverlayTrigger
                        placement="top-end"
                        overlay={
                          <Tooltip id="tooltip-copilot-name">
                            Display the list of active Functions
                          </Tooltip>
                        }
                      >
                        <span style={{ display: 'inline-block' }}>
                          <FaInfoCircle className="info-icon" />
                        </span>
                      </OverlayTrigger>
                    </strong>
                    <Form.Select
                      aria-label="Select Function"
                      name="mappedTo"
                      value={mappedTo}
                      onChange={onchangeCopilotAction}
                      className="instance-picker my-2 form-style"
                    >
                      <option value="">Select Function</option>
                      {functionData.map((data) => (
                        <option key={data.id} value={data.id}>
                          {data.name}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </Col>
              </Row>
              <div className="md-3">
                <strong className="my-3">
                  Active{' '}
                  <OverlayTrigger
                    placement="top-end"
                    overlay={
                      <Tooltip id="tooltip-copilot-name">
                        Check to make it active
                      </Tooltip>
                    }
                  >
                    <span style={{ display: 'inline-block' }}>
                      <FaInfoCircle className="info-icon" />
                    </span>
                  </OverlayTrigger>
                </strong>
                <label className="switch">
                  <input
                    type="checkbox"
                    id="flexCheckChecked"
                    onChange={onchangeCopilotAction}
                    checked={activeFlag}
                    name="active"
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </form>
          </div>
          <div className="separator-line"></div>
          {copilotSubAction.map((item, index) => (
            <div key={index} className="greyBox">
              <form>
                <FontAwesomeIcon icon={faUserCircle} className="user-icon" />
                <div className="subActionText">
                  <strong> Sub-action {index + 1}</strong>
                </div>
                <br></br>
                <Row className="w-100">
                  <Col xs={4} className="pr-1">
                    <div>
                      <strong>
                        Sequence ID{' '}
                        <OverlayTrigger
                          placement="top-end"
                          overlay={
                            <Tooltip id="tooltip-copilot-name">
                              Sequence of the sub-action. This will be
                              auto-populated
                            </Tooltip>
                          }
                        >
                          <span style={{ display: 'inline-block' }}>
                            <FaInfoCircle className="info-icon" />
                          </span>
                        </OverlayTrigger>
                      </strong>
                      <input
                        type="text"
                        className="form-control my-2 form-style"
                        name="name"
                        value={index + 1}
                        readOnly
                      />
                    </div>
                  </Col>
                  <div className="delete-container">
                    {item.id && copilotSubAction.length > 1 && (
                      <button
                        className="deltBtn"
                        onClick={(e) =>
                          handleDeleteConfirmation(e, String(item.id))
                        }
                      >
                        <FaTrashAlt></FaTrashAlt>
                      </button>
                    )}
                    <ConfirmationBox
                      show={showSubActionConfirmation}
                      message="Are you sure you want to delete this sub-action?"
                      onConfirm={handleDeleteSubAction}
                      onHide={handleCancelDelete}
                    />
                  </div>
                  <Col xs={4} className="pr-1">
                    <div>
                      <strong>
                        Type <span className="required-asterisk">*</span>
                        <OverlayTrigger
                          placement="top-end"
                          overlay={
                            <Tooltip id="tooltip-copilot-name">
                              Select the type of the sub-action
                            </Tooltip>
                          }
                        >
                          <span style={{ display: 'inline-block' }}>
                            <FaInfoCircle className="info-icon" />
                          </span>
                        </OverlayTrigger>
                      </strong>
                      <Form.Select
                        aria-label="Select Type"
                        className="instance-picker my-2 form-style"
                        value={item.type}
                        onChange={(e) =>
                          handleTypeChange(index, e.target.value)
                        }
                      >
                        <option>Select Type</option>
                        {type.map((data) => (
                          <option key={index} value={data}>
                            {data}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </Col>
                  <Col xs={4} className="pr-1">
                    {item.type === 'Application' && (
                      <div>
                        <strong>
                          Instance ID{' '}
                          <span className="required-asterisk">*</span>
                          <OverlayTrigger
                            placement="top-end"
                            overlay={
                              <Tooltip id="tooltip-copilot-name">
                                Instance ID for mapped action
                              </Tooltip>
                            }
                          >
                            <span style={{ display: 'inline-block' }}>
                              <FaInfoCircle className="info-icon" />
                            </span>
                          </OverlayTrigger>
                        </strong>
                        <input
                          type="text"
                          className="form-control my-2"
                          name="name"
                          value={item.genaie_instance_id}
                          onChange={(e) =>
                            handleInstanceIdChange(index, e.target.value)
                          }
                        />
                      </div>
                    )}
                    {item.type === 'Service' && (
                      <div>
                        <strong>
                          Sub Type <span className="required-asterisk">*</span>
                          <OverlayTrigger
                            placement="top-end"
                            overlay={
                              <Tooltip id="tooltip-copilot-name">
                                Tooltip for SubType
                              </Tooltip>
                            }
                          >
                            <span style={{ display: 'inline-block' }}>
                              <FaInfoCircle className="info-icon" />
                            </span>
                          </OverlayTrigger>
                        </strong>
                        <Form.Select
                          aria-label="Select Type"
                          className="instance-picker my-2 form-style"
                          value={item.sub_type}
                          onChange={(e) =>
                            handleSubTypeChange(index, e.target.value)
                          }
                        >
                          <option>Select SubType</option>
                          {sub_type.map((data) => (
                            <option key={index} value={data}>
                              {data}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    )}
                  </Col>
                  {item.type === 'Service' &&
                    item.sub_type === 'Conversations' && (
                      <div>
                        <strong>
                          System Prompt{' '}
                          <span className="required-asterisk">*</span>
                          <OverlayTrigger
                            placement="top-end"
                            overlay={
                              <Tooltip id="tooltip-copilot-name">
                                Define the system prompt which will be used by
                                GPT to have conversations with the user
                              </Tooltip>
                            }
                          >
                            <span style={{ display: 'inline-block' }}>
                              <FaInfoCircle className="info-icon" />
                            </span>
                          </OverlayTrigger>
                        </strong>
                        <textarea
                          name="systemprompt"
                          className="form-control"
                          id="systempromptId"
                          rows={5}
                          value={item.system_prompt}
                          onChange={(e) =>
                            handleSystemPromptChange(index, e.target.value)
                          }
                        ></textarea>
                      </div>
                    )}
                  {item.type === 'Service' &&
                    item.sub_type === 'Query Metadata' && (
                      <div>
                        <br></br>
                        <div className="tab custom-tab">
                          <div className="tab-header custom-tab-header">
                            <div className="tab-title">
                              <FontAwesomeIcon
                                icon={faUserCircle}
                                className="user-icon"
                              />
                              <span className="title-text">
                                <strong>Define Data Model</strong>
                              </span>
                            </div>
                            <div className="tab-actions">
                              <span
                                className="action-download"
                                onClick={handleDownload}
                              >
                                <strong> Download Sample</strong>{' '}
                                <FontAwesomeIcon icon={faDownload} />
                              </span>
                              <span
                                className="action-item"
                                onClick={() => importFile('import', index, 0)}
                              >
                                <strong>Import</strong>{' '}
                                <FontAwesomeIcon icon={faFolderPlus} />
                              </span>
                              <span
                                className="action-item plus-icon"
                                onClick={() => pushDataDic(index)}
                              >
                                <strong>Add a row</strong>{' '}
                                <FontAwesomeIcon icon={faPlus} />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="specific-table-container table-scrollbar">
                          <table className="table my-3">
                            <thead>
                              <tr>
                                <th scope="col">Object Name</th>

                                <th scope="col">Object Description</th>

                                <th scope="col" className="text-center">
                                  Actions
                                </th>
                              </tr>
                            </thead>

                            {item.objectMetaDeta &&
                              item.objectMetaDeta.map(
                                (item: any, itemIndex) => (
                                  <tbody className="table-body" key={itemIndex}>
                                    <tr>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control form-style"
                                          value={item.ObjectName}
                                          onChange={(e) =>
                                            handleObjectNameChange(
                                              index,
                                              e.target.value,
                                              itemIndex
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <textarea
                                          rows={1}
                                          className="form-control form-style"
                                          value={item.ObjDescription}
                                          onChange={(e) =>
                                            handleObjectDescriptionChange(
                                              index,
                                              e.target.value,
                                              itemIndex
                                            )
                                          }
                                        />
                                      </td>

                                      <td>
                                        <div className="button-container button-top">
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Tooltip>Download Action</Tooltip>
                                            }
                                          >
                                            <FontAwesomeIcon
                                              className={`upload-download-button ${item.dataDic === null || item.dataDic.length <= 0 ? 'inactive' : ''}`}
                                              icon={faDownload}
                                              onClick={(e) =>
                                                DownloadMetaDetaFile(
                                                  index,
                                                  itemIndex
                                                )
                                              }
                                            />
                                          </OverlayTrigger>

                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Tooltip>Upload Action</Tooltip>
                                            }
                                          >
                                            <FontAwesomeIcon
                                              className="upload-download-button"
                                              icon={faUpload}
                                              onClick={() =>
                                                importFile(
                                                  'upload',
                                                  index,
                                                  itemIndex
                                                )
                                              }
                                            />
                                          </OverlayTrigger>

                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Tooltip>Delete Action</Tooltip>
                                            }
                                          >
                                            <button
                                              className="dltBtn"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                removeDataDic(index, itemIndex);
                                              }}
                                            >
                                              <FaTrashAlt className="dltBtn"></FaTrashAlt>
                                            </button>
                                          </OverlayTrigger>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                )
                              )}
                          </table>
                        </div>
                      </div>
                    )}
                </Row>
              </form>
            </div>
          ))}
          <div className="my-3">
            <button className="add-subaction" onClick={pushSubAction}>
              <strong> + Add SubActions </strong>
            </button>
          </div>
          <div className={`container container-flex saveButtonContainer`}>
            {props.btnText === 'Edit' && (
              <button className="save-button" onClick={editAction}>
                <strong>Save</strong>
              </button>
            )}
            {props.btnText !== 'Edit' && (
              <button className="save-button" onClick={saveActions}>
                <strong>Save</strong>
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={isImport}
        onHide={closeImportModal}
        centered
        className="custom-import-modal"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="custom-modal-header">
          <div className="modal-title-container">
            <Modal.Title>Import File</Modal.Title>
          </div>
          <div className="custom-close-button-container">
            <button className="custom-close-button" onClick={closeImportModal}>
              &times;
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="blue-border-container">
            <div className="my-3">
              <form className="container">
                <Row>
                  <Col xs={12} md={12} className="text-center">
                    <div>
                      <FontAwesomeIcon
                        icon={faPaperclip}
                        className="icon-attachment"
                        size="lg"
                      />
                      <div>
                        <h4>Attach Documents</h4>
                        <h6 className="import-popup-header">
                          You can upload{' '}
                          {isBtnTag === 'upload'
                            ? '.txt file types'
                            : '.xlsx file types'}
                        </h6>
                        <h6 className="import-popup-header">
                          Maximum size is <strong>5MB</strong>
                        </h6>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center mt-3">
                      <input
                        ref={fileInputRef}
                        type="file"
                        className="custom-file-input"
                        onChange={
                          isBtnTag === 'upload' ? uploadFile : handleFileImport
                        }
                        accept={
                          isBtnTag === 'upload'
                            ? '.txt'
                            : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        }
                      />
                      <button
                        className="btn btn-primary mr-2"
                        onClick={handleButtonClick}
                      >
                        Select File
                      </button>
                    </div>
                    <div className="d-flex align-items-center justify-content-center mt-3">
                      {isFileSelected ? (
                        <span>
                          <strong> {selectedFileName} (1 file selected)</strong>
                        </span>
                      ) : (
                        <span>
                          {' '}
                          <strong>No file selected</strong>
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
          <div className="separator-line"></div>
          <div className="container container-flex">
            {isBtnTag === 'import' && (
              <>
                <Button className="btn btn-primary" onClick={handleImport}>
                  Import
                </Button>
                <span className="button-spacing"></span>
                <Button
                  className="btn btn-secondary"
                  onClick={closeImportModal}
                >
                  Cancel
                </Button>
              </>
            )}
            {isBtnTag === 'upload' && (
              <>
                <Button className="btn btn-primary" onClick={handleUpload}>
                  Import
                </Button>
                <span className="button-spacing"></span>
                <Button
                  className="btn btn-secondary"
                  onClick={closeImportModal}
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

//Set default props for AddCopilotActions
AddCopilotActions.defaultProps = {
  copilotActionId: '',
};

export default AddCopilotActions;
