import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../assets/arrows/back-arrow.svg';
import { Appl } from '../../interfaces';
import { Alert, Button, Form } from 'react-bootstrap';
import UserContext from '../../context/UserContext';
import axios from 'axios';
import ContentLoader from '../../components/Loader/contentLoader';
import ClearChat from '../../components/ClearContent';
import CreateInstance from '../../components/CreateInstance';
import stream from '../../utils/stream';
import { CODE_QNA } from '../../utils/constants';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

interface Payload {
  knowledge_id: string;
  input_text: string;
}
const CodeQnA = () => {
  const { projectId, appUrl } = useParams<{
    projectId: string;
    appUrl: string;
  }>();
  const apps = JSON.parse(sessionStorage.getItem('apps') as string) as Appl[];
  const currentApp: Appl | undefined = apps.find((app) => app.url === appUrl);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [outputData, setOutputData] = useState<string>('');
  const [searchOutputData, setSearchOutputData] = useState<string>('');
  const [instanceData, setInstanceData] = useState<any>('');
  const [activityId, setActivityId] = useState<string>('');
  const [knowledgeIDs, setKnowledgeIDs] = useState<any[]>([]);
  const [viewCodeSnippet, setViewCodeSnippet] = useState<boolean>(false);
  const userContext = useContext(UserContext) || {
    setShowToast: () => {},
    showToast: 'false',
    showErrMsg: '',
    userInput: '',
    setUserInput: () => {},
    selectedInstance: '',
    setShowErrMsg: () => {},
  };
  const {
    showErrMsg,
    setShowToast,
    showToast,
    userInput,
    setUserInput,
    selectedInstance,
    setShowErrMsg,
  } = userContext;

  const qna_url = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/code-to-document/submit/code-qna?instance_id=${selectedInstance}`;
  const search_url = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/code-to-document/submit/code-search?instance_id=${selectedInstance}`;

  const submitPrompt = async () => {
    let KnId = Number(knowledgeIDs[knowledgeIDs?.length - 1].id);
    const data = {
      knowledge_id: KnId.toString(),
      input_text: userInput,
    };

    let body = new URLSearchParams(data).toString();
    await axios.all([handleStream(body), handleSubmit(data)]);
  };

  const handleSubmit = async (payload: Payload) => {
    try {
      setSearchOutputData('');
      const url = search_url;
      setIsLoading(true);
      const response = await axios.post(url, payload, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      if (response.status === 200 || response.status === 201) {
        setIsLoading(false);
        let search_result = response.data.search_result;
        setSearchOutputData(search_result);
      } else {
        setShowToast(true);
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      const err = error?.response?.data?.error;
      setIsLoading(false);
      setShowErrMsg(err?.errorMessage);
      setShowToast(true);
      console.error('Error:', error);
    }
  };
  const handleStream = async (payload: string) => {
    setOutputData('');
    setShowToast(false);
    setIsLoading(true);
    stream({
      requestBody: payload,
      streamCallback: (value: string) => {
        setOutputData((prev) => {
          return `${prev}${value}`;
        });
        // setIsLoading(false);
      },
      doneCallback: () => {
        setIsLoading(false);
      },
      setShowToast: setShowToast,
      setIsLoading: setIsLoading,
      setActivityId: setActivityId,
      url: qna_url,
      currentApp: CODE_QNA,
    });
  };
  useEffect(() => {
    setKnowledgeIDs(instanceData?.knowledgeIds);
  }, [instanceData]);

  const handleUserPromptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(e.target.value);
  };
  return (
    <>
      <div className="title">{currentApp?.name}</div>
      <div className="back-button-container">
        <Link to={`/project/${projectId}`}>
          <BackIcon /> Back
        </Link>
      </div>
      <div className="single-app-container">
        <CreateInstance
          projectId={projectId || ''}
          currentApp={currentApp}
          setOutputData={setOutputData}
          setInstanceDetails={setInstanceData}
          instanceDetails={instanceData}
        />
        <Form.Group className="user-input">
          <Form.Label>Enter prompt</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Enter your prompt here."
            rows={3}
            value={userInput}
            onChange={handleUserPromptChange}
            disabled={!selectedInstance}
          />
        </Form.Group>
      </div>
      <>
        <div className="user-story-generator-submit-container">
          {isLoading ? (
            <Button
              className="user-story-generator-submit"
              disabled={true}
              style={{ width: '104px', height: '36px' }}
            >
              <ContentLoader />
            </Button>
          ) : (
            <div className="with-download-button">
              <Button
                className="user-story-generator-submit"
                as="input"
                type="submit"
                value="Submit"
                onClick={submitPrompt}
                disabled={!selectedInstance || !userInput}
              />
              <ClearChat setOutputData={setOutputData} disabled={!outputData} />
            </div>
          )}
        </div>
      </>
      {/* this part will handle code QnA response */}
      {searchOutputData && outputData && (
        <>
          <Form.Control
            as="textarea"
            rows={18}
            className="output-container"
            value={outputData}
            disabled
          />
          <Form.Group className="transcript-toggle-container">
            <Form.Check
              type="switch"
              id="code-view-switch"
              label="View Code Snippet"
              onChange={(evt) => setViewCodeSnippet(evt.target.checked)}
            />
          </Form.Group>
          {/* this part will handle code Search response */}
          {viewCodeSnippet && (
            <>
              <div className="output-container">
                {searchOutputData &&
                Array.isArray(searchOutputData) &&
                searchOutputData?.length > 0 ? (
                  searchOutputData.map((data, index) => (
                    <>
                      <p>
                        <b>{data?.file_name}</b>
                      </p>
                      <SyntaxHighlighter style={vscDarkPlus} language="cobol">
                        {data?.content}
                      </SyntaxHighlighter>
                      {outputData.length - 1 !== index && <hr />}
                    </>
                  ))
                ) : (
                  // temporary message for handling no results in response
                  <p>No results found</p>
                )}
              </div>
            </>
          )}
        </>
      )}

      {showToast && (
        <div className="alert-container">
          <Alert
            variant="danger"
            onClose={() => setShowToast(false)}
            dismissible
          >
            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
            <p>
              {showErrMsg
                ? showErrMsg
                : `Something went wrong. Please try again`}
            </p>
          </Alert>
        </div>
      )}
    </>
  );
};

export default CodeQnA;
