export const azure_llm_models_options = [
  ['GPT- 4 TURBO 128K - $$', 'gpt-4-turbo'],
  ['GPT-4o 128K', 'gpt-4o'],
  ['GPT- 4 32K - $$$', 'gpt4-32k'],
  ['GPT- 3.5 16K - $', 'gpt35-16k'],
];

export const gcp_llm_models_options = [
  ['GEMINI- PRO 32K - $$$', 'gemini-pro'],
  ['TEXT- BISON 32K - $$', 'text-bison-32k'],
  ['TEXT- BISON 8K - $', 'text-bison@001'],
];

export const private_llm_models_options = [
  ['MISTRAL- 7B', 'mistral:7b-instruct'],
  ['LLAMA 2- 7B', 'llama2:7b-chat'],
  ['Medicaid Fed Ft LLM', 'llama2:7b-chat-ft-medicaid-fed-20240201'],
];

export const aws_llm_models_options = [
  ['CLAUDE V2- 1 200K- $$', 'anthropic.claude-v2:1'],
  ['TITAN V1 TEXT 8K- $', 'amazon.titan-text-express-v1'],
];

//Feedback Component Contants
export const ENTER_FEEDBACK_PLACEHOLDER = 'Please enter feedback...';
export const FEEDBACK_THANK_YOU_MSG = 'Thank you for your feedback!';
export const WAS_THIS_HELPFUL = 'Was this helpful?';
export const FEEDBACK_ERROR =
  'Sorry we encountered some issue in sending your feedback!';
export const SUBMIT = 'Submit';
export const THUMBS_UP_ICON = '👍';
export const THUMBS_DOWN_ICON = '👎';

//CaseCopiotConstants
export const UPLOAD_SUCCESS = 'Upload Successful';
export const OPTION_SELECT = 'Choose an option';
export const DATA_FILE = 'Data File';
export const FORMATS_SUPPORTED = 'Formats supported';
export const UPLOAD = 'Uploading';
export const DICTIONARY_FILE = 'Dictionary File';
export const QUESTION = 'Enter your question';
export const SNAP_MESSAGE = 'Something went wrong. Please try again';
export const ERROR_MESSAGE = 'Oh snap! You got an error!';
export const ADVANCED_ERROR_MESSAGE =
  ' Something went wrong with your Advanced settings. Please make sure required fields like query params are not blank.';
export const REFRESHING = 'Refreshing...';

//InstanceGeneratorConstants
export const KNOWLEDGE_IDS = 'Knowledge IDs';

//LlmFineTuneDatasetGeneratorConstants:
export const CREATE_DATASET = 'Create a dataset from document';
export const UPLOAD_DATASET = 'Upload dataset file (CSV/XLSX) to upsample';

// Application constants:
export const USER_STORY_GENERATOR = 'User Story Generator';
export const SALES_ENGINE = 'Sales Engine';
export const MEETING_SUMMARIZER = 'Meeting Summarizer';
export const STATUS_REPORT_GENERATOR = 'Status Report Generator';
export const TEST_SCRIPT_GENERATOR = 'Test Case Generator';
export const SCAN_TO_SUMMIFY = 'Scan to Summify Engine';
export const HELP_DESK_ENGINE = 'Help Desk Engine';
export const NOTICE_ENGINE = 'Notice Explanation Engine';
export const NOTICE_ANALYSIS_ENGINE = 'Notice Analysis Engine';
export const CHANGE_COMMUNICATOR = 'Change Communicator';
export const CALL_CENTER = 'Call Center';
export const DOC_INSIGHT = 'Document Insight';
export const DEVELOPER_HELP_ENGINE = 'Developer Help Engine';
export const POLICY_ENGINE = 'Policy Engine';
export const HELP_ENGINE = 'Help Engine';
export const CODE_DOCUMENTATION = 'Code documentation';
export const METADATA_GENERATOR = 'Document Metadata Generator';
export const CASE_COPILOT_GENERATOR = 'Case Copilot Generator';
export const VISION_QA_ENGINE = 'Vision QA Engine';
//VisionqaFeedback constants:
export const Feed_Back_Message = 'Thank you for the feedback!';

//DocumentOCR constants:
export const File_Format = '.pdf';
export const File_Formats = '.pdf, .jpeg, .jpg, .png';

//code to design constants
export const Code2Design = 'Code To Design';
export const Code_To_Design = 'Code to Design';
export const Code_To_Spec = 'Code to Spec';
export const VISUAL_BASIC = 'VISUAL_BASIC';
export const COBOL = 'COBOL';
export const OMNISCRIPT = 'OMNISCRIPT';

export const CODE_QNA = 'Code QnA';
export const CODE_SEARCH = 'Code Search';

//tab title
export const UPLOAD_TAB = 'Upload';
export const DOWNLOAD_DOCUMENTATION = 'Download Documentation';

//llm models
export const GPT_4_TURBO = 'gpt-4-turbo';
export const GPT_4O = 'gpt-4o';
export const GPT4_32K = 'gpt4-32k';
export const GPT4_8K = 'gpt4-8k';
export const GPT35_16K = 'gpt35-16k';
export const GPT35_4K = 'gpt35-4k';
export const GEMINI_PRO = 'gemini-pro';
export const TEXT_BISON_32K = 'text-bison-32k';
export const TEXT_BISON_001 = 'text-bison@001';
export const MISTRAL_7B = 'mistral:7b-instruct';
export const LLAMA2_7B = 'llama2:7b-chat';
export const LLAMA2_7B_CHAT_FT_MEDICAID_FED_20240201 =
  'llama2:7b-chat-ft-medicaid-fed-20240201';
export const ANTHROPIC_CLAUDE_V2_1 = 'anthropic.claude-v2:1';
export const AMAZON_TITAN_TEXT_EXPRESS_V1 = 'amazon.titan-text-express-v1';

//llm guard Scanner types
export const Anonymize = 'Anonymize';
export const BanCode = 'BanCode';
export const BanCompetitors = 'BanCompetitors';
export const BanSubstrings = 'BanSubstrings';
export const BanTopics = 'BanTopics';
export const Code = 'Code';
export const Gibberish = 'Gibberish';
export const InvisibleText = 'InvisibleText';
export const Language = 'Language';
export const PromptInjection = 'PromptInjection';
export const Regex = 'Regex';
export const TokenLimit = 'TokenLimit';
export const Secrets = 'Secrets';
export const Sentiment = 'Sentiment';
export const Toxicity = 'Toxicity';

//Current User Role
export const CONTRIBUTOR = 'contributor';
export const ADMIN = 'admin';
export const SUPERADMIN = 'superadmin';

//Usage Tracking
export const AllProjects = 'all_Projects';

//Select instance knowledge id Status
export const Pending_Status = 'Pending';
export const Completed_Status = 'Completed';
export const Failed_Status = 'Failed';
export const InProgress_Status = 'In Progress';
export const Pending_Message =
  'Your instance is being set up. It may take a short while, depending on documents uploaded.';
export const Completed_Message = 'All set! Your instance is now ready to use.';
export const Failed_Message =
  'On Snap! Your instance could not be configured successfully.';
export const Failed_Message_Support =
  'Please setup the instance again or contact GenAIe team for assistance.';

//Fine Tune Dataset Generator
export const pending = 'Pending';
export const completed = 'Completed';
export const failed = 'Failed';
export const upload = 'Upload';
export const collections = 'collections';
export const light = 'light';
export const dark = 'dark';
export const primary = 'primary';
export const white = 'white';
export const generateTextVariations = 'Generate Text Variations';
export const dalleimage = 'DALL-E Image Generate';
export const generateqa = 'Generate QA';
export const createsimilarquestionqa = 'Create Similar Questions QA';

//For instance generator
export const testSteps = 'Test Steps';
export const title = 'Title';
export const expectedOutCome = 'Expected Outcome';
export const userNoteOutputColum1 =
  'Fixed Columns: Title, Test Steps, and Expected Result cannot be edited but can be rearranged.';
export const userNoteOutputColum2 = `Add Columns: Click the '+' icon to add new columns. They will appear in the order added.`;
export const userNoteOutputColum3 =
  'Reorder Columns:Drag and drop new columns to rearrange them. The final order will be preserved in the final response.';

//For vector DB :
export const WEAVIATE = 'weaviate';
export const PGVECTOR = 'pgvector';

//For Advance Settings:
export const NORMAL = 'Normal';
