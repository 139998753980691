import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../assets/arrows/back-arrow.svg';
import { Appl, CodeToDesignObject } from '../../interfaces';
import {
  Badge,
  Spinner,
  Form,
  Alert,
  Tab,
  Tabs,
  Button,
} from 'react-bootstrap';
import UserContext from '../../context/UserContext';
import axios from 'axios';
import './CodeToDesign.css';
import ActivityStatusDropDown from '../../components/ActivityStatusDropDown';
import {
  COBOL,
  CODE_DOCUMENTATION,
  Code2Design,
  Code_To_Design,
  Code_To_Spec,
  DOWNLOAD_DOCUMENTATION,
  UPLOAD_TAB,
  OMNISCRIPT,
} from '../../utils/constants';
import ContentLoader from '../../components/Loader/contentLoader';

const CodeToDesignOptions: CodeToDesignObject = {
  COBOL: {
    name: 'Cobol',
    methods: [
      { value: Code_To_Design, text: 'Code To Design' },
      { value: Code_To_Spec, text: 'Code To Spec' },
    ],
    acceptedFiles: '.pco, .cobol, .zip,.cbl',
    displayFiles: 'pco, cobol, zip,cbl',
  },
  VISUAL_BASIC: {
    name: 'Visual Basic',
    methods: [{ value: Code_To_Design, text: 'Code To Design' }],
    acceptedFiles: '.frm, .cls, .ctl, .zip',
    displayFiles: 'frm, cls, clt, zip',
  },
  OMNISCRIPT: {
    name: 'Salesforce OmniScript',
    methods: [{ value: Code_To_Design, text: 'Code To Design' }],
    acceptedFiles: '.json, .xml',
    displayFiles: 'json, xml',
  },
  PYTHON:{
    name: 'Python',
    methods: [{ value: Code_To_Design, text: 'Code To Design' }],
    acceptedFiles: '.py,.zip',
    displayFiles: 'py,zip',
  },
  JAVA:{
    name: 'Java',
    methods: [{ value: Code_To_Design, text: 'Code To Design' }],
    acceptedFiles: '.java,.zip',
    displayFiles: 'java,zip',
  },
  GO:{
    name: 'Go',
    methods: [{ value: Code_To_Design, text: 'Code To Design' }],
    acceptedFiles: '.go,.zip',
    displayFiles: 'go,zip',
  },
  CSHARP:{
    name: 'C#',
    methods: [{ value: Code_To_Design, text: 'Code To Design' }],
    acceptedFiles: '.cs,.zip',
    displayFiles: 'cs,zip',
  },
  CPP:{
    name: 'C++',
    methods: [{ value: Code_To_Design, text: 'Code To Design' }],
    acceptedFiles: '.cpp,.zip',
    displayFiles: 'cpp,zip',
  },
  RUBY:{
    name: 'Ruby',
    methods: [{ value: Code_To_Design, text: 'Code To Design' }],
    acceptedFiles: '.rb,.zip',
    displayFiles: 'rb,zip',
  },
  RUST:{
    name: 'Rust',
    methods: [{ value: Code_To_Design, text: 'Code To Design' }],
    acceptedFiles: '.rs,.zip',
    displayFiles: 'rs,zip',
  },
  SCALA:{
    name: 'Scala',
    methods: [{ value: Code_To_Design, text: 'Code To Design' }],
    acceptedFiles: '.scala, .zip',
    displayFiles: 'scala, zip',
  },
  TYPESCRIPT:{
    name: 'TypeScript',
    methods: [{ value: Code_To_Design, text: 'Code To Design' }],
    acceptedFiles: '.ts,.zip',
    displayFiles: 'ts,zip',
  },
  LUA:{
    name: 'Lua',
    methods: [{ value: Code_To_Design, text: 'Code To Design' }],
    acceptedFiles: '.lua,.zip',
    displayFiles: 'lua,zip',
  },
  KOTLIN:{
    name: 'Kotlin',
    methods: [{ value: Code_To_Design, text: 'Code To Design' }],
    acceptedFiles: '.kt,.zip',
    displayFiles: 'kt,zip',
  },
  PHP:{
    name: 'PHP',
    methods: [{ value: Code_To_Design, text: 'Code To Design' }],
    acceptedFiles: '.php,.zip',
    displayFiles: 'php,zip',
  },
  APEX:{
    name: 'Apex',
    methods: [{ value: Code_To_Design, text: 'Code To Design' }],
    acceptedFiles: '.apex,.zip',
    displayFiles: 'apex,zip',
  },
};

const CodeToDesign = () => {
  const { projectId, appUrl } = useParams();
  const apps = JSON.parse(sessionStorage.getItem('apps') as string) as Appl[];
  const currentApp = apps.find((app) => app.url === appUrl);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [uploadedImages, setUploadedImages] = useState<File[]>([]);
  const [showUploadMessage, setShowUploadMessage] = useState<boolean>(false);
  const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false);
  const filesSelectedForUploadRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inputKey, setInputKey] = useState(Date.now());
  const [activityId, setActivityId] = useState<string>('');
  const [activeTab, setActiveTab] = useState(UPLOAD_TAB);
  const [designMethod, setDesignMethod] = useState<string>(Code_To_Design);
  const [programmingLang, setProgrammingLang] = useState<string>(COBOL);
  const [activityResponse, setActivityResponse] = useState<any>([]);
  const [collectionName, setCollectionName] = useState<string>('');
  const userContext = useContext(UserContext) || {
    setShowToast: () => {},
    showToast: 'false',
    showErrMsg: '',
    setIsActivityActive: () => {},
    isActivityActive: false,
    setShowErrMsg: () => {},
  };
  const {
    showErrMsg,
    setShowToast,
    showToast,
    setIsActivityActive,
    isActivityActive,
    setShowErrMsg,
  } = userContext;

  useEffect(() => {
    const removeSuccessMessage = setTimeout(() => {
      setShowUploadMessage(false);
    }, 2000);

    return () => clearTimeout(removeSuccessMessage);
  }, [showUploadMessage]);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files?.length) {
      setUploadedImages(Array.from(files));
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files?.length) {
      setUploadedFiles(Array.from(files));
    } else {
      setIsUploadLoading(false);
      setShowUploadMessage(false);
    }
  };

  const submitCodeToDesign = async () => {
    const formData = new FormData();
    if (uploadedImages.length > 0 && programmingLang === OMNISCRIPT) {
      uploadedImages.forEach((file: any) => {
        formData.append('images', file);
      });
    }
    uploadedFiles.forEach((file: any) => {
      formData.append('file', file);
    });
    // formData.append('data', designMethod);
    try {
      const queryParams = {
        project_id: projectId,
        application_id: currentApp?.id,
        program_language: programmingLang,
      };
      setShowToast(false);
      setIsLoading(true);
      setIsUploadLoading(true);
      setShowUploadMessage(false);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/code-to-document/submit/code-documentation`,
        formData,
        {
          params: queryParams,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        setIsUploadLoading(false);
        setShowUploadMessage(true);
        setIsLoading(false);
        setUploadedFiles([]);
        setUploadedImages([]);
        setInputKey(Date.now());
        if (filesSelectedForUploadRef && filesSelectedForUploadRef.current) {
          filesSelectedForUploadRef.current.value = '';
        }
        const responseData = response.data;
        setActivityId(response?.data?.response.activity_id);
        setActivityResponse((prev: any) => [...prev, responseData]);
      } else {
        setIsLoading(false);
        setShowToast(true);
        setIsUploadLoading(false);
      }
    } catch (error: any) {
      const err = error?.response?.data?.error;
      setIsLoading(false);
      setShowErrMsg(err?.errorMessage);
      setIsUploadLoading(false);
      setShowToast(true);
      console.error('Error:', error);
    }
  };

  const handleDropdownChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setDesignMethod(event.target.value);
  };
  const handleProgrammingChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setDesignMethod(Code_To_Design);
    setProgrammingLang(event.target.value);
    handleClear();
  };

  const handleClear = () => {
    if (filesSelectedForUploadRef && filesSelectedForUploadRef.current) {
      filesSelectedForUploadRef.current.value = '';
    }
    setUploadedFiles([]);
    setUploadedImages([]);
    setShowUploadMessage(false);
    setIsUploadLoading(false);
    setIsLoading(false);
    setInputKey(Date.now());
  };

  const handleActivitySelect = (selectedActivity: any) => {
    console.error(selectedActivity);
  };

  return (
    <>
      <div className="title">{currentApp?.name}</div>
      <div className="back-button-container">
        <Link to={`/project/${projectId}`}>
          <BackIcon /> Back
        </Link>
      </div>
      <div className="single-app-container">
        <Tabs
          defaultActiveKey="Upload"
          id="justify-tab-example"
          className="mb-3"
          justify
          onSelect={(key: string | null) => {
            if (key !== null) {
              setActiveTab(key);
              setIsActivityActive(!isActivityActive);
            }
          }}
        >
          <Tab eventKey="Upload" title={UPLOAD_TAB}>
            {currentApp?.name === Code2Design && (
              <>
                <Form.Group
                  className="user-input"
                  controlId="controlId.programmingLang"
                >
                  <Form.Label>Select Programming Language</Form.Label>
                  <Form.Select
                    aria-label="Select Programming Language"
                    className="instance-picker"
                    value={programmingLang}
                    onChange={handleProgrammingChange}
                  >
                    {Object.keys(CodeToDesignOptions).map((key: any) => (
                      <option value={key}>
                        {CodeToDesignOptions[key].name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                {/* <Form.Group
                  className="user-input"
                  controlId="controlId.desigMethod"
                > */}
                  {/* <Form.Label>Code to Design Method</Form.Label>
                  <Form.Select
                    aria-label="Code to Design Method"
                    className="instance-picker"
                    value={designMethod}
                    onChange={handleDropdownChange}
                  >
                    {CodeToDesignOptions[programmingLang].methods.map(
                      (method: any) => (
                        <option value={method.value}>{method.text}</option>
                      )
                    )}
                  </Form.Select> */}
                {/* </Form.Group> */}
              </>
            )}
            {currentApp?.name !== Code2Design && (
              <Form.Group className="user-input">
                <Form.Label>Package Name</Form.Label>
                <Form.Control
                  as="input"
                  type="text"
                  placeholder="Enter Code Package Name"
                  value={collectionName}
                  // onChange={handleCollectionNameChange}
                />
              </Form.Group>
            )}
            {programmingLang === OMNISCRIPT && (
              <Form.Group
                className="file-input"
                controlId="controlId.fileInput"
              >
                <Form.Label>Select Image(s)</Form.Label>
                <Form.Control
                  type="file"
                  accept=".jpeg, .jpg, .png"
                  onChange={handleImageUpload}
                  multiple
                  key={inputKey}
                  ref={filesSelectedForUploadRef}
                />
              </Form.Group>
            )}
            <Form.Group className="file-input" controlId="controlId.fileInput">
              <Form.Label>Upload a File</Form.Label>
              <Form.Control
                type="file"
                accept={CodeToDesignOptions[programmingLang].acceptedFiles}
                onChange={handleFileChange}
                key={inputKey}
                ref={filesSelectedForUploadRef}
              />
              {isUploadLoading && (
                <div className="upload-spinner">
                  <Spinner animation="border" size="sm" /> Uploading
                </div>
              )}
              {uploadedFiles && showUploadMessage && (
                <div className="upload-msg">
                  <Badge bg="success">Upload Successful</Badge>
                </div>
              )}
              <div>
                <Form.Text>
                  Formats supported:{' '}
                  {CodeToDesignOptions[programmingLang].displayFiles}
                </Form.Text>
              </div>
            </Form.Group>
            <div className="user-story-generator-submit-container">
              <>
                {isLoading ? (
                  <Button className="send-btn" disabled={true}>
                    <ContentLoader />
                  </Button>
                ) : (
                  <Button
                    className="user-story-generator-submit"
                    as="input"
                    type="submit"
                    value="Submit"
                    onClick={submitCodeToDesign}
                    disabled={
                      programmingLang === OMNISCRIPT
                        ? uploadedFiles.length === 0 ||
                          uploadedImages.length === 0
                        : uploadedFiles.length === 0
                    }
                  />
                )}
              </>
            </div>
          </Tab>
          <Tab eventKey="CodePackages" title={DOWNLOAD_DOCUMENTATION}>
            <ActivityStatusDropDown
              activityResponse={activityResponse}
              activityType={CODE_DOCUMENTATION}
              onActivitySelect={handleActivitySelect}
              isC2D={currentApp?.name === Code2Design ? true : false}
            />
          </Tab>
        </Tabs>
      </div>
      {showToast && (
        <div className="alert-container">
          <Alert
            variant="danger"
            onClose={() => setShowToast(false)}
            dismissible
          >
            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
            <p>
              {showErrMsg
                ? showErrMsg
                : `Something went wrong. Please try again`}
            </p>
          </Alert>
        </div>
      )}
    </>
  );
};

export default CodeToDesign;
