/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useState, useRef } from 'react';
import { Form, Badge, Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import '../UserStoryGenerator/UserStoryGenerator.css';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import CustomFeedback from '../../components/Feedback';
import { ReactComponent as BackIcon } from '../../assets/arrows/back-arrow.svg';
import { Appl, Chat, ChatboxInterface } from '../../interfaces';
import { Llm_models } from '../../types';
import axios from 'axios';
import stream from '../../utils/stream';
import llmGuardSubmit from '../../utils/llmGuardSubmit';
import Chatbox from '../../components/Chatbox';
import SelectInstance from '../../components/SelectInstance';
import ContentLoader from '../../components/Loader/contentLoader';
import AdvancedSettings from '../../components/AdvancedSettings';
import UserContext from '../../context/UserContext';
import { SiGooglecloud, SiAzuredevops, SiAmazonaws } from 'react-icons/si';
import ClearChat from '../../components/ClearContent';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {
  DOC_INSIGHT,
  SCAN_TO_SUMMIFY,
  File_Format,
  File_Formats,
  PGVECTOR,
  NORMAL,
  ADVANCED_ERROR_MESSAGE,
} from '../../utils/constants';

const DocumentOCR = () => {
  const { projectId, appUrl } = useParams();
  const apps = JSON.parse(sessionStorage.getItem('apps') as string) as Appl[];
  const currentApp = apps.find((app) => app.url === appUrl);
  const [outputData, setOutputData] = useState<string>('');
  const [showFeedbackOptions, setShowFeedBackOptions] =
    useState<boolean>(false);
  const [activityId, setActivityId] = useState<string>('');
  const chatbox = useRef<ChatboxInterface>(null);
  const [uploadedFiles, setUploadedFiles] = useState<File | null>(null);
  const [isUploadResponse, setIsUploadResponse] = useState<boolean>(false);
  const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false);
  const [showErrMsg, setShowErrMsg] = useState<string>('');
  const [showRawTranscript, setShowRawTranscript] = useState<boolean>(false);
  const [transcriptData, setTranscriptData] = useState<string>('');
  const [inputText, setInputText] = useState<string>('');
  const overlayContainerRef = useRef<HTMLDivElement | null>(null);
  const [instanceData, setInstanceData] = useState<any>('');
  const abortControllerRef = useRef<AbortController | null>(null);
  const [sanitizedPrompt, setSanitizedPrompt] = useState<string>('');
  const [showSanitizedPrompt, setShowSanitizedPrompt] =
    useState<boolean>(false);
  const userContext = useContext(UserContext) || {
    selectedInstance: '',
    userInput: '',
    setUserInput: () => {},
    isLoading: false,
    setIsLoading: () => {},
    showAdvancedSettings: false,
    setShowAdvancedSettings: () => {},
    showAdvancedSettingsAlert: false,
    setShowAdvancedSettingsAlert: () => {},
    chatContents: [],
    setChatContents: () => [],
    useAdvancedSettings: false,
    advancedSettings: {
      mode: 'autocut',
      autocut: 1,
      query_param: undefined,
      fusion_type: undefined,
      enableChatRegeneration: false,
      debugMode: false,
    },
    showToast: false,
    setShowToast: () => {},
    llmCloud: '',
    filesSelectedForUploadRef: null,
    llmGuardOption: '',
  };

  const {
    selectedInstance,
    advancedSettings,
    userInput,
    setUserInput,
    setShowAdvancedSettingsAlert,
    showAdvancedSettingsAlert,
    setShowAdvancedSettings,
    isLoading,
    setIsLoading,
    chatContents,
    setChatContents,
    useAdvancedSettings,
    showToast,
    setShowToast,
    llmCloud,
    filesSelectedForUploadRef,
    llmGuardOption,
  } = userContext;
  const [selectedLlmModel, setSelectedLlmModel] = useState<Llm_models>(
    llmCloud === 'gcp'
      ? 'gemini-pro'
      : llmCloud === 'aws'
        ? 'anthropic.claude-v2:1'
        : 'gpt-4-turbo'
  );

  useEffect(() => {
    setSelectedLlmModel(
      llmCloud === 'gcp'
        ? 'gemini-pro'
        : llmCloud === 'aws'
          ? 'anthropic.claude-v2:1'
          : 'gpt-4-turbo'
    );
  }, [llmCloud]);

  const submitDocumentOCR = async () => {
    setShowSanitizedPrompt(false);
    setSanitizedPrompt('');
    const azureURL = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/submit/scanned-document-instance`;
    const gcpURL = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/gcp/submit/scanned-document-instance`;
    const awsURL = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/aws/submit/scanned-document-instance`;
    setOutputData('');
    setTranscriptData('');
    setShowRawTranscript(false);
    setUserInput('');
    if (uploadedFiles) {
      const formData = new FormData();
      formData.append('file', uploadedFiles);
      formData.append('model_choice', selectedLlmModel);

      try {
        const queryParams = {
          instance_id: selectedInstance,
        };
        setShowToast(false);
        setShowErrMsg('');
        setShowFeedBackOptions(false);
        setIsLoading(true);
        let url =
          llmCloud === 'aws' ? awsURL : llmCloud === 'gcp' ? gcpURL : azureURL;
        const response = await axios.post(url, formData, {
          params: queryParams,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response.status === 200) {
          setShowFeedBackOptions(true);
          setIsLoading(false);
          setUploadedFiles(null);
          const responseData = response.data;
          setOutputData(responseData?.response);
          setTranscriptData(responseData?.document_text);
          setSanitizedPrompt(responseData?.sanitized_prompt);
          setUserInput(responseData?.document_text);
          setActivityId((response?.headers as any)?.get('x-activityid'));
          // createActivityId();
        } else {
          console.error('Failed to upload files');
          setIsLoading(false);
          setShowToast(true);
        }
      } catch (error: any) {
        console.error('Error:', error);
        const err = error?.response?.data?.error;
        console.error('Notice engine Error:', err);
        setIsLoading(false);
        setShowToast(true);
        setShowErrMsg(err.errorMessage);
      }
    }
  };

  const handleFileChange = (
    files: FileList | null,
    forceClear: boolean = false
  ) => {
    setShowSanitizedPrompt(false);
    setSanitizedPrompt('');
    if (!forceClear && chatContents?.length !== 0) {
      chatbox?.current?.initiateChatWarningModal({
        callback: handleFileChange,
        props: { files: files },
      });
    } else {
      if (files && files.length > 0) {
        setOutputData('');
        setUploadedFiles(files[0]);
      }
    }
  };

  const handleFileChangeInDocumentInsight = (
    files: FileList | null,
    forceClear: boolean = false
  ) => {
    if (!forceClear && chatContents.length !== 0) {
      chatbox?.current?.initiateChatWarningModal({
        callback: handleFileChangeInDocumentInsight,
        prop: files,
      });
    } else {
      // setSelectedInstance(value);
      if (files) {
        let selectedFiles = Array.from(files);
        submitUploadedFiles(selectedFiles);
      }
    }
  };

  useEffect(() => {
    if (
      filesSelectedForUploadRef &&
      filesSelectedForUploadRef.current?.value === ''
    ) {
      setUploadedFiles(null);
      setIsUploadLoading(false);
      setIsUploadResponse(false);
    }
  }, [filesSelectedForUploadRef, selectedInstance]);

  const submitUploadedFiles = async (selectedFiles: any) => {
    if (selectedFiles) {
      const formData = new FormData();
      selectedFiles.forEach((file: any) => {
        formData.append('files', file);
      });

      try {
        const queryParams = {
          instance_id: selectedInstance,
        };
        setIsUploadResponse(false);
        setShowToast(false);
        setShowFeedBackOptions(false);
        setIsUploadLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/knowledge/dynamic-create-knowledge-load`,
          formData,
          {
            params: queryParams,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        if (response.status === 200) {
          // const responseData = response.data;
          setIsUploadLoading(false);
          setIsUploadResponse(true);
        } else {
          console.error('Failed to upload files');
          setIsUploadLoading(false);
          setShowToast(true);
        }
      } catch (error: any) {
        console.error('Error from API:', error.response?.data || error.message);
        const err = error?.response?.data?.error;
        setIsUploadLoading(false);
        setIsLoading(false);
        setShowToast(true);
        setShowErrMsg(err.errorMessage);
      }
    }
  };

  const handlePromptSubmit = async (payload: any, isRegenerate = false) => {
    setInputText(userInput);
    if (
      useAdvancedSettings &&
      advancedSettings.mode !== NORMAL &&
      advancedSettings.query_param === ''
    ) {
      setShowAdvancedSettingsAlert(true);
    } else {
      if (!isRegenerate) {
        setChatContents([
          ...chatContents,
          { role: 'user', content: userInput } as Chat,
          { role: 'assistant', content: '' } as Chat,
        ]);
      } else {
        // Remove the last assistant message
        setChatContents((prevContents) => [
          ...prevContents.slice(0, -1),
          { role: 'assistant', content: '' } as Chat,
        ]);
      }
      setUserInput('');
      setOutputData('');
      setIsLoading(true);
      setShowFeedBackOptions(false);
      abortControllerRef.current = new AbortController();
      stream({
        requestBody: payload,
        streamCallback: (value: string) => {
          setOutputData((prev) => {
            setChatContents((oldChatContents) =>
              oldChatContents.map((chatContent, index, array) => {
                if (index + 1 === array.length) {
                  return { ...chatContent, content: `${prev}${value}` };
                } else {
                  return chatContent;
                }
              })
            );
            return `${prev}${value}`;
          });
          // setOutputData((prev) => `${prev}${value}`);
          // setIsLoading(false);
        },
        doneCallback: () => {
          setIsLoading(false);
          // createActivityId();
          setShowFeedBackOptions(true);
          setShowToast(false);
        },
        setShowToast: setShowToast,
        setIsLoading: setIsLoading,
        llmCloud: llmCloud,
        selectedLlmModel: selectedLlmModel,
        activityId: activityId,
        setActivityId: setActivityId,
        signal: abortControllerRef.current.signal,
      });
    }
  };
  const handleAbortStream = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  };

  const handleLlmGuardSubmit = async (payload: any) => {
    setInputText(userInput);
    if (
      useAdvancedSettings &&
      advancedSettings.mode !== NORMAL &&
      advancedSettings.query_param === ''
    ) {
      setShowAdvancedSettingsAlert(true);
    } else {
      setChatContents([
        ...chatContents,
        { role: 'user', content: userInput } as Chat,
        // { role: 'assistant', content: '' } as Chat,
      ]);
      setUserInput('');
      setOutputData('');
      setIsLoading(true);
      setShowFeedBackOptions(false);
      const response = await llmGuardSubmit({
        requestBody: payload,
        setIsLoading,
        setShowErrMsg,
        setActivityId,
        setShowToast,
        llmCloud,
        setSanitizedPrompt,
      });
      if (response) {
        setChatContents((oldChatContents) => [
          ...oldChatContents,
          { role: 'assistant', content: response },
        ]);
        setShowFeedBackOptions(true);
        setShowToast(false);
      }
    }
  };

  const submitPrompt = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowSanitizedPrompt(false);
    setSanitizedPrompt('');
    setShowToast(false);
    event.preventDefault();
    const { enableChatRegeneration, debugMode, ...filteredAdvancedSettings } =
      advancedSettings;
    const payload = {
      input_text: chatContents.length === 0 ? userInput : undefined,
      messages:
        chatContents.length !== 0
          ? [
              { role: 'system', content: 'You are a helpful assistant' },
              ...chatContents,
              { role: 'user', content: userInput },
            ]
          : undefined,
      instance_id: selectedInstance,
      include_references: true,
      advance_search_params:
        useAdvancedSettings &&
        advancedSettings.mode !== NORMAL &&
        chatContents.length === 0 &&
        instanceData.vectorDb !== PGVECTOR
          ? filteredAdvancedSettings
          : undefined,
    };
    if (llmGuardOption === 'Yes') {
      handleLlmGuardSubmit(payload);
    } else handlePromptSubmit(payload);
  };

  const handleRegenerate = async (content: string) => {
    setIsLoading(true);
    const { enableChatRegeneration, debugMode, ...filteredAdvancedSettings } =
      advancedSettings;
    const payload = {
      input_text:
        chatContents.length <= 2
          ? chatContents[chatContents.length - 2].content
          : undefined,
      messages:
        chatContents.length > 2
          ? [
              { role: 'system', content: 'You are a helpful assistant' },
              ...chatContents.slice(0, -2),
              {
                role: 'user',
                content: chatContents[chatContents.length - 2].content,
              },
            ]
          : undefined,
      instance_id: selectedInstance,
      include_references: true,
      advance_search_params:
        useAdvancedSettings &&
        advancedSettings.mode !== NORMAL &&
        chatContents.length <= 2 &&
        instanceData.vectorDb !== PGVECTOR
          ? filteredAdvancedSettings
          : undefined,
    };
    if (llmGuardOption === 'Yes') {
      await handleLlmGuardSubmit(payload);
    } else {
      await handlePromptSubmit(payload, true);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | React.MouseEvent) => {
      if (
        overlayContainerRef.current &&
        !overlayContainerRef.current.contains(event.target as Node)
      ) {
        setShowAdvancedSettings(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const chatClearCallback = () => {
    setShowToast(false);
    setChatContents([]);
  };

  const clearSelectedFile = () => {
    if (filesSelectedForUploadRef && filesSelectedForUploadRef.current) {
      filesSelectedForUploadRef.current.value = '';
    }
  };

  return (
    <div className="application-page-container">
      <div className="title">{currentApp?.name}</div>
      <div className="back-button-container">
        <Link to={`/project/${projectId}`}>
          <BackIcon /> Back
        </Link>
        {currentApp?.name === DOC_INSIGHT ? (
          <div ref={overlayContainerRef}>
            <AdvancedSettings
              chatbox={chatbox}
              overlayContainerRef={overlayContainerRef}
              vectorDb={instanceData.vectorDb}
            />
          </div>
        ) : (
          <div>
            {selectedInstance && (
              <div className="cloud-icons">
                {llmCloud === 'gcp' ? (
                  <SiGooglecloud style={{ fontSize: '1.5rem' }} />
                ) : llmCloud === 'aws' ? (
                  <SiAmazonaws style={{ fontSize: '1.8rem' }} />
                ) : (
                  <SiAzuredevops />
                )}
                <div className="pipe"></div>
                <span className="cloud-texts">
                  {llmCloud === 'gcp'
                    ? 'GCP'
                    : llmCloud === 'aws'
                      ? 'AWS'
                      : 'Azure'}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="single-app-container">
        {currentApp?.name === DOC_INSIGHT && (
          <SelectInstance
            projectId={projectId}
            currentApp={currentApp}
            step3={false}
            chatbox={chatbox}
            setOutputData={setOutputData}
            setInstanceDetails={setInstanceData}
            instanceDetails={instanceData}
            setisUploadingProps={setIsUploadLoading}
            setIsUploadResponseProps={setIsUploadResponse}
          />
        )}
        {currentApp?.name === SCAN_TO_SUMMIFY && (
          <SelectInstance
            projectId={projectId}
            currentApp={currentApp}
            step3={false}
            outputData={outputData}
            setOutputData={setOutputData}
            setInstanceDetails={setInstanceData}
            instanceDetails={instanceData}
            setisUploadingProps={setIsUploadLoading}
            setIsUploadResponseProps={setIsUploadResponse}
          />
        )}
        <Form.Group className="file-input" controlId="exampleForm.fileInput">
          <Form.Label>Upload document</Form.Label>
          <Form.Control
            type="file"
            accept={
              currentApp?.name === DOC_INSIGHT ? File_Format : File_Formats
            }
            ref={filesSelectedForUploadRef}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
              currentApp?.name === DOC_INSIGHT
                ? handleFileChangeInDocumentInsight(evt?.target?.files)
                : handleFileChange(evt?.target?.files);
            }}
            disabled={!selectedInstance}
          />
          {isUploadLoading && (
            <div className="upload-spinner">
              <Spinner animation="border" size="sm" /> Uploading
            </div>
          )}
          {isUploadResponse && (
            <div className="upload-msg">
              <Badge bg="success">Upload Successful</Badge>
            </div>
          )}
        </Form.Group>
        {currentApp?.name === DOC_INSIGHT ? (
          <Chatbox
            submitPrompt={submitPrompt}
            chatClearCallback={chatClearCallback}
            ref={chatbox}
            useMarkdownFormat={instanceData.renderMarkdown}
            handleAbortStream={handleAbortStream}
            handleRegenerate={handleRegenerate}
          />
        ) : (
          <div className="user-story-generator-submit-container">
            {isLoading ? (
              <Button
                className="user-story-generator-submit "
                disabled={true}
                style={{ width: '104px', height: '36px' }}
              >
                <ContentLoader />
              </Button>
            ) : (
              <Button
                className="user-story-generator-submit "
                as="input"
                type="submit"
                value="Submit"
                onClick={submitDocumentOCR}
                disabled={!uploadedFiles || !selectedInstance?.length}
              />
            )}
            <ClearChat
              disabled={outputData?.length > 0 ? false : true}
              setUploadedFiles={setUploadedFiles}
              setOutputData={setOutputData}
              clearSelectedFile={clearSelectedFile}
              setShowRawTranscript={setShowSanitizedPrompt}
            />
          </div>
        )}
      </div>
      {outputData && currentApp?.name !== DOC_INSIGHT && (
        <>
          {instanceData.renderMarkdown ? (
            <Form.Group
              className="output-container-input"
              controlId="outputTextArea"
            >
              <div className="output-container markdown-table">
                <Markdown remarkPlugins={[remarkGfm]}>{outputData}</Markdown>
              </div>
            </Form.Group>
          ) : (
            <Form.Group
              className="user-input"
              controlId="exampleForm.output"
              style={{ display: 'flex' }}
            >
              <Form.Control
                as="textarea"
                rows={18}
                className="output-container"
                value={outputData}
                disabled
              />
            </Form.Group>
          )}
          <Form.Group
            className="transcript-toggle-container"
            style={{ marginBottom: showRawTranscript ? '0.5rem' : '1.5rem' }}
          >
            <Form.Check
              type="switch"
              id="transcript-view-switch"
              label=" View Raw Transcript"
              onChange={(evt) => setShowRawTranscript(evt.target.checked)}
            />
          </Form.Group>
          {showRawTranscript && (
            <Form.Group controlId="outputTextArea" style={{ display: 'flex' }}>
              <Form.Control
                as="textarea"
                rows={18}
                className="output-container"
                value={`Transcript:\n${transcriptData}`}
                disabled
              />
            </Form.Group>
          )}
          {llmGuardOption === 'Yes' && sanitizedPrompt && (
            <Form.Group className="transcript-toggle-container">
              <Form.Check
                type="switch"
                id="transcript-view-switch"
                label=" View Sanitized Prompt"
                onChange={(evt) => setShowSanitizedPrompt(evt.target.checked)}
              />
            </Form.Group>
          )}
          {showSanitizedPrompt && (
            <Form.Group controlId="outputTextArea">
              <Form.Control
                as="textarea"
                rows={10}
                className="output-container"
                value={`Sanitized Prompt:\n${sanitizedPrompt}`}
                disabled
              />
            </Form.Group>
          )}
        </>
      )}
      {currentApp?.name === DOC_INSIGHT && chatContents?.length !== 0 && (
        <>
          {llmGuardOption === 'Yes' && sanitizedPrompt && (
            <Form.Group className="transcript-toggle-container">
              <Form.Check
                type="switch"
                id="transcript-view-switch"
                label=" View Sanitized Prompt"
                onChange={(evt) => setShowSanitizedPrompt(evt.target.checked)}
              />
            </Form.Group>
          )}
          {showSanitizedPrompt && (
            <Form.Group controlId="outputTextArea">
              <Form.Control
                as="textarea"
                rows={10}
                className="output-container"
                value={`Sanitized Prompt:\n${sanitizedPrompt}`}
                disabled
              />
            </Form.Group>
          )}
        </>
      )}
      {showToast && (
        <div style={{ paddingTop: '80px', width: '50vw', margin: '0 auto' }}>
          <Alert
            variant="danger"
            onClose={() => setShowToast(false)}
            dismissible
          >
            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
            <p>
              {showErrMsg
                ? showErrMsg
                : `Something went wrong. Please try again`}
            </p>
          </Alert>
        </div>
      )}
      {showFeedbackOptions &&
        currentApp?.name !== DOC_INSIGHT &&
        outputData?.length !== 0 && (
          <CustomFeedback
            url={`${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/activity/feedback/update`}
            activityId={activityId}
          />
        )}
      {showFeedbackOptions && chatContents?.length !== 0 && (
        <CustomFeedback
          url={`${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/activity/feedback/update`}
          activityId={activityId}
        />
      )}
      {showAdvancedSettingsAlert && (
        <Alert
          variant="danger"
          onClose={() => setShowAdvancedSettingsAlert(false)}
          dismissible
        >
          <Alert.Heading>Advanced Settings</Alert.Heading>
          <p>{ADVANCED_ERROR_MESSAGE}</p>
        </Alert>
      )}
    </div>
  );
};

export default DocumentOCR;
