import React, { useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import {
  Modal,
  Button,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AddEditCopilotFunctions.css';
import axios from 'axios';

interface AddEditCopilotFunctionsProps {
  btnText: string;
  id: string;
  sendMessageToCopilot: (message: string) => void;
  data: {
    id?: number;
    name: string;
    description: string;
    active: boolean;
    disabled?: boolean;
    sample_question: string;
  };
}

const AddEditCopilotFunctions: React.FC<AddEditCopilotFunctionsProps> = (
  props
) => {
  const [name, setName] = React.useState('');
  const [description, setdescription] = React.useState('');
  const [activeFlag, setActiveFlag] = React.useState(false);
  const [sampleQuestion, setSampleQuestion] = React.useState('');
  const [sampleQuestionError, setSampleQuestionError] = useState<string | null>(
    null
  );
  const [duplicateNameError, setDuplicateNameError] = useState<string | null>(
    null
  );
  const [show, setShow] = React.useState(false);

  const handleClose = () => {
    setName('');
    setdescription('');
    setActiveFlag(false);
    setSampleQuestion('');
    setSampleQuestionError(null);
    props.sendMessageToCopilot('success');
    setShow(false);
    toast.dismiss();
  };
  const handleShow = () => {
    setName(props.data.name);
    setdescription(props.data.description);
    setActiveFlag(props.data.active);
    setSampleQuestion(props.data.sample_question);
    setShow(true);
    setDuplicateNameError(null);
    setSampleQuestionError(null);
  };
  const onchangeCopilotFunction = (event: any) => {
    if (event.target.name === 'name') {
      setName(event.target.value);
    } else if (event.target.name === 'description') {
      setdescription(event.target.value);
    } else if (event.target.name === 'active') {
      setActiveFlag(event.target.checked);
    } else if (event.target.name === 'sample_question') {
      const value = event.target.value;
      if (value.length > 200) {
        setSampleQuestionError('Sample question cannot exceed 200 characters.');
      } else {
        setSampleQuestion(value);
        setSampleQuestionError(null);
      }
    }
  };

  const handleNameBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const newName = event.target.value.replace(/\s+/g, '_');
    setName(newName);
  };

  // fetching instaces from DB
  const createFunction = async () => {
    try {
      if (!name || !description || !sampleQuestion) {
        // Show an error message or alert to the user indicating that name and description are mandatory.
        toast.error(
          'Name,Description, and Sample Question are mandatory fields.',
          {
            onClick: () => toast.dismiss(),
            autoClose: 1000,
          }
        );
        return;
      }
      if (sampleQuestion.length > 200) {
        toast.error('Sample question cannot exceed 200 characters.', {
          onClick: () => toast.dismiss(),
          autoClose: 1000,
        });
        return;
      }
      const isDuplicate = await checkDuplicateName(name);
      if (isDuplicate) {
        setDuplicateNameError(
          'Duplicate function name. Please make sure that the function names are unique'
        );
        return;
      }

      const copilot_params = {
        name: name,
        description: description,
        active: activeFlag,
        sample_question: sampleQuestion,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_COPILOT_BACKEND_DOMAIN}/api/functions/createFunction/` +
          `${props.id}`,
        copilot_params
      );

      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // const data = await response.json();
      const data = response.data;
      // Handle the response data directly here
      handleClose();
    } catch (error) {
      // Handle errors
      //console.error("Error:", error);
      toast.error(
        'An error has occurred. Please reach out to the administrator',
        {
          onClick: () => toast.dismiss(),
          autoClose: 1000,
        }
      );
    }
  };

  // fetching instaces from DB
  const editFunction = async () => {
    try {
      if (!name || !description || !sampleQuestion) {
        // Show an error message or alert to the user indicating that name and description are mandatory.
        toast.error(
          'Name,Description, and Sample Question are mandatory fields.',
          {
            onClick: () => toast.dismiss(),
            autoClose: 1000,
          }
        );
        return;
      }
      if (sampleQuestion.length > 200) {
        toast.error('Sample question cannot exceed 200 characters.', {
          onClick: () => toast.dismiss(),
          autoClose: 1000,
        });
        return;
      }
      const isDuplicate = await checkDuplicateName(name);
      if (isDuplicate) {
        setDuplicateNameError(
          'Duplicate function name. Please make sure that the function names are unique'
        );
        return;
      }

      const copilot_params = {
        name: name,
        description: description,
        active: activeFlag,
        sample_question: sampleQuestion,
      };
      const response = await axios.put(
        `${process.env.REACT_APP_COPILOT_BACKEND_DOMAIN}/api/functions/updateFunction/` +
          `${props.id}`,
        copilot_params
      );

      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // const data = await response.json();
      const data = response.data;

      // Handle the response data directly here
      handleClose();
    } catch (error) {
      // Handle errors
      //console.error("Error:", error);
      toast.error(
        'An error has occurred. Please reach out to the administrator',
        {
          onClick: () => toast.dismiss(),
          autoClose: 1000,
        }
      );
    }
  };

  const checkDuplicateName = async (name: string): Promise<boolean> => {
    try {
      const {
        id,
        data: { name: originalName },
      } = props;

      const copilot_params = {
        copilot_instance_id: props.id,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_COPILOT_BACKEND_DOMAIN}/api/functions/getFunctions`,
        copilot_params
      );
      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Check if the given name is in the array
      const functions = response.data as { id: number; name: string }[];

      const isDuplicate = functions.some((entry) => {
        return (
          entry.name.toLowerCase() === name.toLowerCase() &&
          entry.id !== Number(id)
        );
      });

      if (isDuplicate) {
        toast.error(
          'Duplicate function name. Please make sure that the function names are unique',
          {
            onClick: () => toast.dismiss(),
            autoClose: 1000,
          }
        );
      }
      return isDuplicate;
    } catch (error) {
      console.error('Error checking duplicate name:', error);
      return false;
    }
  };

  return (
    <>
      <ToastContainer
        toastClassName="custom-toast-error custom-toast-container"
        closeButton={false}
        hideProgressBar={true}
      />
      <div>
        {props.btnText !== 'Edit' && (
          <Button className="btn btn-primary" onClick={handleShow}>
            {props.btnText}
          </Button>
        )}
        {props.btnText === 'Edit' && (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Edit Function</Tooltip>}
          >
            <Button className="edit-btn " onClick={handleShow}>
              <FaEdit></FaEdit>
            </Button>
          </OverlayTrigger>
        )}
      </div>
      <ToastContainer closeOnClick autoClose={false} />
      {/* {props.btnText==='Edit' && <Button className="btn btn-primary" style={{ float: 'inline-end',marginBottom: '12px'}} onClick={handleShow}>{props.btnText}</Button>} */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="custom-modal-header">
          <div>
            {props.btnText !== 'Edit' && (
              <Modal.Title>{props.btnText}</Modal.Title>
            )}
            {props.btnText === 'Edit' && (
              <Modal.Title>{props.btnText} Function</Modal.Title>
            )}
          </div>
          <div className="custom-close-button-container">
            <button className="custom-close-button" onClick={handleClose}>
              &times;
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="container-box">
          {/* <div className="my-3">
            <form className="container">
              <Row>
                <Col xs={12} md={12}>
                  <div>
                    <strong>Name:</strong>
                    <input
                      type="text"
                      className="form-control my-2"
                      name="name"
                      onChange={onchangeCopilotFunction}
                      value={name}
                      required
                    />
                  </div>
                  <div className="my-3">
                    <strong>Description:</strong>

                    <textarea
                      name="description"
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows={5}
                      value={description}
                      onChange={onchangeCopilotFunction}
                      required={true}
                    ></textarea>
                  </div>
                  <div className="md-3">
                    <strong className="my-3">Active:</strong>
                    <input
                      className="form-check-input"
                      style={{ marginLeft: "10px" }}
                      type="checkbox"
                      name="active"
                      checked={activeFlag}
                      onChange={onchangeCopilotFunction}
                      id="flexCheckChecked"
                    />
                  </div>
                </Col>
              </Row>
            </form>
          </div> */}

          <div className="my-3">
            <form className="container">
              <Row>
                <Col xs={12} md={12}>
                  <div>
                    <strong>
                      Name
                      <span className="required-asterisk">*</span>
                      <OverlayTrigger
                        placement="top-end"
                        overlay={
                          <Tooltip id="tooltip-name">
                            Define the name of the function
                          </Tooltip>
                        }
                      >
                        <span style={{ display: 'inline-block' }}>
                          <FaInfoCircle className="info-icon" />
                        </span>
                      </OverlayTrigger>
                    </strong>
                    <input
                      type="text"
                      className="form-control my-2"
                      name="name"
                      onChange={onchangeCopilotFunction}
                      onBlur={handleNameBlur}
                      value={name}
                      required
                      placeholder="Enter name"
                    />
                  </div>
                  <div className="my-3">
                    <strong>
                      Description
                      <span className="required-asterisk">*</span>
                      <OverlayTrigger
                        placement="top-end"
                        overlay={
                          <Tooltip id="tooltip-description">
                            Provide a brief description to the function
                          </Tooltip>
                        }
                      >
                        <span style={{ display: 'inline-block' }}>
                          <FaInfoCircle className="info-icon" />
                        </span>
                      </OverlayTrigger>
                    </strong>

                    <textarea
                      name="description"
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows={5}
                      value={description}
                      onChange={onchangeCopilotFunction}
                      required={true}
                      placeholder="Enter description"
                    ></textarea>
                  </div>
                  <div className="my-3">
                    <strong>
                      Sample Query
                      <span className="required-asterisk">*</span>
                      <OverlayTrigger
                        placement="top-end"
                        overlay={
                          <Tooltip id="tooltip-sample-question">
                            Provide a sample question for the function
                          </Tooltip>
                        }
                      >
                        <span style={{ display: 'inline-block' }}>
                          <FaInfoCircle className="info-icon" />
                        </span>
                      </OverlayTrigger>
                    </strong>
                    <input
                      name="sample_question"
                      className="form-control my-2"
                      value={sampleQuestion}
                      onChange={onchangeCopilotFunction}
                      required={true}
                      placeholder="Enter sample question"
                    ></input>
                    {sampleQuestionError && (
                      <div className="text-danger">{sampleQuestionError}</div>
                    )}
                  </div>
                  <div className="md-3">
                    <strong className="my-3">
                      Active{' '}
                      <OverlayTrigger
                        placement="top-end"
                        overlay={
                          <Tooltip id="tooltip-active">
                            Check to make it active
                          </Tooltip>
                        }
                      >
                        <span style={{ display: 'inline-block' }}>
                          <FaInfoCircle className="info-icon" />
                        </span>
                      </OverlayTrigger>
                    </strong>
                    <label className="switch">
                      <input
                        type="checkbox"
                        id="flexCheckChecked"
                        onChange={onchangeCopilotFunction}
                        checked={activeFlag}
                        name="active"
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </Col>
              </Row>
            </form>
          </div>
          <div className="container container-flex">
            {props.btnText === 'Edit' && (
              <button className="save-button" onClick={editFunction}>
                <strong>Save</strong>
              </button>
            )}
            {props.btnText !== 'Edit' && (
              <button className="save-button" onClick={createFunction}>
                <strong>Save</strong>
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

//Set default props for AddEditCopilotFunctions
AddEditCopilotFunctions.defaultProps = {
  data: {
    name: '',
    description: '',
    active: false,
    disabled: false,
    sample_question: '',
  },
};

export default AddEditCopilotFunctions;
