import React from 'react';
import { PaginationProps } from '../../interfaces';

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const maxVisiblePages = 3;
  const pages = [];

  if (totalPages <= maxVisiblePages) {
    // If fewer total pages than the max visible pages,display all page buttons
    for (let page = 1; page <= totalPages; page++) {
      pages.push(
        <button
          key={page}
          className={`pagination-pages ${page === currentPage ? 'active' : ''}`}
          onClick={() => onPageChange(page)}
        >
          {page}
        </button>
      );
    }
  } else {
    // range of pages to display around the current page
    const startPage = Math.max(
      1,
      currentPage - Math.floor(maxVisiblePages / 2)
    );
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    // first page button
    if (currentPage !== 1) {
      pages.push(
        <button
          key="first"
          className={`pagination-pages ${1 === currentPage ? 'active' : ''}`}
          onClick={() => onPageChange(1)}
        >
          &lt;&lt;
        </button>
      );
    }

    // Display ellipses if first page not visible
    if (startPage > 1) {
      pages.push(
        <button
          key="previous"
          className="pagination-pages"
          onClick={() => onPageChange(currentPage - 1)}
        >
          {'<'}
        </button>
      );
    }

    // Display other page buttons.
    for (let page = startPage; page <= endPage; page++) {
      pages.push(
        <button
          key={page}
          className={`pagination-pages ${page === currentPage ? 'active' : ''}`}
          onClick={() => onPageChange(page)}
        >
          {page}
        </button>
      );
    }

    // Display ellipses if last page not visible
    if (endPage < totalPages) {
      pages.push(
        <button
          key="next"
          className="pagination-pages"
          onClick={() => onPageChange(currentPage + 1)}
        >
          {'>'}
        </button>
      );
    }

    //  last page button
    if (currentPage !== totalPages) {
      pages.push(
        <button
          key="last"
          className={`pagination-pages ${totalPages === currentPage ? 'active' : ''}`}
          onClick={() => onPageChange(totalPages)}
        >
          &gt;&gt;
        </button>
      );
    }
  }

  return <div className="pagination">{pages}</div>;
};
export default Pagination;
