import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  Col,
  Row,
  Container,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import MultiSelectDropdown from './DropdownBadge';
import InputBadgeCreator from './inputbadge';
import axios from 'axios';
import './TrustworthyLlm.css';
import { FaInfoCircle } from 'react-icons/fa';
import UserContext from '../../context/UserContext';
import {
  Anonymize,
  BanCode,
  BanCompetitors,
  BanSubstrings,
  BanTopics,
  Code,
  TokenLimit,
  Toxicity,
  Secrets,
  Sentiment,
  Language,
  PromptInjection,
  Gibberish,
  InvisibleText,
  Regex,
  ADMIN,
  SUPERADMIN,
} from '../../utils/constants';
interface Option {
  id: string;
  option: string;
  label: string;
}

const anonymize_options: Option[] = [
  { id: '1', option: 'CREDIT_CARD', label: 'CREDIT_CARD' },
  { id: '2', option: 'PERSON', label: 'PERSON' },
  { id: '3', option: 'PHONE_NUMBER', label: 'PHONE_NUMBER' },
  { id: '4', option: 'URL', label: 'URL' },
  { id: '5', option: 'EMAIL_ADDRESS', label: 'EMAIL_ADDRESS' },
  { id: '6', option: 'IP_ADDRESS', label: 'IP_ADDRESS' },
  { id: '7', option: 'UUID', label: 'UUID' },
  { id: '8', option: 'US_SSN', label: 'US_SSN' },
  { id: '9', option: 'CRYPTO', label: 'CRYPTO' },
  { id: '10', option: 'IBAN_CODE', label: 'IBAN_CODE' },
  { id: '11', option: 'LOCATION', label: 'LOCATION' },
];

const regex_pattern_options: Option[] = [
  { id: '1', option: 'PASSPORT_NUMBER', label: 'PASSPORT_NUMBER' },
];

const programming_lang: Option[] = [
  { id: '1', option: 'ARM Assembly', label: 'ARM Assembly' },
  { id: '2', option: 'AppleScript', label: 'AppleScript' },
  { id: '3', option: 'C', label: 'C' },
  { id: '4', option: 'C#', label: 'C#' },
  { id: '5', option: 'C++', label: 'C++' },
  { id: '6', option: 'COBOL', label: 'COBOL' },
  { id: '7', option: 'Erlang', label: 'Erlang' },
  { id: '8', option: 'Fortran', label: 'Fortran' },
  { id: '9', option: 'Go', label: 'Go' },
  { id: '10', option: 'Java', label: 'Java' },
  { id: '11', option: 'JavaScript', label: 'JavaScript' },
  { id: '12', option: 'Kotlin', label: 'Kotlin' },
  { id: '13', option: 'Lua', label: 'Lua' },
  {
    id: '14',
    option: 'Mathematica/Wolfram Language',
    label: 'Mathematica/Wolfram Language',
  },
  { id: '15', option: 'PHP', label: 'PHP' },
  { id: '16', option: 'Pascal', label: 'Pascal' },
  { id: '17', option: 'Perl', label: 'Perl' },
  { id: '18', option: 'PowerShell', label: 'PowerShell' },
  { id: '19', option: 'Python', label: 'Python' },
  { id: '20', option: 'R', label: 'R' },
  { id: '21', option: 'Ruby', label: 'Ruby' },
  { id: '22', option: 'Rust', label: 'Rust' },
  { id: '23', option: 'Scala', label: 'Scala' },
  { id: '24', option: 'Swift', label: 'Swift' },
  { id: '25', option: 'Visual Basic .NET', label: 'Visual Basic .NET' },
  { id: '26', option: 'jq', label: 'jq' },
];

const language_options: Option[] = [
  { id: '1', option: 'ar', label: 'arabic (ar)' },
  { id: '2', option: 'bg', label: 'bulgarian (bg)' },
  { id: '3', option: 'de', label: 'german (de)' },
  { id: '4', option: 'el', label: 'modern greek (el)' },
  { id: '5', option: 'en', label: 'english (en)' },
  { id: '6', option: 'es', label: 'spanish (es)' },
  { id: '7', option: 'fr', label: 'french (fr)' },
  { id: '8', option: 'hi', label: 'hindi (hi)' },
  { id: '9', option: 'it', label: 'italian (it)' },
  { id: '10', option: 'ja', label: 'japanese (ja)' },
  { id: '11', option: 'nl', label: 'dutch (nl)' },
  { id: '12', option: 'pl', label: 'polish (pl)' },
  { id: '13', option: 'pt', label: 'portuguese (pt)' },
  { id: '14', option: 'ru', label: 'russian (ru)' },
  { id: '15', option: 'sw', label: 'swahili (sw)' },
  { id: '16', option: 'th', label: 'thai (th)' },
  { id: '17', option: 'tr', label: 'turkish (tr)' },
  { id: '18', option: 'ur', label: 'urdu (ur)' },
  { id: '19', option: 'vi', label: 'vietnamese (vi)' },
  { id: '20', option: 'zh', label: 'chinese (zh)' },
];

interface DataProps {
  instanceData?: any;
  nextStep: any;
  prevStep: any;
  setShowNewInstanceModal: any;
  instanceDetails?: any;
}

type RegexPatterns = {
  name: [];
  expressions: [];
  context: [];
};

type Params = {
  allowed_names?: string[];
  hidden_names?: string[];
  entity_types?: string[];
  preamble?: string;
  threshold?: number;
  use_faker?: boolean;
  regex_patterns?: RegexPatterns[];
  language?: string;
  competitors?: string[];
  redact?: boolean;
  substrings?: string[];
  match_type?: string;
  case_sensitive?: boolean;
  contains_all?: boolean;
  topics?: string[];
  languages?: string[];
  is_blocked?: boolean;
  valid_languages?: string[];
  patterns?: string[];
  redact_mode?: string;
  limit?: number;
};

type Scanner = {
  type: string;
  params: Params;
};

type ToggleStates = {
  [key: string]: boolean;
};

type AccordionStates = {
  [key: string]: string | null;
};

const TrusthWorthyLlmForm: React.FC<DataProps> = ({
  instanceData,
  nextStep,
  prevStep,
  setShowNewInstanceModal,
  instanceDetails,
}) => {
  const [toggles, setToggles] = React.useState<ToggleStates>({
    Anonymize: true,
    Secrets: false,
    Sentiment: false,
    BanCode: false,
    BanCompetitors: false,
    BanSubstrings: false,
    BanTopics: false,
    Code: false,
    Gibberish: false,
    TokenLimit: false,
    Language: false,
    PromptInjection: false,
    Regex: false,
    Toxicity: false,
  });
  const [activeKeys, setActiveKeys] = useState<AccordionStates>({});
  const userRole = JSON.parse(sessionStorage.getItem('role') || '{}');
  const [llmGuard, setLlmGuard] = useState<{ input_scanners: Scanner[] }>({
    input_scanners: [
      {
        type: Anonymize,
        params: {
          language: 'en',
          preamble: '',
          threshold: 0.2,
          use_faker: false,
          entity_types: [
            'CREDIT_CARD',
            'EMAIL_ADDRESS',
            'IP_ADDRESS',
            'LOCATION',
            'PHONE_NUMBER',
            'US_SSN',
          ],
          hidden_names: [],
          allowed_names: [],
          // regex_patterns: [
          //   {
          //     name: [],
          //     expressions: [],
          //     context: [],
          //   },
          // ],
        },
      },
      {
        type: BanCode,
        params: {
          threshold: 0.97,
        },
      },
      {
        type: BanCompetitors,
        params: {
          competitors: [],
          threshold: 0.5,
          redact: true,
        },
      },
      {
        type: BanSubstrings,
        params: {
          substrings: [],
          match_type: '',
          case_sensitive: false,
          redact: true,
          contains_all: false,
        },
      },
      {
        type: BanTopics,
        params: {
          topics: [],
          threshold: 0.75,
        },
      },
      {
        type: Code,
        params: {
          languages: ['Python'],
          is_blocked: true,
          threshold: 0.5,
        },
      },
      {
        type: Gibberish,
        params: {
          match_type: 'full',
          threshold: 0.5,
        },
      },
      {
        type: InvisibleText,
        params: {},
      },
      {
        type: Language,
        params: {
          valid_languages: ['en'],
          threshold: 0.5,
          match_type: 'full',
        },
      },
      {
        type: PromptInjection,
        params: {
          threshold: 0.9,
          match_type: 'full',
        },
      },
      {
        type: Regex,
        params: {
          patterns: [],
          is_blocked: true,
          match_type: 'fullmatch',
          redact: true,
        },
      },
      {
        type: Secrets,
        params: {
          redact_mode: 'all',
        },
      },
      {
        type: Sentiment,
        params: {
          threshold: -0.1,
        },
      },
      {
        type: TokenLimit,
        params: {
          limit: 4096,
        },
      },
      {
        type: Toxicity,
        params: {
          threshold: 0.5,
          match_type: 'full',
        },
      },
    ],
  });
  const userContext = useContext(UserContext) || {
    setShowToast: () => {},
    setShowErrMsg: () => {},
  };
  const { setShowToast, setShowErrMsg } = userContext;

  const handleToggleChange = (id: string, value: boolean) => {
    setToggles((prevToggles) => ({
      ...prevToggles,
      [id]: value,
    }));
    // if (!value) {
    //   setActiveKeys((prevActiveKeys) => ({
    //     ...prevActiveKeys,
    //     [id]: null,
    //   }));
    // }
  };

  const handleAccordionToggle = (id: string, eventKey: string) => {
    setActiveKeys((prevActiveKeys) => ({
      ...prevActiveKeys,
      [id]:
        prevActiveKeys[id] === eventKey ? null : toggles[id] ? eventKey : null,
    }));
  };

  const handleFinalClick = () => {
    const payload = llmGuard;
    const newPayload = {
      ...payload,
      input_scanners: payload.input_scanners.filter(
        (scanner) => toggles[scanner.type]
      ),
    };
    if (isLlmGuardEmpty()) {
      setShowToast(true);
      setShowErrMsg('Please fill all the required fields');
      return;
    } else finalAPiCall(newPayload);
  };

  const finalAPiCall = async (payload: any) => {
    try {
      let templateApi = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/instance/update/scanner_configs/${instanceData}`;
      let response;
      response = await axios.put(templateApi, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const responseData = response.data;
      if (response.status === 200) {
        nextStep();
        setShowToast(false);
        return responseData;
      } else {
        const error = responseData?.error;
        setShowToast(true);
        setShowErrMsg(error?.errorMessage);
        console.error('response error', error);
        return;
      }
    } catch (error: any) {
      setShowToast(true);
      const err = error?.response?.data?.error;
      setShowErrMsg(err.errorMessage);
      console.error('Error:', error);
      return err;
    }
  };

  useEffect(() => {
    if (instanceDetails && instanceDetails.scannerConfigs) {
      setLlmGuard((prevState) => {
        const newState = { ...prevState };
        if (
          prevState.input_scanners &&
          instanceDetails.scannerConfigs.input_scanners
        ) {
          newState.input_scanners = [...prevState.input_scanners];

          for (let scanner of instanceDetails.scannerConfigs.input_scanners) {
            const index = newState.input_scanners.findIndex(
              (s) => s.type === scanner.type
            );

            if (index !== -1) {
              newState.input_scanners[index] = scanner;
            } else {
              newState.input_scanners.push(scanner);
            }
          }
        }
        return newState;
      });
      setToggles((prevState) => {
        const newState = { ...prevState };
        const hasAnonymize = instanceDetails.scannerConfigs.input_scanners.some(
          (scanner: any) => scanner.type === Anonymize
        );
        for (let scanner of instanceDetails.scannerConfigs.input_scanners) {
          newState[scanner.type] = true;
        }
        if (!hasAnonymize && toggles[Anonymize]) {
          newState[Anonymize] = false;
        }
        return newState;
      });
    }
  }, [instanceDetails]);

  const isLlmGuardEmpty = () => {
    for (let scanner of llmGuard.input_scanners) {
      if (toggles[scanner.type]) {
        switch (scanner.type) {
          case BanCompetitors:
            if (scanner.params.competitors?.length === 0) return true;
            break;
          case BanSubstrings:
            if (scanner.params.substrings?.length === 0) return true;
            break;
          case BanTopics:
            if (scanner.params.topics?.length === 0) return true;
            break;
          case Regex:
            if (scanner.params.patterns?.length === 0) return true;
            break;
          default:
            break;
        }
      }
    }
    return false;
  };

  const updateAnonymizeParams = (key: any, value: any, type: any) => {
    setLlmGuard((prevState) => ({
      ...prevState,
      input_scanners: prevState?.input_scanners?.map((scanner) =>
        scanner.type === type
          ? {
              ...scanner,
              params: {
                ...scanner.params,
                [key]: value,
              },
            }
          : scanner
      ),
    }));
  };

  const handlePreambleData = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newPreamble = event.target.value;
    updateAnonymizeParams('preamble', newPreamble, Anonymize);
  };

  const handleTokenLimit = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    updateAnonymizeParams('limit', newValue, TokenLimit);
  };

  const setAllowedNames = (newNames: any) => {
    updateAnonymizeParams('allowed_names', newNames, Anonymize);
  };

  const setHiddenNames = (newNames: any) => {
    updateAnonymizeParams('hidden_names', newNames, Anonymize);
  };

  const setRegexbadges = (newNames: any) => {
    updateAnonymizeParams('patterns', newNames, Regex);
  };

  const handleFakerSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked ? true : false;
    updateAnonymizeParams('use_faker', newValue, Anonymize);
  };

  const handleEntityTypesDropDown = (value: any) => {
    const selectedValue = value;
    updateAnonymizeParams('entity_types', selectedValue, Anonymize);
  };

  const regexPatternDetails = {
    PASSPORT_NUMBER: {
      expressions: ['[A-Za-z]{1}\\d{8}', '[A-Za-z]{1}\\d{7}', '[0-9]{9}'],
      context: [
        'passport',
        'passport num',
        'PN',
        'Passport Number',
        'PP Number',
      ],
    },
  };
  const handleRegexPatternDropDown = (value: any) => {
    const selectedValue = value;
    if (value.includes(selectedValue)) {
      updateAnonymizeParams(
        'regex_patterns',
        [
          {
            name: selectedValue,
            expressions: regexPatternDetails['PASSPORT_NUMBER'].expressions,
            context: regexPatternDetails['PASSPORT_NUMBER'].context,
          },
        ],
        Anonymize
      );
    } else {
      updateAnonymizeParams(
        'regex_patterns',
        [{ name: [], expressions: [], context: [] }],
        Anonymize
      );
    }
  };

  const handleLanguageTypesDropDown = (value: any) => {
    const selectedValue = value;
    updateAnonymizeParams('valid_languages', selectedValue, Language);
  };

  const handleAnonymizeThresholdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = parseFloat(event.target.value);
    updateAnonymizeParams('threshold', newValue, Anonymize);
  };

  const handleBanCodeThresholdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = parseFloat(event.target.value);
    updateAnonymizeParams('threshold', newValue, BanCode);
  };

  const handleBanCompetitorsThresholdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = parseFloat(event.target.value);
    updateAnonymizeParams('threshold', newValue, BanCompetitors);
  };

  const handleBanTopicThresholdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = parseFloat(event.target.value);
    updateAnonymizeParams('threshold', newValue, BanTopics);
  };

  const handleCodeThresholdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = parseFloat(event.target.value);
    updateAnonymizeParams('threshold', newValue, Code);
  };
  const handleGibberishThresholdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = parseFloat(event.target.value);
    updateAnonymizeParams('threshold', newValue, Gibberish);
  };

  const handleLanguageThresholdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = parseFloat(event.target.value);
    updateAnonymizeParams('threshold', newValue, Language);
  };

  const handleSentimentThresholdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = parseFloat(event.target.value);
    updateAnonymizeParams('threshold', newValue, Sentiment);
  };

  const handlePromptInjectionThresholdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = parseFloat(event.target.value);
    updateAnonymizeParams('threshold', newValue, PromptInjection);
  };

  const handleToxicityThresholdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = parseFloat(event.target.value);
    updateAnonymizeParams('threshold', newValue, Toxicity);
  };

  const setCompetitorsNames = (newNames: any) => {
    updateAnonymizeParams('competitors', newNames, BanCompetitors);
  };

  const setSubstringBadges = (newNames: any) => {
    updateAnonymizeParams('substrings', newNames, BanSubstrings);
  };

  const handleCaseSensitiveCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.checked ? true : false;
    updateAnonymizeParams('case_sensitive', newValue, BanSubstrings);
  };

  const handleRegexIsBlockedCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.checked ? true : false;
    updateAnonymizeParams('is_blocked', newValue, Regex);
  };

  const handleContainsAllCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.checked ? true : false;
    updateAnonymizeParams('contains_all', newValue, BanSubstrings);
  };

  const setTopicBadges = (newNames: any) => {
    updateAnonymizeParams('topics', newNames, BanTopics);
  };

  const handleProgLangsDropDown = (value: any) => {
    const selectedValue = value;
    updateAnonymizeParams('languages', selectedValue, Code);
  };

  const handleIsBlockedCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.checked ? true : false;
    updateAnonymizeParams('is_blocked', newValue, Code);
  };

  const handleMatchTypeCheck = (event: any) => {
    const { value } = event.target;
    updateAnonymizeParams('match_type', value, BanSubstrings);
  };

  const handleGibberishMatchTypeCheck = (event: any) => {
    const { value } = event.target;
    updateAnonymizeParams('match_type', value, Gibberish);
  };

  const handleLanguageMatchTypeCheck = (event: any) => {
    const { value } = event.target;
    updateAnonymizeParams('match_type', value, Language);
  };

  const handlePromptInjectionMatchTypeCheck = (event: any) => {
    const { value } = event.target;
    updateAnonymizeParams('match_type', value, PromptInjection);
  };

  const handleRegexMatchTypeCheck = (event: any) => {
    const { value } = event.target;
    updateAnonymizeParams('match_type', value, Regex);
  };

  const handleToxicityMatchTypeCheck = (event: any) => {
    const { value } = event.target;
    updateAnonymizeParams('match_type', value, Toxicity);
  };

  return (
    <div>
      <Container style={{ maxHeight: '400px', overflow: 'scroll' }}>
        <Row className="justify-content-md-center">
          <div className="mb-2">
            <b>Privacy</b>
          </div>
          <Row>
            <Col xs md="2">
              <Form.Check
                type="switch"
                id="Anonymize-switch"
                checked={toggles[Anonymize] || false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleToggleChange(Anonymize, event.target.checked);
                }}
              />
            </Col>
            <Col xs md="8">
              <Accordion
                activeKey={toggles[Anonymize] ? activeKeys[Anonymize] : null}
                onSelect={(eventKey) =>
                  handleAccordionToggle(Anonymize, eventKey as string)
                }
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Anonymize</Accordion.Header>
                  <Accordion.Body className="accordion-body-container">
                    <MultiSelectDropdown
                      options={anonymize_options}
                      label="Select entities to Anonymize"
                      setSelectedOption={handleEntityTypesDropDown}
                      isdisabled={!toggles[Anonymize]}
                      selectedEntity={
                        (
                          instanceDetails?.scannerConfigs?.input_scanners?.find(
                            (scanner: any) => scanner?.type === Anonymize
                          )?.params?.entity_types || []
                        )?.length > 0
                          ? instanceDetails?.scannerConfigs?.input_scanners?.find(
                              (scanner: any) => scanner?.type === Anonymize
                            )?.params?.entity_types
                          : llmGuard.input_scanners?.[0]?.params.entity_types
                      }
                    />

                    <InputBadgeCreator
                      label="Allowed Names"
                      keywordBadges={
                        instanceDetails?.scannerConfigs?.input_scanners?.[0]
                          ?.params.allowed_names
                      }
                      setKeywordBadge={setAllowedNames}
                      isdisabled={!toggles[Anonymize]}
                    />

                    <InputBadgeCreator
                      label="Hidden Names"
                      keywordBadges={
                        instanceDetails?.scannerConfigs?.input_scanners?.[0]
                          ?.params.hidden_names
                      }
                      setKeywordBadge={setHiddenNames}
                      isdisabled={!toggles[Anonymize]}
                    />
                    {/* <MultiSelectDropdown
                      options={regex_pattern_options}
                      label="Select regex patterns to Anonymize"
                      setSelectedOption={handleRegexPatternDropDown}
                      isdisabled={!toggles[Anonymize]}
                      selectedEntity={
                        instanceDetails?.scannerConfigs?.input_scanners?.find(
                          (scanner: any) => scanner?.type === Anonymize
                        )?.params.regex_patterns?.[0]?.name
                      }
                    /> */}
                    <Form.Group
                      className="user-input"
                      controlId="Preamble.userInput"
                    >
                      {/* <Form.Label>Preamble</Form.Label> */}
                      <Form.Control
                        placeholder="Preamble"
                        as="textarea"
                        rows={1}
                        value={llmGuard.input_scanners?.[0]?.params.preamble}
                        onChange={handlePreambleData}
                        disabled={!toggles[Anonymize]}
                      />
                    </Form.Group>
                    <div className="faker-libraby-switch">
                      <Form.Check
                        type="switch"
                        id="fakde-lib-switch"
                        checked={llmGuard.input_scanners?.[0]?.params.use_faker}
                        onChange={handleFakerSwitch}
                        disabled={!toggles[Anonymize]}
                      />{' '}
                      <span>Use Faker library to generate fake data</span>
                    </div>
                    <div className="basic-details-wrapper">
                      <Form.Group controlId="formSlider">
                        {' '}
                        <Form.Label className="label-text">
                          Threshold :{' '}
                          <Form.Text className="label-text">
                            {llmGuard.input_scanners?.[0]?.params.threshold}
                          </Form.Text>
                        </Form.Label>
                        <Form.Range
                          value={llmGuard.input_scanners?.[0]?.params.threshold}
                          min={0}
                          max={1}
                          step={0.1}
                          onChange={handleAnonymizeThresholdChange}
                          className="custom-slider"
                          disabled={!toggles[Anonymize]}
                        />
                      </Form.Group>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col xs md="2">
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip>
                    The Anonymize Scanner acts as your digital guardian,
                    ensuring your user prompts remain confidential and free from
                    sensitive data exposure.
                  </Tooltip>
                }
              >
                <div style={{ width: 'fit-content' }}>
                  <FaInfoCircle />
                </div>
              </OverlayTrigger>
            </Col>
          </Row>
          <Row>
            <Col xs md="2">
              <Form.Check
                type="switch"
                id="Secrets-switch"
                checked={toggles[Secrets] || false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleToggleChange(Secrets, event.target.checked);
                }}
              />
            </Col>
            <Col xs md="8">
              <Accordion
                activeKey={toggles[Secrets] ? activeKeys[Secrets] : null}
                onSelect={(eventKey) =>
                  handleAccordionToggle(Secrets, eventKey as string)
                }
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Secrets</Accordion.Header>
                  <Accordion.Body className="accordion-body-container">
                    <div className="basic-details-wrapper">
                      <Form.Label className="label-text">
                        Redact Mode
                      </Form.Label>
                      <Form.Select
                        value={
                          llmGuard.input_scanners?.find(
                            (scanner: any) => scanner?.type === Secrets
                          )?.params?.redact_mode
                        }
                        aria-label="Select Redact Mode"
                        onChange={(evt) => {
                          updateAnonymizeParams(
                            'redact_mode',
                            evt.target.value,
                            Secrets
                          );
                        }}
                        disabled={!toggles[Secrets]}
                      >
                        <option value="all">All</option>
                        <option value="partial">Partial</option>
                        <option value="hash">Hash</option>
                      </Form.Select>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col xs md="2">
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip>
                    This scanner diligently examines user inputs, ensuring that
                    they don't carry any Secrets before they are processed by
                    the language model.
                  </Tooltip>
                }
              >
                <div style={{ width: 'fit-content' }}>
                  <FaInfoCircle />
                </div>
              </OverlayTrigger>
            </Col>
          </Row>
        </Row>
        <Row className="justify-content-md-center">
          <div className="mb-2">
            <b>Fair & Impartial</b>
          </div>
          <Row>
            <Col xs md="2">
              <Form.Check
                type="switch"
                id="Sentiment-switch"
                checked={toggles[Sentiment] || false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleToggleChange(Sentiment, event.target.checked);
                }}
              />
            </Col>
            <Col xs md="8">
              <Accordion
                activeKey={toggles[Sentiment] ? activeKeys[Sentiment] : null}
                onSelect={(eventKey) =>
                  handleAccordionToggle(Sentiment, eventKey as string)
                }
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Sentiment</Accordion.Header>
                  <Accordion.Body className="accordion-body-container">
                    <div className="basic-details-wrapper">
                      <Form.Group controlId="formSlider">
                        {' '}
                        <Form.Label className="label-text">
                          Threshold :{' '}
                          <Form.Text className="label-text">
                            {
                              llmGuard.input_scanners?.find(
                                (scanner: any) => scanner?.type === Sentiment
                              )?.params.threshold
                            }
                          </Form.Text>
                        </Form.Label>
                        <Form.Range
                          value={
                            llmGuard.input_scanners?.find(
                              (scanner: any) => scanner?.type === Sentiment
                            )?.params.threshold
                          }
                          min={-1}
                          max={1}
                          step={0.1}
                          onChange={handleSentimentThresholdChange}
                          className="custom-slider"
                          disabled={!toggles[Sentiment]}
                        />
                      </Form.Group>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col xs md="2">
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip>
                    It scans and evaluates the overall Sentiment of prompts
                    using the SentimentIntensityAnalyzer from the NLTK (Natural
                    Language Toolkit) library.
                  </Tooltip>
                }
              >
                <div style={{ width: 'fit-content' }}>
                  <FaInfoCircle />
                </div>
              </OverlayTrigger>
            </Col>
          </Row>
        </Row>
        <Row className="justify-content-md-center">
          <div className="mb-2">
            <b>Responsible</b>
          </div>
          {/* <Row>
            <Col xs md="2">
              <Form.Check
                type="switch"
                id="BanCode-switch"
                checked={toggles[BanCode] || false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleToggleChange(BanCode, event.target.checked);
                }}
              />
            </Col>
            <Col xs md="8">
              <Accordion
                activeKey={toggles[BanCode] ? activeKeys[BanCode] : null}
                onSelect={(eventKey) =>
                  handleAccordionToggle(BanCode, eventKey as string)
                }
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Ban Code</Accordion.Header>
                  <Accordion.Body className="accordion-body-container">
                    <div className="basic-details-wrapper">
                      <Form.Group controlId="formSlider">
                        {' '}
                        <Form.Label className="label-text">
                          Threshold :{' '}
                          <Form.Text className="label-text">
                            {llmGuard.input_scanners?.[1]?.params.threshold}
                          </Form.Text>
                        </Form.Label>
                        <Form.Range
                          value={llmGuard.input_scanners?.[1]?.params.threshold}
                          min={0}
                          max={1}
                          step={0.01}
                          onChange={handleBanCodeThresholdChange}
                          className="custom-slider"
                          disabled={!toggles[BanCode]}
                        />
                      </Form.Group>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col xs md="2">
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip>
                    The BanCode scanner is designed to detect and ban Code in
                    the prompt.
                  </Tooltip>
                }
              >
                <div style={{ width: 'fit-content' }}>
                  <FaInfoCircle />
                </div>
              </OverlayTrigger>
            </Col>
          </Row> */}
          <Row>
            <Col xs md="2">
              <Form.Check
                type="switch"
                id="BanCompetitors-switch"
                checked={toggles[BanCompetitors] || false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleToggleChange(BanCompetitors, event.target.checked);
                }}
              />
            </Col>
            <Col xs md="8">
              <Accordion
                activeKey={
                  toggles[BanCompetitors] ? activeKeys[BanCompetitors] : null
                }
                onSelect={(eventKey) =>
                  handleAccordionToggle(BanCompetitors, eventKey as string)
                }
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Ban Competitors</Accordion.Header>
                  <Accordion.Body className="accordion-body-container">
                    <InputBadgeCreator
                      label="Competitors Names"
                      keywordBadges={
                        instanceDetails?.scannerConfigs?.input_scanners?.find(
                          (scanner: any) => scanner?.type === BanCompetitors
                        )?.params.competitors
                      }
                      setKeywordBadge={setCompetitorsNames}
                      isdisabled={!toggles[BanCompetitors]}
                      isRequired={
                        isLlmGuardEmpty() &&
                        (
                          llmGuard?.input_scanners?.find(
                            (scanner: any) => scanner?.type === BanCompetitors
                          )?.params?.competitors || []
                        )?.length === 0
                          ? true
                          : false
                      }
                    />
                    <div className="basic-details-wrapper">
                      <Form.Group controlId="formSlider">
                        {' '}
                        <Form.Label className="label-text">
                          Threshold :{' '}
                          <Form.Text className="label-text">
                            {
                              llmGuard.input_scanners?.find(
                                (scanner: any) =>
                                  scanner?.type === BanCompetitors
                              )?.params.threshold
                            }
                          </Form.Text>
                        </Form.Label>
                        <Form.Range
                          value={
                            llmGuard.input_scanners?.find(
                              (scanner: any) => scanner?.type === BanCompetitors
                            )?.params.threshold
                          }
                          min={0}
                          max={1}
                          step={0.1}
                          onChange={handleBanCompetitorsThresholdChange}
                          className="custom-slider"
                          disabled={!toggles[BanCompetitors]}
                        />
                      </Form.Group>
                    </div>
                    {/* <div className="faker-libraby-switch">
                    <Form.Check
                      type="switch"
                      id="fakde-lib-switch"
                      checked={llmGuard.input_scanners?.[2]?.params.redact}
                      onChange={handleRedactSwitch}
                      disabled={anonymizeSwitch === 'true' ? false : true}
                    />{' '}
                    <span>Redact Mode</span>
                  </div> */}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col xs md="2">
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip>
                    The BanCompetitors scanner is designed to prevent the
                    inclusion of competitor names in the prompts submitted by
                    users. This scanner ensures that prompts containing
                    references to known competitors are either flagged or
                    altered, according to user settings, to maintain a strict
                    focus on the user's own products or services.
                  </Tooltip>
                }
              >
                <div style={{ width: 'fit-content' }}>
                  <FaInfoCircle />
                </div>
              </OverlayTrigger>
            </Col>
          </Row>
          <Row>
            <Col xs md="2">
              <Form.Check
                type="switch"
                id="BanSubstrings-switch"
                checked={toggles[BanSubstrings] || false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleToggleChange(BanSubstrings, event.target.checked);
                }}
              />
            </Col>
            <Col xs md="8">
              <Accordion
                activeKey={
                  toggles[BanSubstrings] ? activeKeys[BanSubstrings] : null
                }
                onSelect={(eventKey) =>
                  handleAccordionToggle(BanSubstrings, eventKey as string)
                }
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Ban Substring</Accordion.Header>
                  <Accordion.Body className="accordion-body-container">
                    <InputBadgeCreator
                      label="Type strings here"
                      keywordBadges={
                        instanceDetails?.scannerConfigs?.input_scanners?.find(
                          (scanner: any) => scanner?.type === BanSubstrings
                        )?.params.substrings
                      }
                      setKeywordBadge={setSubstringBadges}
                      isdisabled={!toggles[BanSubstrings]}
                      isRequired={
                        isLlmGuardEmpty() &&
                        (
                          llmGuard?.input_scanners?.find(
                            (scanner: any) => scanner?.type === BanSubstrings
                          )?.params?.substrings || []
                        )?.length === 0
                          ? true
                          : false
                      }
                    />
                    <Row>
                      <Col>
                        <Form>
                          <Form.Label>Match Type</Form.Label>
                          <div key="matchTypeRadios">
                            <Form.Check
                              type="radio"
                              id="string"
                              label="String"
                              name="matchType"
                              value="string"
                              checked={
                                llmGuard.input_scanners?.find(
                                  (scanner: any) =>
                                    scanner?.type === BanSubstrings
                                )?.params.match_type === 'string'
                              }
                              onChange={handleMatchTypeCheck}
                              disabled={!toggles[BanSubstrings]}
                            />
                            <Form.Check
                              type="radio"
                              id="word"
                              label="Word"
                              name="matchType"
                              value="word"
                              checked={
                                llmGuard.input_scanners?.find(
                                  (scanner: any) =>
                                    scanner?.type === BanSubstrings
                                )?.params.match_type === 'word'
                              }
                              onChange={handleMatchTypeCheck}
                              disabled={!toggles[BanSubstrings]}
                            />
                          </div>
                        </Form>
                      </Col>
                      <Col>
                        <Form>
                          <Form.Label>Options</Form.Label>
                          <div key="optionCheckbox">
                            <Form.Check
                              type="checkbox"
                              id="CaseSensitive"
                              label="Case Sensitive"
                              name="CaseSensitive"
                              checked={
                                llmGuard.input_scanners?.find(
                                  (scanner: any) =>
                                    scanner?.type === BanSubstrings
                                )?.params.case_sensitive
                              }
                              onChange={handleCaseSensitiveCheckboxChange}
                              disabled={!toggles[BanSubstrings]}
                            />
                            {/* <Form.Check
                            type="checkbox"
                            id="Redact"
                            label="Redact"
                            name="Redact"
                            checked={
                              llmGuard.input_scanners?.[3]?.params.redact
                            }
                            onChange={handleRedactCheckboxChange}
                            disabled={!toggles[BanSubstrings]}
                          /> */}
                            <Form.Check
                              type="checkbox"
                              id="ContainsAll"
                              label="Contains All"
                              name="ContainsAll"
                              checked={
                                llmGuard.input_scanners?.find(
                                  (scanner: any) =>
                                    scanner?.type === BanSubstrings
                                )?.params.contains_all
                              }
                              onChange={handleContainsAllCheckboxChange}
                              disabled={!toggles[BanSubstrings]}
                            />
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col xs md="2">
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip>
                    Ensure that specific undesired substrings never make it into
                    your prompts with the BanSubstrings scanner.
                  </Tooltip>
                }
              >
                <div style={{ width: 'fit-content' }}>
                  <FaInfoCircle />
                </div>
              </OverlayTrigger>
            </Col>
          </Row>
          <Row>
            <Col xs md="2">
              <Form.Check
                type="switch"
                id="BanTopics-switch"
                checked={toggles[BanTopics] || false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleToggleChange(BanTopics, event.target.checked);
                }}
              />
            </Col>
            <Col xs md="8">
              <Accordion
                activeKey={toggles[BanTopics] ? activeKeys[BanTopics] : null}
                onSelect={(eventKey) =>
                  handleAccordionToggle(BanTopics, eventKey as string)
                }
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Ban Topics</Accordion.Header>
                  <Accordion.Body className="accordion-body-container">
                    <InputBadgeCreator
                      label="Type topics here"
                      keywordBadges={
                        instanceDetails?.scannerConfigs?.input_scanners?.find(
                          (scanner: any) => scanner?.type === BanTopics
                        )?.params.topics
                      }
                      setKeywordBadge={setTopicBadges}
                      isdisabled={!toggles[BanTopics]}
                      isRequired={
                        isLlmGuardEmpty() &&
                        (
                          llmGuard?.input_scanners?.find(
                            (scanner: any) => scanner?.type === BanTopics
                          )?.params?.topics || []
                        )?.length === 0
                          ? true
                          : false
                      }
                    />
                    <div className="basic-details-wrapper">
                      <Form.Group controlId="formSlider">
                        {' '}
                        <Form.Label className="label-text">
                          Threshold :{' '}
                          <Form.Text className="label-text">
                            {
                              llmGuard.input_scanners?.find(
                                (scanner: any) => scanner?.type === BanTopics
                              )?.params.threshold
                            }
                          </Form.Text>
                        </Form.Label>
                        <Form.Range
                          value={
                            llmGuard.input_scanners?.find(
                              (scanner: any) => scanner?.type === BanTopics
                            )?.params.threshold
                          }
                          min={0}
                          max={1}
                          step={0.01}
                          onChange={handleBanTopicThresholdChange}
                          className="custom-slider"
                          disabled={!toggles[BanTopics]}
                        />
                      </Form.Group>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col xs md="2">
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip>
                    This scanner is designed to restrict specific topics, such
                    as religion, violence, from being introduced in the prompt
                    using Zero-Shot classifier. This ensures that interactions
                    remain within acceptable boundaries and avoids potentially
                    sensitive or controversial discussions.
                  </Tooltip>
                }
              >
                <div style={{ width: 'fit-content' }}>
                  <FaInfoCircle />
                </div>
              </OverlayTrigger>
            </Col>
          </Row>
          <Row>
            <Col xs md="2">
              <Form.Check
                type="switch"
                id="Toxicity-switch"
                checked={toggles[Toxicity] || false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleToggleChange(Toxicity, event.target.checked);
                }}
              />
            </Col>
            <Col xs md="8">
              <Accordion
                activeKey={toggles[Toxicity] ? activeKeys[Toxicity] : null}
                onSelect={(eventKey) =>
                  handleAccordionToggle(Toxicity, eventKey as string)
                }
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Toxicity</Accordion.Header>
                  <Accordion.Body className="accordion-body-container">
                    <div className="basic-details-wrapper">
                      <Form.Group controlId="formSlider">
                        {' '}
                        <Form.Label className="label-text">
                          Threshold :{' '}
                          <Form.Text className="label-text">
                            {
                              llmGuard.input_scanners?.find(
                                (scanner: any) => scanner?.type === Toxicity
                              )?.params.threshold
                            }
                          </Form.Text>
                        </Form.Label>
                        <Form.Range
                          value={
                            llmGuard.input_scanners?.find(
                              (scanner: any) => scanner?.type === Toxicity
                            )?.params.threshold
                          }
                          min={0}
                          max={1}
                          step={0.1}
                          onChange={handleToxicityThresholdChange}
                          className="custom-slider"
                          disabled={!toggles[Toxicity]}
                        />
                      </Form.Group>
                    </div>
                    <Form>
                      <Form.Label>Match Type</Form.Label>
                      <div key="matchTypeRadios">
                        <Form.Check
                          type="radio"
                          id="full"
                          label="Full"
                          name="full"
                          value="full"
                          checked={
                            llmGuard.input_scanners?.find(
                              (scanner: any) => scanner?.type === Toxicity
                            )?.params.match_type === 'full'
                          }
                          onChange={handleToxicityMatchTypeCheck}
                          disabled={!toggles[Toxicity]}
                        />
                        <Form.Check
                          type="radio"
                          id="sentence"
                          label="Sentence"
                          name="matchType"
                          value="sentence"
                          checked={
                            llmGuard.input_scanners?.find(
                              (scanner: any) => scanner?.type === Toxicity
                            )?.params.match_type === 'sentence'
                          }
                          onChange={handleToxicityMatchTypeCheck}
                          disabled={!toggles[Toxicity]}
                        />
                      </div>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col xs md="2">
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip>
                    The Toxicity Scanner provides a mechanism to analyze and
                    mitigate the Toxicity of text content, playing a crucial
                    role in maintaining the health and safety of online
                    interactions. This tool is instrumental in preventing the
                    dissemination of harmful or offensive content.
                  </Tooltip>
                }
              >
                <div style={{ width: 'fit-content' }}>
                  <FaInfoCircle />
                </div>
              </OverlayTrigger>
            </Col>
          </Row>
        </Row>
        {/* <Row className="justify-content-md-center">
          <div className="mb-2">
            <b>Robust & Reliable</b>
          </div>
          <Row>
            <Col xs md="2">
              <Form.Check
                type="switch"
                id="TokenLimit-switch"
                checked={toggles[TokenLimit] || false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleToggleChange(TokenLimit, event.target.checked);
                }}
              />
            </Col>
            <Col xs md="8">
              <Accordion
                activeKey={
                  toggles[TokenLimit] ? activeKeys[TokenLimit] : null
                }
                onSelect={(eventKey) =>
                  handleAccordionToggle(TokenLimit, eventKey as string)
                }
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Token Limit</Accordion.Header>
                  <Accordion.Body className="accordion-body-container">
                    <div className="basic-details-wrapper">
                      <Form.Label className="label-text">Limit</Form.Label>
                      <Form.Control
                        as="input"
                        type="number"
                        placeholder="Limit"
                        className="text-input"
                        onChange={handleTokenLimit}
                        disabled={!toggles[TokenLimit]}
                        value={llmGuard.input_scanners?.[13]?.params.limit}
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col xs md="2">
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip>
                    It ensures that prompts do not exceed a predetermined token
                    count, helping prevent resource-intensive operations and
                    potential denial of service attacks on large language models
                    (LLMs).
                  </Tooltip>
                }
              >
                <div style={{ width: 'fit-content' }}>
                  <FaInfoCircle />
                </div>
              </OverlayTrigger>
            </Col>
          </Row>
        </Row> */}
        <Row className="justify-content-md-center">
          <div className="mb-2">
            <b>Safe & Secure</b>
          </div>
          <Row>
            <Col xs md="2">
              <Form.Check
                type="switch"
                id="Code-switch"
                checked={toggles[Code] || false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleToggleChange(Code, event.target.checked);
                }}
              />
            </Col>
            <Col xs md="8">
              <Accordion
                activeKey={toggles[Code] ? activeKeys[Code] : null}
                onSelect={(eventKey) =>
                  handleAccordionToggle(Code, eventKey as string)
                }
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Code</Accordion.Header>
                  <Accordion.Body className="accordion-body-container">
                    <MultiSelectDropdown
                      options={programming_lang}
                      label="Select Programming Languages"
                      setSelectedOption={handleProgLangsDropDown}
                      isdisabled={!toggles[Code]}
                      selectedEntity={
                        instanceDetails?.scannerConfigs?.input_scanners?.find(
                          (scanner: any) => scanner?.type === Code
                        )?.params?.languages
                      }
                    />
                    <div key="optionCheckbox">
                      <Form.Check
                        type="checkbox"
                        id="Blocked"
                        label="Blocked"
                        name="Blocked"
                        checked={
                          llmGuard.input_scanners?.find(
                            (scanner: any) => scanner?.type === Code
                          )?.params.is_blocked
                        }
                        onChange={handleIsBlockedCheckboxChange}
                        disabled={!toggles[Code]}
                      />
                    </div>
                    <div className="basic-details-wrapper">
                      <Form.Group controlId="formSlider">
                        {' '}
                        <Form.Label className="label-text">
                          Threshold :{' '}
                          <Form.Text className="label-text">
                            {
                              llmGuard.input_scanners?.find(
                                (scanner: any) => scanner?.type === Code
                              )?.params.threshold
                            }
                          </Form.Text>
                        </Form.Label>
                        <Form.Range
                          value={
                            llmGuard.input_scanners?.find(
                              (scanner: any) => scanner?.type === Code
                            )?.params.threshold
                          }
                          min={0}
                          max={1}
                          step={0.1}
                          onChange={handleCodeThresholdChange}
                          className="custom-slider"
                          disabled={!toggles[Code]}
                        />
                      </Form.Group>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col xs md="2">
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip>
                    This scanner is designed to detect and validate Code in the
                    prompt. It can be particularly useful in applications that
                    need to accept only Code snippets in specific languages.
                  </Tooltip>
                }
              >
                <div style={{ width: 'fit-content' }}>
                  <FaInfoCircle />
                </div>
              </OverlayTrigger>
            </Col>
          </Row>
          <Row>
            <Col xs md="2">
              <Form.Check
                type="switch"
                id="Gibberish-switch"
                checked={toggles[Gibberish] || false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleToggleChange(Gibberish, event.target.checked);
                }}
              />
            </Col>
            <Col xs md="8">
              <Accordion
                activeKey={toggles[Gibberish] ? activeKeys[Gibberish] : null}
                onSelect={(eventKey) =>
                  handleAccordionToggle(Gibberish, eventKey as string)
                }
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Gibberish</Accordion.Header>
                  <Accordion.Body className="accordion-body-container">
                    <Form>
                      <Form.Label>Match Type</Form.Label>
                      <div key="matchTypeRadios">
                        <Form.Check
                          type="radio"
                          id="full"
                          label="Full"
                          name="full"
                          value="full"
                          checked={
                            llmGuard.input_scanners?.find(
                              (scanner: any) => scanner?.type === Gibberish
                            )?.params.match_type === 'full'
                          }
                          onChange={handleGibberishMatchTypeCheck}
                          disabled={!toggles[Gibberish]}
                        />
                        <Form.Check
                          type="radio"
                          id="sentence"
                          label="Sentence"
                          name="matchType"
                          value="sentence"
                          checked={
                            llmGuard.input_scanners?.find(
                              (scanner: any) => scanner?.type === Gibberish
                            )?.params.match_type === 'sentence'
                          }
                          onChange={handleGibberishMatchTypeCheck}
                          disabled={!toggles[Gibberish]}
                        />
                      </div>
                    </Form>
                    <div className="basic-details-wrapper">
                      <Form.Group controlId="formSlider">
                        {' '}
                        <Form.Label className="label-text">
                          Threshold :{' '}
                          <Form.Text className="label-text">
                            {
                              llmGuard.input_scanners?.find(
                                (scanner: any) => scanner?.type === Gibberish
                              )?.params.threshold
                            }
                          </Form.Text>
                        </Form.Label>
                        <Form.Range
                          value={
                            llmGuard.input_scanners?.find(
                              (scanner: any) => scanner?.type === Gibberish
                            )?.params.threshold
                          }
                          min={0}
                          max={1}
                          step={0.1}
                          onChange={handleGibberishThresholdChange}
                          className="custom-slider"
                          disabled={!toggles[Gibberish]}
                        />
                      </Form.Group>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col xs md="2">
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip>
                    This scanner is designed to identify and filter out
                    gibberish or nonsensical inputs in English language text. It
                    proves invaluable in applications that require coherent and
                    meaningful user inputs, such as chatbots and automated
                    processing systems.
                  </Tooltip>
                }
              >
                <div style={{ width: 'fit-content' }}>
                  <FaInfoCircle />
                </div>
              </OverlayTrigger>
            </Col>
          </Row>
          <Row>
            <Col xs md="2">
              <Form.Check
                type="switch"
                id="Language-switch"
                checked={toggles[Language] || false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleToggleChange(Language, event.target.checked);
                }}
              />
            </Col>
            <Col xs md="8">
              <Accordion
                activeKey={toggles[Language] ? activeKeys[Language] : null}
                onSelect={(eventKey) =>
                  handleAccordionToggle(Language, eventKey as string)
                }
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Language</Accordion.Header>
                  <Accordion.Body className="accordion-body-container">
                    <MultiSelectDropdown
                      options={language_options}
                      label="Select the Allowed Languages"
                      setSelectedOption={handleLanguageTypesDropDown}
                      isdisabled={!toggles[Language]}
                      selectedEntity={
                        instanceDetails?.scannerConfigs?.input_scanners?.find(
                          (scanner: any) => scanner?.type === Language
                        )?.params?.valid_languages
                      }
                    />
                    <div className="basic-details-wrapper">
                      <Form.Group controlId="formSlider">
                        {' '}
                        <Form.Label className="label-text">
                          Threshold :{' '}
                          <Form.Text className="label-text">
                            {
                              llmGuard.input_scanners?.find(
                                (scanner: any) => scanner?.type === Language
                              )?.params.threshold
                            }
                          </Form.Text>
                        </Form.Label>
                        <Form.Range
                          value={
                            llmGuard.input_scanners?.find(
                              (scanner: any) => scanner?.type === Language
                            )?.params.threshold
                          }
                          min={0}
                          max={1}
                          step={0.1}
                          onChange={handleLanguageThresholdChange}
                          className="custom-slider"
                          disabled={!toggles[Language]}
                        />
                      </Form.Group>
                    </div>
                    <Form>
                      <Form.Label>Match Type</Form.Label>
                      <div key="matchTypeRadios">
                        <Form.Check
                          type="radio"
                          id="full"
                          label="Full"
                          name="full"
                          value="full"
                          checked={
                            llmGuard.input_scanners?.find(
                              (scanner: any) => scanner?.type === Language
                            )?.params.match_type === 'full'
                          }
                          onChange={handleLanguageMatchTypeCheck}
                          disabled={!toggles[Language]}
                        />
                        <Form.Check
                          type="radio"
                          id="sentence"
                          label="Sentence"
                          name="matchType"
                          value="sentence"
                          checked={
                            llmGuard.input_scanners?.find(
                              (scanner: any) => scanner?.type === Language
                            )?.params.match_type === 'sentence'
                          }
                          onChange={handleLanguageMatchTypeCheck}
                          disabled={!toggles[Language]}
                        />
                      </div>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col xs md="2">
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip>
                    This scanner identifies and assesses the authenticity of the
                    language used in prompts.
                  </Tooltip>
                }
              >
                <div style={{ width: 'fit-content' }}>
                  <FaInfoCircle />
                </div>
              </OverlayTrigger>
            </Col>
          </Row>
          <Row>
            <Col xs md="2">
              <Form.Check
                type="switch"
                id="PromptInjection-switch"
                checked={toggles[PromptInjection] || false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleToggleChange(PromptInjection, event.target.checked);
                }}
              />
            </Col>
            <Col xs md="8">
              <Accordion
                activeKey={
                  toggles[PromptInjection] ? activeKeys[PromptInjection] : null
                }
                onSelect={(eventKey) =>
                  handleAccordionToggle(PromptInjection, eventKey as string)
                }
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Prompt Injection</Accordion.Header>
                  <Accordion.Body className="accordion-body-container">
                    <div className="basic-details-wrapper">
                      <Form.Group controlId="formSlider">
                        {' '}
                        <Form.Label className="label-text">
                          Threshold :{' '}
                          <Form.Text className="label-text">
                            {
                              llmGuard.input_scanners?.find(
                                (scanner: any) =>
                                  scanner?.type === PromptInjection
                              )?.params.threshold
                            }
                          </Form.Text>
                        </Form.Label>
                        <Form.Range
                          value={
                            llmGuard.input_scanners?.find(
                              (scanner: any) =>
                                scanner?.type === PromptInjection
                            )?.params.threshold
                          }
                          min={0}
                          max={1}
                          step={0.1}
                          onChange={handlePromptInjectionThresholdChange}
                          className="custom-slider"
                          disabled={!toggles[PromptInjection]}
                        />
                      </Form.Group>
                    </div>
                    <Form>
                      <Form.Label>Match Type</Form.Label>
                      <div key="matchTypeRadios">
                        <Form.Check
                          type="radio"
                          id="full"
                          label="Full"
                          name="full"
                          value="full"
                          checked={
                            llmGuard.input_scanners?.find(
                              (scanner: any) =>
                                scanner?.type === PromptInjection
                            )?.params.match_type === 'full'
                          }
                          onChange={handlePromptInjectionMatchTypeCheck}
                          disabled={!toggles[PromptInjection]}
                        />
                        <Form.Check
                          type="radio"
                          id="sentence"
                          label="Sentence"
                          name="matchType"
                          value="sentence"
                          checked={
                            llmGuard.input_scanners?.find(
                              (scanner: any) =>
                                scanner?.type === PromptInjection
                            )?.params.match_type === 'sentence'
                          }
                          onChange={handlePromptInjectionMatchTypeCheck}
                          disabled={!toggles[PromptInjection]}
                        />
                      </div>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col xs md="2">
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip>
                    It is specifically tailored to guard against crafty input
                    manipulations targeting large language models (LLM). By
                    identifying and mitigating such attempts, it ensures the LLM
                    operates securely without succumbing to injection attacks.
                  </Tooltip>
                }
              >
                <div style={{ width: 'fit-content' }}>
                  <FaInfoCircle />
                </div>
              </OverlayTrigger>
            </Col>
          </Row>
          <Row>
            <Col xs md="2">
              <Form.Check
                type="switch"
                id="Regex-switch"
                checked={toggles[Regex] || false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleToggleChange(Regex, event.target.checked);
                }}
              />
            </Col>
            <Col xs md="8">
              <Accordion
                activeKey={toggles[Regex] ? activeKeys[Regex] : null}
                onSelect={(eventKey) =>
                  handleAccordionToggle(Regex, eventKey as string)
                }
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Regex</Accordion.Header>
                  <Accordion.Body className="accordion-body-container">
                    <InputBadgeCreator
                      label="Type patterns here"
                      keywordBadges={
                        instanceDetails?.scannerConfigs?.input_scanners?.find(
                          (scanner: any) => scanner?.type === Regex
                        )?.params.patterns
                      }
                      setKeywordBadge={setRegexbadges}
                      isdisabled={!toggles[Regex]}
                      isRequired={
                        isLlmGuardEmpty() &&
                        (
                          llmGuard?.input_scanners?.find(
                            (scanner: any) => scanner?.type === Regex
                          )?.params?.patterns || []
                        )?.length === 0
                          ? true
                          : false
                      }
                    />
                    <Row>
                      <Col>
                        <Form>
                          <Form.Label>Match Type</Form.Label>
                          <div key="matchTypeRadios">
                            <Form.Check
                              type="radio"
                              id="search"
                              label="Search"
                              name="matchType"
                              value="search"
                              checked={
                                llmGuard.input_scanners?.find(
                                  (scanner: any) => scanner?.type === Regex
                                )?.params.match_type === 'search'
                              }
                              onChange={handleRegexMatchTypeCheck}
                              disabled={!toggles[Regex]}
                            />
                            <Form.Check
                              type="radio"
                              id="fullmatch"
                              label="Fullmatch"
                              name="matchType"
                              value="fullmatch"
                              checked={
                                llmGuard.input_scanners?.find(
                                  (scanner: any) => scanner?.type === Regex
                                )?.params.match_type === 'fullmatch'
                              }
                              onChange={handleRegexMatchTypeCheck}
                              disabled={!toggles[Regex]}
                            />
                          </div>
                        </Form>
                      </Col>
                      <Col>
                        <Form>
                          <Form.Label>Options</Form.Label>
                          <div key="optionCheckbox">
                            <Form.Check
                              type="checkbox"
                              id="IsBlocked"
                              label="Is Blocked"
                              name="IsBlocked"
                              checked={
                                llmGuard.input_scanners?.find(
                                  (scanner: any) => scanner?.type === Regex
                                )?.params.is_blocked
                              }
                              onChange={handleRegexIsBlockedCheckboxChange}
                              disabled={!toggles[Regex]}
                            />
                            {/* <Form.Check
                            type="checkbox"
                            id="Redact"
                            label="Redact"
                            name="Redact"
                            checked={
                              llmGuard.input_scanners?.[9]?.params.redact
                            }
                            onChange={handleRegexRedactCheckboxChange}
                            disabled={!toggles[Regex]}
                          /> */}
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col xs md="2">
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip>
                    This scanner is designed to sanitize prompts based on
                    predefined regular expression patterns. It offers
                    flexibility in defining patterns to identify and process
                    desirable or undesirable content within the prompts.
                  </Tooltip>
                }
              >
                <div style={{ width: 'fit-content' }}>
                  <FaInfoCircle />
                </div>
              </OverlayTrigger>
            </Col>
          </Row>
        </Row>
      </Container>
      <div className="two-button-container">
        <Button
          variant="primary"
          onClick={() => prevStep()}
          className="secondary-btn-style modal-button"
        >
          Back
        </Button>
        {userRole && (userRole === ADMIN || userRole === SUPERADMIN) && (
        <Button
          variant="primary"
          onClick={handleFinalClick}
          className="modal-button"
          // disabled={isLlmGuardEmpty()}
        >
          Next
        </Button>
        )
       }
      </div>
    </div>
  );
};

export default TrusthWorthyLlmForm;
