/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { useContext, useState, useRef, useEffect } from 'react';
import {
  Form,
  Button,
  Alert,
  Tabs,
  Tab,
  Badge,
  Spinner,
} from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import CustomFeedback from '../../components/Feedback';
import { ReactComponent as BackIcon } from '../../assets/arrows/back-arrow.svg';
import { Appl } from '../../interfaces';
import './MeetingSummarizer.css';
import ContentLoader from '../../components/Loader/contentLoader';
import SelectInstance from '../../components/SelectInstance';
import ActivityStatusDropDown from '../../components/ActivityStatusDropDown';
import stream from '../../utils/stream';
import llmGuardSubmit from '../../utils/llmGuardSubmit';
import UserContext from '../../context/UserContext';
import { SiGooglecloud, SiAzuredevops, SiAmazonaws } from 'react-icons/si';
import ClearChat from '../../components/ClearContent';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { FaRegStopCircle } from 'react-icons/fa';

const MeetingSummarizer = () => {
  const { projectId, appUrl } = useParams();
  const apps = JSON.parse(sessionStorage.getItem('apps') as string) as Appl[];
  const currentApp = apps.find((app) => app.url === appUrl);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showFeedbackOptions, setShowFeedBackOptions] =
    useState<boolean>(false);
  const [activityId, setActivityId] = useState<string>('');
  const [showRawTranscript, setShowRawTranscript] = useState<boolean>(false);
  const [showErrMsg, setShowErrMsg] = useState<string>('');
  const [meetingSummarizerUserInput, setMeetingSummarizerUserInput] =
    useState<string>('');
  const [activityResponse, setActivityResponse] = useState<any>([]);
  const [outputData, setOutputData] = useState<string>('');
  const [activeTab, setActiveTab] = useState('Upload');
  const [isUploadResponse, setIsUploadResponse] = useState<boolean>(false);
  const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false);
  const [instanceData, setInstanceData] = useState<any>('');
  const abortControllerRef = useRef<AbortController | null>(null);
  const [sanitizedPrompt, setSanitizedPrompt] = useState<string>('');
  const [showSanitizedPrompt, setShowSanitizedPrompt] =
    useState<boolean>(false);
  const userContext = useContext(UserContext) || {
    selectedInstance: '',
    userInput: '',
    setUserInput: () => {},
    setIsActivityActive: () => {},
    isActivityActive: false,
    showToast: false,
    setShowToast: () => {},
    llmCloud: '',
    filesSelectedForUploadRef: null,
    llmGuardOption: '',
  };

  const {
    selectedInstance,
    setIsActivityActive,
    isActivityActive,
    showToast,
    setShowToast,
    llmCloud,
    filesSelectedForUploadRef,
    llmGuardOption,
  } = userContext;
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files?.length) {
      let selectedFiles = Array.from(files);
      setUploadedFiles(Array.from(files));
      submitMeetingSummarizer(selectedFiles);
      setOutputData('');
      setMeetingSummarizerUserInput('');
      setShowRawTranscript(false);
    } else {
      setIsUploadLoading(false);
      setIsUploadResponse(false);
    }
  };
  useEffect(() => {
    if (
      filesSelectedForUploadRef &&
      filesSelectedForUploadRef.current?.value === ''
    ) {
      setUploadedFiles([]);
      setIsUploadLoading(false);
      setIsUploadResponse(false);
    }
  }, [filesSelectedForUploadRef, selectedInstance]);

  const submitMeetingSummarizer = async (selectedFiles: any) => {
    const formData = new FormData();

    selectedFiles.forEach((file: any) => {
      formData.append('file', file);
    });

    try {
      const queryParams = {
        instance_id: selectedInstance,
      };
      setShowToast(false);
      setShowFeedBackOptions(false);
      setIsLoading(true);
      setIsUploadLoading(true);
      setIsUploadResponse(false);
      let apiUrl = '';
      if (llmCloud === 'gcp') {
        apiUrl = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/gcp/submit/async/audio-file-summary`;
      } else {
        apiUrl = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/submit/async/audio-file-summary`;
      }
      const response = await axios.post(apiUrl, formData, {
        params: queryParams,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        setShowFeedBackOptions(true);
        setIsUploadLoading(false);
        setIsUploadResponse(true);
        setIsLoading(false);
        setUploadedFiles([]);
        const responseData = response.data;
        setActivityResponse((prev: any) => [...prev, responseData]);
      } else {
        console.error('Failed to upload files');
        setIsLoading(false);
        setShowToast(true);
        setIsUploadLoading(false);
      }
    } catch (error: any) {
      console.error('Error:', error);
      setIsLoading(false);
      const err = error?.response?.data?.error;
      setIsLoading(false);
      setIsUploadLoading(false);
      setShowToast(true);
      setShowErrMsg(err.errorMessage);
    }
  };

  const handlePromptSubmit = async (payload: any) => {
    setOutputData('');
    setIsLoading(true);
    setShowFeedBackOptions(false);
    setShowRawTranscript(false);
    abortControllerRef.current = new AbortController();
    stream({
      requestBody: payload,
      streamCallback: (value: string) => {
        setOutputData((prev) => `${prev}${value}`);
      },
      doneCallback: () => {
        setIsLoading(false);
        setShowFeedBackOptions(true);
        setShowToast(false);
        // setIsDisabled(true);
      },
      setShowToast: setShowToast,
      setIsLoading: setIsLoading,
      llmCloud: llmCloud,
      activityId: activityId,
      setActivityId: setActivityId,
      signal: abortControllerRef.current.signal,
    });
  };
  const handleAbortStream = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      setIsLoading(false);
    }
  };

  const handleLlmGuardSubmit = async (payload: any) => {
    setOutputData('');
    setIsLoading(true);
    setShowFeedBackOptions(false);
    const response = await llmGuardSubmit({
      requestBody: payload,
      setIsLoading,
      setShowToast,
      setActivityId,
      llmCloud,
      setSanitizedPrompt,
    });
    if (response) {
      setOutputData((prev) => `${prev}${response}`);
      setShowFeedBackOptions(true);
      setShowToast(false);
    }
  };

  const submitPrompt = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowSanitizedPrompt(false);
    setSanitizedPrompt('');
    event.preventDefault();
    setShowToast(false);
    const payload = {
      // model: llmCloud === 'gcp' ? 'gemini-pro' : 'gpt-4-turbo',
      input_text: meetingSummarizerUserInput,
      instance_id: selectedInstance,
      include_references: true,
      advance_search_params: undefined,
    };

    if (llmGuardOption === 'Yes') {
      handleLlmGuardSubmit(payload);
    } else handlePromptSubmit(payload);
  };

  const clearSelectedFile = () => {
    if (filesSelectedForUploadRef && filesSelectedForUploadRef.current) {
      filesSelectedForUploadRef.current.value = '';
    }
  };

  const handleActivitySelect = (selectedActivity: any) => {
    setActivityId(selectedActivity);
    setOutputData('');
  };

  return (
    <>
      <div className="title">{currentApp?.name}</div>
      <div className="back-button-container">
        <Link to={`/project/${projectId}`}>
          <BackIcon /> Back
        </Link>
        {selectedInstance && (
          <div className="cloud-icons">
            {llmCloud === 'gcp' ? (
              <SiGooglecloud style={{ fontSize: '1.5rem' }} />
            ) : llmCloud === 'aws' ? (
              <SiAmazonaws style={{ fontWeight: '1.8rem' }} />
            ) : (
              <SiAzuredevops />
            )}
            <div className="pipe"></div>
            <span className="cloud-texts">
              {llmCloud === 'gcp'
                ? 'GCP'
                : llmCloud === 'aws'
                  ? 'AWS'
                  : 'Azure'}
            </span>
          </div>
        )}
      </div>
      <div className="single-app-container">
        <SelectInstance
          projectId={projectId}
          currentApp={currentApp}
          step3={false}
          outputData={outputData}
          setOutputData={setOutputData}
          setInstanceDetails={setInstanceData}
          instanceDetails={instanceData}
          setisUploadingProps={setIsUploadLoading}
          setIsUploadResponseProps={setIsUploadResponse}
        />
        <Tabs
          defaultActiveKey="Upload"
          id="justify-tab-example"
          className="mb-3"
          justify
          // activeKey={activeTab}
          onSelect={(key: string | null) => {
            if (key !== null) {
              setActiveTab(key);
              setIsActivityActive(!isActivityActive);
            }
          }}
        >
          <Tab eventKey="Upload" title="Upload">
            <Form.Group
              className="file-input"
              controlId="exampleForm.fileInput"
            >
              <Form.Label>Upload Audio File</Form.Label>
              <Form.Control
                type="file"
                accept=".wav, .mp3, .m4a, .mp4"
                onChange={handleFileChange}
                ref={filesSelectedForUploadRef}
                disabled={!selectedInstance}
              />
              {isUploadLoading && (
                <div className="upload-spinner">
                  <Spinner animation="border" size="sm" /> Uploading
                </div>
              )}
              {uploadedFiles && isUploadResponse && (
                <div className="upload-msg">
                  <Badge bg="success">Upload Successful</Badge>
                </div>
              )}
              <div>
                <Form.Text>Formats supported: wav, m4a, mp3 & mp4</Form.Text>
              </div>
              <div>
                <Form.Text>
                  It takes ~2 minutes to process an audio file of ~5 minutes
                  duration.
                </Form.Text>
              </div>
            </Form.Group>
          </Tab>
          <Tab eventKey="Activity" title="Activity">
            <ActivityStatusDropDown
              activityResponse={activityResponse}
              activityType="Meeting Summarizer"
              onActivitySelect={handleActivitySelect}
              setMeetingSummarizerUserInput={setMeetingSummarizerUserInput}
              isMeetingSummarizerOutput={outputData.length > 0 ? true : false}
            />
          </Tab>
        </Tabs>
      </div>
      <div
        className="user-story-generator-submit-container"
        style={{ marginBottom: '2rem', marginTop: '2rem' }}
      ></div>
      {activeTab === 'Activity' && (
        <div className="user-story-generator-submit-container">
          {meetingSummarizerUserInput.length > 0 && (
            <>
              {isLoading ? (
                <Button className="send-btn" disabled={true}>
                  <ContentLoader />
                </Button>
              ) : (
                <Button
                  className="user-story-generator-submit"
                  as="input"
                  type="submit"
                  value="Submit"
                  onClick={submitPrompt}
                  disabled={!selectedInstance}
                />
              )}
            </>
          )}
          {outputData.length > 0 && !isLoading ? (
            <ClearChat
              setUploadedFiles={setUploadedFiles}
              setOutputData={setOutputData}
              setShowRawTranscript={setShowRawTranscript}
              clearSelectedFile={clearSelectedFile}
            />
          ) : (
            outputData && (
              <Button
                className="abort-btn"
                variant="danger"
                type="button"
                onClick={handleAbortStream}
                style={{ marginLeft: '16px' }}
                disabled={!outputData}
              >
                <FaRegStopCircle size="1.25rem" />
              </Button>
            )
          )}
        </div>
      )}
      {showToast && (
        <div style={{ paddingTop: '80px', width: '50vw', margin: '0 auto' }}>
          <Alert
            variant="danger"
            onClose={() => setShowToast(false)}
            dismissible
          >
            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
            <p>
              {showErrMsg
                ? showErrMsg
                : `Something went wrong. Please try again`}
            </p>
          </Alert>
        </div>
      )}
      <div className="transcript-output-container">
        {/* {apiResponse?.response?.output?.length  */}
        {activeTab === 'Activity' && outputData && (
          <>
            {instanceData.renderMarkdown ? (
              <Form.Group controlId="outputTextArea">
                <div className="output-container markdown-table">
                  <Markdown remarkPlugins={[remarkGfm]}>{outputData}</Markdown>
                </div>
              </Form.Group>
            ) : (
              <Form.Group
                className="user-input"
                controlId="exampleForm.output"
                style={{ display: 'flex' }}
              >
                <Form.Control
                  as="textarea"
                  rows={18}
                  className="output-container"
                  value={outputData}
                  disabled
                />
              </Form.Group>
            )}
            <Form.Group className="transcript-toggle-container">
              <Form.Check
                type="switch"
                id="transcript-view-switch"
                label=" View Raw Transcript"
                onChange={(evt) => setShowRawTranscript(evt.target.checked)}
              />
            </Form.Group>
            {showRawTranscript && (
              <Form.Group controlId="outputTextArea">
                <Form.Control
                  as="textarea"
                  rows={18}
                  className="output-container"
                  value={`Transcript:\n${meetingSummarizerUserInput}`}
                  disabled
                />
              </Form.Group>
            )}
            {llmGuardOption === 'Yes' && sanitizedPrompt && (
              <Form.Group className="transcript-toggle-container">
                <Form.Check
                  type="switch"
                  id="transcript-view-switch"
                  label=" View Sanitized Prompt"
                  onChange={(evt) => setShowSanitizedPrompt(evt.target.checked)}
                />
              </Form.Group>
            )}
            {showSanitizedPrompt && (
              <Form.Group controlId="outputTextArea">
                <Form.Control
                  as="textarea"
                  rows={10}
                  className="output-container"
                  value={`Sanitized Prompt:\n${sanitizedPrompt}`}
                  disabled
                />
              </Form.Group>
            )}
          </>
        )}
        {activeTab === 'Activity' && outputData && showFeedbackOptions && (
          <CustomFeedback
            url={`${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/activity/feedback/update`}
            activityId={activityId}
          />
        )}
      </div>
    </>
  );
};

export default MeetingSummarizer;
