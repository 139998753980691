import React, { useState } from 'react';
import { Container, Row, Col, Button, Form, Modal } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import './VideoViewer.css';

type DomainProps = {
  onRemoveImage?: any; // Callback to notify parent about removal
  isSelect?: boolean;
  selectedImages?: any;
  displayImages: string[];
  onSelectImage?: any;
  selectedImageNames?: any;
  isCancel?: boolean;
  isEditMode?: boolean;
};

const VideoViewer: React.FunctionComponent<DomainProps> = ({
  onRemoveImage,
  isSelect=false,
  selectedImages,
  displayImages,
  onSelectImage,
  selectedImageNames,
  isCancel=false,
  isEditMode,
}) => {
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const deleteWarning = () => {
    setShowDeleteWarning(true);
  };
  const removedeleteWarning = () => {
    setShowDeleteWarning(false);
  };
  const handleCheckboxChange = (imageName: string, index: number) => {
    onSelectImage(imageName, index);
  };

  const removeImage = (imageName: string, index: number) => {
    onRemoveImage(imageName, index); // Call the parent's callback to handle image removal
    setShowDeleteWarning(false);
  };

  return (
    <div className="justify-content-center">
      <Container className="justify-content-center">
        <Row xs={3}>
          {displayImages.map((image, index) => (
            <Col key={index} className=" justify-content-center">
              <div
                className="parent-div"
                onMouseEnter={(e) => {
                  isSelect &&
                    (e.currentTarget.style.backgroundColor = '#e6fbff');
                }}
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = 'transparent')
                }
              >
                <div className="parent-div">
                  {isSelect && !isCancel && (
                    <React.Fragment>
                      {
                        <Form.Check
                          type="checkbox"
                          checked={selectedImageNames.includes(image)}
                          onChange={() => handleCheckboxChange(image, index)}
                          className="checkbox-positioning"
                        />
                      }
                     <video  controls width="100%">
                    <source src={image} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video> 
                    </React.Fragment>
                  )}
                  {isCancel && !isSelect && (
                    <React.Fragment key={index}>
                      <div className="images-dsiplay">
                        <Button
                          className="d-flex justify-content-center align-items-center cross-btn"
                          variant="danger"
                          onClick={() => removeImage(image, index)}
                        >
                          <FaTimes />
                        </Button>
                        <video  controls width="100%">
                    <source src={image} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video> 
                      </div>
                      {showDeleteWarning && (
                        <div className="clear-chat-warning-container">
                          <Modal
                            show={showDeleteWarning}
                            onHide={removedeleteWarning}
                            backdrop="static"
                            keyboard={true}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>Clear Image?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              {isEditMode ? (
                                <div className="delete-warning">
                                  <p>
                                    This Action will remove video from selected
                                    instance. Are you sure you want to remove
                                    video?
                                  </p>
                                </div>
                              ) : (
                                <div className="delete-warning">
                                  <p>
                                    This Action will remove video. Are you sure
                                    you want to remove video?
                                  </p>
                                </div>
                              )}
                            </Modal.Body>

                            <Modal.Footer>
                              <Button
                                variant="danger"
                                onClick={() => removeImage(image, index)}
                              >
                                Clear
                              </Button>
                              <Button
                                variant="secondary"
                                onClick={removedeleteWarning}
                              >
                                Cancel
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                  {!isCancel && !isSelect && (
                   <video  controls width="100%">
                    <source src={image} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video> 
                  )}
                  {isSelect && isCancel && (
                    <React.Fragment>
                      <Button
                        className="d-flex justify-content-center align-items-center cross-btn"
                        variant="danger"
                        onClick={deleteWarning}
                      >
                        <FaTimes />
                      </Button>
                      <input
                        type="checkbox"
                        checked={selectedImageNames.includes(image)}
                        onChange={() => handleCheckboxChange(image, index)}
                        className="checkbox-positioning"
                      />
                      <img
                        src={image} // Assuming image is a URL or path
                        alt={image}
                        className={`img ${selectedImageNames.includes(image) ? 'blurred-border' : ''}`}
                        onClick={() => handleCheckboxChange(image, index)}
                      />
                    </React.Fragment>
                  )}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default VideoViewer;

