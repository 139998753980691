/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect, useRef } from 'react';
import {
  Form,
  Alert,
  Button,
  Modal,
  Tabs,
  Tab,
  Badge,
  Spinner,
} from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import './ToolIn24Hours.css';
import { ReactComponent as BackIcon } from '../../assets/arrows/back-arrow.svg';
import { Appl } from '../../interfaces';
import ContentLoader from '../../components/Loader/contentLoader';
import UserContext from '../../context/UserContext';
import ClearChat from '../../components/ClearContent';
import ImageViewer from '../../components/ImageViewer';
import axios from 'axios';
import ActivityStatusDropDown from '../../components/ActivityStatusDropDown';
import { a } from 'js-tiktoken/dist/core-262103d7';

const MadeIn24Hours = () => {
  const { projectId, appUrl } = useParams();
  const apps = JSON.parse(sessionStorage.getItem('apps') as string) as Appl[];
  const currentApp = apps.find((app) => app.url === appUrl);
  const [outputData, setOutputData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [fileUpload, setFileUpload] = useState<boolean>(false);

  const [activityId, setActivityId] = useState<string>('');

  const [inputText, setInputText] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const pdfFileInputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File[]>([]);
  const [userPrompt, setUserPrompt] = useState<string | undefined>(undefined);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [removeFileName, setRemoveFileName] = useState('');
  const [removeFileindex, setRemoveFileIndex] = useState(-1);
  const [activeTab, setActiveTab] = useState('Upload');
  const [collectionName, setCollectionName] = useState<string>('');
  const [activityResponse, setActivityResponse] = useState<any>([]);
  const [imageString, setImageString] = useState<string>('');
  const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false);
  const [isUploadResponse, setIsUploadResponse] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [promptFormat, setPromptFormat] = useState('Dictionary Mapper');
  const [promptFlag, setPromptFlag] = useState<number>(1);
  const [submitDisabled ,setSubitDisabled]=useState<boolean>(true);
  const [fileTypeError,setFileTypeError]=useState<boolean>(false);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [imageUploaadResponse, setImageUploadResponse] = useState<string[]>([]);
  const acceptedFiles=['.pdf','.jpg','.jpeg','.png','.xlsx']
  const userContext = useContext(UserContext) || {
    selectedInstance: '',
    userInput: '',
    setUserInput: () => {},
    showErrMsg: '',
    setIsActivityActive: () => {},
    isActivityActive: false,
  };
  const {
    userInput,
    setUserInput,
    showErrMsg,
    setIsActivityActive,
    isActivityActive,
  } = userContext;

  const handleUserPromptChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setUserInput(event.target.value);
    setUserPrompt(event.target.value);
  };

  const handleClearChat = () => {
    setUserPrompt('');
    setFileTypeError(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    if (pdfFileInputRef.current) {
      pdfFileInputRef.current.value = '';
    }
    setFileUpload(false);
    setFile([]);
    setSelectedImages([]);
    setImageUploadResponse([]);
    setShowToast(false);
  };
  const deleteimage = async (fileName: string, index: number) => {
    const updatedImages = selectedImages.filter((_, i) => i !== index);
    const updatedFiles = file.filter((_, i) => i !== index);
    setSelectedImages(updatedImages);
    setFile(updatedFiles);
  };
 

  const handleFileInputChange = async (e: any) => {
    const newFiles: any = Array.from(e.target.files);
    if(newFiles != undefined && newFiles?.length>0){
      setFileTypeError(false);
    const extensionCheck=acceptedFiles.some(ext=>newFiles[0].name.endsWith(ext));
    if(extensionCheck){
    setSubitDisabled(false);
    setFileUpload(true);
    setIsUploadLoading(true);
    setIsUploadResponse(false);
    setOutputData('');
    const files = e.target.files;
    setUploadedFiles(Array.from(files));
    if (!files) return;

    for (const file of files) {
      const reader = new FileReader();

      Promise.all(
        Array.from(files).map((file: any) => {
          return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              resolve(reader.result as string);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
          });
        })
      )
        .then((imageDataUrls) => {
          imageDataUrls.map((data: any, index: any) => {
            if (
              !imageDataUrls[index].includes('data:application/pdf') &&
              !imageDataUrls[index].includes(
                'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              )
            ) {
              setSelectedImages((prevImages) => {
                let newImages = [imageDataUrls[index]];
                newImages.push(...prevImages);
                return newImages;
              });
            }
          });
        })
        .catch((error) => {
          console.error('Error reading files:', error);
        });

      reader.onerror = (error) => {
        console.error(`Error reading file: ${error}`);
      };

      reader.readAsDataURL(file);
    }

    file.push(newFiles);

    const formData = new FormData();
    newFiles.forEach((file: any) => {
      formData.append('files', file);
    });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/visionocr/get-image-links`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        setIsUploadLoading(false);
        setIsUploadResponse(true);
        imageUploaadResponse.push(...response.data);
        setImageString(response.data);
        setUploadedFiles([]);
      } else {
        setShowToast(true);
      }
      setIsUploadLoading(false);
    } catch (error) {
      console.error(error);
      setIsUploadLoading(false);
      setIsUploadResponse(false);
    }
  }
  else{
    setFileTypeError(true)
  }
}
  };

  const submitPrompt = async () => {
    setIsLoading(true);
    setInputText(userInput);
    const payload = imageUploaadResponse;
    try {
      const queryParams = {
        project_id: projectId,
        application_id: currentApp?.id,
        format_prompt: promptFormat === 'Custom JSON' ? userInput : promptFormat ,
        prompt_flag: promptFlag,
        collection_name: collectionName,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/visionocr/ocr-response`,
        payload,
        {
          params: queryParams,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        setIsLoading(false);
        const responseData = response.data;
        setActivityResponse((prev: any) => [...prev, responseData]);
        setSubitDisabled(true);
      } else {
        setShowToast(true);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setShowToast(true);
      console.error(error);
    }
  };

  const removedeleteWarning = () => {
    setShowDeleteWarning(false);
  };

  const handleCopyJSON = (e: any) => {
    navigator.clipboard.writeText(
      typeof outputData === 'object'
        ? JSON.stringify(outputData, null, 2)
        : outputData
    );
    e.target.value = 'Copied!';
    setTimeout(() => {
      e.target.value = 'Copy JSON';
    }, 1000);
  };

  const handleCollectionNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSubitDisabled(false);
    setCollectionName(event.target.value);
  };

  const handleActivitySelect = (selectedActivity: any) => {
    setActivityId(selectedActivity);
  };
useEffect(()=>{
 let uploadResponseTimeout:any;
 if(isUploadResponse===true){
  uploadResponseTimeout = setTimeout(()=>setIsUploadResponse(false),3000)

 return () => {
  clearTimeout(uploadResponseTimeout)
  }

 }
},[isUploadResponse])
  useEffect(() => {
    setPromptFlag(
      //  promptFormat === 'SF Compatible JSON'
      //   ? 1
      //   : promptFormat === 'Kreator-compatible JSON'
      //     ? 2
      //     : promptFormat === 'Create Bulleted Lists'
      //     ?3
      //     :promptFormat==='Custom JSON'?
      //     4:promptFormat==='Custom Prompt'?5:5
      promptFormat === 'Dictionary Mapper' ? 1 
      : promptFormat === 'Custom Json'? 2 : 2
    );
  }, [promptFormat]);
  return (
    <>
      <div className="title">{currentApp?.name}</div>
      <div className="back-button-container">
        <Link to={`/project/${projectId}`}>
          <BackIcon /> Back
        </Link>
      </div>
      <div className="single-app-container">
        <Tabs
          defaultActiveKey="Upload"
          id="justify-tab-example"
          className="mb-3"
          justify
          onSelect={(key: string | null) => {
            if (key !== null) {
              setActiveTab(key);
              setIsActivityActive(!isActivityActive);
            }
          }}
        >
          <Tab eventKey="Upload" title="Upload">
            <Form.Group className="file-input">
              <Form.Label>Upload Wireframe</Form.Label>
              <Form.Control
                type="file"
                name="datafile"
                accept=".jpeg, .jpg, .png,.pdf"
                multiple
                onChange={handleFileInputChange}
                disabled={isLoading}
                ref={fileInputRef}
              />
              <Form.Label className="margin-top">Upload Data Model</Form.Label>
              <Form.Control
                type="file"
                name="datafile"
                accept=".xlsx"
                multiple
                onChange={handleFileInputChange}
                disabled={isLoading}
                ref={pdfFileInputRef}
              />
              {isUploadLoading  && (
                <div className="upload-spinner margin-top">
                  <Spinner animation="border" size="sm" /> Uploading
                </div>
              )}
              {uploadedFiles && isUploadResponse && !fileTypeError && (
                <div className="upload-msg margin-top">
                  
                  <Badge bg="success">Upload Successful</Badge>
                </div>
              )}
              {
                fileTypeError && (
                  <div className='wrong-format-warning'>
                    Format Not Supported
                  </div>
                )
              }
            </Form.Group>
            <div className="image-viewer">
              <ImageViewer displayImages={selectedImages} />
            </div>
            <Form.Group className="user-input">
              <Form.Label>Collection Name</Form.Label>
              <Form.Control
                as="input"
                type="text"
                placeholder="Enter Collection Name"
                value={collectionName}
                onChange={handleCollectionNameChange}
              />
            </Form.Group>
            <Form.Group className="user-input">
              <Form.Label>Default Format</Form.Label>
              <Form.Select
                aria-label="Select Format"
                onChange={(evt) => {
                  setPromptFormat(evt.target.value);
                }}
                value={promptFormat}
              >
                {/* <option value="Create XML">Create XML</option> */}
                {/* <option value="SF Compatible JSON">SF Compatible JSON</option>
                <option value="Kreator-compatible JSON">Kreator-compatible JSON</option>
                <option value="Create Bulleted Lists">
                  Create Bulleted Lists
                </option>
                <option value="Custom JSON">Custom JSON</option>
                <option value="Custom Prompt">Custom Prompt</option> */}
                <option value="Dictionary Mapper" selected>
                  Dictionary Mapper
                </option>
                <option value="Custom JSON">Custom JSON</option>
              </Form.Select>
            </Form.Group>
            {(promptFormat === 'Custom Prompt' ||
              promptFormat === 'Custom JSON') && (
              <Form.Group className="user-input">
                <Form.Label>Enter Custom JSON</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter your Custom JSON here."
                  rows={3}
                  value={userInput}
                  onChange={handleUserPromptChange}
                />
              </Form.Group>
            )}
          </Tab>
          <Tab eventKey="Collections" title="Collections">
            <ActivityStatusDropDown
              activityResponse={activityResponse}
              activityType="Document Metadata Generator"
              onActivitySelect={handleActivitySelect}
              setOutputData={setOutputData}
            />
          </Tab>
        </Tabs>
      </div>
      {showDeleteWarning && (
        <div className="clear-chat-warning-container">
          <Modal
            show={showDeleteWarning}
            onHide={removedeleteWarning}
            backdrop="static"
            keyboard={true}
          >
            <Modal.Header closeButton>
              <Modal.Title>Clear Image?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="delete-warning">
                <p>
                  This Action will remove image. Are you sure you want to remove
                  image?
                </p>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="danger"
                onClick={() => deleteimage(removeFileName, removeFileindex)}
              >
                Delete
              </Button>

              <Button variant="secondary" onClick={removedeleteWarning}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
      {activeTab === 'Upload' && (
        <>
          <div className="user-story-generator-submit-container">
            {isLoading ? (
              <Button
                className="user-story-generator-submit"
                disabled={true}
                style={{ width: '104px', height: '36px' }}
              >
                <ContentLoader />
              </Button>
            ) : (
              <div className="with-download-button">
                <Button
                  className="user-story-generator-submit"
                  as="input"
                  type="submit"
                  value="Submit"
                  onClick={submitPrompt}
                  disabled={!collectionName || !fileUpload || submitDisabled}
                />
                <ClearChat
                  disabled={
                    !collectionName && !pdfFileInputRef.current?.value && !fileInputRef.current?.value
                  }
                  setOutputData={setOutputData}
                  setCollectionName={setCollectionName}
                  setIsUploadResponse={setIsUploadResponse}
                  setUploadedFiles={handleClearChat}
                />
              </div>
            )}
          </div>
        </>
      )}
      {activeTab === 'Collections' && (
        <div className="single-app-container">
          <div className="results-container">
            <h4>
              Results{' '}
              <Button
                className="btn-copy-json"
                as="input"
                type="button"
                value="Copy"
                onClick={handleCopyJSON}
                disabled={!outputData}
              />
            </h4>
            {/* commented code for future use */}

            {/* {outputData && Object.keys(outputData).length > 0 && (
                    <div className="result-data-wrapper">
                      {outputData.map((data: any, index: number) => {
                        return (
                          <pre>
                            {typeof data.Content === 'object'
                              ? JSON.stringify(data.Content, null, 2)
                              : data.Content}
                          </pre>
                        );
                      })}
                    </div>
                  )} */}
            {outputData && Object.keys(outputData).length > 0 && (
              <div className="result-data-wrapper">
                <pre>
                  {typeof outputData === 'object'
                    ? JSON.stringify(outputData, null, 2)
                    : outputData}
                </pre>
              </div>
            )}
          </div>
        </div>
      )}
      {showToast && (
        <div
          style={{
            paddingTop: '80px',
            width: '50%',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Alert
            variant="danger"
            onClose={() => setShowToast(false)}
            dismissible
          >
            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
            <p>
              {showErrMsg
                ? showErrMsg
                : `Something went wrong. Please try again`}
            </p>
          </Alert>
        </div>
      )}
      {/* {activeTab === 'Collections' && outputData && (
        <>
          {instanceData.renderMarkdown ? (
            <Form.Group controlId="outputTextArea">
              <div className="output-container markdown-table">
                <Markdown remarkPlugins={[remarkGfm]}>{outputData}</Markdown>
              </div>
            </Form.Group>
          ) : (
            <Form.Control
              as="textarea"
              rows={18}
              className="output-container"
              value={outputData}
              disabled
            />
          )}
        </>
      )} */}
      {/* {activeTab === 'Collections' &&
        outputData.length > 0 &&
        showFeedbackOptions && (
          <CustomFeedback
            url={`${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/activity/feedback/update`}
            activityId={activityId}
          />
        )} */}
    </>
  );
};

export default MadeIn24Hours;
