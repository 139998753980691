const fixNewlineChars = (str: String) => {
  return str?.replace(/\n/g, '\\n');
};

// This function will replace XML tags with markdown bold tags. Relevant to fix response of AWS Bedrock LLMs.
const fixXMLTags = (str: String) => {
  return str?.replace(/<\/([^>]+)>/g, '').replace(/<([^>]+)>/g, '**$1:**');
};

export { fixNewlineChars, fixXMLTags };
