import { Spinner } from 'react-bootstrap';

import './Loader.css';

const Loader = () => (
  <div className="spinner-container">
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </div>
);

export default Loader;
