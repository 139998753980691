import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  ChangeEvent,
} from 'react';
import {
  Col,
  Container,
  Form,
  Row,
  Button,
  Alert,
  Badge,
  Spinner,
} from 'react-bootstrap';
import { ReactComponent as DragIcon } from '../../assets/DragIcon.svg';
import axios from 'axios';
import { fixNewlineChars } from '../../utils/stringProcessing';
import './InstanceGenerator.css';
import UserContext from '../../context/UserContext';
import { Appl } from '../../interfaces';
import { useParams } from 'react-router-dom';
import { Knowledge } from '../../interfaces';
import { ItemComponentProps } from '@webscopeio/react-textarea-autocomplete';
import CustomReactTextareaAutocomplete from '../../utils/CustomReactTextareaAutocomplete.js';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import the FontAwesomeIcon component from the '@fortawesome/react-fontawesome' package.
import assert from 'node:assert';
import { getEncoding, encodingForModel } from 'js-tiktoken';
import {
  DEFAULT_TEMPLATE_PROMPT,
  USER_STORY_PROMPT,
  SALES_ENGINE_PROMPT,
  MEETING_SUMMARIZER_PROMPT,
  STATUS_REPORT_PROMPT,
  TEST_SCRIPT_PROMPT,
  SCAN_TO_SUMMIFY_PROMPT,
  HELP_DESK_PROMPT,
  NOTICE_ENGINE_PROMPT,
  CALL_CENTER_PROMPT,
  DOC_INSIGHTS_PROMPT,
  DEVELOPER_HELP_PROMPT,
  POLICY_ENGINE_PROMPT,
  HELP_ENGINE_PROMPT,
} from '../../utils/defaultTemplatePrompts';

import KnowledgeTable from '../KnowledgeIdTable';
import {
  KNOWLEDGE_IDS,
  USER_STORY_GENERATOR,
  SALES_ENGINE,
  MEETING_SUMMARIZER,
  STATUS_REPORT_GENERATOR,
  TEST_SCRIPT_GENERATOR,
  SCAN_TO_SUMMIFY,
  HELP_DESK_ENGINE,
  NOTICE_ENGINE,
  CALL_CENTER,
  DOC_INSIGHT,
  DEVELOPER_HELP_ENGINE,
  POLICY_ENGINE,
  HELP_ENGINE,
  NOTICE_ANALYSIS_ENGINE,
  CHANGE_COMMUNICATOR,
  userNoteOutputColum1,
  userNoteOutputColum2,
  userNoteOutputColum3,
  expectedOutCome,
  testSteps,
  title,
  WEAVIATE,
  PGVECTOR,
  ADMIN,
  SUPERADMIN,
} from '../../utils/constants';
import Stepper from '../Stepper';
// import TrusthWorthyLlmForm from '../TrustworthyLlm';
import { cp } from 'node:fs';
import TrusthWorthyLlmForm from '../TrustworthyLlm';
import { VectorDb } from '../../types';
type InstanceGeneratorProps = {
  currentStep: number;
  projectId: any;
  currentAppId: any;
  nextStep: any;
  setShowNewInstanceModal: any;
  step1?: boolean;
  step2?: boolean;
  step3?: boolean;
  step4?: boolean;
  isUploadtemplate?: boolean;
  isEditMode?: any;
  instanceDetails?: any;
  selectedInstance?: any;
  prevStep: any;
  isBackClicked?: boolean;
  getInstanceData?: any;
};

interface KeyValue {
  key: string;
  value: string;
}

const InstanceGenerator: React.FC<InstanceGeneratorProps> = ({
  currentStep,
  projectId,
  currentAppId,
  nextStep,
  prevStep,
  setShowNewInstanceModal,
  step1 = true,
  step2 = true,
  step3 = true,
  step4 = true,
  isUploadtemplate = false,
  isEditMode,
  instanceDetails,
  selectedInstance,
  isBackClicked,
  getInstanceData,
}) => {
  const { appUrl } = useParams();
  const apps = JSON.parse(sessionStorage.getItem('apps') as string) as Appl[];
  const currentApp = apps.find((app) => app.url === appUrl);
  const [knowledgeIDs, setKnowledgeIDs] = useState<any[]>([]);
  const [currentKnowledgeID, setCurrentKnowledgeID] = useState<any[]>([]);
  const [newKnowledgeID, setNewKnowledgeID] = useState<string>('');
  const [newTemplateName, setNewTemplateName] = useState<string>('');
  const [sliderValue, setSliderValue] = useState<number>(0.0);
  const [chunkSizeValue, setChunkSizeValue] = useState<number>(5);
  const [kNearChunksValue, setKNearChunksValue] = useState<number>(6);
  const [instanceName, setInstanceName] = useState<string>('');
  const [flowId, setFlowid] = useState<string>('');
  const [count, setCount] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [templatePrompt, setTemplatePrompt] = useState<string>('');
  const [totalSteps, setTotalSteps] = useState<number>();
  const [fileId, setFileId] = useState<any[]>([]);
  const [showFileUploadSuccessMsg, setShowFileUploadSuccessMsg] =
    useState<Boolean>(false);
  const filesSelectedForUploadRef = useRef<HTMLInputElement>(null);
  const knowledgeFileUpload = useRef<HTMLInputElement>(null);
  const [chunkingStrategy, setChunkingStrategy] = useState('TOKEN_LIMIT_CHUNK');
  const [keyValues, setKeyValues] = useState<KeyValue[]>([
    { key: '', value: '' },
  ]);
  const [instanceData, setInstanceData] = useState<any>(null);
  const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false);
  const [isUploadSuccess, setIsUploadSuccess] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState('Automatic');
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());
  const [isScannedDoc, setIsScannedDoc] = useState(false);
  const [renderMarkdown, setRenderMarkdown] = useState('No');
  // const [llmGuardOption, setLlmGuardOption] = useState('No');
  const [cloudProviderOptions, setCloudProviderOptions] = useState<any>([]);
  const [selectedModel, setSelectedModel] = useState<any>('');
  const [tokencount, setTokencount] = useState<number>(0);
  const [filetokencount, setFiletokencount] = useState<number>(0);
  const [knowledgeidAdded, setKnowlwdgeidAdded] = useState<boolean>(false);
  const [tokelimit, settokenlimit] = useState<any>();
  const [documentContents, setDocumentContents] = useState<string | null>(null);
  const dragOutputColumns = useRef<number>(0);
  const dragggedOutputColumns = useRef<number>(0);
  const [vectorDb, setVectorDb] = useState<VectorDb>(WEAVIATE);
  const containerRef = useRef<HTMLDivElement>(null);
  const userRole = JSON.parse(sessionStorage.getItem('role') || '{}');
  const [models, setModels] = useState([
    {
      name: 'azure',
      models: [
        { modelName: 'GPT-4o 128K', modelValue: 'gpt-4o', tokenSize: 126000 },
        {
          modelName: 'GPT-4 Turbo 128K',
          modelValue: 'gpt-4-turbo',
          tokenSize: 126000,
        },
        { modelName: 'GPT-4 32K', modelValue: 'gpt4-32k', tokenSize: 30768 },
        { modelName: 'GPT-3.5 16K', modelValue: 'gpt35-16k', tokenSize: 14384 },
      ],
    },
    {
      name: 'gcp',
      models: [
        {
          modelName: 'Gemini 1.0 Pro',
          modelValue: 'gemini-pro',
          tokenSize: 30760,
        },
        {
          modelName: 'GEMINI 1.5 PRO',
          modelValue: 'gemini-1.5-pro-preview-0409',
          tokenSize: 1000000,
        },
      ],
    },
    {
      name: 'aws',
      models: [
        {
          modelName: 'Claude V2-1',
          modelValue: 'anthropic.claude-v2:1',
          tokenSize: 198000,
        },
        // {
        //   modelName: 'Titan V1 Text',
        //   modelValue: 'amazon.titan-text-express-v1',
        //   tokenSize: 6192,
        // },
      ],
    },
    {
      name: 'cfg_ai',
      models: [
        {
          modelName: 'Llama3-70B-Instruct',
          modelValue: 'Llama3-70B-Instruct',
          tokenSize: 198000,
        },
        {
          modelName: 'Mixtral-8x7B',
          modelValue: 'Mixtral-8x7B',
          tokenSize: 6192,
        },
        {
          modelName: 'Mistral-Orca',
          modelValue: 'Mistral-Orca',
          tokenSize: 6192,
        },
      ],
    },
  ]);
  const [uploadError, setUploadError] = useState(false);
  const userContext = useContext(UserContext) || {
    showToast: false,
    setShowToast: () => {},
    showErrMsg: '',
    setShowErrMsg: () => {},
    cloudProvider: 'azure',
    setCloudProvider: () => {},
    setSelectedInstance: () => {},
    llmGuardOption: '',
    setLlmGuardOption: () => {},
    queryExpansion: false,
    setQueryExpansion: () => {},
    outputColumns: [{ value: '' }],
    setOutputcolumns: () => {},
  };

  const {
    setShowToast,
    setShowErrMsg,
    cloudProvider,
    setCloudProvider,
    setSelectedInstance,
    llmGuardOption,
    setLlmGuardOption,
    queryExpansion,
    setQueryExpansion,
    outputColumns,
    setOutputcolumns,
  } = userContext;
  const Item: React.FC<ItemComponentProps<Knowledge>> = ({
    entity: { id, name },
  }) => <div>{`${id}: ${name}`}</div>;
  const Loading: any = ({}) => <div>Loading..</div>;

  useEffect(() => {
    setKnowledgeIDs(instanceDetails?.knowledgeIds);
  }, [instanceDetails]);
  const addKnowledgeID = async (id: string) => {
    setKnowledgeIDs([]);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/knowledge/flow/${isEditMode ? instanceDetails.flow : id}`
      );

      if (response.status === 200) {
        setShowToast(false);
        const responseData = response.data;
        setKnowledgeIDs(responseData?.response);
        getInstanceData(isEditMode ? selectedInstance : instanceData?.id);
        setIsUploadLoading(false);
        setIsUploadSuccess(true);
        return responseData;
      } else {
        console.error('Get request failed');
        setIsUploadLoading(false);
        return;
      }
    } catch (error) {
      setShowToast(true);
      console.error('Error:', error);
      setIsUploadLoading(false);
      return;
    }
  };
  const handleTemplateNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewTemplateName(event.target.value);
  };

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    setSliderValue(newValue);
  };

  const handleChunkSizeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = parseFloat(event.target.value);
    setChunkSizeValue(newValue);
  };

  const handleKNearChunksChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = parseFloat(event.target.value);
    setKNearChunksValue(newValue);
  };

  const handleInstanceCreation = async (payload: any) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/instance/create-instance`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const responseData = response.data;
      if (response.status === 200) {
        setShowToast(false);
        setShowErrMsg('');
        const instanceData = responseData?.response;
        setSelectedInstance(instanceData?.id);
        setInstanceData(instanceData);
        setFlowid(instanceData?.flow);
        nextStep();
        setNewTemplateName(instanceName);
        getInstanceData(instanceData?.id);
        if (currentApp?.name === 'Change Communicator') {
          setShowNewInstanceModal(false);
        }
        return responseData;
      } else {
        const error = responseData?.error;
        setShowToast(true);
        setShowErrMsg(error?.errorMessage);
        console.error('response error', error);
        return;
      }
    } catch (error: any) {
      console.error(
        'Error fetching projects:',
        error.response?.data || error.message
      );
      const err = error?.response?.data?.error;
      setShowToast(true);
      setShowErrMsg(err.errorMessage);
      if (err && err.errorMessage) {
        setShowErrMsg(err.errorMessage);
      } else {
        console.error('Error message not available');
      }
    }
  };

  const createInstance = () => {
    outputColumnAdd();
    const payload = {
      llm_model: selectedModel,
      name: instanceName,
      projectId: projectId,
      temperature: sliderValue,
      applicationId: currentAppId,
      chunkSize: chunkSizeValue,
      chunkingStrategy: step3 ? chunkingStrategy : undefined,
      kNearChunks: kNearChunksValue,
      cloud_environment: cloudProvider ? cloudProvider : 'azure',
      render_markdown: renderMarkdown === 'Yes' ? true : false,
      llm_guard: llmGuardOption === 'Yes' ? true : false,
      query_expansion: queryExpansion,
      vector_db: vectorDb,
    };
    const resData = handleInstanceCreation(payload);
    if (Object.keys(resData)?.length) {
    }
  };

  const fetchInstance = () => {
    nextStep();
    // if (!step4) {
    setShowNewInstanceModal(false);
    // }
  };

  const handleInstanceNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInstanceName(event.target.value);
  };
  const [uploadcount, setUploadCount] = useState<number>(0);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsUploadLoading(false);
    setIsUploadSuccess(false);
    const files = event.target.files;
    if (files) {
      const uploadedFiles = Array.from(files).filter((file) => {
        // Check for CSV extension (case-insensitive)
        const extension = file.name.split('.').pop()?.toLowerCase();
        return extension === 'csv';
      });
      if (newKnowledgeID.length == 0) {
        setUploadError(true);
        setUploadCount(1);
      }
      setUploadedFiles(Array.from(files));
    }
  };
  const editModeusedTokenCount = () => {
    const enc = getEncoding('gpt2');

    let newValue = templatePrompt
      ?.replace(/\{[\s\{]*\{/g, '{{')
      .replace(/\}[\s\}]*\}/g, '}}');
    // AutoComplete Bug solution- Jira ticket no. 500
    newValue = newValue.replace(/}}([^{]+}}?)/g, '}}');
    let newValue1 = enc.encode(newValue); // Encode the text using gpt2 encoding
    setTokencount(newValue1.length);
    let count = 0;
    if (isEditMode) {
      if (instanceDetails.knowledgeIds.length) {
        for (let i = 0; i < instanceDetails.knowledgeIds.length; i++) {
          count =
            count +
            templatePrompt.split(instanceDetails.knowledgeIds[i].id).length -
            1;
        }
      }

      if (knowledgeIDs.length > 0) {
        for (let i = 0; i < knowledgeIDs.length; i++) {
          count = count + templatePrompt.split(knowledgeIDs[i].id).length - 1;
        }
        // count=count-1;
      }
      count = count;
      const knowledgetoken =
        (count / 2) * instanceDetails.chunkSize * instanceDetails.kNearChunks;

      setFiletokencount(knowledgetoken);
    }
  };
  const loadKnowledge = async () => {
    setCount(0);
    setIsUploadLoading(true);
    setIsUploadSuccess(false);
    const formData = new FormData();
    uploadedFiles.forEach((file) => {
      formData.append('files', file);
    });
    try {
      const queryParams = {
        knowledge_name: newKnowledgeID ? newKnowledgeID : instanceDetails.name,
        flow_id: isEditMode ? instanceDetails.flow : flowId,
        chunking_strategy: step3
          ? isEditMode
            ? instanceDetails.chunking_strategy
            : chunkingStrategy
          : undefined,
      };
      let url = '';
      const azure_api = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/knowledge/load`;
      const azure_ocr_api = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/knowledge/load-with-ocr`;
      // const azure_image_api = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/knowledge/load-images-with-ocr`;
      const gcp_api = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/gcp/knowledge/load`;
      const gcp_ocr_api = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/gcp/knowledge/load-with-ocr`;
      const aws_api = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/aws/knowledge/load`;
      const aws_ocr_api = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/aws/knowledge/load-with-ocr`;
      if (isEditMode) {
        if (instanceDetails.cloudEnvironment === 'aws') {
          if (isScannedDoc) {
            url = aws_ocr_api;
          } else {
            url = aws_api;
          }
        } else if (instanceDetails.cloudEnvironment === 'gcp') {
          if (isScannedDoc) {
            url = gcp_ocr_api;
          } else {
            url = gcp_api;
          }
        } else if (instanceDetails.cloudEnvironment === 'azure') {
          if (documentContents === 'tables-or-images') {
            url = azure_ocr_api;
          } else {
            url = azure_api;
          }
        }
      } else {
        if (cloudProvider === 'aws') {
          url = aws_api;
        } else if (cloudProvider === 'gcp') {
          if (isScannedDoc) {
            url = gcp_ocr_api;
          } else {
            url = gcp_api;
          }
        } else if (cloudProvider === 'azure') {
          if (documentContents === 'tables-or-images') {
            url = azure_ocr_api;
          } else {
            url = azure_api;
          }
        }
      }
      const response = await axios.post(url, formData, {
        params: queryParams,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        setShowToast(false);
        setShowErrMsg('');
        setUploadedFiles([]);
        setUploadCount(0);
        setUploadError(false);
        setNewKnowledgeID('');
        setKnowlwdgeidAdded(true);
        setDocumentContents(null);
        setIsScannedDoc(false);
        if (knowledgeFileUpload && knowledgeFileUpload?.current) {
          knowledgeFileUpload.current.value = '';
        }
        const responseData = response.data;
        const knowledgeArray = [responseData.knowledge];
        const transformedKnowledge: Knowledge[] =
          knowledgeArray.map((item: Knowledge) => ({
            id: item.id,
            name: item.name,
          })) || [];
        setCurrentKnowledgeID((prevKnowledge) => [
          ...prevKnowledge,
          ...transformedKnowledge,
        ]);
        addKnowledgeID(flowId);
      } else {
        setShowToast(true);
        setIsUploadLoading(false);
      }
    } catch (error: any) {
      const err = error?.response?.data?.error;
      setShowErrMsg(err?.errorMessage);
      setIsUploadLoading(false);
      setDocumentContents(null);
      setIsScannedDoc(false);
      setShowToast(true);
      console.error('Error:', error);
    }
  };

  const handleCreateTemplate = async (payload: any) => {
    try {
      let templateApi = '';
      let response;
      if (isEditMode) {
        templateApi = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/template/flow`;
        response = await axios.put(templateApi, payload, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } else {
        templateApi = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/template`;
        response = await axios.post(templateApi, payload, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
      }
      const responseData = response.data;
      if (response.status === 200) {
        setShowToast(false);
        setShowErrMsg('');
        getInstanceData(selectedInstance);
        nextStep();
        setShowNewInstanceModal(false);
        setCurrentKnowledgeID([]);
        return responseData;
      } else {
        setShowToast(true);
        return;
      }
    } catch (error: any) {
      const err = error?.response?.data?.error;
      setShowToast(true);
      setShowErrMsg(err?.errorMessage);
      console.error('Error:', error);
      return;
    }
  };

  const createTemplate = () => {
    const matches = templatePrompt?.match(/{{knowledgeid_(\w+)}}/g);
    const knowledgeIdsInPrompt = matches?.map((match) =>
      match.substring(14, match.length - 2)
    );

    const duplicateKnowledgeIds = matches?.filter(
      (item, index, array) => array.indexOf(item) !== index
    );

    if (duplicateKnowledgeIds && duplicateKnowledgeIds?.length > 0) {
      // Keeping only single duplicate Ids to show in error message
      const uniqueDuplicates = [
        ...new Set(
          duplicateKnowledgeIds?.map((match) =>
            match.substring(14, match.length - 2)
          )
        ),
      ];
      setShowErrMsg(
        `Knowledge ID(s) - ${uniqueDuplicates.join(', ')} are referenced in the system template more than once. Please correct and refer them only once.`
      );
      setShowToast(true);
      return;
    }
    const allKnowledgeIds = [
      ...(knowledgeIDs?.map((item) => item.id) ?? []),
      ...(newKnowledgeIds?.map((item) => item.id) ?? []),
      ...(instanceDetails.knowledgeIds?.map((item: any) => item.id) ?? []),
    ];
    const missingIds = knowledgeIdsInPrompt
      ? knowledgeIdsInPrompt.filter(
          (id) => !allKnowledgeIds.includes(parseInt(id))
        )
      : [];

    const docInsightKnowledgeIds = allKnowledgeIds?.[0];
    if (currentApp?.name === DOC_INSIGHT) {
      if (
        allKnowledgeIds.length === 0 &&
        !templatePrompt.includes('{{knowledgeid_xxx}}')
      ) {
        setShowErrMsg(
          'Please add {{knowledgeid_xxx}} to the template to proceed further.'
        );
        setShowToast(true);
        return;
      } else if (
        allKnowledgeIds?.length > 0 &&
        (missingIds?.length > 0 || !knowledgeIdsInPrompt)
      ) {
        setShowErrMsg(
          `The Knowledge ID ${missingIds?.length > 0 ? `- ${missingIds}` : `- ${docInsightKnowledgeIds}`} referenced in the system template are either incorrect or deleted. Please correct to proceed further.`
        );
        setShowToast(true);
        return;
      }
    } else {
      if (
        allKnowledgeIds.length > 0 &&
        (missingIds?.length > 0 ||
          (missingIds?.length === 0 && !knowledgeIdsInPrompt))
      ) {
        setShowErrMsg(
          `The Knowledge ID(s) ${missingIds?.length > 0 ? `- ${missingIds}` : ''} referenced in the system template are either incorrect or deleted. Please correct to proceed further.`
        );
        setShowToast(true);
        return;
      }
    }
    let payload;
    // outputColumns.push(...extraOutputColumns);
    let outputColumnValues: any = [];
    outputColumnValues = outputColumns.map(
      (output_columns) => output_columns.value
    );
    let columnOptions: any = [];
    columnOptions.push(
      outputColumnValues.filter((output_columns: any) => output_columns !== '')
    );
    const outputColumnsPayload = columnOptions?.[0]?.[0]
      ? columnOptions?.[0]
      : undefined;
    if (isEditMode) {
      payload = {
        flow_id: instanceDetails.flow,
        template_id: instanceDetails.templateId,
        output_columns: outputColumnsPayload,
        prompt_template: templatePrompt
          ? templatePrompt
          : instanceDetails?.template,
        temperature: sliderValue,
      };
    } else {
      payload = {
        name: newTemplateName ? newTemplateName : instanceDetails.templateName,
        flow: flowId,
        output_columns: outputColumnsPayload,
        promptTemplate: fixNewlineChars(templatePrompt),
        doc_template_ids: fileId,
      };
    }
    handleCreateTemplate(payload);
  };

  useEffect(() => {
    //code for llm guard
    if (step1 && step2 && step3 && step4) {
      setTotalSteps(4);
    } else if (step1 && step2 && step4) {
      setTotalSteps(3);
    } else if (step1 && step3 && step4) {
      setTotalSteps(3);
    } else if (step1 && step2 && step3) {
      setTotalSteps(3);
    } else if (step1 && (step3 || step4)) {
      setTotalSteps(2);
    } else {
      setTotalSteps(1);
    }
  }, [step1, step2, step3, step4]);

  useEffect(() => {
    if (chunkingStrategy === 'SPACY_SENT_CHUNK') {
      setChunkSizeValue(10);
      setKNearChunksValue(8);
    } else if (chunkingStrategy === 'TOKEN_LIMIT_CHUNK') {
      setChunkSizeValue(1500);
      setKNearChunksValue(6);
    } else if (chunkingStrategy === 'CUSTOM_PARA_CHUNK') {
      setChunkSizeValue(100);
      setKNearChunksValue(10);
    } else if (chunkingStrategy === 'PAGE_WISE_CHUNK') {
      setChunkSizeValue(1);
      setKNearChunksValue(6);
    } else if (chunkingStrategy === 'LANGCHAIN_SENT_CHUNK') {
      setChunkSizeValue(25);
      setKNearChunksValue(15);
    } else if (chunkingStrategy === 'LANGCHAIN_PARA_CHUNK') {
      setChunkSizeValue(400);
      setKNearChunksValue(15);
    } else if (chunkingStrategy === 'LLAMA_AGENTIC_CHUNK') {
      setChunkSizeValue(5500);
      setKNearChunksValue(1);
    }
  }, [chunkingStrategy]);

  useEffect(() => {
    const removeSuccessMessage = setTimeout(() => {
      setIsUploadSuccess(false);
    }, 2000);

    return () => clearTimeout(removeSuccessMessage);
  }, [isUploadSuccess]);

  useEffect(() => {
    if (isEditMode) {
      setChunkSizeValue(instanceDetails.chunkSize);
      setKNearChunksValue(instanceDetails.kNearChunks);
      setSliderValue(instanceDetails.temperature);
    }
  }, [isEditMode, instanceDetails]);
  useEffect(() => {
    let count = 0;
    if (isEditMode) {
      if (instanceDetails && instanceDetails.knowledgeIds.length) {
        for (let i = 0; i < instanceDetails.knowledgeIds.length; i++) {
          count =
            count +
            templatePrompt.split(instanceDetails.knowledgeIds[i].id).length -
            1;
        }
      }

      if (knowledgeIDs.length > 0) {
        for (let i = 0; i < knowledgeIDs.length; i++) {
          count = count + templatePrompt.split(knowledgeIDs[i].id).length - 1;
        }
        // count=count-1;
      }
      count = count;
      const knowledgetoken =
        (count / 2) * instanceDetails.chunkSize * instanceDetails.kNearChunks;

      setFiletokencount(knowledgetoken);
    }

    if (!isEditMode) {
      if (knowledgeIDs) {
        for (let i = 0; i < knowledgeIDs.length; i++) {
          count = count + templatePrompt.split(knowledgeIDs[i].id).length - 1;
        }
      }
      const knowledgetoken = count * chunkSizeValue * kNearChunksValue;
      setFiletokencount(knowledgetoken);
    }
  }, [selectedModel]);
  useEffect(() => {
    if (isEditMode) {
      instanceDetails.llmGuard
        ? setLlmGuardOption('Yes')
        : setLlmGuardOption('No');
      instanceDetails.renderMarkdown
        ? setRenderMarkdown('Yes')
        : setRenderMarkdown('No');
    }
  }, [isEditMode, instanceDetails.llmGuard, instanceDetails.renderMarkdown]);

  const handleUploadInstanceDoc = async () => {
    const formData = new FormData();
    uploadedFiles.forEach((file) => {
      formData.append('files', file);
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/template/document/submit`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        setShowToast(false);
        setUploadedFiles([]);
        const responseData = response.data;
        const data: Array<string> = [];
        const res = responseData.response;
        res.forEach((item: any) => {
          data.push(item.id);
          setFileId(data);
        });
        setShowFileUploadSuccessMsg(true);
        if (filesSelectedForUploadRef?.current) {
          filesSelectedForUploadRef.current.value = '';
        }
      } else {
        setShowToast(true);
      }
    } catch (error: any) {
      setShowToast(true);
      console.error('Error:', error);
      const err = error?.response?.data;
      if (err && err.error) {
        setShowErrMsg(err.error.errorMessage);
      }
    }
  };

  const handleAddKeyValue = () => {
    setKeyValues([...keyValues, { key: '', value: '' }]);
  };

  const handleRemoveKeyValue = (index: number) => {
    const updatedKeyValues = [...keyValues];
    updatedKeyValues.splice(index, 1);
    setKeyValues(updatedKeyValues);
  };

  const handleKeyValueInputChange = (
    index: number,
    field: 'key' | 'value',
    value: string
  ) => {
    const updatedKeyValues = [...keyValues];
    updatedKeyValues[index][field] = value;
    setKeyValues(updatedKeyValues);
  };

  const handleKeyValueSubmit = () => {
    const nonEmptyPairs = keyValues.filter(
      ({ key, value }) => key !== '' && value !== ''
    );
    const keyValueJson = Object.fromEntries(
      nonEmptyPairs.map(({ key, value }) => [key, value])
    );
    const keyValueJsonString = JSON.stringify(keyValueJson);
    uploadSampleDocument(keyValueJsonString);
  };

  const uploadSampleDocument = async (keyValueJsonString: any) => {
    setIsUploadLoading(true);
    setIsUploadSuccess(false);
    const formData = new FormData();
    uploadedFiles.forEach((file) => {
      formData.append('pdf_file', file);
    });

    try {
      const queryParams = {
        metadata: keyValueJsonString,
        instance_id: instanceData?.id,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/one-shot-chain/notice-analysis/upload-sample-notice`,
        formData,
        {
          params: queryParams,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        setIsUploadLoading(false);
        setIsUploadSuccess(true);
        setShowToast(false);
        setShowErrMsg('');
        setUploadedFiles([]);
        setKeyValues([{ key: '', value: '' }]);
        if (filesSelectedForUploadRef?.current) {
          filesSelectedForUploadRef.current.value = '';
        }
        const responseData = response.data;
      } else {
        setShowToast(true);
        setIsUploadLoading(false);
        console.error('Failed to upload files');
      }
    } catch (error: any) {
      setIsUploadLoading(false);
      const err = error?.response?.data?.error;
      setShowToast(true);
      setShowErrMsg(err?.errorMessage);
      console.error('Error:', error);
    }
  };
  const handleKnowledgeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 0) {
      setUploadError(false);
    }
    if (event.target.value.length === 0 && uploadcount == 1) {
      setUploadError(true);
    }
    setNewKnowledgeID(event.target.value);
  };
  useEffect(() => {
    setUploadError(false);
  }, [currentStep]);
  useEffect(() => {
    if (isEditMode && instanceDetails && instanceDetails.template) {
      setTemplatePrompt(instanceDetails.template.replace(/\\n/g, '\n'));
    }
  }, [instanceDetails, isEditMode]);

  useEffect(() => {
    if (!isEditMode && currentApp && !templatePrompt.length) {
      switch (currentApp?.name) {
        case USER_STORY_GENERATOR:
          setTemplatePrompt(USER_STORY_PROMPT);
          break;
        case SALES_ENGINE:
          setTemplatePrompt(SALES_ENGINE_PROMPT);
          break;
        case MEETING_SUMMARIZER:
          setTemplatePrompt(MEETING_SUMMARIZER_PROMPT);
          break;
        case STATUS_REPORT_GENERATOR:
          setTemplatePrompt(STATUS_REPORT_PROMPT);
          break;
        case TEST_SCRIPT_GENERATOR:
          setTemplatePrompt(TEST_SCRIPT_PROMPT);
          break;
        case SCAN_TO_SUMMIFY:
          setTemplatePrompt(SCAN_TO_SUMMIFY_PROMPT);
          break;
        case HELP_DESK_ENGINE:
          setTemplatePrompt(HELP_DESK_PROMPT);
          break;
        case NOTICE_ENGINE:
          setTemplatePrompt(NOTICE_ENGINE_PROMPT);
          break;
        case CALL_CENTER:
          setTemplatePrompt(CALL_CENTER_PROMPT);
          break;
        case DOC_INSIGHT:
          setTemplatePrompt(DOC_INSIGHTS_PROMPT);
          break;
        case DEVELOPER_HELP_ENGINE:
          setTemplatePrompt(DEVELOPER_HELP_PROMPT);
          break;
        case POLICY_ENGINE:
          setTemplatePrompt(POLICY_ENGINE_PROMPT);
          break;
        case HELP_ENGINE:
          setTemplatePrompt(HELP_ENGINE_PROMPT);
          break;
        default:
          setTemplatePrompt(DEFAULT_TEMPLATE_PROMPT);
          break;
      }
    }
  }, []);
  useEffect(() => {
    if (isEditMode && instanceDetails) {
      const selectedCp = models.filter((cp) => {
        if (cp.name === instanceDetails.cloudEnvironment) return cp;
      });
      const cpOptions = selectedCp[0].models;
      setCloudProviderOptions(cpOptions);
      setSelectedModel(cpOptions[0].modelValue);
      if (instanceDetails.llmModel) {
        setSelectedModel(instanceDetails.llmModel);
      }
    }
  }, [instanceDetails]);

  useEffect(() => {
    if (isEditMode && instanceDetails) {
      const selectedCp = models.filter((cp) => {
        if (cp.name === instanceDetails.cloudEnvironment) return cp;
      });
      const cpOptions = selectedCp[0].models;
      setCloudProviderOptions(cpOptions);
      setSelectedModel(cpOptions[0].modelValue);
      if (instanceDetails.llmModel) {
        setSelectedModel(instanceDetails.llmModel);
      }
    } else {
      const selectedCp = models.filter((cp) => {
        if (cp.name === cloudProvider) return cp;
      });
      const cpOptions = selectedCp[0].models;
      setCloudProviderOptions(cpOptions);
      settokenlimit(cpOptions[0]);
      // setSelectedModel("GPT-3.5 Turbo");
    }
  }, [cloudProvider]);
  useEffect(() => {
    if (!isEditMode && selectedModel === '') {
      setSelectedModel('gpt-4o');
    }
    if (!isEditMode && !(selectedModel === '')) {
      const selectedCp = cloudProviderOptions.filter((cp: any) => {
        if (cp.modelName === selectedModel || cp.modelValue === selectedModel)
          return cp.tokenSize;
      });
      setSelectedModel(selectedCp[0].modelValue);
      settokenlimit(selectedCp[0]);
    }
  }, [instanceName]);
  useEffect(() => {
    if (!isEditMode) {
      const enc = getEncoding('gpt2');

      let newValue = templatePrompt
        ?.replace(/\{[\s\{]*\{/g, '{{')
        .replace(/\}[\s\}]*\}/g, '}}');
      // AutoComplete Bug solution- Jira ticket no. 500
      newValue = newValue.replace(/}}([^{]+}}?)/g, '}}');
      let newValue1 = enc.encode(newValue); // Encode the text using gpt2 encoding
      setTokencount(newValue1.length);
    }
  }, [instanceName]);
  const handleOutputColumnInputChange = (
    index: number,
    field: 'value',
    value: string
  ) => {
    const updatedKeyValues: any = [...outputColumns];
    updatedKeyValues[index][field] = value;
    setOutputcolumns(updatedKeyValues);
  };

  const handleAddOutputColumn = () => {
    setOutputcolumns([...outputColumns, { value: '' }]);
  };
  const handleRemoveOutputColumn = (index: number) => {
    const updatedKeyValues = [...outputColumns];
    updatedKeyValues.splice(index, 1);
    setOutputcolumns(updatedKeyValues);
  };

  useEffect(() => {
    cloudProvidrModelChange();
  }, [selectedModel, cloudProviderOptions, currentStep]);

  useEffect(() => {
    let outputColumn = document.getElementById('output-column');
    if (outputColumn != null) {
      outputColumn.scrollTop = outputColumn.scrollHeight;
    }
  }, [outputColumns]);

  const cloudProvidrModelChange = () => {
    const selectedCp = cloudProviderOptions.filter((cp: any) => {
      if (cp.modelName === selectedModel || cp.modelValue === selectedModel)
        return cp.tokenSize;
    });
    settokenlimit(selectedCp[0]);
  };
  const toggleRowExpansion = (rowIndex: number) => {
    setExpandedRows((prevExpandedRows) => {
      const newExpandedRows = new Set(prevExpandedRows);
      if (newExpandedRows.has(rowIndex)) {
        newExpandedRows.delete(rowIndex);
      } else {
        newExpandedRows.add(rowIndex);
      }
      return newExpandedRows;
    });
  };

  const newKnowledgeIds =
    knowledgeIDs &&
    knowledgeIDs.filter(
      (knowledgeID) =>
        !instanceDetails.knowledgeIds?.some(
          (instanceDetail: any) => instanceDetail.id === knowledgeID.id
        )
    );
  const outputColumnAdd = () => {
    setOutputcolumns([
      { value: 'Title' },
      { value: 'Test Steps' },
      { value: 'Expected Outcome' },
      { value: '' },
    ]);
  };
  const editTemperature = async () => {
    editModeusedTokenCount();

    let payload;
    if (currentApp?.name === CHANGE_COMMUNICATOR) {
      payload = {
        temperature: sliderValue,
        llm_guard: false,
        render_markdown: false,
      };
    } else {
      payload = {
        llm_model: selectedModel,
        temperature: sliderValue,
        llm_guard: llmGuardOption === 'Yes' ? true : false,
        render_markdown: renderMarkdown === 'Yes' ? true : false,
        query_expansion: queryExpansion,
      };
    }
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/instance/update/temperature-markdown-llmguard/${selectedInstance}`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const responseData = response.data;
      if (response.status === 200) {
        setShowToast(false);
        setShowErrMsg('');
        if (currentApp?.name === CHANGE_COMMUNICATOR) {
          setShowNewInstanceModal(false);
          getInstanceData(selectedInstance);
        } else {
          nextStep();
        }
      } else {
        const error = responseData?.error;
        setShowToast(true);
        setShowErrMsg(error?.errorMessage);
        console.error('response error', error);
        return;
      }
    } catch (error: any) {
      console.error(
        'Error Editing instance',
        error.response?.data || error.message
      );
      const err = error?.response?.data?.error;
      setShowToast(true);
      setShowErrMsg(err.errorMessage);
      if (err && err.errorMessage) {
        setShowErrMsg(err.errorMessage);
      } else {
        console.error('Error message not available');
      }
    }
  };
  const handleswitchchange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLlmGuardOption(event.target.checked ? 'Yes' : 'No');
  };
  const handleOutputColumnSort = () => {
    let cloneOutputColumns = [...outputColumns];
    const temp1 = cloneOutputColumns[dragOutputColumns.current];
    if (dragOutputColumns.current > dragggedOutputColumns.current) {
      for (
        let i = dragOutputColumns.current;
        i > dragggedOutputColumns.current;
        i--
      ) {
        cloneOutputColumns[i] = cloneOutputColumns[i - 1];
      }
      cloneOutputColumns[dragggedOutputColumns.current] = temp1;
    }
    // if(dragOutputColumns.current < dragggedOutputColumns.current)
    else {
      for (
        let i = dragOutputColumns.current;
        i < dragggedOutputColumns.current;
        i++
      ) {
        cloneOutputColumns[i] = cloneOutputColumns[i + 1];
      }
      cloneOutputColumns[dragggedOutputColumns.current] = temp1;
    }
    // const temp = cloneOutputColumns[dragOutputColumns.current];
    // cloneOutputColumns[dragggedOutputColumns.current] = temp;
    const filteredClone = cloneOutputColumns.filter(
      (output_columns) => output_columns.value !== ''
    );
    setOutputcolumns([...filteredClone, { value: '' }]);
  };
  const handleoautomaticmanualptionchange = (event: any) => {
    setSelectedOption(event.target.checked ? 'Manual' : 'Automatic');
    if (!isEditMode) {
      setRenderMarkdown('No');
      setLlmGuardOption('No');
      setSliderValue(0.0);
      if (chunkingStrategy === 'SPACY_SENT_CHUNK') {
        setChunkSizeValue(10);
        setKNearChunksValue(8);
      }
      if (chunkingStrategy === 'TOKEN_LIMIT_CHUNK') {
        setChunkSizeValue(1500);
        if (cloudProvider === 'cfg_ai') {
          setKNearChunksValue(3);
        } else {
          setKNearChunksValue(6);
        }
      }
      if (chunkingStrategy === 'CUSTOM_PARA_CHUNK') {
        setChunkSizeValue(100);
        setKNearChunksValue(10);
      }
      if (chunkingStrategy === 'PAGE_WISE_CHUNK') {
        setChunkSizeValue(1);
        setKNearChunksValue(6);
      }
      if (chunkingStrategy === 'LANGCHAIN_SENT_CHUNK') {
        setChunkSizeValue(25);
        setKNearChunksValue(15);
      }
      if (chunkingStrategy === 'LANGCHAIN_PARA_CHUNK') {
        setChunkSizeValue(400);
        setKNearChunksValue(15);
      }
      if (chunkingStrategy === 'LLAMA_AGENTIC_CHUNK') {
        setChunkSizeValue(5500);
        setKNearChunksValue(1);
      }
    }
  };
  const handlemodelselectchange = (event: any) => {
    const selectedCp = cloudProviderOptions.filter((cp: any) => {
      if (
        cp.modelName === event.target.value ||
        cp.modelValue === event.target.value
      )
        return cp.tokenSize;
    });
    setSelectedModel(event.target.value);
    settokenlimit(selectedCp[0]);
  };

  const getColor = (percentage: any) => {
    if (percentage <= 75) {
      return 'success'; // Green
    } else if (percentage < 100) {
      return 'warning'; // Yellow
    } else {
      return 'danger'; // Red
    }
  };

  function handleTemplatePromptChange(
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) {
    let count = 0;
    if (isEditMode) {
      if (instanceDetails.knowledgeIds.length) {
        for (let i = 0; i < instanceDetails.knowledgeIds.length; i++) {
          count =
            count +
            event.target.value.split(instanceDetails.knowledgeIds[i].id)
              .length -
            1;
        }
      }

      if (knowledgeIDs.length > 0) {
        for (let i = 0; i < knowledgeIDs.length; i++) {
          count =
            count + event.target.value.split(knowledgeIDs[i].id).length - 1;
        }
        // count=count-1;
      }
      count = count;
      const knowledgetoken =
        (count / 2) * instanceDetails.chunkSize * instanceDetails.kNearChunks;

      setFiletokencount(knowledgetoken);
    }

    if (!isEditMode) {
      if (knowledgeIDs) {
        for (let i = 0; i < knowledgeIDs.length; i++) {
          count =
            count + event.target.value.split(knowledgeIDs[i].id).length - 1;
        }
      }
      const knowledgetoken = count * chunkSizeValue * kNearChunksValue;
      setFiletokencount(knowledgetoken);
    }

    // Replace '{ {' with '{{' and '} }' with '}}'
    const enc = getEncoding('gpt2');

    let newValue = event.target.value
      ?.replace(/\{[\s\{]*\{/g, '{{')
      .replace(/\}[\s\}]*\}/g, '}}');
    // AutoComplete Bug solution- Jira ticket no. 500
    newValue = newValue.replace(/}}([^{]+}}?)/g, '}}');
    let newValue1 = enc.encode(newValue); // Encode the text using gpt2 encoding
    setTokencount(newValue1.length);
    let newValue2 = enc.decode(newValue1);
    setTemplatePrompt(newValue);
  }
  const cloudProviderSelectChange = (event: any) => {
    setCloudProvider(event.target.value);
    const selectedCp = models.filter((cp) => {
      if (cp.name === event.target.value) return cp;
    });
    const cpOptions = selectedCp[0].models;
    setSelectedModel(cpOptions[0].modelValue);
  };
  const handleDocumentContentsChange = (count: any) => {
    if (count % 2 == 0) {
      setDocumentContents('tables-or-images');
    } else {
      setDocumentContents('');
    }
    setCount(count + 1);
  };
  const handleQueryExpansion = (event: any) => {
    setQueryExpansion(event.target.checked);
  };
  return (
    <div>
      {currentApp?.name !== 'Change Communicator' && (
        <Stepper currentStep={currentStep} totalSteps={totalSteps} />
      )}
      {
        currentStep === 1 && step1 ? (
          <>
            <Form.Group
              className="instance-name"
              controlId="exampleForm.instanceName"
            >
              <div className="modal-header-container">
                <h2 className="modal-header-title">Basic Details</h2>
              </div>

              <div className="basic-details-container">
                <div className="basic-details-wrapper">
                  <Form.Label className="label-text">Instance Name</Form.Label>
                  <Form.Control
                    as="input"
                    type="text"
                    value={isEditMode ? instanceDetails.name : instanceName}
                    onChange={handleInstanceNameChange}
                    placeholder="Enter Instance Name"
                    className="text-input"
                    disabled={isEditMode || isBackClicked}
                  />
                </div>
              </div>
              <>
                <div className="basic-details-container">
                  <div className="cloudprovider-model-selector">
                    <div className="basic-details-wrapper cloudprovider-model-selector-width">
                      <Form.Label className="label-text">
                        Cloud Provider
                      </Form.Label>
                      <div className="cloudprovider-options">
                        <Form.Select
                          aria-label="Select Cloud Provider"
                          onChange={(evt) => cloudProviderSelectChange(evt)}
                          value={
                            isEditMode
                              ? instanceDetails.cloudEnvironment
                              : cloudProvider
                          }
                          disabled={isEditMode || isBackClicked}
                          className="model-selector"
                        >
                          <option value="azure">Azure</option>
                          <option value="gcp">GCP*</option>
                          <option value="aws">AWS*</option>
                          <option value="cfg_ai">CFG AI</option>
                        </Form.Select>
                      </div>
                    </div>
                    <div className="basic-details-wrapper cloudprovider-model-selector-width gap">
                      <Form.Label className="label-text">Model</Form.Label>
                      <Form.Select
                        className="model-selector"
                        value={selectedModel}
                        onChange={(event) => handlemodelselectchange(event)}
                      >
                        {cloudProviderOptions.map((models: any) => (
                          <option value={models.modelValue}>
                            {models.modelName}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </div>
                  <div>
                    <Form.Text>
                      * Upcoming Cloud Provider: GCP and AWS will be available
                      soon. Please refrain from selecting it while creating an
                      instance.
                    </Form.Text>
                  </div>
                </div>

                {currentApp?.name !== NOTICE_ANALYSIS_ENGINE && (
                  <div className="basic-details-container">
                    <div
                      style={{ fontWeight: 'bold' }}
                      className="option-labels"
                    >
                      <span
                        className={
                          selectedOption === 'Manual' ? 'disable-text' : ''
                        }
                      >
                        Automatic
                      </span>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={selectedOption === 'Manual'}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleoautomaticmanualptionchange(event)}
                      />
                      <span
                        className={
                          selectedOption === 'Manual' ? '' : 'disable-text'
                        }
                      >
                        Manual
                      </span>
                    </div>
                  </div>
                )}
              </>
              {selectedOption === 'Manual' && (
                <div className="basic-details-container">
                  <div className="basic-details-wrapper">
                    <Form.Group controlId="formSlider">
                      {' '}
                      <div className="custom-slider-wrapper">
                        <span className="precise-label">Precise</span>
                        <span className="creative-label">Creative</span>
                      </div>
                      <Form.Range
                        value={sliderValue}
                        min={0.0}
                        max={1.0}
                        step={0.1}
                        onChange={handleSliderChange}
                        className="custom-slider"
                      />
                    </Form.Group>
                  </div>
                  {currentApp?.name !== 'Change Communicator' && (
                    <>
                      {step3 && (
                        <div className="basic-details-wrapper">
                          <Form.Label className="label-text">
                            Vector Database
                          </Form.Label>
                          <Form.Select
                            aria-label="Select Vector Database"
                            onChange={(evt) => {
                              const value = evt.target.value as VectorDb;
                              setVectorDb(value);
                            }}
                            value={
                              isEditMode ? instanceDetails.vectorDb : vectorDb
                            }
                            disabled={isEditMode || isBackClicked}
                          >
                            <option value={WEAVIATE}>Weaviate</option>
                            <option value={PGVECTOR}>PG Vector</option>
                          </Form.Select>
                        </div>
                      )}
                      <div className="basic-details-wrapper">
                        <Form.Label className="label-text">
                          Markdown Format Response
                        </Form.Label>
                        <div
                          style={{ fontWeight: 'bold' }}
                          className="markdown-option-labels"
                        >
                          <span
                            className={
                              renderMarkdown === 'Yes' ? 'disable-text' : ''
                            }
                          >
                            No
                          </span>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            checked={renderMarkdown === 'Yes'}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setRenderMarkdown(
                                event.target.checked ? 'Yes' : 'No'
                              );
                            }}
                          />
                          <span
                            className={
                              renderMarkdown === 'Yes' ? '' : 'disable-text'
                            }
                          >
                            Yes
                          </span>
                        </div>
                      </div>
                      {cloudProvider !== 'cfg_ai' && (
                        <div className="basic-details-wrapper">
                          <Form.Label className="label-text label-container">
                            LLM Guard{' '}
                            <p className="llm-guard-upcoming-feature-text">
                              ** Upcoming Feature
                            </p>
                          </Form.Label>

                          <div
                            style={{ fontWeight: 'bold' }}
                            className="markdown-option-labels"
                          >
                            <span
                              className={
                                llmGuardOption === 'Yes' ? 'disable-text' : ''
                              }
                            >
                              No
                            </span>
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              checked={llmGuardOption === 'Yes'}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setLlmGuardOption(
                                  event.target.checked ? 'Yes' : 'No'
                                );
                              }}
                            />
                            <span
                              className={
                                llmGuardOption === 'Yes' ? '' : 'disable-text'
                              }
                            >
                              Yes
                            </span>
                          </div>
                        </div>
                      )}
                      {(isEditMode ? instanceDetails.vectorDb : vectorDb) ===
                        WEAVIATE && (
                        <div className="basic-details-wrapper">
                          <Form.Label className="label-text">
                            Enhanced Document Search
                            <span style={{ fontWeight: 'normal' }}>
                              {' '}
                              (Slower but more accurate)
                            </span>
                          </Form.Label>
                          <div
                            style={{ fontWeight: 'bold' }}
                            className="markdown-option-labels"
                          >
                            <span
                              className={queryExpansion ? 'disable-text' : ''}
                            >
                              No
                            </span>
                            <Form.Check
                              type="switch"
                              id="query-expansion-switch"
                              onChange={handleQueryExpansion}
                              checked={queryExpansion}
                            />
                            <span
                              className={queryExpansion ? '' : 'disable-text'}
                            >
                              Yes
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
              {selectedOption === 'Manual' &&
                step3 &&
                currentApp?.name !== 'Notice Analysis Engine' && (
                  <>
                    <div className="basic-details-container">
                      <div className="basic-details-wrapper">
                        <Form.Label className="label-text">
                          Chunking Strategy
                        </Form.Label>
                        <Form.Select
                          aria-label="Select Chunking strategy"
                          onChange={(evt) => {
                            setChunkingStrategy(evt.target.value);
                          }}
                          value={
                            isEditMode
                              ? instanceDetails.chunking_strategy
                              : chunkingStrategy
                          }
                          disabled={isEditMode || isBackClicked}
                        >
                          <option value="SPACY_SENT_CHUNK">
                            Sentence chunking (SpaCy)
                          </option>
                          <option value="CUSTOM_PARA_CHUNK">
                            Paragraph chunking (Custom)
                          </option>
                          <option value="TOKEN_LIMIT_CHUNK">
                            Token chunking
                          </option>
                          <option value="PAGE_WISE_CHUNK">
                            Page-wise Chunking
                          </option>
                          <option value="LANGCHAIN_SENT_CHUNK">
                            Sentence chunking (LangChain)
                          </option>
                          <option value="LANGCHAIN_PARA_CHUNK">
                            Paragraph chunking (LangChain)
                          </option>
                          <option value="LLAMA_AGENTIC_CHUNK">
                            Llama Agentic Chunking (Character-Wise)*
                          </option>
                        </Form.Select>
                        {(isEditMode
                          ? instanceDetails.chunking_strategy
                          : chunkingStrategy) === 'LLAMA_AGENTIC_CHUNK' && (
                          <div>
                            <Form.Text>
                              * GCP and AWS supports only small documents with
                              Llama Agentic Chunking Method.
                            </Form.Text>
                          </div>
                        )}
                      </div>
                      {(isEditMode
                        ? instanceDetails.chunking_strategy
                        : chunkingStrategy) === 'SPACY_SENT_CHUNK' && (
                        <>
                          <div className="basic-details-wrapper">
                            <Form.Group controlId="formSlider">
                              {' '}
                              <Form.Label className="label-text">
                                {'Chunk Size '}
                                {chunkSizeValue && (
                                  <Form.Text className="label-text">
                                    {chunkSizeValue}
                                  </Form.Text>
                                )}
                              </Form.Label>
                              <Form.Range
                                value={chunkSizeValue}
                                min={10}
                                max={25}
                                step={1}
                                onChange={handleChunkSizeChange}
                                className="custom-slider"
                                disabled={isEditMode || isBackClicked}
                              />
                            </Form.Group>
                          </div>
                          <div className="basic-details-wrapper">
                            <Form.Group controlId="formSlider">
                              {' '}
                              <Form.Label className="label-text">
                                {'Chunks per Response: '}
                                {kNearChunksValue && (
                                  <Form.Text className="label-text">
                                    {kNearChunksValue}
                                  </Form.Text>
                                )}
                              </Form.Label>
                              <Form.Range
                                value={kNearChunksValue}
                                min={8}
                                max={40}
                                step={1}
                                onChange={handleKNearChunksChange}
                                className="custom-slider"
                                disabled={isEditMode || isBackClicked}
                              />
                            </Form.Group>
                          </div>
                        </>
                      )}
                      {(isEditMode
                        ? instanceDetails.chunking_strategy
                        : chunkingStrategy) === 'TOKEN_LIMIT_CHUNK' && (
                        <>
                          <div className="basic-details-wrapper">
                            <Form.Group controlId="formSlider">
                              {' '}
                              <Form.Label className="label-text">
                                {'Chunk Size '}
                                {chunkSizeValue && (
                                  <Form.Text className="label-text">
                                    {chunkSizeValue}
                                  </Form.Text>
                                )}
                              </Form.Label>
                              <Form.Range
                                value={chunkSizeValue}
                                min={1000}
                                max={3000}
                                step={100}
                                onChange={handleChunkSizeChange}
                                className="custom-slider"
                                disabled={isEditMode || isBackClicked}
                              />
                            </Form.Group>
                          </div>
                          <div className="basic-details-wrapper">
                            <Form.Group controlId="formSlider">
                              {' '}
                              <Form.Label className="label-text">
                                {'Chunks per Response: '}
                                {kNearChunksValue && (
                                  <Form.Text className="label-text">
                                    {kNearChunksValue}
                                  </Form.Text>
                                )}
                              </Form.Label>
                              <Form.Range
                                value={kNearChunksValue}
                                min={6}
                                max={15}
                                step={1}
                                onChange={handleKNearChunksChange}
                                className="custom-slider"
                                disabled={isEditMode || isBackClicked}
                              />
                            </Form.Group>
                          </div>
                        </>
                      )}
                      {(isEditMode
                        ? instanceDetails.chunking_strategy
                        : chunkingStrategy) === 'CUSTOM_PARA_CHUNK' && (
                        <>
                          <div className="basic-details-wrapper">
                            <Form.Group controlId="formSlider">
                              {' '}
                              <Form.Label className="label-text">
                                {'Chunk Size '}
                                {chunkSizeValue && (
                                  <Form.Text className="label-text">
                                    {chunkSizeValue}
                                  </Form.Text>
                                )}
                              </Form.Label>
                              <Form.Range
                                value={chunkSizeValue}
                                min={100}
                                max={200}
                                step={5}
                                onChange={handleChunkSizeChange}
                                className="custom-slider"
                                disabled={isEditMode || isBackClicked}
                              />
                            </Form.Group>
                          </div>
                          <div className="basic-details-wrapper">
                            <Form.Group controlId="formSlider">
                              {' '}
                              <Form.Label className="label-text">
                                {'Chunks per Response: '}
                                {kNearChunksValue && (
                                  <Form.Text className="label-text">
                                    {kNearChunksValue}
                                  </Form.Text>
                                )}
                              </Form.Label>
                              <Form.Range
                                value={kNearChunksValue}
                                min={8}
                                max={30}
                                step={1}
                                onChange={handleKNearChunksChange}
                                className="custom-slider"
                                disabled={isEditMode || isBackClicked}
                              />
                            </Form.Group>
                          </div>
                        </>
                      )}
                      {(isEditMode
                        ? instanceDetails.chunking_strategy
                        : chunkingStrategy) === 'PAGE_WISE_CHUNK' && (
                        <>
                          <div className="basic-details-wrapper">
                            <Form.Group controlId="formSlider">
                              {' '}
                              <Form.Label className="label-text">
                                {'Chunk Size '}
                                {chunkSizeValue && (
                                  <Form.Text className="label-text">
                                    {chunkSizeValue}
                                  </Form.Text>
                                )}
                              </Form.Label>
                              <Form.Range
                                value={chunkSizeValue}
                                min={1}
                                max={3}
                                step={1}
                                onChange={handleChunkSizeChange}
                                className="custom-slider"
                                disabled={isEditMode || isBackClicked}
                              />
                            </Form.Group>
                          </div>
                          <div className="basic-details-wrapper">
                            <Form.Group controlId="formSlider">
                              {' '}
                              <Form.Label className="label-text">
                                {'Chunks per Response: '}
                                {kNearChunksValue && (
                                  <Form.Text className="label-text">
                                    {kNearChunksValue}
                                  </Form.Text>
                                )}
                              </Form.Label>
                              <Form.Range
                                value={kNearChunksValue}
                                min={4}
                                max={8}
                                step={1}
                                onChange={handleKNearChunksChange}
                                className="custom-slider"
                                disabled={isEditMode || isBackClicked}
                              />
                            </Form.Group>
                          </div>
                        </>
                      )}
                      {(isEditMode
                        ? instanceDetails.chunking_strategy
                        : chunkingStrategy) === 'LANGCHAIN_SENT_CHUNK' && (
                        <>
                          <div className="basic-details-wrapper">
                            <Form.Group controlId="formSlider">
                              {' '}
                              <Form.Label className="label-text">
                                {'Chunk Size '}
                                {chunkSizeValue && (
                                  <Form.Text className="label-text">
                                    {chunkSizeValue}
                                  </Form.Text>
                                )}
                              </Form.Label>
                              <Form.Range
                                value={chunkSizeValue}
                                min={25}
                                max={50}
                                step={1}
                                onChange={handleChunkSizeChange}
                                className="custom-slider"
                                disabled={isEditMode || isBackClicked}
                              />
                            </Form.Group>
                          </div>
                          <div className="basic-details-wrapper">
                            <Form.Group controlId="formSlider">
                              {' '}
                              <Form.Label className="label-text">
                                {'Chunks per Response: '}
                                {kNearChunksValue && (
                                  <Form.Text className="label-text">
                                    {kNearChunksValue}
                                  </Form.Text>
                                )}
                              </Form.Label>
                              <Form.Range
                                value={kNearChunksValue}
                                min={15}
                                max={30}
                                step={1}
                                onChange={handleKNearChunksChange}
                                className="custom-slider"
                                disabled={isEditMode || isBackClicked}
                              />
                            </Form.Group>
                          </div>
                        </>
                      )}
                      {(isEditMode
                        ? instanceDetails.chunking_strategy
                        : chunkingStrategy) === 'LANGCHAIN_PARA_CHUNK' && (
                        <>
                          <div className="basic-details-wrapper">
                            <Form.Group controlId="formSlider">
                              {' '}
                              <Form.Label className="label-text">
                                {'Chunk Size '}
                                {chunkSizeValue && (
                                  <Form.Text className="label-text">
                                    {chunkSizeValue}
                                  </Form.Text>
                                )}
                              </Form.Label>
                              <Form.Range
                                value={chunkSizeValue}
                                min={400}
                                max={600}
                                step={20}
                                onChange={handleChunkSizeChange}
                                className="custom-slider"
                                disabled={isEditMode || isBackClicked}
                              />
                            </Form.Group>
                          </div>
                          <div className="basic-details-wrapper">
                            <Form.Group controlId="formSlider">
                              {' '}
                              <Form.Label className="label-text">
                                {'Chunks per Response: '}
                                {kNearChunksValue && (
                                  <Form.Text className="label-text">
                                    {kNearChunksValue}
                                  </Form.Text>
                                )}
                              </Form.Label>
                              <Form.Range
                                value={kNearChunksValue}
                                min={15}
                                max={30}
                                step={1}
                                onChange={handleKNearChunksChange}
                                className="custom-slider"
                                disabled={isEditMode || isBackClicked}
                              />
                            </Form.Group>
                          </div>
                        </>
                      )}
                      {(isEditMode
                        ? instanceDetails.chunking_strategy
                        : chunkingStrategy) === 'LLAMA_AGENTIC_CHUNK' && (
                        <>
                          <div className="basic-details-wrapper">
                            <Form.Group controlId="formSlider">
                              {' '}
                              <Form.Label className="label-text">
                                {'Chunk Size '}
                                {chunkSizeValue && (
                                  <Form.Text className="label-text">
                                    {chunkSizeValue}
                                  </Form.Text>
                                )}
                              </Form.Label>
                              <Form.Range
                                value={chunkSizeValue}
                                min={4000}
                                max={6000}
                                step={100}
                                onChange={handleChunkSizeChange}
                                className="custom-slider"
                                disabled={isEditMode || isBackClicked}
                              />
                            </Form.Group>
                          </div>
                          <div className="basic-details-wrapper">
                            <Form.Group controlId="formSlider">
                              {' '}
                              <Form.Label className="label-text">
                                {'Chunks per Response: '}
                                {kNearChunksValue && (
                                  <Form.Text className="label-text">
                                    {kNearChunksValue}
                                  </Form.Text>
                                )}
                              </Form.Label>
                              <Form.Range
                                value={kNearChunksValue}
                                min={1}
                                max={3}
                                step={1}
                                onChange={handleKNearChunksChange}
                                className="custom-slider"
                                disabled={isEditMode || isBackClicked}
                              />
                            </Form.Group>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}

              <div className="button-container">
                {currentApp?.name !== 'Change Communicator' && (
                  <>
              {userRole && (userRole === ADMIN || userRole === SUPERADMIN) && (
                  <Button
                    variant="primary"
                    onClick={() =>
                      isEditMode || isBackClicked
                        ? editTemperature()
                        : createInstance()
                    }
                    className="primary-btn-style modal-button"
                  >
                    Next
                  </Button>
                
                )}
                </>
                )}
                {currentApp?.name === 'Change Communicator' && (
                  <>
                  {userRole && (userRole === ADMIN ||userRole === SUPERADMIN) && (
                  <Button
                    variant="primary"
                    onClick={() =>
                      isEditMode || isBackClicked
                        ? editTemperature()
                        : createInstance()
                    }
                    className="primary-btn-style modal-button"
                  >
                    Finish
                  </Button>
                  )}
                  </>
                )}
              </div>
            </Form.Group>
          </>
        ) : currentStep === 2 && step2 ? (
          <>
            <TrusthWorthyLlmForm
              instanceData={isEditMode ? selectedInstance : instanceData?.id}
              nextStep={nextStep}
              prevStep={prevStep}
              setShowNewInstanceModal={setShowNewInstanceModal}
              instanceDetails={instanceDetails}
            />
          </>
        ) : (!step2 && currentStep === 2 && step3) ||
          (step2 && currentStep === 3 && step3) ? (
          currentApp?.name === 'Notice Analysis Engine' ? (
            <Container>
              <div className="modal-header-container">
                <h2 className="modal-header-title">Upload Sample Document</h2>
              </div>
              <Row>
                <Form.Group
                  className="file-input"
                  controlId="exampleForm.fileInput"
                >
                  <Form.Label className="label-text">
                    Upload document(s)
                  </Form.Label>
                  <Form.Control
                    type="file"
                    multiple
                    accept=".pdf"
                    onChange={handleFileChange}
                    ref={filesSelectedForUploadRef}
                    disabled={isEditMode || isBackClicked}
                  />
                </Form.Group>
              </Row>
              <div className="keyValue-container">
                {keyValues.map((keyValue, index) => (
                  <>
                    <Row key={index} className="mb-2">
                      <Col md={10} className="mx-1">
                        <Form.Control
                          type="text"
                          placeholder="Key"
                          value={keyValue.key}
                          className="mb-2"
                          onChange={(e) =>
                            handleKeyValueInputChange(
                              index,
                              'key',
                              e.target.value
                            )
                          }
                          disabled={isEditMode || isBackClicked}
                        />
                        <Form.Control
                          as="textarea"
                          rows={2}
                          placeholder="Value"
                          value={keyValue.value}
                          onChange={(e) =>
                            handleKeyValueInputChange(
                              index,
                              'value',
                              e.target.value
                            )
                          }
                          disabled={isEditMode || isBackClicked}
                        />
                      </Col>
                      <Col md={1}>
                        {index === keyValues.length - 1 ? (
                          <Button
                            variant="success"
                            onClick={handleAddKeyValue}
                            disabled={isEditMode || isBackClicked}
                          >
                            +
                          </Button>
                        ) : (
                          <Button
                            variant="danger"
                            onClick={() => handleRemoveKeyValue(index)}
                          >
                            -
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </>
                ))}
              </div>
              <>
                {isUploadLoading && (
                  <div className="upload-spinner">
                    <Spinner animation="border" size="sm" /> Uploading
                  </div>
                )}
                {isUploadSuccess && (
                  <div className="upload-msg">
                    <Badge bg="success">Upload Successful</Badge>
                  </div>
                )}
              </>
              <div className="d-flex mt-4">
                <div className="button-container-secondary">
                  <Button
                    variant="primary"
                    onClick={handleKeyValueSubmit}
                    className="secondary-btn-style modal-button"
                    disabled={isEditMode || isBackClicked}
                  >
                    Submit
                  </Button>
                </div>
              </div>
              <hr />
              <div className="d-flex">
                <Button
                  variant="primary"
                  onClick={fetchInstance}
                  className="primary-btn-style modal-button"
                  style={{ display: 'flex', marginLeft: 'auto' }}
                >
                  Finish
                </Button>
              </div>
            </Container>
          ) : (
            <Container>
              <div className="modal-header-container">
                <h2 className="modal-header-title">Knowledge Details</h2>
              </div>
              <div className="basic-details-container-step2">
                <div className="basic-details-knowledge-wrapper">
                  <Form.Group
                    className="knowledge-name"
                    controlId="exampleForm.knowledgeName"
                  >
                    <Form.Label className="label-text">
                      Knowledge Name
                    </Form.Label>
                    <Form.Control
                      as="input"
                      // value={isEditMode ? instanceDetails.name : newKnowledgeID}
                      value={newKnowledgeID}
                      className="text-input"
                      onChange={handleKnowledgeName}
                      // disabled={isEditMode}
                    />
                  </Form.Group>
                </div>
                <div className="basic-details-knowledge-wrapper">
                  <Form.Group
                    className="file-input"
                    controlId="exampleForm.fileInput"
                  >
                    <Form.Label className="label-text">
                      Upload Document
                    </Form.Label>
                    <Form.Control
                      type="file"
                      multiple
                      accept=".pdf, .docx, .txt, .xlsx ,.csv"
                      onChange={handleFileChange}
                      ref={knowledgeFileUpload}
                    />
                    {isUploadLoading && (
                      <div className="upload-spinner">
                        <Spinner animation="border" size="sm" /> Uploading
                      </div>
                    )}
                    {uploadedFiles && isUploadSuccess && (
                      <div className="upload-msg">
                        <Badge bg="success">Upload Successful</Badge>
                      </div>
                    )}
                    <div className="basic-details-container-checkbox">
                      {(isEditMode
                        ? instanceDetails.cloudEnvironment
                        : cloudProvider) === 'azure' && (
                        <Form.Group>
                          <Form.Label>
                            The document being uploaded contains :{' '}
                          </Form.Label>
                          <Form.Check
                            type="checkbox"
                            name="documentContents"
                            label="Tables Or *Images (coming soon)"
                            value="tables-or-images"
                            checked={documentContents === 'tables-or-images'}
                            defaultChecked={false}
                            onChange={() => handleDocumentContentsChange(count)}
                          />
                        </Form.Group>
                      )}
                    </div>
                    <div className="choose-file-note">
                      Note: 1. Formats supported: pdf, doc, docx, txt, xlsx & csv
                    </div>
                    {((!isEditMode && cloudProvider === 'azure') ||
                      (isEditMode &&
                        instanceDetails.cloudEnvironment === 'azure')) && (
                      <div className="choose-file-note">
                        2. To ensure accurate results, please upload documents
                        containing tables in pdf format only. If documents are
                        uploaded in other formats (such as doc, docx, or txt),
                        the content within tables may not be recognized
                        correctly.
                      </div>
                    )}
                  </Form.Group>
                </div>
              </div>
              <div className="basic-details-container">
                <Button
                  variant="secondary"
                  onClick={loadKnowledge}
                  className="secondary-btn-style modal-button"
                  disabled={uploadError}
                >
                  Upload
                </Button>
              </div>
              {uploadError && newKnowledgeID.length <= 0 && (
                <div className="upload-error">
                  Knowledge Name is a mandatory field. Please enter the required
                  Knowledge Name to proceed further
                </div>
              )}
              <Form.Label className="label-text">{KNOWLEDGE_IDS}</Form.Label>
              <div className="basic-details-container">
                <KnowledgeTable
                  vectorDb={isEditMode ? instanceDetails.vectorDb : vectorDb}
                  data={
                    instanceDetails
                      ? instanceDetails.knowledgeIds
                      : knowledgeIDs
                  }
                  expandedRows={expandedRows}
                  toggleRowExpansion={toggleRowExpansion}
                  getInstanceData={getInstanceData}
                  showDelete={true}
                  cloudProvider={
                    instanceDetails
                      ? instanceDetails.cloudEnvironment
                      : cloudProvider
                  }
                  selectedInstance={
                    instanceDetails ? selectedInstance : instanceData?.id
                  }
                />
              </div>
              <div className="two-button-container">
                <Button
                  variant="primary"
                  onClick={() => {
                    setCount(0);
                    setDocumentContents('');
                    prevStep();
                  }}
                  className="secondary-btn-style modal-button"
                >
                  Back
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    setCount(0);
                    setDocumentContents('');
                    nextStep();
                  }}
                  className="primary-btn-style modal-button"
                  // disabled={uploadError}
                >
                  Next
                </Button>
              </div>
            </Container>
          )
        ) : (
          ((!step2 && currentStep === 3 && step4) ||
            (!step3 && currentStep === 3 && step4) ||
            (currentStep === 4 && step4) ||
            (!step3 && currentStep === 2 && step4)) && (
            <Container>
              <div className="modal-header-container">
                <h2 className="modal-header-title">Template Details</h2>
              </div>
              <Form.Group
                className="template-container"
                controlId="exampleForm.createTemplate"
              >
                <div className="template-field-wrapper">
                  <Form.Label className="label-text">Template Name</Form.Label>
                  <Form.Control
                    as="input"
                    type="text"
                    value={
                      isEditMode
                        ? instanceDetails.templateName
                        : newTemplateName
                    }
                    onChange={handleTemplateNameChange}
                    className="text-input"
                    disabled={isEditMode || isBackClicked}
                  />
                </div>
                <div className="template-field-wrapper">
                  <Form.Label className="label-text">
                    Template Instructions
                  </Form.Label>
                  <CustomReactTextareaAutocomplete
                    className="form-control rta__textarea"
                    rows={3}
                    value={templatePrompt}
                    minChar={0}
                    onChange={handleTemplatePromptChange}
                    loadingComponent={Loading}
                    trigger={{
                      '{': {
                        dataProvider: () => {
                          // for DOC_INSIGHT app only, return only the last knowledge id - temporary fix to be removed later
                          if (currentApp?.name === DOC_INSIGHT) {
                            if (
                              instanceDetails &&
                              instanceDetails.knowledgeIds &&
                              instanceDetails.knowledgeIds.length > 0
                            ) {
                              return [
                                instanceDetails.knowledgeIds[
                                  instanceDetails.knowledgeIds.length - 1
                                ],
                              ];
                            } else {
                              return knowledgeIDs && knowledgeIDs.length > 0
                                ? [knowledgeIDs[knowledgeIDs.length - 1]]
                                : [];
                            }
                          } else {
                            if (instanceDetails) {
                              return [...instanceDetails.knowledgeIds];
                            } else {
                              return knowledgeIDs || [];
                            }
                          }
                        },
                        component: Item,
                        output: (item: Knowledge, trigger: string) =>
                          `{{knowledgeid_${item.id}}}`,
                      },
                    }}
                  />

                  {isEditMode &&
                    instanceDetails.chunking_strategy !==
                      'TOKEN_LIMIT_CHUNK' && (
                      <div className="tokens-div">
                        Progress bar is only visible when Chunking Stratergy is
                        Token Chunking
                      </div>
                    )}

                  {!isEditMode && chunkingStrategy !== 'TOKEN_LIMIT_CHUNK' && (
                    <div className="tokens-div">
                      Progress bar is only visible when Chunking Stratergy is
                      Token Chunking
                    </div>
                  )}
                  {isEditMode &&
                    instanceDetails.chunking_strategy ===
                      'TOKEN_LIMIT_CHUNK' && (
                      <div className="tokens-div">
                        {((filetokencount + tokencount) /
                          tokelimit?.tokenSize) *
                          100 >=
                          100 && (
                          <div className="token-limit-exceeded-warning-msg">
                            {' '}
                            Token limit for {selectedModel} exceeded. To fix
                            this issue, please consider changing the LLM model
                            or reducing the knowledge IDs used in the above
                            template
                          </div>
                        )}
                        <div className="token-counter-bar-wrapper">
                          <div className="token-display">
                            Tokens Used {filetokencount + tokencount}/
                            {tokelimit.tokenSize}
                          </div>
                          <ProgressBar
                            className="token-count-progress"
                            now={
                              ((filetokencount + tokencount) /
                                tokelimit.tokenSize) *
                              100
                            }
                            variant={getColor(
                              ((filetokencount + tokencount) /
                                tokelimit.tokenSize) *
                                100
                            )}
                          />
                        </div>
                      </div>
                    )}
                  {!isEditMode &&
                    chunkingStrategy === 'TOKEN_LIMIT_CHUNK' &&
                    !step3 && (
                      <div className="tokens-div">
                        Progress bar is only visible when Chunking Stratergy is
                        Token Chunking
                      </div>
                    )}
                  {!isEditMode &&
                    chunkingStrategy === 'TOKEN_LIMIT_CHUNK' &&
                    step3 && (
                      <div className="tokens-div">
                        {((filetokencount + tokencount) / tokelimit.tokenSize) *
                          100 >=
                          100 && (
                          <div className="token-limit-exceeded-warning-msg">
                            {' '}
                            Token limit for ${selectedModel} exceeded. To fix
                            this issue, please consider changing the LLM model
                            or reducing the knowledge IDs used in the above
                            template
                          </div>
                        )}
                        <div className="token-counter-bar-wrapper">
                          <div className="token-display">
                            Tokens Used {filetokencount + tokencount}/
                            {tokelimit.tokenSize}
                          </div>
                          <ProgressBar
                            className="token-count-progress"
                            now={
                              ((filetokencount + tokencount) /
                                tokelimit.tokenSize) *
                              100
                            }
                            variant={getColor(
                              ((filetokencount + tokencount) /
                                tokelimit.tokenSize) *
                                100
                            )}
                          />
                        </div>
                      </div>
                    )}
                  {/* {currentApp?.name === 'Test Case Generator' && (
                    <div className="margin-top margin-bottom">
                      <div className="choose-file-note">Note:</div>
                      <div className="choose-file-note">
                        {userNoteOutputColum1}
                      </div>
                      <div className="choose-file-note">
                        {userNoteOutputColum2}
                      </div>
                      <div className="choose-file-note">
                        {userNoteOutputColum3}
                      </div>
                    </div>
                  )} */}
                  {/* {currentApp?.name === 'Test Case Generator' && (
                    <div className="output-column-parent-container">
                      <div
                        className="keyValue-container output-column-container"
                        id="output-column"
                      >
                        {outputColumns.map((outputcolumn, index) => (
                          <>
                            <Row
                              key={index}
                              className="mb-2"
                              draggable={
                                outputColumns[index].value != '' ? true : false
                              }
                              onDragStart={() => {
                                dragOutputColumns.current = index;
                              }}
                              onDragEnter={(e: any) => {
                                dragggedOutputColumns.current = index;
                              }}
                              onDragEnd={() => handleOutputColumnSort()}
                              onDragOver={(e) => e.preventDefault()}
                            >
                              <Col md={1}>
                                {outputColumns[index].value != '' ? (
                                  <button className="sorting-button">
                                    <DragIcon />
                                  </button>
                                ) : (
                                  <></>
                                )}
                              </Col>
                              <Col md={9} className="output-column-field">
                                <Form.Control
                                  type="text"
                                  placeholder="Output Column"
                                  value={outputcolumn.value}
                                  disabled={
                                    outputcolumn.value === expectedOutCome ||
                                    outputcolumn.value === testSteps ||
                                    outputcolumn.value === title
                                  }
                                  className="mb-2"
                                  draggable={false}
                                  onChange={(e) =>
                                    handleOutputColumnInputChange(
                                      index,
                                      'value',
                                      e.target.value
                                    )
                                  }
                                />
                              </Col>
                              <Col
                                md={1}
                                className="add-remove-output-columns-button-column"
                              >
                                {index === outputColumns.length - 1 ? (
                                  <Button
                                    variant="success"
                                    className="add-remove-output-columns"
                                    onClick={handleAddOutputColumn}
                                  >
                                    +
                                  </Button>
                                ) : (
                                  <Button
                                    variant="danger"
                                    className="add-remove-output-columns"
                                    disabled={
                                      outputcolumn.value === expectedOutCome ||
                                      outputcolumn.value === testSteps ||
                                      outputcolumn.value === title
                                    }
                                    onClick={() =>
                                      handleRemoveOutputColumn(index)
                                    }
                                  >
                                    -
                                  </Button>
                                )}
                              </Col>
                            </Row>
                          </>
                        ))}
                      </div>
                    </div>
                  )} */}
                </div>
                {isUploadtemplate && (
                  <Col>
                    <Row>
                      <Form.Group
                        className="file-input"
                        controlId="exampleForm.fileInput"
                      >
                        <Form.Label>Upload document(s)</Form.Label>
                        <Form.Control
                          type="file"
                          multiple
                          accept={
                            currentApp?.name === 'Status Report Generator'
                              ? '.ppt, .pptx, .docx'
                              : '.csv, .xlsx, .pdf, .docx, .txt'
                          }
                          onChange={handleFileChange}
                          ref={filesSelectedForUploadRef}
                          disabled={isEditMode || isBackClicked}
                        />
                      </Form.Group>
                      <div>
                        <Form.Text>Formats supported: docx & ppt</Form.Text>
                      </div>
                      <div style={{ marginBottom: '10px' }}>
                        <Form.Text>
                          Note: If no templates are added then default templates
                          will be added from backend.
                        </Form.Text>
                      </div>
                    </Row>
                    <Row>
                      {showFileUploadSuccessMsg && (
                        <Alert variant="success">
                          File uploaded successfully
                        </Alert>
                      )}
                    </Row>
                    <Row>
                      <Col>
                      { userRole && (userRole === ADMIN || userRole === SUPERADMIN) && (
                        <Button
                          variant="secondary"
                          onClick={() => handleUploadInstanceDoc()}
                          className="secondary-btn-style upload-secondary"
                          disabled={isEditMode || isBackClicked}
                        >
                          Upload File
                        </Button>
                      )
                     }
                      </Col>
                    </Row>
                  </Col>
                )}
                {step3 && (
                  <div className="template-field-wrapper ">
                    <Form.Label className="label-text">
                      {KNOWLEDGE_IDS}
                    </Form.Label>
                    <div className="knowledgeId-list-wraper">
                      {instanceDetails ? (
                        <KnowledgeTable
                          data={instanceDetails.knowledgeIds}
                          expandedRows={expandedRows}
                          toggleRowExpansion={toggleRowExpansion}
                          showDelete={false}
                        />
                      ) : (
                        <KnowledgeTable
                          data={knowledgeIDs}
                          expandedRows={expandedRows}
                          toggleRowExpansion={toggleRowExpansion}
                          showDelete={false}
                        />
                      )}
                    </div>
                  </div>
                )}
              </Form.Group>

              <div className="two-button-container">
                <Button
                  variant="primary"
                  onClick={() => prevStep()}
                  className="secondary-btn-style modal-button"
                >
                  Back
                </Button>
                {userRole && (userRole === ADMIN || userRole === SUPERADMIN) && (
                <Button
                  variant="primary"
                  onClick={createTemplate}
                  className="modal-button"
                  disabled={filetokencount + tokencount > tokelimit.tokenSize}
                >
                  Finish
                </Button>
                )
               }
              </div>
            </Container>
          )
        )
        //code for llm guard
        // : (
        //   ((!step3 && currentStep === 3) ||
        //     (!step2 && currentStep === 3) ||
        //     (currentStep === 4 && step4)) && (
        //     <>
        //       <TrusthWorthyLlmForm
        //         instanceData={instanceData}
        //         nextStep={nextStep}
        //         prevStep={prevStep}
        //         setShowNewInstanceModal={setShowNewInstanceModal}
        //       />
        //     </>
        //   )
        // )
      }
    </div>
  );
};

export default InstanceGenerator;
