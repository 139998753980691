import './Copilot.css';
import {
  InstanceParams,
  copilotInstances,
  copilotAction,
  copilotPrompt,
} from '../../interfaces';
import { ReactComponent as BackIcon } from '../../assets/arrows/back-arrow.svg';
import { Link, useParams } from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';
import {
  Alert,
  Form,
  Modal,
  Row,
  Col,
  Card,
  Container,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { json } from 'stream/consumers';
import AddCopilotInstance from '../../components/Modals/AddCopilotInstancs/Index';
import AddEditCopilotFunctions from '../../components/Modals/AddEditCopilotFunctions';
import { FaTrashAlt } from 'react-icons/fa';
import ConfirmationBox from '../../components/ConfirmationBox';
import axios from 'axios';
import AddCopilotActions from '../../components/Modals/AddCopilotActions';
import Tabs from '../../components/Tabs/Tabs';
import AddEditCopilotPrompts from '../../components/Modals/AddCopilotPrompts';

const Copilot: React.FC = () => {
  const { projectId, appUrl } = useParams();

  const [isInstanceSelected, setIsInstanceSelected] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [copilotAction, SetCopilotAction] = useState<copilotAction[]>([]);
  const [copilotPrompt, SetCopilotPrompt] = useState<copilotPrompt[]>([]);
  const [showActionConfirmation, setShowActionConfirmation] = useState(false);

  const [seletedCopilotInsId, setSeletedCopilotInsId] = React.useState('');

  const [instanceData, setInstanceData] = useState<copilotInstances[]>([]);
  const [selectedInstance, setSelectedInstance] = useState('');
  const [itemId, setItemId] = useState('');
  const [itemActionId, setItemActionId] = useState('');
  const [itemPromptId, setItemPromptId] = useState('');
  const [showPromptConfirmation, setShowPromptConfirmation] = useState(false);

  const [copilotFunction, setcopilotFunction] = useState({
    name: 'case Insight 1',
    description: 'Description for Insight 1',
    active: true,
    copilot_instance_id: 300,
    type: 'Condition',
    type_id: 1001,
  });
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [instanceParams, setinstanceParams] = useState<InstanceParams>({
    copilotInstanceName: '',
    description: '',
    Id: '',
    functions: [
      {
        id: '',
        name: '',
        description: '',
        active: true,
      },
    ],
  });

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  //On Load
  useEffect(() => {
    // Replace the URL with your actual API endpoint
    fetchInstances().then(() => {
      // After fetching instances, set isInitialLoad to false
      setIsInitialLoad(false);
    });
  }, []);

  //fetching instaces from DB
  const fetchInstances = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_COPILOT_BACKEND_DOMAIN}/api/instances/getInstances`
      );
      const data = response.data;

      setInstanceData(data);
      // Handle the response data directly here
    } catch (error) {
      // Handle errors
      console.error('Error:', error);
    }
  };

  //fetching instaces from DB
  const fetchCopilotData = async (param: string) => {
    try {
      const copilot_params = {
        copilot_instance_id: param,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_COPILOT_BACKEND_DOMAIN}/api/functions/getFunctions`,
        copilot_params
      );

      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = response.data;
      setinstanceParams((prevInstanceParams) => ({
        ...prevInstanceParams,
        functions: data.map((item: any) => ({
          id: item.id,
          name: item.name,
          description: item.description,
          active: item.active,
          sample_question: item.sample_question,
        })),
      }));

      //setInstanceData(data);

      // Handle the response data directly here
    } catch (error) {
      // Handle errors
      console.error('Error:', error);
    }
  };

  //set id of selected instance
  const selectInstances = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSeletedCopilotInsId(selectedValue);
    const selectedDataItem = instanceData.find(
      (item) => item.id == selectedValue
    );
    const selectedName: string = selectedDataItem?.name || ''; // Provide a default value if name is undefined
    const selectedDescription: string = selectedDataItem?.description || '';
    const seletedId: string = selectedDataItem?.id || ''; // Provide a default value if description is undefined
    setinstanceParams((prevState) => ({
      ...prevState,
      copilotInstanceName: selectedName,
      description: selectedDescription,
      Id: seletedId,
    }));

    setSelectedInstance(selectedValue);
    fetchCopilotData(selectedValue);
    fetchActions(selectedValue);
    fetchPrompts(selectedValue);
    setIsInstanceSelected(selectedValue !== '');
    SetCopilotAction([]);
    SetCopilotPrompt([]);
  };

  const [isFunctionsTabActive, setFunctionsTabActive] = useState(true);
  const [isActionsTabActive, setActionsTabActive] = useState(false);
  const [isRollOverTabActive, setRollOverTabActive] = useState(false);
  const [isPromptsTabActive, setPromptsTabActive] = useState(false);

  const handleAddNewFunction = () => {
    fetchCopilotData(seletedCopilotInsId);
  };

  const handleMessageFromCopilotFunction = (message: string): void => {
    fetchCopilotData(seletedCopilotInsId);
  };

  const handleHideConfirmations = () => {
    setShowConfirmation(false);
  };

  const handleConfirmFunctions = () => {
    removeFunction(itemId);
    handleHideConfirmations();
  };

  //fetching actions from DB
  const fetchActions = async (param: string) => {
    try {
      const copilot_params = {
        copilot_instance_id: param,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_COPILOT_BACKEND_DOMAIN}/api/actions/getCopilotActions`,
        copilot_params
      );
      const data = response.data;
      SetCopilotAction(data.data);
    } catch (error) {
      // Handle errors
      console.error('Error:', error);
    }
  };

  //fetching prompts from DB
  // const fetchPrompts = async (param: string) => {
  //   try {
  //     const copilot_params = {
  //       copilot_instance_id: param,
  //     };
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_COPILOT_BACKEND_DOMAIN}/api/prompts/getCopilotPrompts`,
  //       copilot_params
  //     );
  //     const data = response.data;
  //     SetCopilotPrompt(data.data);
  //   } catch (error) {
  //     // Handle errors
  //     console.error('Error:', error);
  //   }
  // };
  const fetchPrompts = async (param: string) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_COPILOT_BACKEND_DOMAIN}/api/prompts/getPrompts`,
        { copilot_instance_id: param }
      );
      // console.log('Prompts response:', response.data);
      SetCopilotPrompt(response.data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleConfirmActions = () => {
    removeAction(itemActionId);
    handleHideConfirmationActions();
  };
  const handleHideConfirmationActions = () => {
    setShowActionConfirmation(false);
  };

  const handleMsgFromAction = (message: string): void => {
    fetchActions(seletedCopilotInsId);
  };

  const handleMessageFromPrompt = (message: string): void => {
    fetchPrompts(seletedCopilotInsId);
  };

  const handleConfirmPrompt = () => {
    removePrompt(itemPromptId);
    handleHidePromptConfirmation();
  };

  const handleHidePromptConfirmation = () => {
    setShowPromptConfirmation(false);
  };

  const removePrompt = async (promptId: any) => {
    try {
      const url = `/api/prompts/deletePrompt/${promptId}`;
      const response = await axios.delete(
        `${process.env.REACT_APP_COPILOT_BACKEND_DOMAIN}` + url
      );

      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      fetchPrompts(seletedCopilotInsId);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleShowPromptConfirmation = (e: any, itemActionId: string) => {
    e.preventDefault();
    setItemPromptId(itemActionId);
    setShowPromptConfirmation(true);
  };

  const tabsData = [
    {
      id: 'functions',
      title: 'Functions',
      content: isFunctionsTabActive ? (
        <div>
          <div className="tab_space">
            {isInstanceSelected &&
            selectedInstance !== 'Select an instance' &&
            !isInitialLoad ? (
              <>
                <AddEditCopilotFunctions
                  btnText="Add New Function"
                  id={seletedCopilotInsId}
                  sendMessageToCopilot={handleMessageFromCopilotFunction}
                  data={{
                    name: '',
                    description: '',
                    active: false,
                    disabled: isInitialLoad || !isInstanceSelected,
                    sample_question: '',
                  }}
                />
              </>
            ) : null}
          </div>
          {isInstanceSelected &&
          selectedInstance !== 'Select an instance' &&
          isFunctionsTabActive &&
          !isInitialLoad ? (
            <table className="table my-3">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Sample Query</th>
                  <th scope="col">Active</th>
                  <th scope="col" className="text-left">
                    Action
                  </th>
                </tr>
              </thead>
              {instanceParams.functions.map((item, index) => (
                <tbody key={index + 1}>
                  <tr>
                    <th scope="row" className="normal-weight">
                      {index + 1}
                    </th>
                    <td scope="row">{item.name}</td>
                    <td scope="row">{item.description}</td>
                    <td scope="row">{item.sample_question}</td>{' '}
                    <td scope="row">
                      <Form.Check type="checkbox" checked={item.active} />
                    </td>
                    <td scope="row">
                      <div className="function-buttons-container">
                        <AddEditCopilotFunctions
                          btnText="Edit"
                          id={String(item.id)}
                          sendMessageToCopilot={
                            handleMessageFromCopilotFunction
                          }
                          data={{
                            id: Number(item.id),
                            name: item.name,
                            description: item.description,
                            active: item.active,
                            disabled: isInitialLoad || !isInstanceSelected,
                            sample_question: String(item.sample_question),
                          }}
                        />
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Delete Function</Tooltip>}
                        >
                          <button
                            className="dltBtn"
                            onClick={(e) =>
                              handleShowConfirmation(
                                e,
                                String(item.id),
                                Boolean(item.active)
                              )
                            }
                            disabled={isInitialLoad || !isInstanceSelected}
                          >
                            <FaTrashAlt></FaTrashAlt>
                          </button>
                        </OverlayTrigger>
                      </div>
                    </td>
                    <ConfirmationBox
                      show={showConfirmation}
                      onHide={handleHideConfirmations}
                      onConfirm={handleConfirmFunctions}
                      message={confirmationMessage}
                    />
                  </tr>
                </tbody>
              ))}
            </table>
          ) : (
            <table className="table my-3">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Sample Query</th>
                  <th scope="col">Active</th>
                </tr>
              </thead>
            </table>
          )}
        </div>
      ) : null,
    },
    {
      id: 'actions',
      title: 'Actions',
      content: isActionsTabActive ? (
        <div>
          {isInstanceSelected &&
          selectedInstance !== 'Select an instance' &&
          !isInitialLoad ? (
            <>
              <AddCopilotActions
                copilotInstanceId={seletedCopilotInsId}
                btnText="Add New Action"
                sendMessageToParent={handleMsgFromAction}
                copilotActionId=""
              ></AddCopilotActions>
            </>
          ) : null}
          <Form>
            <div>
              <table className="table my-3">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Active</th>
                    <th scope="col">Function</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                {copilotAction.map((item, index) => (
                  <tbody key={index}>
                    <tr>
                      <th scope="row" className="normal-weight">
                        {index + 1}
                      </th>
                      <td scope="row">{item.name}</td>
                      <td scope="row">
                        <Form.Check type="checkbox" checked={item.active} />
                      </td>
                      <td scope="row">{item.functionname}</td>
                      <td scope="row">
                        <div className="action-buttons-container">
                          <AddCopilotActions
                            copilotInstanceId={seletedCopilotInsId}
                            btnText="Edit"
                            sendMessageToParent={handleMsgFromAction}
                            copilotActionId={item.copilot_action_id}
                          ></AddCopilotActions>
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Delete Action</Tooltip>}
                          >
                            <button
                              className="dltBtn"
                              onClick={(e) =>
                                handleShowActionConfirmation(
                                  e,
                                  String(item.copilot_action_id)
                                )
                              }
                            >
                              <FaTrashAlt></FaTrashAlt>
                            </button>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
              <ConfirmationBox
                show={showActionConfirmation}
                onHide={handleHideConfirmationActions}
                onConfirm={handleConfirmActions}
                message="Are you sure you want to delete this action?"
              />
            </div>
          </Form>
        </div>
      ) : null,
    },
    {
      id: 'prompts',
      title: 'Prompts',
      content: isPromptsTabActive ? (
        <div>
          {isInstanceSelected &&
          selectedInstance !== 'Select an instance' &&
          !isInitialLoad ? (
            <>
              <AddEditCopilotPrompts
                btnText="Add New Prompt"
                sendMessageToParent={handleMessageFromPrompt}
                id={seletedCopilotInsId}
                data={{
                  name: '',
                  prompt: '',
                  functionname: '',
                  active: false,
                  disabled: isInitialLoad || !isInstanceSelected,
                }}
              />
            </>
          ) : null}
          <Form>
            <div>
              <table className="table my-3">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Prompt</th>
                    <th scope="col">Active</th>
                    <th scope="col">Function</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                {copilotPrompt.map((item, index) => (
                  <tbody key={index}>
                    <tr>
                      <th scope="row" className="normal-weight">
                        {index + 1}
                      </th>
                      <td scope="row">{item.name}</td>
                      <td scope="row">{item.prompt}</td>
                      <td scope="row">
                        <Form.Check type="checkbox" checked={item.active} />
                      </td>
                      <td scope="row">{item.functionname}</td>
                      <td scope="row">
                        <div className="action-buttons-container">
                          <AddEditCopilotPrompts
                            btnText="Edit"
                            sendMessageToParent={handleMessageFromPrompt}
                            id={seletedCopilotInsId}
                            data={{
                              id: Number(item.id),
                              name: item.name,
                              prompt: item.prompt,
                              functionname: item.functionname,
                              active: item.active,
                              disabled: isInitialLoad || !isInstanceSelected,
                            }}
                          />
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Delete Prompt</Tooltip>}
                          >
                            <button
                              className="dltBtn"
                              onClick={(e) =>
                                handleShowPromptConfirmation(e, String(item.id))
                              }
                            >
                              <FaTrashAlt></FaTrashAlt>
                            </button>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
              <ConfirmationBox
                show={showPromptConfirmation}
                onHide={handleHidePromptConfirmation}
                onConfirm={handleConfirmPrompt}
                message="Are you sure you want to delete this prompt?"
              />
            </div>
          </Form>
        </div>
      ) : null,
    },
    // {
    //   id: 'runtime',
    //   title: 'Runtime',
    //   content: isRollOverTabActive ? <div>Roll Over Tab</div> : null,
    // },
  ];

  const handleTabChange = (tabId: string) => {
    if (tabId === 'functions') {
      setFunctionsTabActive(true);
    } else {
      setFunctionsTabActive(false);
    }
    if (tabId === 'actions') {
      setActionsTabActive(true);
    } else {
      setActionsTabActive(false);
    }
    if (tabId === 'rollover') {
      setRollOverTabActive(true);
    } else {
      setRollOverTabActive(false);
    }
    if (tabId === 'prompts') {
      setPromptsTabActive(true);
    } else {
      setPromptsTabActive(false);
    }
  };

  const removeFunction = async (id: any) => {
    // event.preventDefault();
    try {
      const url = '/api/functions/deleteFunction/' + id;
      const response = await axios.delete(
        `${process.env.REACT_APP_COPILOT_BACKEND_DOMAIN}` + url
      );

      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      fetchCopilotData(seletedCopilotInsId);
    } catch (error) {
      // Handle errors
      console.error('err');
      console.error('Error:', error);
    }
  };

  const removeAction = async (copilot_action_id: any) => {
    try {
      const url = `/api/actions/deleteCopilotAction/${copilot_action_id}`;
      const response = await axios.delete(
        `${process.env.REACT_APP_COPILOT_BACKEND_DOMAIN}` + url
      );

      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      fetchActions(seletedCopilotInsId);
    } catch (error) {}
  };

  const handleMessageFromChild = (message: string): void => {
    fetchInstances();
  };

  const handelMessageFromCopilotFunction = (message: string): void => {
    fetchCopilotData(seletedCopilotInsId);
  };

  const handleShowConfirmation = (e: any, itemId: string, active: boolean) => {
    e.preventDefault();
    setItemId(itemId);
    let confirmationMessage = '';

    if (active) {
      confirmationMessage =
        'You are trying to delete an active function. Do you still want to proceed?';
    } else {
      confirmationMessage = 'Are you sure you want to delete this function?';
    }

    // Set the confirmation message and show the confirmation box
    setConfirmationMessage(confirmationMessage);
    setShowConfirmation(true);
  };

  const handleShowActionConfirmation = (e: any, itemActionId: string) => {
    e.preventDefault();
    setItemActionId(itemActionId);
    setShowActionConfirmation(true);
  };

  const handleHideConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleConfirmAction = () => {
    removeFunction(itemId);
    handleHideConfirmation();
  };

  return (
    <>
      <div className="application-page-container">
        <div className="title">Co-pilot</div>
        <div className="back-button-container">
          <Link to={`/project/${projectId}`}>
            <BackIcon />
            Back
          </Link>
        </div>
        <div>
          <div className="single-app-container">
            <div>
              <Form.Group
                className="user-input"
                controlId="exampleForm.instancePicker"
              >
                <Form.Label>Select an Instance</Form.Label>
                <Form.Select
                  aria-label="Select an instance"
                  className="instance-picker"
                  value={selectedInstance}
                  onChange={selectInstances}
                >
                  <option>Select an instance</option>
                  {instanceData.map((instance) => (
                    <option key={instance.id} value={instance.id}>
                      {instance.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Label className="create-new-instance-link">
                  <AddCopilotInstance
                    sendMessageToParent={handleMessageFromChild}
                  ></AddCopilotInstance>
                </Form.Label>
              </Form.Group>
            </div>
            <div>
              <div className="sectionStyle">
                <Container>
                  <Form>
                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formid">
                          <Form.Label>Instance ID </Form.Label>
                          <Form.Control
                            type="text"
                            readOnly
                            value={instanceParams.Id}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formDesc">
                          <Form.Label>Description </Form.Label>
                          <Form.Control
                            type="text"
                            readOnly
                            value={instanceParams.description}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Container>
              </div>
              <Tabs tabs={tabsData} handleTabChange={handleTabChange} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Copilot;
