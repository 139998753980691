/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect, useRef } from 'react';
import { Form, Alert, Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import './StatusReport.css';
import stream from '../../utils/stream';
import llmGuardSubmit from '../../utils/llmGuardSubmit';
import CustomFeedback from '../../components/Feedback';
import { ReactComponent as BackIcon } from '../../assets/arrows/back-arrow.svg';
import { Appl } from '../../interfaces';
import ContentLoader from '../../components/Loader/contentLoader';
import DownloadButton from '../../components/DownloadButton';
import { Llm_models } from '../../types';
import SelectInstance from '../../components/SelectInstance';
import UserContext from '../../context/UserContext';
import { SiGooglecloud, SiAzuredevops, SiAmazonaws } from 'react-icons/si';
import ClearChat from '../../components/ClearContent';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { FaRegStopCircle } from 'react-icons/fa';
import {
  aws_llm_models_options,
  azure_llm_models_options,
  gcp_llm_models_options,
  // private_llm_models_options,
} from '../../utils/constants';

const StatusReport = () => {
  const { projectId, appUrl } = useParams();
  const apps = JSON.parse(sessionStorage.getItem('apps') as string) as Appl[];
  const currentApp = apps.find((app) => app.url === appUrl);
  const [outputData, setOutputData] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [showFeedbackOptions, setShowFeedBackOptions] =
    useState<boolean>(false);
  const [activityId, setActivityId] = useState<string>('');
  const [showDownload, setShowDownload] = useState<boolean>(false);
  const [inputText, setInputText] = useState<string>('');
  const [instanceData, setInstanceData] = useState<any>('');
  const userInputRef = useRef<HTMLTextAreaElement>(null);
  const [userInputNewLinecount, setUserInputNewLineCount] = useState(0);
  const [sanitizedPrompt, setSanitizedPrompt] = useState<string>('');
  const [showSanitizedPrompt, setShowSanitizedPrompt] =
    useState<boolean>(false);
  const userContext = useContext(UserContext) || {
    selectedInstance: '',
    userInput: '',
    setUserInput: () => {},
    showErrMsg: '',
    llmCloud: '',
    llmGuardOption: '',
  };
  const abortControllerRef = useRef<AbortController | null>(null);
  const {
    selectedInstance,
    userInput,
    setUserInput,
    showErrMsg,
    llmCloud,
    llmGuardOption,
  } = userContext;
  const [selectedLlmModel, setSelectedLlmModel] = useState<Llm_models>(
    llmCloud === 'gcp'
      ? 'gemini-pro'
      : llmCloud === 'aws'
        ? 'anthropic.claude-v2:1'
        : 'gpt-4-turbo'
  );
  const handleUserPromptChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    let pattern = /^[a-zA-Z0-9]*$/;
    if (pattern.test(userInput[userInput.length - 1])) {
      setUserInputNewLineCount(0);
    }
    setUserInput(event.target.value);
  };

  useEffect(() => {
    setSelectedLlmModel(
      llmCloud === 'gcp'
        ? 'gemini-pro'
        : llmCloud === 'aws'
          ? 'anthropic.claude-v2:1'
          : 'gpt-4-turbo'
    );
  }, [llmCloud]);

  const handlePromptSubmit = async (payload: any) => {
    setOutputData('');
    setShowToast(false);
    setIsLoading(true);
    setShowFeedBackOptions(false);
    setShowDownload(false);
    abortControllerRef.current = new AbortController();
    stream({
      requestBody: payload,
      streamCallback: (value: string) => {
        setOutputData((prev) => {
          return `${prev}${value}`;
        });
        // setIsLoading(false);
      },
      doneCallback: () => {
        setIsLoading(false);
        setShowFeedBackOptions(true);
        setShowDownload(true);
        setShowToast(false);
      },
      setShowToast: setShowToast,
      setIsLoading: setIsLoading,
      llmCloud: llmCloud,
      selectedLlmModel: selectedLlmModel,
      activityId: activityId,
      setActivityId: setActivityId,
      signal: abortControllerRef.current.signal,
    });
  };

  const handleLlmGuardSubmit = async (payload: any) => {
    setOutputData('');
    setIsLoading(true);
    setShowFeedBackOptions(false);
    setShowDownload(false);
    const response = await llmGuardSubmit({
      requestBody: payload,
      setIsLoading,
      setShowToast,
      setActivityId,
      llmCloud,
      setSanitizedPrompt,
    });
    if (response) {
      setOutputData((prev) => `${prev}${response}`);
      setShowFeedBackOptions(true);
      setShowDownload(true);
      setShowToast(false);
    }
  };

  const handleAbortStream = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      setIsLoading(false);
    }
  };

  const submitPrompt = () => {
    setShowSanitizedPrompt(false);
    setSanitizedPrompt('');
    setInputText(userInput);
    const payload = {
      input_text: userInput,
      instance_id: selectedInstance,
      include_references: true,
    };

    if (llmGuardOption === 'Yes') {
      handleLlmGuardSubmit(payload);
    } else handlePromptSubmit(payload);
  };

  const handleLlmModelChange = (value: string) => {
    setSelectedLlmModel(value as Llm_models);
  };
  const handleEnterAndNewLinecheck = (event: any) => {
    //Enter is pressed and there is userInput and Shiftkey is not pressed query is submitted
    if (userInputRef.current) {
      userInputRef.current.style.height = 'auto';
      userInputRef.current.style.height = `${userInputRef.current.scrollHeight}px`;
    }
    if (event.keyCode === 13 && userInput && !event.shiftKey) {
      submitPrompt();
      setUserInputNewLineCount(0);
    }
    //Enter is pressed and userinput is empty and no shiftkey will do nothing
    if (event.keyCode === 13 && !userInput && !event.shiftKey) {
      event.preventDefault();
      setUserInputNewLineCount(0);
      return;
    }
    if (event.keyCode === 13 && event.shiftKey) {
      // Add new line on shift + enter
      if (userInputNewLinecount > 2) {
        event.preventDefault();
        return;
      } else {
        const previousUserInput = userInput + '\n';
        setUserInputNewLineCount(userInputNewLinecount + 1);
        setUserInput(previousUserInput);
      }
    }
  };
  useEffect(() => {
    if (userInputRef.current) {
      userInputRef.current.style.height = 'auto';
      userInputRef.current.style.height = `${userInputRef.current.scrollHeight}px`;
    }
  }, [userInput]);

  return (
    <>
      <div className="title">{currentApp?.name}</div>
      <div className="back-button-container">
        <Link to={`/project/${projectId}`}>
          <BackIcon /> Back
        </Link>
        {selectedInstance && (
          <div className="cloud-icons">
            {llmCloud === 'gcp' ? (
              <SiGooglecloud style={{ fontSize: '1.5rem' }} />
            ) : llmCloud === 'aws' ? (
              <SiAmazonaws style={{ fontSize: '1.8rem' }} />
            ) : (
              <SiAzuredevops />
            )}
            <div className="pipe"></div>
            <span className="cloud-texts">
              {llmCloud === 'gcp'
                ? 'GCP'
                : llmCloud === 'aws'
                  ? 'AWS'
                  : 'Azure'}
            </span>
          </div>
        )}
      </div>
      <div className="single-app-container">
        <SelectInstance
          projectId={projectId}
          currentApp={currentApp}
          step3={false}
          isUploadtemplate={true}
          outputData={outputData}
          setOutputData={setOutputData}
          setShowDownload={setShowDownload}
          setInstanceDetails={setInstanceData}
          instanceDetails={instanceData}
        />

        <Form.Group className="user-input" controlId="exampleForm.userInput">
          <Form.Label>Enter your prompt</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={userInput}
            onChange={handleUserPromptChange}
            onKeyDown={(e) => handleEnterAndNewLinecheck(e)}
            ref={userInputRef}
            draggable={false}
            disabled={!selectedInstance?.length}
            className="srg-userInput-field"
          />
        </Form.Group>
      </div>
      <div className="user-story-generator-submit-container">
        {isLoading ? (
          <div className="with-download-button">
            <Button
              className="user-story-generator-submit send-btn"
              disabled={true}
            >
              <ContentLoader />
            </Button>
            {llmGuardOption === 'No' && (
              <Button
                className="abort-btn"
                variant="danger"
                type="button"
                onClick={handleAbortStream}
                style={{ marginLeft: '16px' }}
                disabled={!outputData}
              >
                <FaRegStopCircle size="1.25rem" />
              </Button>
            )}
          </div>
        ) : (
          <div className="with-download-button">
            <Button
              className="user-story-generator-submit"
              as="input"
              type="submit"
              value="Submit"
              onClick={submitPrompt}
              disabled={!userInput || outputData.length > 0}
            />
            <ClearChat
              disabled={outputData.length > 0 ? false : true}
              setOutputData={setOutputData}
              setShowDownload={setShowDownload}
              setShowRawTranscript={setShowSanitizedPrompt}
            />
            {showDownload && (
              <div style={{ marginLeft: '16px' }}>
                <DownloadButton
                  instanceId={selectedInstance}
                  outputData={outputData}
                  title="Download As"
                  activityType="Status Report Generator"
                />
              </div>
            )}
          </div>
        )}
      </div>

      {showToast && (
        <div
          style={{
            paddingTop: '80px',
            width: '50%',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Alert
            variant="danger"
            onClose={() => setShowToast(false)}
            dismissible
          >
            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
            <p>
              {showErrMsg
                ? showErrMsg
                : `Something went wrong. Please try again`}
            </p>
          </Alert>
        </div>
      )}
      {outputData && (
        <>
          {instanceData.renderMarkdown ? (
            <Form.Group controlId="outputTextArea">
              <div className="output-container markdown-table">
                <Markdown remarkPlugins={[remarkGfm]}>{outputData}</Markdown>
              </div>
            </Form.Group>
          ) : (
            <Form.Control
              as="textarea"
              rows={18}
              className="output-container"
              value={outputData}
              disabled
            />
          )}
        </>
      )}
      {llmGuardOption === 'Yes' &&
        sanitizedPrompt &&
        outputData?.length > 0 && (
          <Form.Group className="transcript-toggle-container">
            <Form.Check
              type="switch"
              id="transcript-view-switch"
              label=" View Sanitized Prompt"
              onChange={(evt) => setShowSanitizedPrompt(evt.target.checked)}
            />
          </Form.Group>
        )}
      {showSanitizedPrompt && (
        <Form.Group controlId="outputTextArea">
          <Form.Control
            as="textarea"
            rows={10}
            className="output-container"
            value={`Sanitized Prompt:\n${sanitizedPrompt}`}
            disabled
          />
        </Form.Group>
      )}
      {outputData.length > 0 && showFeedbackOptions && (
        <CustomFeedback
          url={`${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/activity/feedback/update`}
          activityId={activityId}
        />
      )}
    </>
  );
};

export default StatusReport;
