import { useState } from 'react';
import { authenticateUser } from '../../auth';
import { Button } from 'react-bootstrap';
import ContentLoader from '../../components/Loader/contentLoader';
import './LogoutPage.css';

const LogoutPage = () => {
  const [isLoading, setisLoading] = useState<boolean>(false);
  const handleLogin = () => {
    authenticateUser();
    setisLoading(true);
  };
  return (
    <>
      <header>
        <h1>
          <img src="/logo.png" alt="GenAIe Logo" className="genaie-logo" />
        </h1>
      </header>
      <div className="logout-page ">
        <div className="error-page-container">
          <h2>You have successfully logged out of GenAIe.</h2>
          <p>Click on the Login button below to log back in!</p>
          {isLoading ? (
            <Button disabled={true}>
              <ContentLoader />
            </Button>
          ) : (
            <Button onClick={handleLogin}>Login</Button>
          )}
        </div>
      </div>
      <footer>
        <div className="footer-container">
          <div>© 2024 GenAIe - Powered by SmartGPT</div>
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://cookienotice.deloitte.com"
            >
              Cookies
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default LogoutPage;
