import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Home from './pages/Home';
import Project from './pages/Project';
import ErrorPage from './pages/ErrorPage';
import Application from './pages/Application';
import ActivityHistoryTable from './pages/ActivityHistoryTable';
import UserContextProvider from './context/UserContextProvider';
import { PrivateRoute } from './privateRoute';

import Copilot from './pages/Copilot';
import CopilotNoUIV2 from './pages/CopilotNoUIV2';
import TotalCost from './pages/TotalCost';
import LogoutPage from './pages/LogoutPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <PrivateRoute>
        <UserContextProvider>
          <App />
        </UserContextProvider>
      </PrivateRoute>
    ),
    // errorElement: <NotFoundPage />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: 'project/:projectId',
        element: <Project />,
      },
      {
        path: 'project/:projectId/:appUrl',
        element: <Application />,
      },
      {
        path: 'project/:projectId/activity-history',
        element: <ActivityHistoryTable />,
      },
      {
        path: 'project/:projectId/co-pilot',
        element: <Copilot />,
      },
      {
        path: 'project/:projectId/copilotNoUI',
        element: <CopilotNoUIV2></CopilotNoUIV2>,
      },
      {
        path: 'project/track-usage',
        element: <TotalCost />,
      },
    ],
  },
  {
    path: '*',
    element: (
      <Routes>
        <Route path="*" element={<ErrorPage error={404} />} />
        <Route path="/403" element={<ErrorPage error={403} />} />
        <Route path="/logout" element={<LogoutPage />} />
      </Routes>
    ),
  },
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
