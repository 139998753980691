/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { useState, useRef, useContext } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import './CaseCopilotGenerator.css';
import { ReactComponent as BackIcon } from '../../assets/arrows/back-arrow.svg';
import { Appl, ChatboxInterface } from '../../interfaces';
import AdvancedSettings from '../../components/AdvancedSettings';
import UserContext from '../../context/UserContext';
import ContentLoader from '../../components/Loader/contentLoader';
import {
  ADVANCED_ERROR_MESSAGE,
  DATA_FILE,
  DICTIONARY_FILE,
  ERROR_MESSAGE,
  FORMATS_SUPPORTED,
  OPTION_SELECT,
  QUESTION,
  SNAP_MESSAGE,
  UPLOAD,
  UPLOAD_SUCCESS,
} from '../../utils/constants';
const CaseCopilotGenerator = () => {
  const { projectId, appUrl } = useParams();
  const apps = JSON.parse(sessionStorage.getItem('apps') as string) as Appl[];
  const chatbox = useRef<ChatboxInterface>(null);
  const overlayContainerRef = useRef<HTMLDivElement | null>(null);
  const [showErrMsg, setShowErrMsg] = useState<string>('');
  const [imgsrc, setImgsrc] = useState<string>('');
  const [showimg, setShowimg] = useState(false);
  const currentApp = apps.find((app) => app.url === appUrl);
  const userContext = useContext(UserContext) || {
    selectedInstance: '',
    useAdvancedSettings: false,
    userInput: '',
    setUserInput: () => {},
    isLoading: false,
    setIsLoading: () => {},
    showAdvancedSettings: false,
    setShowAdvancedSettings: () => {},
    showAdvancedSettingsAlert: false,
    setShowAdvancedSettingsAlert: () => {},
    chatContents: [],
    setChatContents: () => [],
    advancedSettings: {
      mode: 'autocut',
      autocut: 1,
      query_param: undefined,
      fusion_type: undefined,
    },
    localSelectedModel: '',
    setIsActivityActive: () => {},
    isActivityActive: false,
    showToast: false,
    setShowToast: () => {},
    queryExpansion: false,
  };

  const {
    setShowAdvancedSettingsAlert,
    showAdvancedSettingsAlert,
    showToast,
    setShowToast,
  } = userContext;

  interface FormState {
    dropdownSelection1: string;
    datafile: File | null;
    dictfile: File | null;
    query: string;
  }
  const initialFormState: FormState = {
    dropdownSelection1: '',
    datafile: null,
    dictfile: null,
    query: '',
  };
  const [formState, setFormState] = useState<FormState>(initialFormState);
  const [dataResponse, setDataRensponse] = useState('');
  const [dictResponse, setDictRensponse] = useState('');
  const [resultsData, setResultsData] = useState('');
  const [isUploadDataResponse, setIsUploadDataResponse] =
    useState<boolean>(false);
  const [isUploadDictResponse, setIsUploadDictResponse] =
    useState<boolean>(false);
  const [isUploadLoadingData, setIsUploadLoadingData] =
    useState<boolean>(false);
  const [isUploadLoadingDict, setIsUploadLoadingDict] =
    useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //Maintaining the state for Input,Select,Text fields
  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
    if (
      formState.query.length > 0 &&
      dataResponse.length > 0 &&
      dictResponse.length > 0
    ) {
      setSubmitDisabled(false);
    }
  };

  //Uploading the data file to endpoint and recieing response
  const handleDataFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile1 = event.target.files && event.target.files[0];
    setFormState((prevState) => ({
      ...prevState,
      datafile: selectedFile1,
      file1Uploaded: true,
    }));
    if (selectedFile1) {
      try {
        setIsUploadLoadingData(true);
        const formData = new FormData();
        formData.append('upload_file', selectedFile1);

        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/tabularqa/upload-data/`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        if (response?.status === 200 || response?.status === 201) {
          setDataRensponse(response.data);
          setIsUploadLoadingData(false);
          setIsUploadDataResponse(true);
        } else {
          setIsUploadLoadingData(false);
          setIsUploadDataResponse(true);
          console.error(`Error uploading file`);
        }
      } catch (error) {
        setIsUploadLoadingData(false);
        setIsUploadDataResponse(true);
        console.error(`Error uploading file`, error);
      }
    }
  };

  //Uploading the dictionary file to endpoint and recieing response
  const handleDictionaryFile = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile1 = event.target.files && event.target.files[0];
    setFormState((prevState) => ({
      ...prevState,
      dictfile: selectedFile1,
    }));
    if (selectedFile1) {
      try {
        setIsUploadLoadingDict(true);
        const formData = new FormData();
        formData.append('upload_file', selectedFile1);

        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/tabularqa/upload-dictionary/`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        if (response?.status === 200 || response?.status === 201) {
          setDictRensponse(response?.data);
          setIsUploadLoadingDict(false);
          setIsUploadDictResponse(true);
        } else {
          console.error(`Error uploading file`);
          setIsUploadLoadingDict(false);
          setIsUploadDictResponse(true);
        }
      } catch (error) {
        setIsUploadLoadingDict(false);
        setIsUploadDictResponse(true);
        console.error(`Error uploading file`, error);
      }
    }
  };

  //Submitting the form to endpoint

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setShowimg(false);
    try {
      const postData = {
        option: formState.dropdownSelection1,
        dict_path: dictResponse,
        data_path: dataResponse,
        query: formState.query,
      };
      if (postData?.query) {
        setSubmitDisabled(true);
        setIsLoading(true);
        setShowToast(false);

        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/tabularqa/chat`,
          postData,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.status === 200 || response.status === 201) {
          const data = response.data;
          if ('response' in data) {
            if (data.response.startsWith('data:image/png;base64,')) {
              setImgsrc(data.response);
              setShowimg(true);
              setResultsData('');
            } else {
              setShowimg(false);
              setResultsData(data.response);
            }
          }
          setIsLoading(false);
          setSubmitDisabled(false);
        } else {
          setIsLoading(true);
          setShowToast(true);
          console.error(`Error submitting file`);
        }
      }
    } catch (error: any) {
      setSubmitDisabled(false);
      setShowimg(false);
      setIsLoading(false);
      setShowToast(true);
      console.error('Error occurred:', error);
    }
  };

  return (
    <>
      <div className="application-page-container">
        <div className="title">{currentApp?.name}</div>
        <div className="back-button-container">
          <Link to={`/project/${projectId}`}>
            <BackIcon /> Back
          </Link>
          <div ref={overlayContainerRef}>
            <AdvancedSettings
              chatbox={chatbox}
              overlayContainerRef={overlayContainerRef}
            />
          </div>
        </div>
        <div className="single-app-container">
          <Form.Group className="fields">
            <Form.Label className="form-label">{OPTION_SELECT}</Form.Label>
            <Form.Control
              as="select"
              name="dropdownSelection1"
              onChange={handleInputChange}
              value={formState.dropdownSelection1}
            >
              <option value="">Select an option</option>
              <option value="Generic">Generic</option>
              <option value="OR">OR</option>
            </Form.Control>
          </Form.Group>
          <Form.Group className="data_upload">
            <Form.Label className="form-label">{DATA_FILE}</Form.Label>
            <Form.Control
              type="file"
              name="datafile"
              accept=".xlsx, .csv"
              onChange={handleDataFile}
            />
            <div>
              <Form.Text>{FORMATS_SUPPORTED}: xlsx and csv</Form.Text>
            </div>
            {isUploadLoadingData && (
              <div className="upload-spinner">
                <Spinner animation="border" size="sm" /> {UPLOAD}
              </div>
            )}
            {isUploadDataResponse && (
              <div className="upload-msg">
                <Badge bg="success">{UPLOAD_SUCCESS}</Badge>
              </div>
            )}
          </Form.Group>
          <Form.Group className="fields">
            <Form.Label className="form-label">{DICTIONARY_FILE}</Form.Label>
            <Form.Control
              type="file"
              name="dictfile"
              accept=".xlsx"
              onChange={handleDictionaryFile}
            />
            <div>
              <Form.Text>{FORMATS_SUPPORTED}: xlsx</Form.Text>
            </div>
            {isUploadLoadingDict && (
              <div className="upload-spinner">
                <Spinner animation="border" size="sm" /> {UPLOAD}
              </div>
            )}
            {isUploadDictResponse && (
              <div className="upload-msg">
                <Badge bg="success">{UPLOAD_SUCCESS}</Badge>
              </div>
            )}
          </Form.Group>
          <Form.Group className="user-input fields">
            <Form.Label className="form-label">{QUESTION}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="query"
              value={formState.query}
              onChange={handleInputChange}
            />
          </Form.Group>
          <div className="user-story-generator-submit-container">
            <div className="with-download-button">
              {isLoading ? (
                <Button
                  className="user-story-generator-submit is-loading-btn"
                  disabled={true}
                >
                  <ContentLoader></ContentLoader>
                </Button>
              ) : (
                <Button
                  className="user-story-generator-submit"
                  as="input"
                  type="submit"
                  disabled={submitDisabled}
                  value="Submit"
                  onClick={handleSubmit}
                />
              )}
            </div>
          </div>
        </div>
        <div className="single-app-container">
          <div className="results-container">
            <h4>Results</h4>
            {resultsData && (
              <div>
                <p>{resultsData}</p>
              </div>
            )}
            {showimg && (
              <div>
                <img className="img-upload" src={imgsrc} alt="" />
              </div>
            )}
          </div>
          <div>
            {showToast && (
              <div className="show-toast">
                <Alert
                  variant="danger"
                  onClose={() => setShowToast(false)}
                  dismissible
                >
                  <Alert.Heading>{ERROR_MESSAGE}</Alert.Heading>
                  <p>{showErrMsg ? showErrMsg : SNAP_MESSAGE}</p>
                </Alert>
              </div>
            )}
            {showAdvancedSettingsAlert && (
              <Alert
                variant="danger"
                onClose={() => setShowAdvancedSettingsAlert(false)}
                dismissible
              >
                <Alert.Heading>Advanced Settings</Alert.Heading>
                <p>{ADVANCED_ERROR_MESSAGE}</p>
              </Alert>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseCopilotGenerator;
