import React, { useState } from 'react';
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  FormControl,
  FormGroup,
} from 'react-bootstrap';
import {
  ENTER_FEEDBACK_PLACEHOLDER,
  FEEDBACK_ERROR,
  FEEDBACK_THANK_YOU_MSG,
  SUBMIT,
  THUMBS_DOWN_ICON,
  THUMBS_UP_ICON,
} from '../../utils/constants';
import './Feedback.css';
import axios from 'axios';

type CustomFeedbackProps = {
  url: string;
  activityId: string;
};

const CustomFeedback: React.FC<CustomFeedbackProps> = ({ url, activityId }) => {
  const [feedback, setFeedback] = useState<string | null>(null);
  const [showFeedback, setShowFeedback] = useState<boolean>(true);
  const [feedbackText, setFeedbackText] = useState<string>('');
  const [activeButton, setActiveButton] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [feedbackPayload, setFeedbackPayload] = useState<Object>({
    'was it helpful': '',
    message: '',
  });

  const feedbackErrorHandle = () => {
    setFeedback('');
    setShowFeedback(false);
    setFeedbackText(FEEDBACK_ERROR);
  };

  const handleFeedback = async () => {
    const payload = {
      activityId: activityId,
      feedback: feedbackPayload,
    };
    if (activityId) {
      try {
        const response = await axios.post(url, payload, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 200) {
          setFeedback('Yes');
          setShowFeedback(false);
          setFeedbackText(FEEDBACK_THANK_YOU_MSG);
        } else {
          console.error('Post request failed');
          feedbackErrorHandle();
        }
      } catch (error) {
        console.error('Error:', error);
        feedbackErrorHandle();
      }
    } else {
      feedbackErrorHandle();
    }
  };

  const handleFeedbackChange = (e: any, value: string) => {
    if (e?.target?.type === 'button') {
      setActiveButton(value);
      setFeedbackPayload({ ...feedbackPayload, 'was it helpful': value });
      setIsDisabled(value === 'no');
    } else {
      setFeedbackPayload({ ...feedbackPayload, message: value });
      setIsDisabled(value.trim() === '' && activeButton === 'no');
    }
  };

  return (
    <div className="feedback-container">
      <p className="feedback-text">{feedbackText}</p>
      {showFeedback && (
        <div className="main">
          <div className="mb-3 text-center">
            <FormGroup>
              <Col sm={12}>
                <Form.Label htmlFor="feedbackMessage">
                  Was this helpful?
                </Form.Label>
                <ButtonGroup className="btn-container mb-2">
                  <Button
                    className={activeButton === 'yes' ? 'active' : ''}
                    variant={feedback === 'up' ? 'success' : 'outline-success'}
                    onClick={(e) => handleFeedbackChange(e, 'yes')}
                  >
                    {THUMBS_UP_ICON}
                  </Button>
                  <Button
                    className={activeButton === 'no' ? 'active' : ''}
                    variant={feedback === 'down' ? 'danger' : 'outline-danger'}
                    onClick={(e) => handleFeedbackChange(e, 'no')}
                  >
                    {THUMBS_DOWN_ICON}
                  </Button>
                </ButtonGroup>
                {activeButton && (
                  <FormControl
                    as="textarea"
                    id="feedbackMessage"
                    className="feedback-message"
                    rows={3}
                    placeholder={ENTER_FEEDBACK_PLACEHOLDER}
                    onChange={(e) => handleFeedbackChange(e, e.target.value)}
                  />
                )}
              </Col>
            </FormGroup>
          </div>
          {activeButton && (
            <div className="user-story-generator-submit-container">
              <Button
                className="user-story-generator-submit "
                as="input"
                type="submit"
                value={SUBMIT}
                onClick={() => handleFeedback()}
                disabled={isDisabled}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomFeedback;
