import React, { useState, useEffect } from 'react';
import './Login.css';
import Settings from './settings';
import noUIIText from '../../assets/icon-no-ui.svg';
import noUIIcon from '../../assets/noui-logo.svg';
import backgroundImage from '../../assets/waves-horizontal.svg';

interface LoginProps {
  onLogin: (username: string, password: string) => void;
}

interface configureButton {
  Label: string;
  Alignment: string;
  Endpoint: string;
  Method: string;
  Message: string;
}

interface configureAction {
  EngineName: string;
  Endpoint: string;
  Method: string;
}

interface configureConnectToOrg {
  endpointURL: string;
  userName: string;
  password: string;
  grantType: string;
  clientId: string;
  clientSecret: string;
}

const Login: React.FC<LoginProps> = ({ onLogin }) => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [copilotInstanceId, SetcopilotInstanceId] = useState('');
  const [suggestionStatus, setSuggestionsStatus] = useState(false);
  const [configursButtonData, setConfigursButtonData] = useState<
    configureButton[]
  >([]);
  const [configureAction, setConfigureAction] = useState<configureAction[]>([]);
  const [configureConnectToOrg, setConfigureConnectToOrg] = useState<
    configureConnectToOrg[]
  >([]);
  const [showLoginForm, setShowLoginForm] = useState<boolean>(true);
  const [generateLogs, setGenerateLogs] = useState(false);

  useEffect(() => {
    const storedUsername = sessionStorage.getItem('username');
    const storedPassword = sessionStorage.getItem('password');

    if (storedUsername && storedPassword) {
      setUsername(storedUsername);
      setPassword(storedPassword);
      setShowLoginForm(false);
    }
    const storedButtonConfig = sessionStorage.getItem('buttonConfig');
    if (storedButtonConfig) {
      setConfigursButtonData(JSON.parse(storedButtonConfig));
    }

    const storedActionConfig = sessionStorage.getItem('actionConfig');
    if (storedActionConfig) {
      setConfigureAction(JSON.parse(storedActionConfig));
    }

    const storedConnectToOrgConfig =
      sessionStorage.getItem('connectToOrgConfig');
    if (storedConnectToOrgConfig) {
      setConfigureConnectToOrg(JSON.parse(storedConnectToOrgConfig));
    }

    const storedInstanceId = sessionStorage.getItem('copilotInstanceId');
    if (storedInstanceId) {
      SetcopilotInstanceId(storedInstanceId);
    }
  }, []);

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleLogin = () => {
    onLogin(username, password);
    sessionStorage.setItem('username', username);
    sessionStorage.setItem('password', password);
  };

  const handleChangeCopilotInstanceId = (id: string) => {
    SetcopilotInstanceId(id);
    sessionStorage.setItem('copilotInstanceId', id);
  };

  const handleSuggestionchange = (status: boolean) => {
    setSuggestionsStatus(status);
  };

  const handleConfigureButton = (data: any[]) => {
    setConfigursButtonData(data);
    sessionStorage.setItem('buttonConfig', JSON.stringify(data));
  };
  const handleConfigureAction = (data: any[]) => {
    setConfigureAction(data);
    sessionStorage.setItem('actionConfig', JSON.stringify(configureAction));
  };

  const handleConfigureConnectToOrg = (data: any) => {
    setConfigureConnectToOrg(data);
    sessionStorage.setItem(
      'connectToOrgConfig',
      JSON.stringify(configureConnectToOrg)
    );
  };

  const handleSignIn = () => {
    setShowLoginForm(true);
  };
  const handleGenerateLogs = () => {
    setGenerateLogs(!generateLogs);
  };
  return (
    <>
      <div>
        <div className="header-container">
          <div
            className="header"
            style={{ backgroundImage: `url(${backgroundImage})` }}
          >
            <div className="logo-box">
              <img
                src={noUIIcon}
                alt="DEMOCRATIZE.AI Logo"
                className="logo-icon-noui"
              />
            </div>
            <div className="text-box">
              <img
                src={noUIIText}
                alt="DEMOCRATIZE.AI Text"
                className="logo-text-noui"
              />
            </div>
          </div>
          <Settings
            onInstanceIdChange={handleChangeCopilotInstanceId}
            handleSuggestionEnable={handleSuggestionchange}
            configurButton={handleConfigureButton}
            configurAction={handleConfigureAction}
            configurConnectToOrgData={handleConfigureConnectToOrg}
            className="gear-icon-login"
            generateLogs={generateLogs}
            onGenerateLogs={handleGenerateLogs}
          />
        </div>
      </div>
      <div className="login-container">
        {!showLoginForm ? (
          <>
            <div>
              <button className="user-login" onClick={handleLogin}>
                Login with {username}
              </button>
              <p className="user-login-p">
                <span className="orText">OR</span>
                <a href="#" onClick={handleSignIn}>
                  Sign in with new username
                </a>
              </p>
              {/*<button className="user-signin" onClick={handleSignIn}>Sign in with new username</button>*/}
            </div>
          </>
        ) : (
          <form
            className="login-form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div>
              <input
                placeholder="Enter your username"
                type="text"
                id="username"
                value={username}
                onChange={handleUsernameChange}
                required
              />
            </div>
            <div>
              <input
                placeholder="Enter your password"
                type="password"
                id="password"
                value={password}
                onChange={handlePasswordChange}
                required
              />
            </div>
            <button onClick={handleLogin} type="button">
              Login
            </button>
          </form>
        )}
      </div>
    </>
  );
};

export default Login;
