/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Appl } from '../../interfaces';
import { ApplicationDetails } from '../../types';
import { useNavigate } from 'react-router-dom';
import './Application.css';
import MeetingSummarizer from '../MeetingSummarizer';
import UserStoryGenerator from '../UserStoryGenerator';
import StatusReport from '../StatusReport';
import NoticeEngine from '../NoticeEngine';
import CallCenter from '../CallCenter';
import NoticeAnalysisEngine from '../NoticeAnalysisEngine';
import LlmFineTune from '../llmFineTune';
import CaseCopilotGenerator from '../CaseCopilotGenerator';
import DocumentOCR from '../DocumentOCR';
import ComputerVisionUseCase from '../ComputerVisionUseCase/index';
import CodeToDesign from '../CodeToDesign';
import MadeIn24Hours from '../ToolIn24Hours/ToolIn24HoursIndex';
import CodeQnA from '../CodeQnA';
import VisionVideoAnalytics from '../VideoAnalytics';
const Application = () => {
  const { projectId, appUrl } = useParams();
  const apps = JSON.parse(sessionStorage.getItem('apps') as string) as Appl[];
  const currentApp = apps.find((app) => app.url === appUrl);
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentApp) {
      navigate('/403');
    }
  }, []);

  const applicationList: Record<string, ApplicationDetails> = {
    'User Story Generator': {
      pageComponent: UserStoryGenerator,
    },
    'Policy Engine': {
      pageComponent: UserStoryGenerator,
    },
    'Sales Engine': {
      pageComponent: UserStoryGenerator,
    },
    'Meeting Summarizer': {
      pageComponent: MeetingSummarizer,
    },
    'Help Engine': {
      pageComponent: UserStoryGenerator,
    },
    'Status Report Generator': {
      pageComponent: StatusReport,
    },
    'Test Case Generator': {
      pageComponent: UserStoryGenerator,
    },
    'Scan to Summify Engine': {
      pageComponent: DocumentOCR,
    },
    'Help Desk Engine': {
      pageComponent: UserStoryGenerator,
    },
    'Notice Explanation Engine': {
      pageComponent: NoticeEngine,
    },
    'Call Center': {
      pageComponent: CallCenter,
    },
    'Document Insight': {
      pageComponent: DocumentOCR,
    },
    'Developer Help Engine': {
      pageComponent: UserStoryGenerator,
    },
    'Notice Analysis Engine': {
      pageComponent: NoticeAnalysisEngine,
    },
    'Change Communicator': {
      pageComponent: NoticeAnalysisEngine,
    },
    'LLM Fine-tune Dataset Generator': {
      pageComponent: LlmFineTune,
    },
    'Case Copilot Generator': {
      pageComponent: CaseCopilotGenerator,
    },
    'Vision QA engine': {
      pageComponent: ComputerVisionUseCase,
    },
    'Code To Design': {
      pageComponent: CodeToDesign,
    },
    'Made in 24 Hours':{
      pageComponent: MadeIn24Hours,
    },
    'Code Search': {
      pageComponent: CodeQnA,
    },
    'Code QnA': {
      pageComponent: CodeQnA,
    },
    'Vision Video Analytics':{
      pageComponent: VisionVideoAnalytics,
    },
  };

  const getApplicationJsx = (type: string) => {
    const component = applicationList[type]?.pageComponent;
    if (component) {
      return React.createElement(component, {
        currentApp: currentApp,
        projectId: projectId,
      });
    }
  };
  return (
    <div className="application-page-container">
      {currentApp?.type && getApplicationJsx(currentApp?.type)}
    </div>
  );
};

export default Application;
