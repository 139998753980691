import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import {
  Form,
  Alert,
  InputGroup,
  Button,
  Modal,
  Tab,
  Tabs,
  Badge,
  Spinner,
  Dropdown,
  Row,
  Card,
  Col,
  Container,
} from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import { ReactComponent as BackIcon } from '../../assets/arrows/back-arrow.svg';
import { Appl } from '../../interfaces';
import './LlmFineTune.css';
import UserContext from '../../context/UserContext';
import { FiSettings } from 'react-icons/fi';
import ContentLoader from '../../components/Loader/contentLoader';
import { IoMdRefresh } from 'react-icons/io';
import {
  REFRESHING,
  CREATE_DATASET,
  UPLOAD_DATASET,
  generateTextVariations,
  dalleimage,
  generateqa,
  createsimilarquestionqa,
} from '../../utils/constants';

const LlmFineTune = () => {
  const { projectId, appUrl } = useParams();
  const apps = JSON.parse(sessionStorage.getItem('apps') as string) as Appl[];
  const currentApp = apps.find((app) => app.url === appUrl);
  const [selectedFile, setSelectedFile] = useState<File[] | null>(null);
  const [selectedExcelFile, setSelectedExcelFile] = useState<File[] | null>(
    null
  );
  const [responseData, setResponseData] = useState<any>({});
  const [showErrMsg, setShowErrMsg] = useState<string>('');
  const [llmModel, setLlmModel] = useState<string>('OpenAI');
  const [showSystemPromptModal, setShowSystemPromptModal] = useState(false);
  const defaultSystemPrompt =
    'You are a helpful assistant who gives concise answers to questions about policy documents';
  const [systemPrompt, setSystemPrompt] = useState(defaultSystemPrompt);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isStep1Uploaded, setIsStep1Uploaded] = useState(false);
  const [isStep2Uploaded, setIsStep2Uploaded] = useState(false);
  const [selectActivity, setSelectActivity] = useState<string>('');
  const [showSatus, setShowStatus] = useState<boolean>(false);
  const [listActivity, setListActivity] = useState<any>([]);
  const [activityStatus, setActivityStatus] = useState<any>({});
  const [activeTab, setActiveTab] = useState('Upload');
  const [activeId, setActiveId] = useState<string>('');
  const [isJobActive, setIsJobActive] = useState<boolean>(false);
  const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false);
  const [isUploadLoadingExcel, setIsUploadLoadingExcel] =
    useState<boolean>(false);
  const [isUploadLoaded, setIsUploadLoaded] = useState<boolean>(false);
  const [isUploadLoadedExcel, setIsUploadLoadedExcel] =
    useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [promptType, setPromptType] = useState<string>('descriptive');
  const [multipleSelect, setMultipleSelect] = useState(false);
  const [selectedOption, setSelectedOption] = useState('chatbox');
  const [imageResponseData, setImageResponseData] = useState<any>({});
  const [textResponseData, setTextResponseData] = useState<any>({});

  const userContext = useContext(UserContext) || {
    showToast: false,
    setShowToast: () => {},
    userInput: '',
    setUserInput: () => {},
    setIsActivityActive: () => {},
    isActivityActive: false,
    setIsRefreshing: () => {},
    isRefreshing: false,
  };
  const {
    showToast,
    setShowToast,
    userInput,
    setUserInput,
    setIsActivityActive,
    isActivityActive,
    setIsRefreshing,
    isRefreshing,
  } = userContext;

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files?.length) {
      setSelectedFile(Array.from(files));
      setSelectedExcelFile(null);
      setIsStep1Uploaded(true);
      setIsStep2Uploaded(false);
      setIsUploadLoaded(false);
      setIsUploadLoadedExcel(false);
      setIsDisabled(false);
    }
  };

  const submitPrompt = async () => {
    setIsLoading(true);
    if (isStep1Uploaded) {
      uploadFile(selectedFile);
    } else if (isStep2Uploaded) {
      uploadExcelFile(selectedExcelFile);
    }
  };

  const uploadFile = async (selectedFile: any) => {
    setShowToast(false);
    setIsUploadLoading(true);
    setIsUploadLoaded(false);
    const formData = new FormData();
    selectedFile.forEach((file: any) => {
      formData.append('files', file);
      formData.append('systemprompt', systemPrompt);
    });
    try {
      const queryParams = {
        llmmodel: llmModel,
        prompttype: promptType,
      };
      const stepOneApi =
        '/api/v1/ui/dataset-generator/async-upload-files-generate-qa';
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}${stepOneApi}`,
        formData,
        {
          params: queryParams,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        const responseData = response.data[0];
        setIsLoading(false);
        setIsDisabled(true);
        setIsUploadLoading(false);
        setIsUploadLoaded(true);
        fetchActivity();
      } else {
        console.error('Failed to upload files');
        setShowToast(true);
        setIsLoading(false);
        setIsUploadLoading(false);
        setIsUploadLoaded(false);
      }
    } catch (error: any) {
      setIsUploadLoading(false);
      setIsUploadLoaded(false);
      console.error('Error:', error);
      const err = error?.response?.data;
      if (err) {
        setShowErrMsg(err);
      }
      setShowToast(true);
      setIsLoading(false);
    }
  };

  const handleExcelFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files?.length) {
      setSelectedExcelFile(Array.from(files));
      setSelectedFile(null);
      setIsStep1Uploaded(false);
      setIsStep2Uploaded(true);
      setIsUploadLoaded(false);
      setIsUploadLoadedExcel(false);
      setIsDisabled(false);
    }
  };

  const uploadExcelFile = async (selectedFile: any) => {
    setShowToast(false);
    setIsUploadLoadingExcel(true);
    setIsUploadLoadedExcel(false);
    const formData = new FormData();
    selectedFile.forEach((file: any) => {
      formData.append('systemprompt', systemPrompt);
      formData.append('question_columnname', 'Question');
      formData.append('answer_columnname', 'Answer');
      formData.append('files', file);
    });
    try {
      const queryParams = {
        llmmodel: llmModel,
      };
      const stepTwoApi =
        '/api/v1/ui/dataset-generator/async/upload-files-and-create-similar-questions/';

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}${stepTwoApi}`,
        formData,
        {
          params: queryParams,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        setIsLoading(false);
        setIsUploadLoadingExcel(false);
        setIsUploadLoadedExcel(true);
        setIsDisabled(true);
        const responseData = response.data[0];
        fetchActivity();
      } else {
        console.error('Failed to upload files');
        setShowToast(true);
        setIsLoading(false);
        setIsUploadLoadingExcel(false);
        setIsUploadLoadedExcel(false);
      }
    } catch (error: any) {
      console.error('Error:', error);
      setIsUploadLoadingExcel(false);
      setIsUploadLoadedExcel(false);
      const err = error?.response?.data;
      if (err && err.error) {
        setShowErrMsg(err.error);
      }
      setShowToast(true);
      setIsLoading(false);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files?.length) {
      setSelectedFile(Array.from(files));
      setSelectedExcelFile(null);
      setIsStep1Uploaded(true);
      setIsStep2Uploaded(false);
      setIsUploadLoaded(false);
      setIsUploadLoadedExcel(false);
      setIsDisabled(false);
    }
  };

  const handleExcelDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files?.length) {
      setSelectedExcelFile(Array.from(files));
      setSelectedFile(null);
      setIsStep1Uploaded(false);
      setIsStep2Uploaded(true);
      setIsUploadLoaded(false);
      setIsUploadLoadedExcel(false);
      setIsDisabled(false);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDropdownChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setLlmModel(event.target.value);
  };

  const handlePromptTypeDropdownChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setPromptType(event.target.value);
  };

  const openSystemPromptModal = (event: React.MouseEvent) => {
    event.preventDefault();
    setShowSystemPromptModal(true);
    setShowToast(false);
  };

  useEffect(() => {
    const storedSystemPrompt = localStorage.getItem('systemPrompt');
    if (storedSystemPrompt) {
      setSystemPrompt(storedSystemPrompt);
    } else {
      localStorage.setItem('systemPrompt', defaultSystemPrompt);
    }
  }, []);

  const handleSystemPromptChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setSystemPrompt(event.target.value);
  };
  const resetSystemPrompt = () => {
    localStorage.removeItem('systemPrompt');
    setSystemPrompt(defaultSystemPrompt);
  };
  const submitSystemPrompt = () => {
    localStorage.setItem('systemPrompt', systemPrompt);
    setShowSystemPromptModal(false);
  };

  const handleCheckActivity = (value: string) => {
    value.slice(0, 3);
    setResponseData({});
    setImageResponseData({});
    setSelectActivity(value);
    setShowStatus(false);
    getActivityStatus(value);
  };
  const handleUserPromptChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setUserInput(event.target.value);
  };
  const [selectedCard, setSelectedCard] = useState(0);
  const [textListActivity, setTextListActivity] = useState<any>([]);
  const [imageListActivity, setImageListActivity] = useState<any>([]);

  const fetchActivity = async () => {
    const params: Record<string, string | number> = {
      type: 'LLM Fine-tune Dataset Generator',
    };
    const queryparam = new URLSearchParams(
      params as Record<string, string>
    ).toString();
    const fetchresponse = await axios.get(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/activity/all?type=LLM%20Fine-tune%20Dataset%20Generator`
    );

    const data = await fetchresponse.data.response.edges;
    if (selectedCard === 2) {
      const FilteredArray = data.filter(
        (x: any) =>
          x.node.metaData[0].app_type === 'Generate QA' ||
          x.node.metaData[0].app_type === 'Create Similar Questions QA'
      );
      const res = FilteredArray?.slice(-1);
      setSelectActivity(res[0]?.id);
      setListActivity(FilteredArray);
      getActivityStatus(res[0].node.id);
    }
    if (selectedCard === 0) {
      const FilteredArray = data.filter(
        (x: any) => x.node.metaData[0].app_type === 'Generate Text Variations'
      );
      const res = FilteredArray?.slice(-1);
      setSelectActivity(res[0]?.id);
      getActivityStatus(res[0].node.id);
      setTextListActivity(FilteredArray);
    }
    if (selectedCard === 1) {
      const FilteredArray = data.filter(
        (x: any) => x.node.metaData[0].app_type === 'DALL-E Image Generate'
      );
      const res = FilteredArray?.slice(-1);
      setSelectActivity(res[0]?.id);
      getActivityStatus(res[0].node.id);
      setImageListActivity(FilteredArray);
    }
    const res = data?.slice(-1);
  };
  useEffect(() => {
    fetchActivity();
  }, [selectedCard]);

  const handleCardClick = (index: any) => {
    setUserInput('');
    setActiveTab('Upload');
    setMultipleSelect(false);
    setSelectedCard(index);
    setSelectedOption('chatbox');
  };

  useEffect(() => {
    if (selectedCard === 0 && selectActivity && textListActivity.length > 0) {
      const selectedJobId = textListActivity?.find(
        (data: any) => data?.id === selectActivity
      );
      if (selectedJobId) {
        getActivityStatus(selectedJobId.id);
      }
    }
    if (selectedCard === 1 && selectActivity && imageListActivity.length > 0) {
      const selectedJobId = imageListActivity?.find(
        (data: any) => data?.id === selectActivity
      );
      if (selectedJobId) {
        getActivityStatus(selectedJobId.id);
      }
    }
    if (selectedCard === 2 && selectActivity && listActivity.length > 0) {
      const selectedJobId = listActivity?.find(
        (data: any) => data?.id === selectActivity
      );
      if (selectedJobId) {
        getActivityStatus(selectedJobId.id);
      }
    }
  }, [
    selectActivity,
    listActivity,
    textListActivity,
    isJobActive,
    selectedCard,
    activeTab,
  ]);
  const getActivityStatus = async (jobId: any) => {
    setActiveId(jobId);
    try {
      setIsRefreshing(true);
      const formData: { [key: string]: any } = {};
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/dataset-generator/status/task-by-activityid/?activity_id=${jobId}`
      );
      if (response.status === 200 || response.status === 201) {
        setShowToast(false);
        setIsRefreshing(false);
        const newStatus = { ...activityStatus };
        newStatus[jobId] = response.data.status;
        setActivityStatus(newStatus);
        setShowStatus(true);
        if (selectedCard === 2 || selectedCard === 0) {
          setResponseData(response.data.download_link);
        }

        if (selectedCard === 1) {
          setImageResponseData(response.data);
        }
      } else {
        console.error(
          'Error fetching job status:',
          response.data.status_message
        );
      }
    } catch (error) {
      console.error('response after check activity', error);
      setShowStatus(false);
      setIsRefreshing(false);
    }
  };
  interface ActivityStatusMap {
    [key: string]: string;
  }
  const [statusCheck, setStatusCheck] = useState('');

  //Creating Jobid for Image And Text

  const submitPrompt2 = async () => {
    setIsLoading(true);
    setUserInput('');
    const params: Record<string, string | number> = {
      prompt: userInput,
    };

    params.number_of_images = Number(multipleSelect ? 5 : 1);

    params.image_style = String('vivid');

    const queryparam = new URLSearchParams(
      params as Record<string, string>
    ).toString();

    const Textparams: Record<string, string | number> = {
      prompt: userInput,
    };

    Textparams.number_of_variations = Number(multipleSelect ? 5 : 1);
    const Textqueryparam = new URLSearchParams(
      Textparams as Record<string, string>
    ).toString();

    try {
      const ImageApi = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/dataset-generator/async/generate-images?${queryparam}`;
      const TextApi = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/dataset-generator/async/generate-text-variations?${Textqueryparam}`;
      const Api = selectedCard === 0 ? TextApi : ImageApi;
      const response = await axios.post(
        Api,

        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        const jobId = response.data.job_id;
        setIsLoading(false);
        const jobIdparams: Record<string, string | number> = {
          job_id: Number(jobId),
        };
        const Jobidqueryparam = new URLSearchParams(
          jobIdparams as Record<string, string>
        ).toString();
      } else {
        setShowToast(true);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setShowToast(true);
      console.error(error);
    }
  };
  const activityIdCheck = async (retry: number, activityId: string) => {
    const params: Record<string, string | number> = {
      activity_id: activityId,
    };

    const queryparam = new URLSearchParams(
      params as Record<string, string>
    ).toString();
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/dataset-generator/status/task-by-activityid/?${queryparam}`
    );
    if (response.data.status === 'Complete') {
      setIsLoading(false);
      fetchActivity();
      setMultipleSelect(false);
      setSelectedOption('chatbox');
    } else {
      if (
        retry < 20 &&
        response.data.ready !== true &&
        response.data.successful !== true
      ) {
        activityIdCheck(retry + 1, activityId);
        // setTimeout(() => {
        //   fileUploadCheck(retry + 1, taskid)
        // }, 1000);
      }
    }
  };

  const renderRefreshBlock = React.useMemo(() => {
    // Determine content based on status and refresh state
    if (
      activityStatus[activeId] === 'running' ||
      activityStatus[activeId] === 'Pending'
    ) {
      return isRefreshing ? (
        <div>{REFRESHING}</div>
      ) : (
        <IoMdRefresh
          onClick={() => {
            setIsJobActive(!isJobActive);
            getActivityStatus(activeId);
          }}
        />
      );
    }
  }, [activityStatus[activeId], isRefreshing, isJobActive]);

  const makeActivityStaus = () => {
    const activityStatusMap: ActivityStatusMap = {
      Pending: 'warning',
      Complete: 'success',
      failed: 'danger',
      running: 'warning',
    };
    return activityStatusMap[activityStatus[activeId]];
  };

  return (
    <>
      <div className="title">{currentApp?.name}</div>
      <div className="back-button-container">
        <Link to={`/project/${projectId}`}>
          <BackIcon /> Back
        </Link>
        {selectedCard == 2 && (
          <Button
            className="user-story-generator-submit primary-btn-style"
            type="submit"
            onClick={openSystemPromptModal}
          >
            <FiSettings />
          </Button>
        )}
        {showSystemPromptModal ? (
          <Modal
            show={showSystemPromptModal}
            onHide={() => {
              setShowSystemPromptModal(false);
            }}
          >
            <Modal.Header closeButton>System Prompt</Modal.Header>
            <Modal.Body>
              <Form.Control
                as="textarea"
                rows={3}
                value={systemPrompt}
                onChange={handleSystemPromptChange}
              />
              <div className="button-container">
                <Button
                  variant="secondary"
                  onClick={resetSystemPrompt}
                  style={{ backgroundColor: 'green', borderColor: 'green' }}
                >
                  Reset
                </Button>
                <Button variant="primary" onClick={submitSystemPrompt}>
                  Submit
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        ) : null}
      </div>
      <div className="single-app-container">
        <Container fluid>
          <Row className="justify-content-center cardsoptions">
            <Col className="summary-cards" sm={4}>
              <Card
                bg={selectedCard === 0 ? 'primary' : 'light'}
                text={selectedCard === 0 ? 'white' : 'dark'}
                onClick={() => {handleCardClick(0);setActiveTab('Upload');}}
                className="text-center"
              >
                <Card.Body>
                  <Card.Title>Text DataSet Generator</Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col className="summary-cards" sm={4}>
              <Card
                bg={selectedCard === 1 ? 'primary' : 'light'}
                text={selectedCard === 1 ? 'white' : 'dark'}
                onClick={() => {handleCardClick(1);setActiveTab('Upload');}}
                className="text-center"
              >
                <Card.Body>
                  <Card.Title>Image Dataset Generator</Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col className="summary-cards" sm={4}>
              <Card
                bg={selectedCard === 2 ? 'primary' : 'light'}
                text={selectedCard === 2 ? 'white' : 'dark'}
                onClick={() => {handleCardClick(2);setActiveTab('Upload');}}
                className="text-center"
              >
                <Card.Body>
                  <Card.Title>Finetune DataSet Generator</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        {selectedCard === 0 && (
          <>
            <Tabs
              defaultActiveKey="Upload"
              id="justify-tab-example"
              className="mb-3"
              justify
              onSelect={(key: string | null) => {
                if (key !== null) {
                  setActiveTab(key);
                  fetchActivity();
                }
              }}
            >
              <Tab eventKey="Upload" title="Query">
                <div className="option-labels">
                  <span
                    className={
                      selectedOption === 'fileUpload' ? 'disable-text' : ''
                    }
                  >
                    Single Text
                  </span>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    checked={selectedOption === 'fileUpload'}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setSelectedOption(
                        event.target.checked ? 'fileUpload' : 'chatbox'
                      );
                      if (selectedOption === 'fileUpload') {
                        setMultipleSelect(false);
                      }
                      if (selectedOption === 'chatbox') {
                        setMultipleSelect(true);
                      }
                      setShowToast(false);
                    }}
                  />
                  <span
                    className={
                      selectedOption === 'fileUpload' ? '' : 'disable-text'
                    }
                  >
                    Multiple Text
                  </span>
                </div>
                <Form.Group>
                  <Form.Label>Enter prompt</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Enter your prompt here."
                    rows={3}
                    value={userInput}
                    onChange={handleUserPromptChange}
                  />
                </Form.Group>
                {selectedOption === 'fileUpload' && (
                  <div className="upload-warning">
                    *Note: Only set of 5 text are supported in a batch for
                    processing.
                  </div>
                )}
              </Tab>
              <Tab eventKey="collections" title="Activity">
                <div>
                  <Form.Group
                    className="user-input"
                    controlId="exampleForm.instancePicker"
                  >
                    <Form.Label>Activity List</Form.Label>
                    <Form.Select
                      aria-label="Activity status"
                      className="instance-picker"
                      value={selectActivity}
                      onChange={(evt) => {
                        const selectedValue = evt.target.value;
                        handleCheckActivity(selectedValue);
                      }}
                    >
                      {textListActivity &&
                        textListActivity
                          ?.slice()
                          .reverse()
                          .filter(
                            (data: any) =>
                              data !== null &&
                              data?.node?.metaData?.[0]?.app_type ===
                               generateTextVariations
                          )
                          ?.map((data: any, index: any) => {
                            return (
                              <option key={index} value={data?.node?.id}>
                                {data?.node?.id} -{' '}
                                {data?.node?.metaData?.[0]?.app_type} -{' '}
                                {data?.node?.inputText}
                              </option>
                            );
                          })}
                    </Form.Select>
                    {showSatus && (
                      <Form.Label
                        style={{
                          marginTop: '1rem',
                          textTransform: 'capitalize',
                        }}
                      >
                        Status :{' '}
                        <Badge
                          className={
                            activityStatus[activeId] === 'Pending' ||
                            activityStatus[activeId] === 'running'
                              ? 'text-dark'
                              : ''
                          }
                          bg={makeActivityStaus()}
                        >
                          {activityStatus[activeId]}
                        </Badge>
                        {renderRefreshBlock}
                      </Form.Label>
                    )}
                  </Form.Group>
                </div>
              </Tab>
            </Tabs>
            {activeTab === 'collections' && responseData?.length > 0 && (
              <>
                <div
                  className="download-container"
                  style={{ justifyContent: 'center' }}
                >
                  <a href={responseData[0]?.txt?.sas_url}>
                    <Button variant="success">Download</Button>
                  </a>
                </div>
              </>
            )}
          </>
        )}{' '}
        {selectedCard === 1 && (
          <>
            <Tabs
              defaultActiveKey="Upload"
              id="justify-tab-example"
              className="mb-3"
              justify
              // activeKey={activeTab}
              onSelect={(key: string | null) => {
                if (key !== null) {
                  setActiveTab(key);
                  fetchActivity();
                  //   setIsActivityActive(!isActivityActive);
                }
              }}
            >
              <Tab eventKey="Upload" title="Query">
                <div className="option-labels">
                  <span
                    className={
                      selectedOption === 'fileUpload' ? 'disable-text' : ''
                    }
                  >
                    Single Image
                  </span>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    checked={selectedOption === 'fileUpload'}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setSelectedOption(
                        event.target.checked ? 'fileUpload' : 'chatbox'
                      );
                      if (selectedOption === 'fileUpload') {
                        setMultipleSelect(false);
                      }
                      if (selectedOption === 'chatbox') {
                        setMultipleSelect(true);
                      }
                      setShowToast(false);
                      // setShowFeedBackOptions(false);
                    }}
                  />
                  <span
                    className={
                      selectedOption === 'fileUpload' ? '' : 'disable-text'
                    }
                  >
                    Multiple Image
                  </span>
                </div>
                <Form.Group className="user-input">
                  <Form.Label>Enter prompt</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Enter your prompt here."
                    rows={3}
                    value={userInput}
                    onChange={handleUserPromptChange}
                  />
                  {selectedOption === 'fileUpload' && (
                    <div className="upload-warning-image">
                      *Note: Only set of 5 images are supported in a batch for
                      processing.
                    </div>
                  )}
                </Form.Group>
              </Tab>
              <Tab eventKey="collections" title="Activity">
                <div>
                  <Form.Group
                    className="user-input"
                    controlId="exampleForm.instancePicker"
                  >
                    <Form.Label>Activity List</Form.Label>
                    <Form.Select
                      aria-label="Activity status"
                      className="instance-picker"
                      value={selectActivity}
                      onChange={(evt) => {
                        const selectedValue = evt.target.value;
                        handleCheckActivity(selectedValue);
                      }}
                    >
                      {imageListActivity &&
                        imageListActivity
                        ?.slice()
                        .reverse()
                        .filter(
                          (data: any) =>
                            data !== null &&
                            data?.node?.metaData?.[0]?.app_type ===
                             dalleimage
                        )
                          ?.map((data: any, index: any) => {
                            return (
                              <option key={index} value={data?.node?.id}>
                                {data?.node?.id} -{' '}
                                {data?.node?.metaData?.[0]?.app_type} -{' '}
                                {data?.node?.inputText}
                              </option>
                            );
                          })}
                    </Form.Select>
                    {showSatus && (
                      <Form.Label
                        style={{
                          marginTop: '1rem',
                          textTransform: 'capitalize',
                        }}
                      >
                        Status :{' '}
                        <Badge
                          className={
                            activityStatus[activeId] === 'Pending' ||
                            activityStatus[activeId] === 'running'
                              ? 'text-dark'
                              : ''
                          }
                          bg={makeActivityStaus()}
                        >
                          {activityStatus[activeId]}
                        </Badge>
                        {renderRefreshBlock}
                      </Form.Label>
                    )}
                  </Form.Group>
                </div>
                {imageResponseData.status_message &&
                  imageResponseData.status_message.includes(
                    'Error code: 400'
                  ) &&
                  imageResponseData.status_message.includes(
                    "'code': 'content_policy_violation'"
                  ) && (
                    <div className="upload-error">
                      Content Policy Violation:Your request was rejected as a
                      result of our safety system. Your prompt may contain text
                      that is not allowed by our safety system.
                    </div>
                  )}
                {imageResponseData.status_message &&
                  imageResponseData.status_message.includes(
                    'Error code: 429'
                  ) && (
                    <div className="upload-error">
                      Rate Limit Exceeded:Requests to the
                      ImageGenerations_Create Operation have exceeded call rate
                      limit. Please retry after 10 seconds.
                    </div>
                  )}
              </Tab>
            </Tabs>

            {activeTab === 'collections' &&
              imageResponseData.download_link?.length > 0 && (
                <>
                  <div
                    className="download-container"
                    style={{ justifyContent: 'center' }}
                  >
                    {typeof imageResponseData.download_link === 'string' && (
                      <a href={imageResponseData.download_link}>
                        <Button variant="success">Download</Button>
                      </a>
                    )}
                  </div>
                </>
              )}
          </>
        )}{' '}
        {selectedCard === 2 && (
          <>
            <Form.Group
              className="user-input"
              controlId="exampleForm.llmPicker"
            >
              <Form.Label>Choose Output Format for LLM Model</Form.Label>
              <Form.Select
                aria-label="Choose LLM Model"
                className="instance-picker"
                value={llmModel}
                onChange={handleDropdownChange}
              >
                <option value="OpenAI">Open AI- GPT</option>
                <option value="Llama">Llama-2-7B- Chat</option>
                <option value="Gemma">Gemma-7B- IT</option>
                <option value="Mistral">Mistral-7B- Instruct</option>
              </Form.Select>
            </Form.Group>
            <Form.Group
              className="user-input"
              controlId="exampleForm.promptType"
            >
              <Form.Label>Answers Type</Form.Label>
              <Form.Select
                aria-label="Prompt Type"
                className="instance-picker"
                value={promptType}
                onChange={handlePromptTypeDropdownChange}
              >
                <option value="short">Short</option>
                <option value="descriptive">Descriptive</option>
              </Form.Select>
            </Form.Group>
            <Tabs
              defaultActiveKey="Upload"
              id="justify-tab-example"
              className="mb-3"
              justify
              onSelect={(key: string | null) => {
                if (key !== null) {
                  setActiveTab(key);
                  setIsActivityActive(!isActivityActive);
                  fetchActivity();
                }
              }}
            >
              <Tab eventKey="Upload" title="Upload">
                <Form>
                  <div className="upload-container">
                    <Form.Group
                      controlId="fileUpload"
                      className="FormGrop-container"
                    >
                      <Form.Label className="file-upload-label-text">
                        {CREATE_DATASET}
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="file"
                          onChange={handleFileChange}
                          style={{ display: 'none' }}
                          accept=".pdf,.txt,.md"
                        />
                        <InputGroup.Text
                          onClick={() => {
                            const fileInput =
                              document.getElementById('fileUpload');
                            if (fileInput) {
                              (fileInput as HTMLInputElement).click();
                            }
                          }}
                          onDrop={handleDrop}
                          onDragOver={handleDragOver}
                          className="file-upload-container"
                        >
                          {selectedFile ? (
                            <div className="file-container">
                              <ul style={{ paddingLeft: 'unset' }}>
                                {selectedFile.map((file, index) => (
                                  <li className="file-list-item" key={index}>
                                    {file.name}
                                  </li>
                                ))}
                              </ul>
                              {isUploadLoading && (
                                <div className="upload-spinner">
                                  <Spinner animation="border" size="sm" />{' '}
                                  Uploading
                                </div>
                              )}
                              {isUploadLoaded && (
                                <div className="upload-msg">
                                  <Badge bg="success">Upload Successful</Badge>
                                </div>
                              )}
                            </div>
                          ) : (
                            <>
                              Drag a file to upload
                              <br />
                              Supported file types: PDF, MD, TXT (DOCX - Coming
                              Soon)
                            </>
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                      <Form.Label className="label-sub-text">Step 1</Form.Label>
                    </Form.Group>
                    <Form.Group
                      controlId="excelUpload"
                      className="FormGrop-container"
                    >
                      <Form.Label className="file-upload-label-text">
                        {UPLOAD_DATASET}
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="file"
                          onChange={handleExcelFileChange}
                          style={{ display: 'none' }}
                          accept=".csv,.xlsx"
                        />
                        <InputGroup.Text
                          onClick={() => {
                            const excelFileInput =
                              document.getElementById('excelUpload');
                            if (excelFileInput) {
                              (excelFileInput as HTMLInputElement).click();
                            }
                          }}
                          onDrop={handleExcelDrop}
                          onDragOver={handleDragOver}
                          className="file-upload-container"
                        >
                          {selectedExcelFile ? (
                            <div className="file-container">
                              <ul style={{ paddingLeft: 'unset' }}>
                                {selectedExcelFile.map((file, index) => (
                                  <li className="file-list-item" key={index}>
                                    {file.name}
                                  </li>
                                ))}
                              </ul>
                              {isUploadLoadingExcel && (
                                <div className="upload-spinner">
                                  <Spinner animation="border" size="sm" />{' '}
                                  Uploading
                                </div>
                              )}
                              {isUploadLoadedExcel && (
                                <div className="upload-msg">
                                  <Badge bg="success">Upload Successful</Badge>
                                </div>
                              )}
                            </div>
                          ) : (
                            <>
                              Drag a file to upload
                              <br />
                              Supported file types: CSV, XLSX
                            </>
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                      <Form.Label className="label-sub-text">Step 2</Form.Label>
                    </Form.Group>
                  </div>
                </Form>
                <div>
                  <Form.Text>
                    Note: PDF documents having a maximum of 20 pages and 2 MB
                    size are supported.
                  </Form.Text>
                </div>
                <div>
                  <Form.Text>
                    TXT and MD files having a maximum of 20 KB are supported.
                  </Form.Text>
                </div>
                <div
                  className="user-story-generator-submit-container"
                  style={{ marginTop: '30px' }}
                >
                  {isLoading ? (
                    <Button
                      className="user-story-generator-submit"
                      disabled={true}
                      style={{ width: '104px', height: '36px' }}
                    >
                      <ContentLoader />
                    </Button>
                  ) : (
                    <Button
                      className="user-story-generator-submit"
                      as="input"
                      type="submit"
                      value="Submit"
                      onClick={submitPrompt}
                      disabled={isDisabled}
                    />
                  )}
                </div>
              </Tab>
              <Tab eventKey="Activity" title="Activity">
                <div>
                  <Form.Group
                    className="user-input"
                    controlId="exampleForm.instancePicker"
                  >
                    <Form.Label>Activity List</Form.Label>
                    <Form.Select
                      aria-label="Activity status"
                      className="instance-picker"
                      value={selectActivity}
                      onChange={(evt) => {
                        const selectedValue = evt.target.value;
                        handleCheckActivity(selectedValue);
                      }}
                    >
                      {listActivity &&
                        listActivity
                          ?.slice()
                          .reverse()
                          .filter(
                            (data: any) =>
                              data !== null &&
                              (data?.node?.metaData[0].app_type ===
                                generateqa ||
                                data?.node?.metaData[0].app_type ===
                                  createsimilarquestionqa)
                          )
                          ?.map((data: any, index: any) => {
                            return (
                              <option key={index} value={data?.node?.id}>
                                {data?.node?.id} -{' '}
                                {data?.node?.metaData[0].app_type} -{' '}
                                {data?.node?.inputText}
                              </option>
                            );
                          })}
                    </Form.Select>
                    {showSatus && (
                      <Form.Label
                        style={{
                          marginTop: '1rem',
                          textTransform: 'capitalize',
                        }}
                      >
                        Status :{' '}
                        <Badge
                          className={
                            activityStatus[activeId] === 'pending' ||
                            activityStatus[activeId] === 'running'
                              ? 'text-dark'
                              : ''
                          }
                          bg={makeActivityStaus()}
                        >
                          {activityStatus[activeId]}
                        </Badge>
                        {renderRefreshBlock}
                      </Form.Label>
                    )}
                  </Form.Group>
                </div>
              </Tab>
            </Tabs>
            {activeTab === 'Activity' && responseData?.length > 0 && (
              <>
                <div
                  className="download-container"
                  style={{ justifyContent: 'center' }}
                >
                  {responseData?.map((result: any, index: any) => (
                    <Dropdown key={index}>
                      <Dropdown.Toggle
                        variant="outline-success download-button-tag"
                        id={`dropdown-basic-${index}`}
                      >
                        Download As
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {Object.keys(result).map((key: any) => (
                          <Dropdown.Item
                            key={key}
                            href={result[key].local_path || result[key].sas_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {key.toUpperCase()}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </div>
      {activeTab === 'Upload' && (selectedCard === 0 || selectedCard === 1) && (
        <div className="user-story-generator-submit-container">
          {isLoading ? (
            <Button
              className="user-story-generator-submit"
              disabled={true}
              style={{ width: '104px', height: '36px' }}
            >
              <ContentLoader />
            </Button>
          ) : (
            <div className="with-download-button">
              <Button
                className="user-story-generator-submit"
                as="input"
                type="submit"
                value="Submit"
                onClick={submitPrompt2}
                disabled={!userInput}
              />
            </div>
          )}
        </div>
      )}

      {showToast && (
        <div style={{ paddingTop: '80px', width: '50vw', margin: '0 auto' }}>
          <Alert
            variant="danger"
            onClose={() => setShowToast(false)}
            dismissible
          >
            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
            <p>
              {showErrMsg
                ? showErrMsg
                : `Something went wrong. Please try again`}
            </p>
          </Alert>
        </div>
      )}
    </>
  );
};

export default LlmFineTune;
