import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './CardDetailsSummary.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer } from 'react-toastify';

interface CardDetailsProps {
  onClickCardSummary: (task: string) => void;
  onClickApplicationCardSummary: (
    messageIndex: number,
    entityIndex: number,
    cardIndex: number
  ) => void;
  loadDefaultCard: boolean;
  cardJSON?: any[];
  index?: number;
}

const CardSummaryPage: React.FC<CardDetailsProps> = ({
  onClickCardSummary,
  onClickApplicationCardSummary,
  cardJSON,
  index,
  loadDefaultCard,
}) => {
  const message = '';

  const handleChatClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    const strongText = (event.currentTarget as HTMLAnchorElement).querySelector(
      'strong'
    )?.textContent;
    onClickCardSummary(strongText ? String(strongText) : '');
  };

  const formatDate = (date: Date) => {
    const day = date.getDate();
    const suffix =
      day === 1 || day === 21 || day === 31
        ? 'st'
        : day === 2 || day === 22
          ? 'nd'
          : day === 3 || day === 23
            ? 'rd'
            : 'th';
    const month = date.toLocaleDateString('en-US', { month: 'long' });
    const year = date.getFullYear();
    return `${day}${suffix} ${month}, ${year}`;
  };

  const getTodayDate = () => {
    return new Date();
  };

  const getTomorrowDate = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  };

  const handleCardClick = (
    messageIndex: number,
    entityIndex: number,
    cardIndex: number
  ) => {
    onClickApplicationCardSummary(messageIndex, entityIndex, cardIndex);
  };

  return (
    <div>
      <ToastContainer />
      <div>{message}</div>
      <Row className="outer-container-card">
        {loadDefaultCard && (
          <Col md={12}>
            <div className="content-wrapper">
              <h5 className="header-main-content">
                You have 2 <strong>high</strong> priority tasks.
              </h5>
              <div className="appointment shine">
                <div className="header-div">
                  <div className="appointment-link">
                    <FontAwesomeIcon
                      icon={faWandMagicSparkles}
                      className="wand-icon"
                    />
                    <a
                      href="#"
                      onClick={handleChatClick}
                      style={{ color: 'black' }}
                    >
                      <strong>Apply for Medicaid</strong>
                    </a>
                  </div>
                  <div className="case-id">
                    <strong>Case Id:</strong>00451659
                  </div>
                </div>
                <div className="appointment-details">
                  <div className="due-date">
                    <strong>Due Date:{formatDate(getTodayDate())}</strong>
                  </div>
                  <div className="client-name">
                    <strong>Client Name:</strong> John Smith
                  </div>
                </div>
              </div>
              <div className="appointment2">
                <div className="header-div">
                  <div className="appointment-link">
                    <FontAwesomeIcon
                      icon={faWandMagicSparkles}
                      style={{ marginRight: '5px' }}
                    />
                    <a
                      href="#"
                      onClick={handleChatClick}
                      style={{ color: 'black' }}
                    >
                      <strong> Review Permit Application</strong>
                    </a>
                  </div>
                  <div className="case-id">
                    <strong>Case Id:</strong> 00451659
                  </div>
                </div>
                <div className="appointment-details">
                  <div className="due-date">
                    <strong>Due Date: {formatDate(getTodayDate())}</strong>
                  </div>
                  <div className="client-name">
                    <strong>Client Name:</strong> John Smith
                  </div>
                </div>
              </div>
              <h5 className="header-main-content">
                You have 1 medium priority task.
              </h5>
              <div className="appointment-priority">
                <div className="header-div">
                  <div className="appointment-link">
                    <FontAwesomeIcon
                      icon={faWandMagicSparkles}
                      style={{ marginRight: '5px' }}
                    />
                    <a
                      href="#"
                      onClick={handleChatClick}
                      style={{ color: 'black' }}
                    >
                      <strong>Schedule interview with client</strong>
                    </a>
                  </div>
                  <div className="case-id">
                    <strong>Case Id:</strong> 00451659
                  </div>
                </div>
                <div className="appointment-details">
                  <div className="due-date">
                    <strong>Due Date: {formatDate(getTomorrowDate())}</strong>
                  </div>
                  <div className="client-name">
                    <strong>Client Name:</strong> John Smith
                  </div>
                </div>
              </div>
              <h5 className="header-main-content">
                You have 1 low Priority task.
              </h5>
              <div className="appointment-priority2">
                <div className="item-wrapper">
                  <div className="content">
                    <FontAwesomeIcon
                      icon={faWandMagicSparkles}
                      style={{ marginRight: '5px' }}
                    />
                    <a href="#">
                      <strong>
                        Follow up on email from citizen regarding park
                        maintenance
                      </strong>
                    </a>
                    <div className="due-date">
                      <strong>Due Date: {formatDate(getTomorrowDate())}</strong>
                    </div>
                  </div>
                  <div className="case-id">
                    <strong>Case Id:</strong> 00451659
                  </div>
                </div>
                <div className="client-details">
                  <strong>Client Name:</strong> John Smith
                </div>
              </div>
            </div>
          </Col>
        )}

        {!loadDefaultCard && (
          <Col md={12}>
            <div className="content-wrapper">
              {cardJSON &&
                cardJSON.map((entity, entityIndex: any) => (
                  <div key={entityIndex} className="entity-card">
                    <h6>
                      {entity.definition.length} {entity.entity}
                      {entity.definition.length > 1 && 's'}
                    </h6>
                    {entity.definition &&
                      entity.definition.map((card: any, cardIndex: number) => (
                        <div
                          key={cardIndex}
                          className="card-records"
                          onClick={() =>
                            handleCardClick(index || 0, entityIndex, cardIndex)
                          }
                        >
                          <div className="card-item">
                            <strong>
                              {entity.entity} {cardIndex + 1}
                            </strong>
                          </div>
                        </div>
                      ))}
                  </div>
                ))}
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};
//Set default props for CardSummaryPage
CardSummaryPage.defaultProps = {
  cardJSON: [],
};

export default CardSummaryPage;
