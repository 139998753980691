import React, { useState, useEffect, useRef } from 'react';
import './CopilotNoUIV2.css';
import { TbBulbFilled } from 'react-icons/tb';
import { FaMicrophoneAlt } from 'react-icons/fa';
import { FaRecordVinyl } from 'react-icons/fa6';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import CardDetailsPage from './CardDetails';
import './CardDetailsSummary.css';
import CardSummaryPage from './CardSummary';
import { Form, InputGroup, FormControl } from 'react-bootstrap';
import DOMPurify from 'dompurify';
import { messages } from '../../interfaces';
import DynamicTabsAndForms from './Accordian';
import { IoIosSend } from 'react-icons/io';
import processingIcon from '../../assets/loader-noui.svg';
import { PiFileArrowDownDuotone } from 'react-icons/pi';
declare var webkitSpeechRecognition: any;
declare var SpeechRecognition: any;
interface ChatProps {
  copilotInstanceId: string;
  // sendDataToParent: (data: any) => void;
  suggestionStatus: boolean;
  configursButtonData: configureButton[];
  configurAction: configureAction[];
  configurConnectToOrgData: configureConnectToOrg;
  generateLogs: boolean;
}
interface GroupedData {
  [key: string]: any[];
}

interface configureButton {
  Label: string;
  Alignment: string;
  Endpoint: string;
  Method: string;
  Message: string;
}

interface configureAction {
  EngineName: string;
  Endpoint: string;
  Method: string;
}

interface configureConnectToOrg {
  endpointURL: string;
  userName: string;
  password: string;
  grantType: string;
  clientId: string;
  clientSecret: string;
}

var finalMessage: any[] = [];
// var generateMessages: any[] = [];
var logData: any[] = [];
var messageIndex: number = 0;
var latestAccordianIndex = 0;
var timer_2 = true;
var timer_3 = true;
var timer_4 = true;
let cardJSON = [{ entity: '', definition: [] }];
const ChatComponent: React.FC<ChatProps> = ({
  copilotInstanceId,
  /*sendDataToParent*/ suggestionStatus,
  configursButtonData,
  configurAction,
  configurConnectToOrgData,
  generateLogs,
}) => {
  const [userInput, setUserInput] = useState('');
  const [messages, setMessages] = useState<messages[]>([]);
  const [isInputFlag, setIsInputFlag] = useState(false);
  // const [copilotInstanceId, setCopilotInstanceId] = useState('');
  const [suggestions, setSuggestions] = useState('');
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);
  const [isSuggestiveResponseEnable, setIsSuggestiveResponseEnable] =
    useState(true);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  // const [modalstatus, setModalstatus] = useState(false);
  // const [lastuserInput, setlastuserInput] = useState('');
  // const [jsonData1, setJSONData] = useState<any>();
  const [showSendButton, setShowSendButton] = useState(true);
  const [showLogs, setShowLogs] = useState(false);
  const [suggestionInputs, setSuggestionInputs] = useState<string[]>([]);
  const [isSpeechRecognitionActive, setIsSpeechRecognitionActive] =
    useState(false);
  const [enableTextareaFocus, setEnableTextareaFocus] = useState(false);
  var msg1 = 'Hey, Trividha!!';
  var msg2 = 'Looking at what you should focus on today..';
  var msg3 = 'Here are the things which requires your focus';

  useEffect(() => {
    initialDataSetup();
    scrollToBottom(); // Scroll to bottom initially
  }, []);

  useEffect(() => {
    scrollToBottom(); // Scroll to bottom whenever messages change
  }, [messages]);

  useEffect(() => {
    const sessionStorageValue = sessionStorage.getItem('showLogs');
    if (sessionStorageValue === 'true') {
      setShowLogs(true);
    } else {
      setShowLogs(false);
    }
  }, []);

  useEffect(() => {
    adjustInputSize();
  }, []);

  useEffect(() => {
    adjustInputSize();
  }, [userInput]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const inputs =
      document.querySelectorAll<HTMLInputElement>('.suggestion-input');
    inputs.forEach((input) => {
      input.style.width = '10%';
    });
  }, []);

  useEffect(() => {
    if (isSpeechRecognitionActive) {
      setEnableTextareaFocus(true);
    } else {
      setEnableTextareaFocus(false);
    }
  }, [isSpeechRecognitionActive]);

  useEffect(() => {
    if (enableTextareaFocus) {
      const inputElement = document.getElementById(
        'userInput'
      ) as HTMLTextAreaElement;
      inputElement.focus();
    }
  }, [enableTextareaFocus]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(e.target.value);
    adjustInputSize();
  };

  // const handleClearMessages = () => {
  //   setMessages([]);
  // };

  const adjustInputSize = () => {
    const inputElement = document.getElementById(
      'userInput'
    ) as HTMLTextAreaElement;
    inputElement.style.height = 'auto';
    inputElement.style.height = inputElement.scrollHeight + 'px';
  };

  // const saveCopilotInstanceId = () => {
  //   if (copilotInstanceId === '') {
  //     toast.error('Please enter Instance Id from Copilot', {
  //       onClick: () => toast.dismiss(),
  //       autoClose: 1000,
  //     });
  //   } else {
  //     // setModalstatus(false);
  //   }
  // };

  const initialDataSetup = () => {
    logData = [];
    messageIndex = 0;
    setMessages([]);
    const newMessage1 = {
      role: 'assistant',
      content: msg1,
      type: 'string',
      index: messageIndex,
      entity: '',
    };

    setMessages((prevMessages) => [...prevMessages, newMessage1]);
    const timer2 = setTimeout(() => {
      if (timer_2) {
        const newMessage2 = {
          role: 'assistant',
          content: msg2,
          type: 'string',
          index: ++messageIndex,
          entity: '',
        };
        setMessages((prevMessages) => [...prevMessages, newMessage2]);
        setShowSendButton(false);
      }
      timer_2 = false;
    }, 1000);
    const timer3 = setTimeout(() => {
      if (timer_3) {
        const newMessage3 = {
          role: 'assistant',
          content: msg3,
          type: 'string',
          index: ++messageIndex,
          entity: '',
        };
        setMessages((prevMessages) => [...prevMessages, newMessage3]);
        setShowSendButton(true);
      }
      timer_3 = false;
    }, 6000);

    const timer4 = setTimeout(() => {
      if (timer_4) {
        const newMessage4 = {
          role: 'assistant',
          content: 'card details',
          type: 'card-details',
          index: ++messageIndex,
          entity: '',
        };
        setMessages((prevMessages) => [...prevMessages, newMessage4]);
      }
      timer_4 = false;
    }, 6000);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    setIsInputFlag(true);
    // setlastuserInput(userInput);
    setSuggestionsLoading(false);
    e.preventDefault();
    const newMessage = {
      role: 'user',
      content: DOMPurify.sanitize(userInput),
      type: 'string',
      index: ++messageIndex,
      entity: '',
    };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setShowSendButton(false);
    await getCopilotdata(userInput);
    setUserInput('');
    adjustInputSize();
  };

  const getApplicationData = async (
    Data: string,
    Endpoint: string,
    Method: string,
    configureConnectToOrg: configureConnectToOrg
  ) => {
    // let endpoint = 'https://govconnectchildsupport--csdemo.sandbox.my.salesforce.com/services/apexrest/fetchAppointmentRecordAPI';
    // let method = "POST";
    const url = configureConnectToOrg.endpointURL;
    const username = configureConnectToOrg.userName;
    const password = configureConnectToOrg.password;
    const grandType = configureConnectToOrg.grantType;
    const clientId = configureConnectToOrg.clientId;
    const clientSecret = configureConnectToOrg.clientSecret;
    try {
      if (Endpoint !== '') {
        if (Method.toUpperCase() === 'POST') {
          const params = {
            jsonData: Data,
            endpoint: Endpoint,
            url: url,
            username: username,
            password: password,
            grandType: grandType,
            clientId: clientId,
            clientSecret: clientSecret,
          };
          const response = await axios.post(
            `${process.env.REACT_APP_COPILOT_BACKEND_DOMAIN}/api/noui/fetchApplication?logid=` +
              sessionStorage.getItem('username'),
            params
          );
          if (response.status === 200) {
            const res = response.data;
            if (res.success && res.data != null) {
              const newMessage9 = {
                role: 'assistant',
                content:
                  'There are multiple records found in the system based on the search. Click on the cards to view more details about a record.',
                type: 'string',
                index: ++messageIndex,
                entity: '',
              };
              setMessages((prevMessages) => [...prevMessages, newMessage9]);
              cardJSON = res.data;
              // setJSONData(JSON.parse(res.data[0].Data__c));
              const newMessage8 = {
                role: 'assistant',
                content: JSON.stringify(cardJSON),
                type: 'card-summary-application',
                index: ++messageIndex,
                entity: '',
              };
              setMessages((prevMessages) => [...prevMessages, newMessage8]);
              toast.success('Data Fetched successfully', {
                onClick: () => toast.dismiss(),
                autoClose: 1000,
              });
            } else {
              const msg = {
                role: 'assistant',
                content: 'Data Not Found',
                type: 'string',
                index: ++messageIndex,
                entity: '',
              };
              setMessages((prevMessages) => [...prevMessages, msg]);
            }
          }
        }
      } else {
        toast.error('Technical Error', {
          onClick: () => toast.dismiss(),
          autoClose: 1000,
        });
      }
      setShowSendButton(true);
    } catch (e) {
      setShowSendButton(true);
    }
  };

  const getCopilotdata = async (uInput: string) => {
    try {
      setSuggestions('');
      let newMessage = {
        role: 'user',
        content: uInput,
      };
      setUserInput('Processing...');

      finalMessage.push(newMessage);

      let copilot_params = {
        copilot_instance_id: Number(copilotInstanceId),
        user_input: uInput,
        messages: finalMessage,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_COPILOT_BACKEND_DOMAIN}/api/copilotAPI?logid=` +
          sessionStorage.getItem('username'),
        copilot_params
      );
      const data = response.data;

      if (
        !data.hasOwnProperty('response') ||
        data.engine_name === "I don't Know"
      ) {
        const msg = {
          role: 'assistant',
          content: 'Technical Error. Please try again',
          type: 'string',
          index: ++messageIndex,
          entity: '',
        };
        setMessages((prevMessages) => [...prevMessages, msg]);
        setIsInputFlag(false);
        setUserInput('');
      } else {
        let found = false;
        let endpoint = '';
        let method = '';
        let url = '';
        let username = '';
        let password = '';
        let grandType = '';
        let clientId = '';
        let clientSecret = '';
        for (let i = 0; i < configurAction.length; i++) {
          if (configurAction[i].EngineName === data.engine_name) {
            found = true;
            endpoint = configurAction[i].Endpoint;
            method = configurAction[i].Method;
            break;
          }
        }
        if (found) {
          getApplicationData(
            JSON.stringify(data.response),
            endpoint,
            method,
            configurConnectToOrgData
          );
          setIsInputFlag(false);
          setUserInput('');
          setShowSendButton(false);
        } else {
          const inputJson: any = data.response;
          let isJson = isJSONContain(data.response);

          if (isJson) {
            let params = {
              input_json: inputJson,
            };
            const response = await axios.post(
              `${process.env.REACT_APP_COPILOT_BACKEND_DOMAIN}/api/noui/getModifyJson?logid=` +
                sessionStorage.getItem('username'),
              params
            );
            const data = response.data.response;
            // setJSONData(JSON.parse(data));
            //  sendDataToParent(JSON.parse(data));

            const msg = {
              role: 'assistant',
              content: data,
              type: 'accordian',
              index: ++messageIndex,
              entity: 'Application',
            };

            setMessages((prevMessages) => [...prevMessages, msg]);
            latestAccordianIndex = messageIndex;
            setIsInputFlag(false);
            setUserInput('');
          } else {
            let responseData = data.response;
            responseData = responseData.replace(/\n/g, '');

            const msg = {
              role: 'assistant',
              content: data.response,
              type: 'string',
              index: ++messageIndex,
              entity: '',
            };

            const msgGPT = {
              role: 'assistant',
              content: responseData,
            };

            let suggestiveResponseBody = [];
            suggestiveResponseBody.push(msgGPT);

            finalMessage.push(msgGPT);
            //if (suggestionStatus && finalMessage.length>=3 && finalMessage.length<=5) getSuggestions(msgGPT);
            if (suggestionStatus && finalMessage.length >= 3)
              getSuggestions(suggestiveResponseBody);

            setMessages((prevMessages) => [...prevMessages, msg]);
            setIsInputFlag(false);
            setUserInput('');
          }
          setShowSendButton(true);
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setIsInputFlag(false);
      setUserInput('');
      const msg = {
        role: 'assistant',
        content: 'Technical Error. Please try again',
        type: 'string',
        index: ++messageIndex,
        entity: '',
      };
      setMessages((prevMessages) => [...prevMessages, msg]);
      setShowSendButton(true);
    }
  };
  const handleSuggestionsChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setSuggestions(e.target.value);
  };

  const handleSuggestionButtonClick = async () => {
    setIsInputFlag(true);
    setShowSendButton(false);
    const userMessage = suggestions.replace(/\{\}/g, () => {
      const inputValue = suggestionInputs.shift() || '';
      return DOMPurify.sanitize(inputValue);
    });
    const newMessage = {
      role: 'user',
      content: userMessage,
      type: 'string',
      index: ++messageIndex,
      entity: '',
    };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setShowSendButton(false);

    await getCopilotdata(userMessage);
    setSuggestionInputs([]);
  };

  // const handleSuggestiveReponse = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setIsSuggestiveResponseEnable(event.target.checked);
  // };

  const isJSONContain = (obj: any): boolean => {
    try {
      //const jsonRegex = /^\s*(\{.*\}|\[.*\])\s*$/;
      const jsonRegex = /\{[\s\S]*\}/;
      if (jsonRegex.test(obj)) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };

  const ApplicationCardSummaryClicked = async (
    index: number,
    entityIndex: number,
    cardIndex: number
  ) => {
    let entityName = cardJSON && cardJSON[entityIndex]?.entity;
    const originalCardData =
      cardJSON &&
      JSON.stringify(cardJSON[entityIndex]?.definition?.[cardIndex]);
    if (originalCardData) {
      const newMessageCard2 = {
        role: 'assistant',
        content: 'Here are the details',
        type: 'string',
        index: ++messageIndex,
        entity: '',
      };

      // Update the messages state
      setMessages((prevMessages) => [...prevMessages, newMessageCard2]);

      const newMessageCard = {
        role: 'assistant',
        content: originalCardData,
        type: 'accordian',
        index: ++messageIndex,
        entity: entityName,
      };

      // Update the messages state
      setMessages((prevMessages) => [...prevMessages, newMessageCard]);
    }
  };

  const cardSummaryClicked = async (taskSummary: string) => {
    setIsInputFlag(true);
    const newMessage = {
      role: 'user',
      content: 'Apply for Medicaid',
      type: 'string',
      index: ++messageIndex,
      entity: '',
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setShowSendButton(false);

    await getCopilotdata('Apply for Medicaid');
  };

  const cardClicked = (task: string) => {
    const newMessage4 = {
      role: 'user',
      content: task,
      type: 'string',
      index: ++messageIndex,
      entity: '',
    };

    setMessages((prevMessages) => [...prevMessages, newMessage4]);

    const newMessage5 = {
      role: 'assistant',
      content: 'card summary',
      type: 'card-summary',
      index: ++messageIndex,
      entity: '',
    };

    setMessages((prevMessages) => [...prevMessages, newMessage5]);
  };

  /** TEST SAVE BUTTON */
  const handleInsights = async (e: React.FormEvent) => {
    e.preventDefault();
    const newMessage = {
      role: 'user',
      content: 'Generate Insights',
      type: 'string',
      index: ++messageIndex,
      entity: '',
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setShowSendButton(false);

    await getCopilotdata('Generate Insights');
  };

  const onConfirgureButtonClicked = async (
    button: any,
    configureConnectToOrg: configureConnectToOrg
  ) => {
    const endpoint = button.Endpoint;
    const method = button.Method;
    const url = configureConnectToOrg.endpointURL;
    const username = configureConnectToOrg.userName;
    const password = configureConnectToOrg.password;
    const grandType = configureConnectToOrg.grantType;
    const clientId = configureConnectToOrg.clientId;
    const clientSecret = configureConnectToOrg.clientSecret;
    const updatedMessages = [...messages];
    let UpdatedJson = updatedMessages[latestAccordianIndex].content;

    try {
      if (endpoint !== '') {
        if (method.toUpperCase() === 'POST') {
          const params = {
            jsonData: UpdatedJson,
            endpoint: endpoint,
            url: url,
            username: username,
            password: password,
            grandType: grandType,
            clientId: clientId,
            clientSecret: clientSecret,
          };
          const response = await axios.post(
            `${process.env.REACT_APP_COPILOT_BACKEND_DOMAIN}/api/noui/postActions?logid=` +
              sessionStorage.getItem('username'),
            params
          );
          if (response.status === 200) {
            toast.success(button.Message, {
              onClick: () => toast.dismiss(),
              autoClose: 1000,
            });
          }
        }
      } else {
        toast.error('Please Enter the Endpoint for' + button.Label + 'Button', {
          onClick: () => toast.dismiss(),
          autoClose: 1000,
        });
      }
    } catch (error) {}
  };

  const updateJsonData = (newData: any[], index: number) => {
    const updatedMessages = [...messages];
    updatedMessages[index] = {
      ...updatedMessages[index],
      content: JSON.stringify(newData),
    };
    setMessages(updatedMessages);
  };

  const downloadLogs = async () => {
    try {
      const logs = await axios.get(
        `${process.env.REACT_APP_COPILOT_BACKEND_DOMAIN}/api/noui/fetchAllLogs?logid=` +
          sessionStorage.getItem('username')
      );
      if (logs.status === 200) {
        logs.data.logs.forEach((log: any) => {
          logData.push(log);
        });

        generateLogFile();
      }
    } catch (error) {
      console.error('Error setlogData:', error);
    }
  };

  const generateLogFile = async () => {
    try {
      // Convert logsData to JSON string
      const logsJsonString = JSON.stringify(logData, null, 2);

      // Create a Blob containing the JSON string
      const blob = new Blob([logsJsonString], { type: 'application/json' });

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'logs.txt'); // Set filename for the downloaded file

      // Append the link to the document body
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error generateLogFile:', error);
    }
  };

  const getSuggestions = async (msgGPT: any) => {
    try {
      setSuggestionsLoading(true);
      setSuggestions('');
      let params = {
        messages: msgGPT,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_COPILOT_BACKEND_DOMAIN}/api/noui/getSuggestions?logid=` +
          sessionStorage.getItem('username'),
        params
      );
      const data = response.data.response;

      if (data.indexOf('<<NO_SUGGESTIONS_GENERATED>>') < 0) {
        setSuggestions(data);
      } else {
        setSuggestions('');
      }

      setSuggestionsLoading(false);
    } catch (e) {
      setSuggestionsLoading(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (!isSpeechRecognitionActive) {
        handleSubmit(e);
      }
    }
  };

  const handleSuggestionInputChange = (index: number, value: string) => {
    const newInputs = [...suggestionInputs];
    newInputs[index] = value;
    setSuggestionInputs(newInputs);
    adjustInputWidth(index);
  };

  const adjustInputWidth = (index: number) => {
    const inputElement = document.getElementById(
      `suggestion-input-${index}`
    ) as HTMLInputElement;
    const suggestiveResponseBox = document.querySelector(
      '.suggestive-response-box'
    ) as HTMLElement;
    if (inputElement && suggestiveResponseBox) {
      const maxWidth = suggestiveResponseBox.offsetWidth - 2;
      const minWidthPercent = 15;
      const minWidthPixels =
        (suggestiveResponseBox.offsetWidth * minWidthPercent) / 100;
      inputElement.style.width = `${Math.max(minWidthPixels, Math.min(inputElement.scrollWidth, maxWidth))}px`;
    }
  };

  const startVoiceRecognition = () => {
    if (!isSpeechRecognitionActive) {
      setIsSpeechRecognitionActive(true);
    } else {
      setIsSpeechRecognitionActive(false);
    }
    if ('webkitSpeechRecognition' in window) {
      const recognition = new webkitSpeechRecognition();
      recognition.continuous = false;
      recognition.interimResults = true;
      recognition.lang = 'en-US';
      recognition.onstart = () => {
        //console.log('Voice recognition started.');
      };
      recognition.onresult = (event: any) => {
        const transcript = event.results[0][0].transcript;
        setUserInput(transcript);
      };
      recognition.onerror = (event: any) => {
        console.error('Voice recognition error:', event.error);
      };
      recognition.onend = () => {
        setIsSpeechRecognitionActive(false);
        //console.log('Voice recognition ended.');
      };
      recognition.start();
    } else if ('SpeechRecognition' in window) {
      const recognition = new SpeechRecognition();
      recognition.continuous = false;
      recognition.interimResults = true;
      recognition.lang = 'en-US';
      recognition.onstart = () => {
        //console.log('Voice recognition started.');
      };
      recognition.onresult = (event: any) => {
        const transcript = event.results[0][0].transcript;
        setUserInput(transcript);
      };
      recognition.onerror = (event: any) => {
        console.error('Voice recognition error:', event.error);
      };
      recognition.onend = () => {
        setIsSpeechRecognitionActive(false);
        //console.log('Voice recognition ended.');
      };
      recognition.start();
    } else {
      console.error('Speech recognition not supported.');
    }
  };
  return (
    <>
      <ToastContainer />
      <pre className="chat-wrapper">
        {!showSendButton && (
          <div className="ai-processing">
            <img
              src={processingIcon}
              alt="Processing"
              className="ai-processing-logo"
            />
          </div>
        )}

        {messages.map((message, index) => (
          <div key={index} className={`message.${message.role}`}>
            {/* Assistant Bubble, type text */}
            {message.role === 'assistant' && message.type === 'string' && (
              <div className="assistant-message">
                <div className="icon-wrapper-assistant">
                  {/*<BsRobot className="assistant-icon" />*/}
                  {/*<img className="assistant-icon" src={NOUIAssistantIcon}/> */}
                </div>
                <div className="message-text-wrapper">
                  <span className="message-content">{message.content}</span>
                </div>
                <div ref={messagesEndRef} />
              </div>
            )}

            {/* Assistant Bubble, type Card */}
            {message.role === 'assistant' &&
              message.type === 'card-details' && (
                <div className="assistant-message">
                  <div className="icon-wrapper-assistant">
                    {/*<BsRobot className="assistant-icon" />*/}
                    {/*<img className="assistant-icon" src={NOUIAssistantIcon}/> */}
                  </div>
                  <div className="message-text-wrapper">
                    <CardDetailsPage onClickCard={cardClicked} />
                  </div>
                  {/* <h2>CARD</h2> */}
                  <div ref={messagesEndRef} />
                </div>
              )}
            {/* Assistant Bubble, type Card Details */}
            {message.role === 'assistant' &&
              message.type === 'card-summary' && (
                <div className="assistant-message">
                  <div className="icon-wrapper-assistant">
                    {/*<BsRobot className="assistant-icon" />*/}
                    {/*<img className="assistant-icon" src={NOUIAssistantIcon}/> */}
                  </div>
                  {/* cardJson={message.content) , index={message.index}, loadDefaultCard=true */}
                  <div className="message-text-wrapper">
                    <CardSummaryPage
                      index={message.index}
                      loadDefaultCard={true}
                      onClickCardSummary={cardSummaryClicked}
                      onClickApplicationCardSummary={
                        ApplicationCardSummaryClicked
                      }
                    />
                  </div>

                  {/* <h2>CARD</h2> */}
                  <div ref={messagesEndRef} />
                </div>
              )}

            {message.role === 'assistant' &&
              message.type === 'card-summary-application' && (
                <div className="assistant-message">
                  <div className="icon-wrapper-assistant">
                    {/*<BsRobot className="assistant-icon" />*/}
                    {/*<img className="assistant-icon" src={NOUIAssistantIcon}/> */}
                  </div>
                  <div className="message-text-wrapper">
                    {/* cardJson={message.content) , index={message.index}, loadDefaultCard=false */}
                    <CardSummaryPage
                      cardJSON={JSON.parse(message.content)}
                      index={message.index}
                      loadDefaultCard={false}
                      onClickCardSummary={cardSummaryClicked}
                      onClickApplicationCardSummary={
                        ApplicationCardSummaryClicked
                      }
                    />
                  </div>

                  {/* <h2>CARD</h2> */}
                  <div ref={messagesEndRef} />
                </div>
              )}

            {/* Assistant Bubble, type Accordians */}
            {message.role === 'assistant' && message.type === 'accordian' && (
              <div className="assistant-message">
                <div className="icon-wrapper-assistant">
                  {/*<BsRobot className="assistant-icon" />*/}
                  {/*<img className="assistant-icon" src={NOUIAssistantIcon}/> */}
                </div>
                <div className="message-accordion-wrapper">
                  <DynamicTabsAndForms
                    jsonData={JSON.parse(message.content)}
                    updateJsonData={(data: any, index: number) =>
                      updateJsonData(data, index)
                    }
                    index={message.index}
                    entity={message.entity}
                  ></DynamicTabsAndForms>
                  {latestAccordianIndex === message.index &&
                    configursButtonData &&
                    configursButtonData.map((button, index) => (
                      <button
                        key={index}
                        className="btn btn-primary"
                        onClick={() =>
                          onConfirgureButtonClicked(
                            button,
                            configurConnectToOrgData
                          )
                        }
                        style={{
                          display: 'block',
                          margin: 'auto',
                          marginTop: '10px',
                        }}
                      >
                        {button.Label}
                      </button>
                    ))}
                </div>

                {/* <button className='btn btn-primary'>Accodian</button> */}
                {/* <h2>CARD</h2> */}
                <div ref={messagesEndRef} />
              </div>
            )}
            {/* User Bubble */}
            {message.role === 'user' && (
              <div>
                <div className="user-message">
                  <div className="message-text-wrapper">
                    <span className="message-content">{message.content}</span>
                  </div>
                  <div className="icon-wrapper-user">
                    {/*<FaRegUserCircle className="user-chat-icon" />*/}
                  </div>
                </div>
                <div ref={messagesEndRef} />
              </div>
            )}
          </div>
        ))}

        {/* Suggestive responses */}
        {isSuggestiveResponseEnable && (
          <div className="message user user-message message-text-wrapper">
            {suggestionsLoading && (
              <div className="slider-loader">
                <div className="textClass">Generating Suggestive Response</div>
                <div className="loader-container">
                  <div className="loader"></div>
                </div>
              </div>
            )}
            {!suggestionsLoading && !isInputFlag && suggestions.length > 0 && (
              <div>
                <div className="textClass">Suggestive Response</div>
                <div className="form-control suggestive-response-box ">
                  {suggestions.split(/\{\}/).map((part, index, array) => (
                    <React.Fragment key={index}>
                      {part}
                      {index !== array.length - 1 && (
                        <input
                          type="text"
                          id={`suggestion-input-${index}`}
                          className="suggestion-input"
                          value={suggestionInputs[index] || ''}
                          onChange={(e) =>
                            handleSuggestionInputChange(index, e.target.value)
                          }
                        />
                      )}
                    </React.Fragment>
                  ))}
                </div>
                <div className="accept-suggestion-btn-box">
                  <button
                    type="button"
                    className="btn btn-primary accept-suggestion-btn"
                    onClick={handleSuggestionButtonClick}
                  >
                    Accept Suggestion
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </pre>

      <Form onSubmit={handleSubmit}>
        <InputGroup className="mb-3">
          <FormControl
            id="userInput"
            as="textarea"
            placeholder="Enter your Query"
            aria-label="Enter your Query"
            value={userInput}
            onChange={handleInputChange}
            disabled={isInputFlag}
            rows={1}
            onKeyDown={handleKeyDown}
          />
          {isSpeechRecognitionActive ? (
            <FaRecordVinyl
              className={`record-icon ${isSpeechRecognitionActive ? 'active' : ''}`}
              onClick={startVoiceRecognition}
            />
          ) : (
            <FaMicrophoneAlt
              className={`mic-icon-ui ${isSpeechRecognitionActive ? 'active' : ''}`}
              onClick={startVoiceRecognition}
            />
          )}

          <button
            className="snd-button-landing"
            aria-label="insights"
            onClick={handleInsights}
          >
            <TbBulbFilled />
          </button>
          <button
            type="submit"
            className="snd-button-landing"
            disabled={isInputFlag || !userInput.trim()}
            aria-label="send"
          >
            <IoIosSend />
          </button>
        </InputGroup>
      </Form>
      {generateLogs && (
        <button className="btn logsButton" onClick={downloadLogs}>
          <PiFileArrowDownDuotone />
        </button>
      )}
    </>
  );
};

export default ChatComponent;
