import React, { useEffect, useRef } from 'react';
import { Badge, InputGroup, FormControl } from 'react-bootstrap';

interface Badges {
  id: number;
  text: string;
}
interface InputProps {
  label: string;
  handleInput?: any;
  ref?: any;
  keywordBadges: any;
  setKeywordBadge: (value: any) => void;
  isdisabled?: any;
  isRequired?: boolean;
}

const InputBadgeCreator: React.FC<InputProps> = ({
  label,
  handleInput,
  ref,
  keywordBadges,
  setKeywordBadge,
  isdisabled,
  isRequired,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [keywords, setKeywords] = React.useState<Badges[]>([]);

  useEffect(() => {
    if (keywordBadges?.length > 0) {
      setKeywords(
        keywordBadges.map((badge: any) => ({
          id: Date.now() + Math.random(),
          text: badge,
        }))
      );
    }
  }, [keywordBadges]);
  useEffect(() => {
    setKeywordBadge(keywords.map((badge: any) => badge.text));
  }, [keywords]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    //keycode = 13 is used to capture keywords from input on pressing enter key
    if (event.keyCode === 13 && inputRef.current) {
      const keywordBadgeText = inputRef.current.value.trim();
      if (keywordBadgeText) {
        //storing all the keywords to represent as badges
        setKeywords([...keywords, { id: Date.now(), text: keywordBadgeText }]);
        inputRef.current.value = '';
      }
    }
  };

  const handleRemoveBadge = (id: number) => {
    setKeywords(keywords.filter((badge: any) => badge.id !== id));
  };

  return (
    <>
      <div className="input-badge-container mb-1">
        <InputGroup>
          <FormControl
            placeholder={label}
            onKeyDown={handleKeyDown}
            ref={inputRef}
            disabled={isdisabled === true ? true : false}
            isInvalid={isRequired ? true : false}
          />
        </InputGroup>
        <p style={{ fontSize: '12px', marginBottom: '0' }}>
          Press Enter after each word
        </p>
      </div>
      <div className="d-flex dropdown-badge-container">
        {keywords.map((badge: any) => (
          <Badge
            key={badge.id}
            bg="primary"
            className="me-2"
            onClick={() => handleRemoveBadge(badge.id)}
          >
            {badge.text} {'  '}
            <span aria-hidden={true}>&times;</span>
          </Badge>
        ))}
      </div>
    </>
  );
};

export default InputBadgeCreator;
