import React, { useEffect, useState, useContext } from 'react';
import { Container, Form, Button, Badge, Spinner } from 'react-bootstrap';
import axios from 'axios';
import UserContext from '../../context/UserContext';
import Stepper from '../Stepper';
import { ADMIN, GPT_4_TURBO, SUPERADMIN } from '../../utils/constants';
import ContentLoader from '../Loader/contentLoader';

type SetStateFunction<T> = React.Dispatch<React.SetStateAction<T>>;

type InstanceGeneratorProps = {
  currentStep: number;
  projectId: string;
  currentAppId: string | undefined;
  nextStep: any;
  setShowNewInstanceModal: SetStateFunction<boolean>;
  step1?: boolean;
  step2?: boolean;
  isEditMode?: boolean;
  instanceDetails?: any;
  selectedInstance?: any;
  prevStep: any;
  isBackClicked?: boolean;
  getInstanceData?: any;
};

const InstanceSteps: React.FC<InstanceGeneratorProps> = ({
  currentStep,
  projectId,
  currentAppId,
  nextStep,
  prevStep,
  setShowNewInstanceModal,
  step1 = true,
  step2 = true,
  isEditMode,
  instanceDetails,
  selectedInstance,
  isBackClicked,
  getInstanceData,
}) => {
  const [sliderValue, setSliderValue] = useState<number>(0.0);
  const [instanceName, setInstanceName] = useState<string>('');
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [totalSteps, setTotalSteps] = useState<number>();
  const [instanceData, setInstanceData] = useState<any>(null);
  const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false);
  const [isUploadSuccess, setIsUploadSuccess] = useState<boolean>(false);
  const [newKnowledgeID, setNewKnowledgeID] = useState<string>('');
  const [uploadError, setUploadError] = useState(false);
  const [flowId, setFlowid] = useState<string>('');
  const userRole = JSON.parse(sessionStorage.getItem('role') || '{}');
  const userContext = useContext(UserContext) || {
    showToast: false,
    setShowToast: () => {},
    showErrMsg: '',
    setShowErrMsg: () => {},
    setSelectedInstance: () => {},
  };

  const { setShowToast, setShowErrMsg, setSelectedInstance } = userContext;

  const handleInstanceCreation = async (payload: any) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/instance/create-instance`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const responseData = response.data;
      if (response.status === 200) {
        setShowToast(false);
        setShowErrMsg('');
        const instanceData = responseData?.response;
        setSelectedInstance(instanceData?.id);
        setInstanceData(instanceData);
        setFlowid(instanceData?.flow);
        nextStep();
        return responseData;
      } else {
        const error = responseData?.error;
        setShowToast(true);
        setShowErrMsg(error?.errorMessage);
        console.error('response error', error);
        return;
      }
    } catch (error: any) {
      console.error(
        'Error fetching projects:',
        error.response?.data || error.message
      );
      const err = error?.response?.data?.error;
      setShowToast(true);
      setShowErrMsg(err.errorMessage);
      if (err && err.errorMessage) {
        setShowErrMsg(err.errorMessage);
      } else {
        console.error('Error message not available');
      }
    }
  };

  const createInstance = () => {
    const payload = {
      name: instanceName,
      projectId: projectId,
      temperature: sliderValue,
      applicationId: currentAppId,
      llm_model: GPT_4_TURBO,
      kNearChunks: 1,
      chunkSize: 1200,
    };
    handleInstanceCreation(payload);
  };

  const handleFinish = () => {
    nextStep();
    getInstanceData(selectedInstance);
    setShowNewInstanceModal(false);
  };

  const handleInstanceNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInstanceName(event.target.value);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsUploadLoading(false);
    setIsUploadSuccess(false);
    const files = event.target.files;
    if (files) {
      setUploadedFiles(Array.from(files));
    }
  };

  useEffect(() => {
    if (step1 && step2) {
      setTotalSteps(2);
    } else {
      setTotalSteps(1);
    }
  }, [step1, step2]);

  useEffect(() => {
    const removeSuccessMessage = setTimeout(() => {
      setIsUploadSuccess(false);
    }, 2000);

    return () => clearTimeout(removeSuccessMessage);
  }, [isUploadSuccess]);


  const editTemperature = async () => {
    let payload;
    payload = {
      llm_model: 'gpt-4-turbo',
      temperature: sliderValue,
    };
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/instance/update/temperature-markdown-llmguard/${selectedInstance}`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const responseData = response.data;
      if (response.status === 200) {
        setShowToast(false);
        setShowErrMsg('');
        nextStep();
      } else {
        const error = responseData?.error;
        setShowToast(true);
        setShowErrMsg(error?.errorMessage);
        console.error('response error', error);
        return;
      }
    } catch (error: any) {
      console.error(
        'Error Editing instance',
        error.response?.data || error.message
      );
      const err = error?.response?.data?.error;
      setShowToast(true);
      setShowErrMsg(err.errorMessage);
      if (err && err.errorMessage) {
        setShowErrMsg(err.errorMessage);
      } else {
        console.error('Error message not available');
      }
    }
  };

  const loadKnowledge = async () => {
    setIsUploadLoading(true);
    setIsUploadSuccess(false);
    const formData = new FormData();
    uploadedFiles.forEach((file) => {
      formData.append('files', file);
    });
    formData.append(
      'knowledge_name',
      newKnowledgeID ? newKnowledgeID : instanceDetails.name
    );
    formData.append('flow_id', isEditMode ? instanceDetails.flow : flowId);
    formData.append(
      'chunking_strategy',
      isEditMode ? instanceDetails.chunking_strategy : 'chunkingStrategy'
    );

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/knowledge/code-to-design-load`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        setShowToast(false);
        setShowErrMsg('');
        setUploadedFiles([]);
        setUploadError(false);
        // setKnowlwdgeidAdded(true);
        setIsUploadLoading(false);
        setIsUploadSuccess(true);
        setTimeout(() => {
          handleFinish();
        }, 500);
      } else {
        setShowToast(true);
        setIsUploadLoading(false);
      }
    } catch (error: any) {
      const err = error?.response?.data?.error;
      setShowToast(true);
      setShowErrMsg(err?.errorMessage);
      setIsUploadLoading(false);
      console.error('Error:', error);
    }
  };

  const handleKnowledgeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewKnowledgeID(event.target.value);
  };

  return (
    <div>
      <Stepper currentStep={currentStep} totalSteps={totalSteps} />
      {currentStep === 1 && step1 ? (
        <>
          <Form.Group
            className="instance-name"
            controlId="exampleForm.instanceName"
          >
            <div className="modal-header-container">
              <h2 className="modal-header-title">Basic Details</h2>
            </div>

            <div className="basic-details-container">
              <div className="basic-details-wrapper">
                <Form.Label className="label-text">Instance Name</Form.Label>
                <Form.Control
                  as="input"
                  type="text"
                  value={isEditMode ? instanceDetails.name : instanceName}
                  onChange={handleInstanceNameChange}
                  placeholder="Enter Instance Name"
                  className="text-input"
                  disabled={isEditMode || isBackClicked}
                />
              </div>
            </div>

            {userRole && (userRole === ADMIN || userRole === SUPERADMIN) && (
            <div className="button-container">
              <Button
                variant="primary"
                onClick={() =>
                  isEditMode || isBackClicked
                    ? editTemperature()
                    : createInstance()
                }
                className="primary-btn-style modal-button"
              >
                Next
              </Button>
            </div>
            )}
          </Form.Group>
        </>
      ) : (
        <Container>
          <div className="modal-header-container">
            <h2 className="modal-header-title">Knowledge Details</h2>
          </div>
          <div className="basic-details-container-step2">
            <div className="basic-details-knowledge-wrapper">
              <Form.Group
                className="knowledge-name"
                controlId="exampleForm.knowledgeName"
              >
                <Form.Label className="label-text">Knowledge Name</Form.Label>
                <Form.Control
                  as="input"
                  value={newKnowledgeID}
                  className="text-input"
                  onChange={handleKnowledgeName}
                  // disabled={isEditMode}
                />
              </Form.Group>
            </div>
            <div className="basic-details-knowledge-wrapper">
              <Form.Group
                className="file-input"
                controlId="exampleForm.fileInput"
              >
                <Form.Label className="label-text">Upload Document</Form.Label>
                <Form.Control
                  type="file"
                  multiple
                  accept=".frm, .zip, .pco, .cobol, .ctl, .cls"
                  onChange={handleFileChange}
                  disabled={!newKnowledgeID}
                />

                <Form.Text>
                  Formats supported: frm, zip, pco, cobol, ctl, cls. Zip also
                  supports these files.
                </Form.Text>
                <br />
                <Form.Text>
                  <b>Note:</b> File(s) must not be password protected.
                </Form.Text>

                {isUploadLoading && (
                  <div className="upload-spinner">
                    <Spinner animation="border" size="sm" /> Uploading
                  </div>
                )}
                {uploadedFiles && isUploadSuccess && (
                  <div className="upload-msg">
                    <Badge bg="success">Upload Successful</Badge>
                  </div>
                )}
              </Form.Group>
            </div>
          </div>
          <div className="two-button-container">
            <Button
              variant="primary"
              onClick={() => prevStep()}
              className="secondary-btn-style modal-button"
            >
              Back
            </Button>
            {isUploadLoading ? (
              <Button
                variant="primary"
                className="primary-btn-style modal-button"
                disabled={true}
              >
                <ContentLoader />
              </Button>
            ) : (
              <Button
                variant="primary"
                onClick={loadKnowledge}
                className="primary-btn-style modal-button"
              >
                Finish
              </Button>
            )}
          </div>
        </Container>
      )}
    </div>
  );
};

export default InstanceSteps;
