/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { useState, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import '../UserStoryGenerator/UserStoryGenerator.css';
import ContentLoader from '../../components/Loader/contentLoader';
import Alert from 'react-bootstrap/Alert';
import CustomFeedback from '../../components/Feedback';
import { ReactComponent as BackIcon } from '../../assets/arrows/back-arrow.svg';
import { Appl } from '../../interfaces';
import { Llm_models } from '../../types';
import axios from 'axios';
import SelectInstance from '../../components/SelectInstance';
import UserContext from '../../context/UserContext';
import ClearChat from '../../components/ClearContent';
import { azure_llm_models_options } from '../../utils/constants';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import stream from '../../utils/stream';

const NoticeEngine = () => {
  const { projectId, appUrl } = useParams();
  const apps = JSON.parse(sessionStorage.getItem('apps') as string) as Appl[];
  const currentApp = apps.find((app) => app.url === appUrl);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showFeedbackOptions, setShowFeedBackOptions] =
    useState<boolean>(false);
  const [activityId, setActivityId] = useState<string>('');
  const [selectedLlmModel, setSelectedLlmModel] =
    useState<Llm_models>('gpt-4-turbo');
  const [uploadedFiles, setUploadedFiles] = useState<File | null>(null);
  const [showErrMsg, setShowErrMsg] = useState<string>('');
  const [instanceData, setInstanceData] = useState<any>('');
  const [sanitizedPrompt, setSanitizedPrompt] = useState<string>('');
  const [showSanitizedPrompt, setShowSanitizedPrompt] =
    useState<boolean>(false);
  const userContext = useContext(UserContext) || {
    selectedInstance: '',
    setOutputData: () => {},
    outputData: '',
    showToast: false,
    setShowToast: () => {},
    filesSelectedForUploadRef: null,
    llmGuardOption: '',
  };

  const {
    selectedInstance,
    setOutputData,
    outputData,
    showToast,
    setShowToast,
    filesSelectedForUploadRef,
    llmGuardOption,
  } = userContext;

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setOutputData('');
      setShowSanitizedPrompt(false);
      setSanitizedPrompt('');
      setUploadedFiles(event.target.files[0]);
    }
  };
  const submitStreamNoticeEngine = async () => {
    setShowSanitizedPrompt(false);
    setSanitizedPrompt('');
    if (uploadedFiles) {
      const formData = new FormData();
      formData.append('file', uploadedFiles);
      formData.append('model_choice', selectedLlmModel);
      setOutputData('');
      setShowToast(false);
      setIsLoading(true);
      setShowFeedBackOptions(false);
      // setShowDownload(false);
      // abortControllerRef.current = new AbortController();
      stream({
        requestBody: formData,
        url: `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/submit/streaming/document-summariser-engine?instance_id=${selectedInstance}&llm_guard=${instanceData.llmGuard}`,
        streamCallback: (value: string) => {
          setOutputData((prev) => {
            return `${prev}${value}`;
          });
        },
        doneCallback: () => {
          setIsLoading(false);
          setShowFeedBackOptions(true);
          // setShowDownload(true);
          setShowToast(false);
        },
        setShowToast: setShowToast,
        setIsLoading: setIsLoading,
        selectedLlmModel: selectedLlmModel,
        activityId: activityId,
        setActivityId: setActivityId,
      });
    }
  };

  const submitNoticeEngine = async () => {
    setShowSanitizedPrompt(false);
    setSanitizedPrompt('');
    if (uploadedFiles) {
      try {
        const formData = new FormData();
        formData.append('model_choice', selectedLlmModel);
        formData.append('file', uploadedFiles);
        const queryParams = {
          instance_id: selectedInstance,
          llm_guard: instanceData.llmGuard,
        };
        setShowToast(false);
        setShowErrMsg('');
        setShowFeedBackOptions(false);
        setIsLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/submit/document-summariser-engine`,
          formData,
          {
            params: queryParams,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        if (response.status === 200) {
          setShowFeedBackOptions(true);
          setIsLoading(false);
          setUploadedFiles(null);
          const responseData = response.data;
          setOutputData(responseData?.response);
          setSanitizedPrompt(responseData?.sanitized_prompt);
          setActivityId((response?.headers as any)?.get('x-activityid'));
        } else {
          console.error('Failed to upload files');
          setIsLoading(false);
          setShowToast(true);
        }
      } catch (error: any) {
        const err = error?.response?.data?.error;
        console.error('Notice engine Error:', err);
        setIsLoading(false);
        setShowToast(true);
        // setShowErrMsg(err.errorMessage)
        if (err && err.errorMessage) {
          setShowErrMsg(err.errorMessage);
        } else {
          console.error('Error message not available');
        }
      }
    }
  };

  const clearSelectedFile = () => {
    if (filesSelectedForUploadRef && filesSelectedForUploadRef.current) {
      filesSelectedForUploadRef.current.value = '';
    }
  };

  return (
    <div className="application-page-container">
      <div className="title">{currentApp?.name}</div>
      <div className="back-button-container">
        <Link to={`/project/${projectId}`}>
          <BackIcon /> Back
        </Link>
      </div>
      <div className="single-app-container">
        <SelectInstance
          projectId={projectId}
          currentApp={currentApp}
          step3={false}
          outputData={outputData}
          setOutputData={setOutputData}
          setInstanceDetails={setInstanceData}
          instanceDetails={instanceData}
        />
        <Form.Group className="file-input" controlId="exampleForm.fileInput">
          <Form.Label>Upload document</Form.Label>
          <Form.Control
            type="file"
            accept=".pdf"
            ref={filesSelectedForUploadRef}
            onChange={handleFileChange}
          />
        </Form.Group>
      </div>
      <div className="user-story-generator-submit-container">
        {isLoading ? (
          <Button
            className="user-story-generator-submit"
            disabled={true}
            style={{ width: '104px', height: '36px' }}
          >
            <ContentLoader />
          </Button>
        ) : (
          <Button
            className="user-story-generator-submit"
            as="input"
            type="submit"
            value="Submit"
            disabled={!uploadedFiles || !selectedInstance?.length}
            onClick={
              llmGuardOption === 'Yes'
                ? submitNoticeEngine
                : submitStreamNoticeEngine
            }
          />
        )}
        <ClearChat
          disabled={outputData.length > 0 ? false : true}
          setUploadedFiles={setUploadedFiles}
          setOutputData={setOutputData}
          clearSelectedFile={clearSelectedFile}
          setShowRawTranscript={setShowSanitizedPrompt}
        />
      </div>
      {showToast && (
        <div
          style={{
            paddingTop: '80px',
            width: '50%',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Alert
            variant="danger"
            onClose={() => setShowToast(false)}
            dismissible
          >
            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
            <p>
              {showErrMsg
                ? showErrMsg
                : `Something went wrong. Please try again`}
            </p>
          </Alert>
        </div>
      )}
      {outputData &&
        (instanceData.renderMarkdown ? (
          <Form.Group controlId="outputTextArea">
            <div className="output-container markdown-table">
              <Markdown remarkPlugins={[remarkGfm]}>{outputData}</Markdown>
            </div>
          </Form.Group>
        ) : (
          <Form.Group
            className="user-input"
            controlId="outputTextArea.output"
            style={{ display: 'flex' }}
          >
            <Form.Control
              as="textarea"
              rows={18}
              className="output-container"
              value={outputData}
              disabled
            />
          </Form.Group>
        ))}
      {llmGuardOption === 'Yes' &&
        sanitizedPrompt &&
        outputData?.length > 0 && (
          <Form.Group className="transcript-toggle-container">
            <Form.Check
              type="switch"
              id="transcript-view-switch"
              label=" View Sanitized Prompt"
              onChange={(evt) => setShowSanitizedPrompt(evt.target.checked)}
            />
          </Form.Group>
        )}
      {showSanitizedPrompt && (
        <Form.Group controlId="outputTextArea">
          <Form.Control
            as="textarea"
            rows={10}
            className="output-container"
            value={`Sanitized Prompt:\n${sanitizedPrompt}`}
            disabled
          />
        </Form.Group>
      )}
      {outputData && showFeedbackOptions && (
        <CustomFeedback
          url={`${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/activity/feedback/update`}
          activityId={activityId}
        />
      )}
    </div>
  );
};

export default NoticeEngine;
