import React, { useEffect } from 'react';
import { Dropdown, DropdownButton, Badge } from 'react-bootstrap';
import { SetStateFunction } from '../../types';

interface Option {
  id: string;
  option: string;
  label: string;
}
interface Options {
  options: Option[];
  label: string;

  setSelectedOption: (value: any) => void;
  isdisabled?: any;
  selectedEntity?: any;
}
const MultiSelectDropdown: React.FC<Options> = ({
  options,
  label,
  setSelectedOption,
  isdisabled,
  selectedEntity,
}) => {
  const [selectOption, setSelectOption] = React.useState<string[]>([]);

  const handleSelect = (value: any) => {
    const newSelected = [...selectOption];
    if (newSelected.includes(value)) {
      newSelected.splice(newSelected.indexOf(value), 1);
    } else {
      newSelected.push(value);
    }
    setSelectOption(newSelected);
    setSelectedOption(newSelected);
  };

  // useEffect(() => {
  //   setSelectedOption(selectOption);
  // }, [selectOption]);

  useEffect(() => {
    if (selectedEntity?.length > 0) {
      setSelectOption(selectedEntity);
    }
  }, [selectedEntity]);

  const handleRemoveBadge = (id: string) => {
    setSelectOption(selectOption.filter((selectedId) => selectedId !== id));
    setSelectedOption(selectOption.filter((selectedId) => selectedId !== id));
  };

  return (
    <div className="multi-dropdown-selector">
      <div>
        <DropdownButton
          className="multi-dropdown multi-dropdown-btn mb-2"
          id="dropdown-multiselect"
          title={label}
          onSelect={handleSelect}
          disabled={isdisabled === true ? true : false}
        >
          {options?.map((option: any) => (
            <Dropdown.Item key={option.id} eventKey={option.option}>
              {option.label}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>
      {selectOption?.length > 0 && (
        <div className="d-flex dropdown-badge-container">
          {selectOption?.map((selectedId) => (
            <Badge
              key={selectedId}
              bg="primary"
              className="me-2"
              onClick={() => handleRemoveBadge(selectedId)}
            >
              {options.find((option) => option.option === selectedId)?.label}
              {'  '}
              <span aria-hidden={true}>&times;</span>
            </Badge>
          ))}
        </div>
      )}
    </div>
  );
};
export default MultiSelectDropdown;
