import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import UserContext from '../../context/UserContext';
import ClearChatModal from '../Modals/ClearChatModal';

const ClearChat = ({
  setUploadedFiles,
  setOutputData,
  setShowRawTranscript,
  disabled,
  clearSelectedFile,
  setShowDownload,
  setUploadedExcelFiles,
  setCollectionName,
  setIsUploadResponse,
  setRecognizedSpeech,
  setRecognizingSpeech,
}: {
  setUploadedFiles?: any;
  setOutputData?: Function;
  setShowRawTranscript?: Function;
  disabled?: boolean;
  clearSelectedFile?: Function;
  setShowDownload?: Function;
  setUploadedExcelFiles?: any;
  setCollectionName?: any;
  setIsUploadResponse?: any;
  setRecognizedSpeech?: any;
  setRecognizingSpeech?: any;
}) => {
  const userContext = useContext(UserContext) || {
    setUserInput: () => {},
    setChatContents: () => [],
  };

  const { setUserInput, setChatContents } = userContext;
  const [showModal, setShowModal] = useState(false);

  const handleClear = () => {
    setShowModal(true);
  };

  const handleModalHide = () => {
    setShowModal(false);
  };
  const handleClearChatConfirmed = () => {
    if (setOutputData) {
      setOutputData('');
    }
    if (setUploadedExcelFiles) {
      setUploadedExcelFiles(null);
    }
    if (setUploadedFiles) {
      setUploadedFiles(null);
    }
    if (setShowRawTranscript) {
      setShowRawTranscript(false);
    }
    if (clearSelectedFile) {
      clearSelectedFile();
    }
    if (setShowDownload) {
      setShowDownload(false);
    }
    if (setCollectionName) {
      setCollectionName('');
    }
    if (setIsUploadResponse) {
      setIsUploadResponse(false);
    }
    if (setRecognizedSpeech) {
      setRecognizedSpeech('');
    }
    if (setRecognizingSpeech) {
      setRecognizingSpeech('');
    }
    setUserInput('');
    setChatContents([]);

    setShowModal(false);
  };

  return (
    <>
      <Button
        variant="danger"
        disabled={disabled}
        onClick={handleClear}
        style={{ marginLeft: '1.5rem' }}
      >
        Clear
      </Button>
      <ClearChatModal
        show={showModal}
        onHide={handleModalHide}
        onClear={handleClearChatConfirmed}
      />
    </>
  );
};

export default ClearChat;
