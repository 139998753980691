import React, { useState } from 'react';
import './Tabs.css';

interface Tab {
  id: string;
  title: string;
  content: React.ReactNode;
}

interface TabsProps {
  tabs: Tab[];
  handleTabChange: (tabId: string) => void;
}

const Tabs: React.FC<TabsProps> = ({ tabs, handleTabChange }) => {
  const [activeTab, setActiveTab] = useState(tabs[0]?.id);

  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
    handleTabChange(tabId);
  };

  return (
    <div className="container">
      <ul className="nav nav-tabs">
        <li className="nav-item">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`nav-link ${activeTab === tab.id ? 'active' : ''}`}
              onClick={() => handleTabClick(tab.id)}
            >
              {tab.title}
            </button>
          ))}
        </li>
      </ul>
      <div className="tab-content" id="pills-tabContent">
        {tabs.map((tab) => (
          <div
            className={`tab-pane fade ${activeTab === tab.id ? 'show active' : ''}`}
            key={tab.id}
          >
            {activeTab === tab.id && tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
