import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import './TotalCost.css';
import { Form, Button, Table, Tabs, Tab } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { ReactComponent as BackIcon } from '../../assets/arrows/back-arrow.svg';
import { Link } from 'react-router-dom';
import ContentLoader from '../../components/Loader/contentLoader';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import TimelinesReport from '../TimelinesReport';
import ChartComponent from '../../components/PieChart';

const TotalCost = () => {
  const [startDate, setStartDate] = useState('2024-07-01');
  const [endDate, setEndDate] = useState('');
  const [responseData, setResponseData] = useState<any>();
  const [responseAppsData, setResponseAppsData] = useState<any>();
  const [loading, isloading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [applicationData, setApplicationData] = useState<any>();
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [showDate, setShowDate] = useState({
    startDate: '',
    endDate: '',
  });
  const [validationError, setValidationError] = useState<string | null>(null);
  const [selectedChartType, setSelectedChartType] =
    useState<string>('CostPieChart');
  const [selectedFilterType, setSelectedFilterType] =
    useState<string>('Engine');
  const [selectedChartTypeForProject, setSelectedChartTypeForProject] =
    useState<string>('ActivitiesPieChart');
  const formatDate = (date: string) => {
    const [yyyy, mm, dd] = date.split('-');
    return `${dd}-${mm}-${yyyy}`;
  };
  const maxDate = new Date().toISOString().split('T')[0];
  const currentDate = formatDate(maxDate);
  const initialDate = '2024-07-01';
  const formatedInitialDate = formatDate(initialDate);
  const Initialparams = {
    start_date: formatedInitialDate,
    project_id: undefined,
    end_date: currentDate,
  };
  const toggleRowExpansion = (index: number) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };
  // const handleSelectChange = (event: any) => {
  //   setSelectedProject(event.target.value);
  //   setStartDate(initialDate);
  //   setEndDate('');
  // };
  const submitValues = async (params: {
    start_date: string;
    project_id: string | undefined;
    end_date: string;
  }) => {
    isloading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/activity/dashboard/summary`,
        { params }
      );
      if (response.status === 200 || response.status === 201) {
        isloading(false);
        const data = response.data;
        let filteredResponseData = data?.project_cost?.filter(
          (item: any) => item.projectId !== null
        );
        filteredResponseData =
          selectedChartType === 'ActivitiesPieChart'
            ? filteredResponseData?.sort(
                (a: any, b: any) => b.activity_count - a.activity_count
              )
            : filteredResponseData?.sort(
                (a: any, b: any) => b.totalCost - a.totalCost
              );
        let filteredResponseAppsData = data?.apps_cost?.filter(
          (item: any) => item.applicationId !== null
        );
        filteredResponseAppsData =
          selectedChartType === 'ActivitiesPieChart'
            ? filteredResponseAppsData?.sort(
                (a: any, b: any) => b.activity_count - a.activity_count
              )
            : filteredResponseAppsData?.sort(
                (a: any, b: any) => b.totalCost - a.totalCost
              );
        setResponseData(filteredResponseData);
        setResponseAppsData(filteredResponseAppsData);
        setShowDate({
          startDate: params.start_date,
          endDate: params.end_date,
        });
      } else {
        isloading(false);
      }
    } catch (error: any) {
      console.error(
        'Error fetching projects:',
        error?.response?.data || error?.message
      );
      const err = error?.response?.data?.error;
    }
  };
  const handleOptionChange = (event: any) => {
    const filteredResponseData =
      event?.target.value === 'ActivitiesPieChart'
        ? responseData?.sort(
            (a: any, b: any) => b.activity_count - a.activity_count
          )
        : responseData?.sort((a: any, b: any) => b.totalCost - a.totalCost);

    const filteredResponseAppsData =
      event?.target.value === 'ActivitiesPieChart'
        ? responseAppsData?.sort(
            (a: any, b: any) => b.activity_count - a.activity_count
          )
        : responseAppsData?.sort((a: any, b: any) => b.totalCost - a.totalCost);

    setSelectedChartType(event?.target.value);
    setResponseData(filteredResponseData);
    setResponseAppsData(filteredResponseAppsData);
  };
  const handleFilterOptionChange = (event: any) => {
    setSelectedFilterType(event?.target.value);
  };
  const handleOptionProjectChange = (event: any, projectId: any) => {
    setSelectedChartTypeForProject(event?.target.value);
  };
  useEffect(() => {
    submitValues(Initialparams);
  }, []);

  const handleClear = () => {
    setValidationError(null);
    setStartDate(initialDate);
    setEndDate('');
    submitValues(Initialparams);
    setExpandedRow(null);
  };
  const handleSubmit = () => {
    setExpandedRow(null);
    let fromDate = undefined;
    let enddate = undefined;
    if (startDate) {
      if (new Date(startDate) > new Date(endDate)) {
        setValidationError('From Date should not be greater than To Date.');
        return;
      } else {
        setValidationError(null);
      }
      isloading(true);
      fromDate = formatDate(startDate);
    }
    if (endDate) {
      enddate = formatDate(endDate);
    } else {
      enddate = currentDate;
    }
    const params = {
      start_date: fromDate ? fromDate : formatedInitialDate,
      project_id: undefined,
      end_date: enddate ? enddate : currentDate,
    };
    submitValues(params);
  };
  const handleRowExpansion = (index: number, projectId: number) => {
    toggleRowExpansion(index);
    getApplicationDetails(projectId, 'ActivitiesDonutChart');
  };
  const getApplicationDetails = async (
    projectId: any,
    selectedChartTypeForProject: any
  ) => {
    setIsDataLoading(true);
    const params = {
      start_date: startDate ? formatDate(startDate) : formatedInitialDate,
      end_date: endDate ? formatDate(endDate) : currentDate,
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/activity/project/app-info/${projectId}`,
        { params }
      );
      if (response.status === 200 || response.status === 201) {
        setIsDataLoading(false);
        const data = response.data;
        const sortedApplicationInformation =
          selectedChartTypeForProject === 'ActivitiesDonutChart' ||
          selectedChartTypeForProject === 'ActivitiesPieChart'
            ? data?.response?.applicationInformation?.sort(
                (a: any, b: any) => b.activities - a.activities
              )
            : data?.response?.applicationInformation?.sort(
                (a: any, b: any) => b.cost - a.cost
              );
        sortedApplicationInformation.activitiesArr =
          data?.response?.applicationInformation?.map(
            (response: any, index: number) => response.activities ?? 0
          );
        sortedApplicationInformation.costArr1 =
          data?.response?.applicationInformation?.map(
            (response: any, index: number) =>
              response.cost ? parseFloat(response.cost?.toFixed(2) ?? 0) : 0
          );
        sortedApplicationInformation.applicationNameArr =
          data?.response?.applicationInformation?.map(
            (response: any, index: number) => response.applicationName
          );

        setApplicationData(sortedApplicationInformation);
      } else {
        setIsDataLoading(false);
      }
    } catch (error: any) {
      console.error(
        'Error fetching application details:',
        error?.response?.data || error?.message
      );
    }
  };
  const sortedResponseData = responseData?.sort((a: any, b: any) => {
    const projectA = a.projectId;
    const projectB = b.projectId;
    return (projectB?.id || 0) - (projectA?.id || 0);
  });
  const costArr = sortedResponseData?.map((response: any, index: number) =>
    response.totalCost ? parseFloat(response.totalCost?.toFixed(2) ?? 0) : 0
  );
  const projectNameArr = sortedResponseData?.map(
    (response: any, index: number) => response.activity_count ?? 0
  );
  const nameArr = sortedResponseData?.map(
    (response: any, index: number) => response.projectname
  );
  let costAppsArr = responseAppsData?.map((response: any, index: number) =>
    response.totalCost ? parseFloat(response.totalCost?.toFixed(2) ?? 0) : 0
  );
  const projectNameAppsArr = responseAppsData?.map(
    (response: any, index: number) => response.activity_count ?? 0
  );
  const nameAppsArr = responseAppsData?.map(
    (response: any, index: number) => response.applicationName
  );


  const ChartMainOptions = useMemo(() => {
    return {
      options: {
        chart: {
          type: 'pie',
        },
        labels: nameArr,
        legend: {
          show: true,
          onItemClick: {
            toggleDataSeries: false,
          },
          onItemHover: {
            highlightDataSeries: false,
          },
        },
      },
      appsOptions: {
        labels: nameAppsArr,
        legend: {
          show: true,
          onItemClick: {
            toggleDataSeries: false,
          },
          onItemHover: {
            highlightDataSeries: false,
          },
        },
      },
      seriesForCostDonut: costArr || [0],
      seriesForActivitiesDonut: projectNameArr || [0],
      seriesForCostAppsDonut: costAppsArr || [0],
      seriesForActivitiesAppsDonut: projectNameAppsArr || [0],
    };
  }, [
    nameArr,
    nameAppsArr,
    costArr,
    projectNameArr,
    costAppsArr,
    projectNameAppsArr,
  ]);

  // console.log('applicationData:', applicationData);
  // console.log('ChartMainOptions', ChartMainOptions)
  const ChartNestOptions = useMemo(() => {
    return {
      options: {
        chart: {
          type: 'pie',
        },
        labels: applicationData?.applicationNameArr,
        legend: {
          show: true,
          onItemClick: {
            toggleDataSeries: false,
          },
          onItemHover: {
            highlightDataSeries: false,
          },
        },
      },
      seriesForCostDonut: applicationData?.costArr1 || [0],
      seriesForActivitiesDonut: applicationData?.activitiesArr || [0],
    };
  }, [applicationData]);
  // console.log('ChartNestOptions', ChartNestOptions)
  const chartConfig: {
    [key: string]: {
      [key: string]: {
        title: string;
        options: any;
        series: any;
      };
    };
  } = {
    CostPieChart: {
      Project: {
        title: 'Cost Chart: Project',
        options: ChartMainOptions.options,
        series: ChartMainOptions.seriesForCostDonut,
      },
      Engine: {
        title: 'Cost Chart: Engine',
        options: ChartMainOptions.appsOptions,
        series: ChartMainOptions.seriesForCostAppsDonut,
      },
    },
    ActivitiesPieChart: {
      Project: {
        title: 'Activities Chart: Project',
        options: ChartMainOptions.options,
        series: ChartMainOptions.seriesForActivitiesDonut,
      },
      Engine: {
        title: 'Activities Chart: Engine',
        options: ChartMainOptions.appsOptions,
        series: ChartMainOptions.seriesForActivitiesAppsDonut,
      },
    },
  };

  const chartProps = chartConfig[selectedChartType]?.[selectedFilterType];
  // console.log('chartProps:', chartProps);


  const handleDateChange = (e: any) => {
    const event = e.target.value;
    setStartDate(event);
  };
  return (
    <>
      <>
        <div className="application-page-container">
          <div className="title">Usage Dashboard</div>
          <div className="back-button-container">
            <Link to={`/`}>
              <BackIcon /> Back
            </Link>
          </div>
          <div className="single-app-container">
            <Tabs
              defaultActiveKey="UsageDashboard"
              id="uncontrolled-tab-example"
              className="application-page-container"
            >
              <Tab eventKey="UsageDashboard" title="Dashboard">
                <div>
                  <Form.Label className="Project-selector margin-top">
                    <b>Date Range</b>
                  </Form.Label>
                  <div className="split-screen">
                    <div>
                      <div>From Date</div>
                      <Form.Control
                        type="date"
                        name="datepic"
                        placeholder="Select Date"
                        value={startDate}
                        className="date-picker"
                        onChange={handleDateChange}
                        min={initialDate}
                        max={maxDate}
                      />
                    </div>
                    <div>
                      <div>To Date</div>
                      <Form.Control
                        type="date"
                        name="datepic"
                        placeholder="Select Date"
                        value={endDate ? endDate : maxDate}
                        className="date-picker"
                        // disabled={!startDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        min={startDate}
                        max={maxDate}
                      />
                    </div>
                  </div>
                  {validationError && (
                    <div className="date-error-message">{validationError}</div>
                  )}
                  {loading ? (
                    <Button className="loading-button margin-top">
                      <ContentLoader />
                    </Button>
                  ) : (
                    <Button onClick={handleSubmit} className="margin-top">
                      Submit
                    </Button>
                  )}
                  <Button
                    variant="danger"
                    className="margin-top"
                    onClick={handleClear}
                    style={{ marginLeft: '1.5rem' }}
                  >
                    Clear
                  </Button>
                  {responseData && responseData.length > 0 && (
                    <>
                      <div className="margin-top all-record-message">
                        Showing results from {showDate.startDate} to{' '}
                        {showDate.endDate}:
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginTop: 20,
                          position: 'relative',
                        }}
                      >
                        <div className="filters-container">
                          <div className="filter-show">
                            <Form.Label>Show</Form.Label>
                            <Form.Select
                              value={selectedFilterType}
                              onChange={handleFilterOptionChange}
                            >
                              <option value="Project">Project</option>
                              <option value="Engine">Engine</option>
                            </Form.Select>
                          </div>
                          <div className="filter-groupby">
                            <Form.Label>Group By</Form.Label>
                            <Form.Select
                              value={selectedChartType}
                              onChange={handleOptionChange}
                            >
                              <option value="CostPieChart">Cost</option>
                              <option value="ActivitiesPieChart">
                                Activity Count
                              </option>
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                      <>
                        {chartProps && (
                          <ChartComponent
                            title={chartProps.title}
                            options={chartProps.options}
                            series={chartProps.series}
                            loading={loading}
                          />
                        )}
                      </>
                      <Table className="margin-top" hover>
                        <thead>
                          <tr>
                            <th></th>
                            <th>Project ID</th>
                            <th>Project Name</th>
                            <th>Activity Count</th>
                            <th>Total Cost</th>
                          </tr>
                        </thead>
                        <tbody>
                          {sortedResponseData &&
                            sortedResponseData.length > 0 &&
                            sortedResponseData.map(
                              (response: any, index: number) => (
                                <>
                                  <tr
                                    onClick={() =>
                                      handleRowExpansion(
                                        index,
                                        response.projectId
                                      )
                                    }
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <td>
                                      {expandedRow === index ? (
                                        <FaChevronUp />
                                      ) : (
                                        <FaChevronDown />
                                      )}
                                    </td>
                                    <td>{response.projectId}</td>
                                    <td>{response.projectname}</td>
                                    <td>{response.activity_count}</td>
                                    <td>
                                      {' '}
                                      $
                                      {response.totalCost
                                        ? parseFloat(
                                            response.totalCost?.toFixed(2) ??
                                              '0'
                                          )
                                        : '0'}
                                    </td>
                                  </tr>
                                  {expandedRow === index &&
                                    (isDataLoading ? (
                                      <tr className="expanded-row">
                                        <td
                                          colSpan={5}
                                          className="expanded-row"
                                        >
                                          <ContentLoader />
                                        </td>
                                      </tr>
                                    ) : (
                                      <tr className="expanded-row">
                                        <td
                                          colSpan={5}
                                          className="expanded-row"
                                        >
                                          <div
                                            style={{
                                              display: 'flex',
                                              flexDirection: 'column',
                                              marginTop: 20,
                                            }}
                                          >
                                            <>
                                              <Form.Label>Group by</Form.Label>
                                              <Form.Select
                                                value={
                                                  selectedChartTypeForProject
                                                }
                                                onChange={(event) =>
                                                  handleOptionProjectChange(
                                                    event,
                                                    response.projectId
                                                  )
                                                }
                                              >
                                                <option value="CostPieChart">
                                                  Cost
                                                </option>
                                                <option value="ActivitiesPieChart">
                                                  Activity Count
                                                </option>
                                              </Form.Select>
                                            </>
                                            {selectedChartTypeForProject ===
                                              'CostPieChart' &&
                                              applicationData && (
                                                <>
                                                  <ChartComponent
                                                    options={
                                                      ChartNestOptions.options
                                                    }
                                                    series={
                                                      ChartNestOptions.seriesForCostDonut
                                                    }
                                                    title="Cost"
                                                    loading={loading}
                                                  />
                                                </>
                                              )}
                                            {selectedChartTypeForProject ===
                                              'ActivitiesPieChart' &&
                                              applicationData && (
                                                <>
                                                  <ChartComponent
                                                    options={
                                                      ChartNestOptions.options
                                                    }
                                                    series={
                                                      ChartNestOptions.seriesForActivitiesDonut
                                                    }
                                                    title="Activities"
                                                    loading={loading}
                                                  />
                                                </>
                                              )}
                                          </div>
                                          <Table
                                            style={{ borderColor: '#ededed' }}
                                            hover
                                            className="applications-table"
                                          >
                                            <thead>
                                              <tr>
                                                <th>Application Name</th>
                                                <th>Application Activites</th>
                                                <th>Cost</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {applicationData &&
                                                applicationData?.length > 0 &&
                                                applicationData.map(
                                                  (app: any) => (
                                                    <tr>
                                                      <td>
                                                        {app.applicationName}
                                                      </td>
                                                      <td>{app.activities}</td>
                                                      <td>
                                                        {' '}
                                                        $
                                                        {app.cost
                                                          ? parseFloat(
                                                              app.cost?.toFixed(
                                                                2
                                                              ) ?? '0'
                                                            )
                                                          : '0'}
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                            </tbody>
                                          </Table>
                                        </td>
                                      </tr>
                                    ))}
                                </>
                              )
                            )}
                        </tbody>
                      </Table>
                    </>
                  )}
                </div>
              </Tab>
              <Tab eventKey="Timelines" title="Timelines">
                <TimelinesReport />
              </Tab>
            </Tabs>
          </div>
        </div>
      </>
    </>
  );
};
export default TotalCost;
