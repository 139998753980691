import { CODE_QNA } from './constants';

interface Chat {
  role: string;
  content: string;
}

type SetStateFunction<T> = React.Dispatch<React.SetStateAction<T>>;
const stream = ({
  requestBody,
  streamCallback,
  doneCallback,
  setShowToast,
  setIsLoading,
  setShowErrMsg,
  llmCloud,
  selectedLlmModel,
  setActivityId,
  activityId,
  signal,
  url,
  currentApp,
}: {
  requestBody: any;
  streamCallback: Function;
  doneCallback?: Function;
  setShowToast?: any;
  setIsLoading: any;
  setShowErrMsg?: SetStateFunction<string>;
  llmCloud?: string;
  selectedLlmModel?: string;
  setActivityId?: any;
  activityId?: string;
  signal?: AbortSignal;
  url?: string;
  currentApp?: any;
}) => {
  const token = sessionStorage.getItem('jwtToken');

  let streamingUrl = '';
  setActivityId('');
  if (url) {
    streamingUrl = url;
  } else {
    if (
      selectedLlmModel === 'mistral:7b-instruct' ||
      selectedLlmModel === 'llama2:7b-chat' ||
      selectedLlmModel === 'llama2:7b-chat-ft-medicaid-fed-20240201'
    ) {
      streamingUrl = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/submit/prompt/streaming/private-llm`;
    } else if (llmCloud === 'gcp') {
      if (requestBody?.advance_search_params) {
        streamingUrl = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/gcp/submit/prompt/streaming/advance/cached`;
      } else if (requestBody?.messages?.length) {
        streamingUrl = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/gcp/submit/prompt/streaming/messages`;
      } else {
        streamingUrl = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/gcp/submit/prompt/streaming`;
      }
    } else if (llmCloud === 'aws') {
      if (requestBody?.advance_search_params) {
        streamingUrl = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/aws/submit/prompt/streaming/advance/cached`;
      } else if (requestBody?.messages?.length) {
        streamingUrl = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/aws/submit/prompt/streaming/messages`;
      } else {
        streamingUrl = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/aws/submit/prompt/streaming`;
      }
    } else if (llmCloud === 'azure') {
      if (requestBody?.advance_search_params) {
        streamingUrl = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/submit/prompt/streaming/advance/cached`;
      } else if (requestBody?.messages?.length) {
        streamingUrl = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/submit/prompt/streaming/messages`;
      } else {
        streamingUrl = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/submit/prompt/streaming`;
      }
    } else if (llmCloud === 'cfg_ai') {
      streamingUrl = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/submit/cfg/prompt/streaming/private-llm`;
    }
  }

  if (requestBody?.messages?.length) {
    requestBody.messages = requestBody?.messages?.filter(
      (message: Chat, index: number) =>
        index === 0 || requestBody.messages.length - index < 4
    );
  }
  const isFormData = requestBody instanceof FormData;
  let headers: any = {
    accept: 'application/json',
    Authorization: `Bearer ${token}`,
  };

  if (!isFormData) {
    if (currentApp === CODE_QNA) {
      headers = {
        ...headers,
        'Content-Type': 'application/x-www-form-urlencoded',
      };
    } else {
      headers = {
        ...headers,
        'Content-Type': 'application/json',
      };
    }
  }

  fetch(streamingUrl, {
    method: 'POST',
    headers: headers,
    body:
      isFormData || currentApp === CODE_QNA
        ? requestBody
        : JSON.stringify(requestBody),
    signal: signal,
  })
    .then((response) => {
      if (response.ok) {
        const reader = response?.body?.getReader();
        setActivityId((response?.headers as any)?.get('x-activityid'));
        const read = () => {
          reader
            ?.read()
            .then(({ done, value }) => {
              if (done) {
                if (doneCallback) {
                  doneCallback();
                }
                return;
              }

              const decoder = new TextDecoder();
              streamCallback(decoder.decode(value));
              read();
            })
            .catch((error) => {
              if (error.name === 'AbortError') {
                console.error('Fetch aborted');
              } else {
                console.error('Fetch error:', error);
              }
            });
        };

        read();
      } else {
        setShowToast(true);
        setShowErrMsg &&
          setShowErrMsg('There is a internal server error. Please try again.');
        setIsLoading(false);
      }
    })
    .catch((err) => {
      setShowToast(true);
      setIsLoading(false);
      console.error('err==>', err);
    });
};

export default stream;
