import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Nav, Tab, Form } from 'react-bootstrap';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import './CopilotNoUIV2.css';

interface SettingsProps {
  onInstanceIdChange: (id: string) => void;
  handleSuggestionEnable: (isSuggestion: boolean) => void;
  configurButton: (data: configureButton[]) => void;
  configurAction: (data: configureAction[]) => void;
  configurConnectToOrgData: (data: configureConnectToOrg) => void;
  className?: string;
  generateLogs: boolean;
  onGenerateLogs: () => void;
}

interface configureButton {
  Label: string;
  Alignment: string;
  Endpoint: string;
  Method: string;
  Message: string;
}

interface configureAction {
  EngineName: string;
  Endpoint: string;
  Method: string;
}

interface configureConnectToOrg {
  endpointURL: string;
  userName: string;
  password: string;
  grantType: string;
  clientId: string;
  clientSecret: string;
}

const Settings: React.FC<SettingsProps> = ({
  onInstanceIdChange,
  handleSuggestionEnable,
  configurButton,
  configurAction,
  configurConnectToOrgData,
  className,
  generateLogs,
  onGenerateLogs,
}) => {
  const [modalstatus, setModalstatus] = useState(false);
  const [copilotInstanceId, SetcopilotInstanceId] = useState('');
  const [configurationFlag, SetConfigurationFlag] =
    useState('Salesforce_Setup');
  const [selectedTab, setSelectedTab] = useState('Salesforce_Setup');
  // const [savedHtml, setSavedHtml] = useState<string>('');
  // const [textareaContent, setTextareaContent] = useState('');
  const [isSuggestiveResponseEnable, setIsSuggestiveResponseEnable] =
    useState(true);
  const alignment = ['bottom-right', 'bottom-left', 'top-right', 'top-left'];
  const apiMethods = ['POST', 'GET', 'DELETE', 'PUT'];
  const [configursButtonData, setConfigursButtonData] = useState<
    configureButton[]
  >([]);
  const [configureAction, setConfigureAction] = useState<configureAction[]>([]);

  const [configureConnectToOrg, setConfigureConnectToOrg] =
    useState<configureConnectToOrg>({
      endpointURL: 'https://test.salesforce.com/services/oauth2/token',
      userName: 'copilot-noui@csdemoorg.com',
      password: 'Password00sR2XlEGnLfRvWZxlERpfZ3vS',
      grantType: 'password',
      clientId:
        '3MVG9ETm0tIcsfrxmQfVpo8vyt8bF8SAIKVEt6HRM5nVNm90oDH0h39DFtJuFHyGqBWnJ.TK6aCmEWnJHZuat',
      clientSecret:
        '30C14A44E1E0D532443F2671AD4DC07128C51401DCEFA0C3BE8FA8419A2D927F',
    });

  useEffect(() => {
    const storedActionConfig = sessionStorage.getItem('actionConfig');
    if (storedActionConfig) {
      setConfigureAction(JSON.parse(storedActionConfig));
    }

    const storedButtonConfig = sessionStorage.getItem('buttonConfig');
    if (storedButtonConfig) {
      setConfigursButtonData(JSON.parse(storedButtonConfig));
    }

    sessionStorage.setItem(
      'connectToOrgConfig',
      JSON.stringify(configureConnectToOrg)
    );

    // const storedConnectToOrgConfig = sessionStorage.getItem('connectToOrgConfig');
    // if (storedConnectToOrgConfig) {
    //   setConfigureConnectToOrg(JSON.parse(storedConnectToOrgConfig));
    // }

    const storedInstanceId = sessionStorage.getItem('copilotInstanceId');
    if (storedInstanceId) {
      SetcopilotInstanceId(storedInstanceId);
      onInstanceIdChange(storedInstanceId);
    }
    handleSuggestionEnable(true);
  }, []);

  const closeModel = async () => {
    setModalstatus(false);
  };

  const openModal = async () => {
    setModalstatus(true);
  };

  const changeSalesforceConfiguration = async () => {
    SetConfigurationFlag('Salesforce_Setup');
    setSelectedTab('Salesforce_Setup');
  };

  const changeCopilotConfiguration = async () => {
    SetConfigurationFlag('Copilot_Setup');
    setSelectedTab('Copilot_Setup');
  };

  const changeAIConfiguration = async () => {
    SetConfigurationFlag('AI_Setup');
    setSelectedTab('AI_Setup');
  };

  const changeButtonConfiguration = async () => {
    SetConfigurationFlag('Configure_Button_Setup');
    setSelectedTab('Configure_Button_Setup');
  };

  const changeConfigureAction = async () => {
    SetConfigurationFlag('Configure_Action');
    setSelectedTab('Configure_Action');
  };

  const changeCopilotInstanceId = async (event: any) => {
    SetcopilotInstanceId(event.target.value);
    onInstanceIdChange(event.target.value);
  };

  const saveCopilotInstanceId = () => {
    if (copilotInstanceId === '') {
      toast.error('Please enter Instance Id from Copilot', {
        onClick: () => toast.dismiss(),
        autoClose: 1000,
      });
    } else {
      setModalstatus(false);
    }
  };

  const handleSuggestiveReponse = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsSuggestiveResponseEnable(event.target.checked);
    handleSuggestionEnable(event.target.checked);
  };

  const addConfigursButton = () => {
    const newConfigursButton: configureButton = {
      Label: '',
      Alignment: '',
      Endpoint: '',
      Method: '',
      Message: '',
    };
    // Use spread operator to create a new array with the added object
    setConfigursButtonData([...configursButtonData, newConfigursButton]);
    configurButton(configursButtonData);
  };

  const addConfigureAction = () => {
    const newConfigureAction: configureAction = {
      EngineName: '',
      Endpoint: '',
      Method: '',
    };
    // Use spread operator to create a new array with the added object
    setConfigureAction([...configureAction, newConfigureAction]);
    configurAction(configureAction);
  };

  // const addConfigureConnectToOrg = () => {
  //   const newConfigureConnectToOrg: configureConnectToOrg = {
  //     endpointURL: '',
  //     userName: '',
  //     password: '',
  //     grantType: '',
  //     clientId: '',
  //     clientSecret: '',
  //   };
  //   setConfigureConnectToOrg([...configureConnectToOrg, newConfigureConnectToOrg]);
  //   configurConnectToOrg(configureConnectToOrg);
  // };

  const addConfigureConnectToOrg = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string
  ) => {
    setConfigureConnectToOrg((prevConfigureConnectToOrg) => {
      const updatedConfigureConnectToOrg = {
        ...prevConfigureConnectToOrg,
        [name]: e.target.value,
      };
//       console.log(
//         'Configure data connect to org check',
//         JSON.stringify(updatedConfigureConnectToOrg)
      // );
      configurConnectToOrgData(updatedConfigureConnectToOrg);
      return updatedConfigureConnectToOrg;
    });
  };

  const saveConfigureAction = () => {
    sessionStorage.setItem('actionConfig', JSON.stringify(configureAction));
    closeModel();
  };

  const saveConfigureButtonData = () => {
    sessionStorage.setItem('buttonConfig', JSON.stringify(configursButtonData));
    closeModel();
  };

  const saveConfigureConnectToOrg = () => {
    sessionStorage.setItem(
      'connectToOrgConfig',
      JSON.stringify(configureConnectToOrg)
    );
    closeModel();
  };

  return (
    <div>
      <FontAwesomeIcon
        icon={faGear}
        className={className}
        onClick={openModal}
      />
      {/* {savedHtml && (
        <div
          className="saved-html-content"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(savedHtml) }}
        />
      )} */}
      <Modal show={modalstatus} onHide={closeModel} centered>
        <Modal.Header closeButton>
          <Modal.Title>Setup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container setup">
            <div className="row gutters-sm">
              <div className="col-md-4">
                <div className="fixed-nav-container">
                  <div className="nav-fit">
                    <nav className="nav flex-column nav-pills nav-gap-y-1">
                      <a
                        data-toggle="tab"
                        className={`nav-item nav-link has-icon nav-link-faded ${selectedTab === 'Configure_Action' ? 'active' : ''}`}
                        onClick={changeConfigureAction}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-settings mr-2 icon-space"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="3"
                            onClick={changeCopilotConfiguration}
                          ></circle>
                          <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                        </svg>
                        <span
                          className="nav-name"
                          style={{ marginLeft: '5px' }}
                        >
                          Configure Action
                        </span>
                      </a>
                      <a
                        data-toggle="tab"
                        className={`nav-item nav-link has-icon nav-link-faded ${selectedTab === 'Configure_Button_Setup' ? 'active' : ''}`}
                        onClick={changeButtonConfiguration}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-settings mr-2 icon-space"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="3"
                            onClick={changeCopilotConfiguration}
                          ></circle>
                          <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                        </svg>
                        <span
                          className="nav-name"
                          style={{ marginLeft: '5px' }}
                        >
                          Configure Button
                        </span>
                      </a>
                      <a
                        data-toggle="tab"
                        className={`nav-item nav-link has-icon nav-link-faded ${selectedTab === 'Salesforce_Setup' ? 'active' : ''}`}
                        onClick={changeSalesforceConfiguration}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-user mr-2 icon-space"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                          <circle
                            cx="12"
                            cy="7"
                            r="4"
                            onClick={changeSalesforceConfiguration}
                          ></circle>
                        </svg>
                        <span
                          className="nav-name"
                          style={{ marginLeft: '5px' }}
                        >
                          Connect to an Org
                        </span>
                      </a>
                      <a
                        data-toggle="tab"
                        className={`nav-item nav-link has-icon nav-link-faded ${selectedTab === 'Copilot_Setup' ? 'active' : ''}`}
                        onClick={changeCopilotConfiguration}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-settings mr-2 icon-space"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="3"
                            onClick={changeCopilotConfiguration}
                          ></circle>
                          <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                        </svg>
                        <span
                          className="nav-name"
                          style={{ marginLeft: '5px' }}
                        >
                          Copilot Setup
                        </span>
                      </a>
                      <a
                        data-toggle="tab"
                        className={`nav-item nav-link has-icon nav-link-faded ${selectedTab === 'AI_Setup' ? 'active' : ''}`}
                        onClick={changeAIConfiguration}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-settings mr-2 icon-space"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="3"
                            onClick={changeCopilotConfiguration}
                          ></circle>
                          <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                        </svg>
                        <span
                          className="nav-name"
                          style={{ marginLeft: '5px' }}
                        >
                          Extra Settings
                        </span>
                      </a>
                    </nav>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                {/* <div className="card"> */}
                <div>
                  {/* <div className="card-body tab-content"> */}
                  <div>
                    {configurationFlag === 'Configure_Action' && (
                      <div>
                        <h6>Configure Action Setup</h6>
                        <hr />
                        <span
                          className="action-item plus-icon my-2"
                          onClick={() => addConfigureAction()}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </span>
                        <div className="my-2">
                          <Tab.Container id="label-tabs" defaultActiveKey={0}>
                            <Nav variant="tabs">
                              {configureAction.map((action, index) => (
                                <Nav.Item key={index}>
                                  <Nav.Link eventKey={index}>
                                    {action.EngineName !== ''
                                      ? action.EngineName
                                      : 'Engine Name'}
                                  </Nav.Link>
                                </Nav.Item>
                              ))}
                            </Nav>
                            <Tab.Content className="w-100">
                              {configureAction.map((Action, index) => (
                                <Tab.Pane key={index} eventKey={index}>
                                  <Form>
                                    <div className="form-group my-2">
                                      <label>Engine Name</label>
                                      <input
                                        type="text"
                                        className="form-control my-2"
                                        value={Action.EngineName}
                                        onChange={(e) => {
                                          const newValue = e.target.value;
                                          setConfigureAction((prevState) => {
                                            const newData = [...prevState];
                                            newData[index].EngineName =
                                              newValue;
                                            return newData;
                                          });
                                          configurAction(configureAction);
                                        }}
                                      />
                                    </div>
                                    <div className="form-group my-2">
                                      <label>Endpoint</label>
                                      <input
                                        type="text"
                                        className="form-control my-2"
                                        value={Action.Endpoint}
                                        onChange={(e) => {
                                          const newValue = e.target.value;
                                          setConfigureAction((prevState) => {
                                            const newData = [...prevState];
                                            newData[index].Endpoint = newValue;
                                            return newData;
                                          });
                                          configurAction(configureAction);
                                        }}
                                      />
                                    </div>
                                    <div className="form-group my-2">
                                      <label>Method</label>
                                      <Form.Select
                                        aria-label="Select Method"
                                        className="my-2 form-style"
                                        value={Action.Method}
                                        onChange={(e) => {
                                          const newValue = e.target.value;
                                          setConfigureAction((prevState) => {
                                            const newData = [...prevState];
                                            newData[index].Method = newValue;
                                            return newData;
                                          });
                                          configurAction(configureAction);
                                        }}
                                      >
                                        <option>Select Method</option>
                                        {apiMethods.map((data, optionIndex) => (
                                          <option
                                            key={optionIndex}
                                            value={data}
                                          >
                                            {data}
                                          </option>
                                        ))}
                                      </Form.Select>
                                    </div>
                                  </Form>
                                </Tab.Pane>
                              ))}
                            </Tab.Content>
                          </Tab.Container>
                        </div>
                        {configureAction.length > 0 && (
                          <div className="form-group my-2">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={saveConfigureAction}
                            >
                              Save
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                    {configurationFlag === 'Configure_Button_Setup' && (
                      <div>
                        <h6>Configure Button Setup</h6>
                        <hr />
                        <span
                          className="action-item plus-icon my-2"
                          onClick={() => addConfigursButton()}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </span>
                        <div className="my-2">
                          <Tab.Container id="label-tabs" defaultActiveKey={0}>
                            <Nav variant="tabs">
                              {configursButtonData.map((button, index) => (
                                <Nav.Item key={index}>
                                  <Nav.Link eventKey={index}>
                                    {button.Label !== ''
                                      ? button.Label
                                      : 'Label'}
                                  </Nav.Link>
                                </Nav.Item>
                              ))}
                            </Nav>
                            <Tab.Content className="w-100">
                              {configursButtonData.map((button, index) => (
                                <Tab.Pane key={index} eventKey={index}>
                                  <Form>
                                    <div className="form-group my-2">
                                      <label>Label</label>
                                      <input
                                        type="text"
                                        className="form-control my-2"
                                        value={button.Label}
                                        onChange={(e) => {
                                          const newValue = e.target.value;
                                          setConfigursButtonData(
                                            (prevState) => {
                                              const newData = [...prevState];
                                              newData[index].Label = newValue;
                                              return newData;
                                            }
                                          );
                                          configurButton(configursButtonData);
                                        }}
                                      />
                                    </div>
                                    <div className="form-group my-2">
                                      <label>Alignment</label>
                                      <Form.Select
                                        aria-label="Select alignment"
                                        className="my-2 form-style"
                                        value={button.Alignment}
                                        onChange={(e) => {
                                          const newValue = e.target.value;
                                          setConfigursButtonData(
                                            (prevState) => {
                                              const newData = [...prevState];
                                              newData[index].Alignment =
                                                newValue;
                                              return newData;
                                            }
                                          );
                                          configurButton(configursButtonData);
                                        }}
                                      >
                                        <option>Select alignment</option>
                                        {alignment.map((data, optionIndex) => (
                                          <option
                                            key={optionIndex}
                                            value={data}
                                          >
                                            {data}
                                          </option>
                                        ))}
                                      </Form.Select>
                                    </div>
                                    <div className="form-group my-2">
                                      <label>Endpoint</label>
                                      <input
                                        type="text"
                                        className="form-control my-2"
                                        value={button.Endpoint}
                                        onChange={(e) => {
                                          const newValue = e.target.value;
                                          setConfigursButtonData(
                                            (prevState) => {
                                              const newData = [...prevState];
                                              newData[index].Endpoint =
                                                newValue;
                                              return newData;
                                            }
                                          );
                                          configurButton(configursButtonData);
                                        }}
                                      />
                                    </div>
                                    <div className="form-group my-2">
                                      <label>Method</label>
                                      <Form.Select
                                        aria-label="Select Method"
                                        className="my-2 form-style"
                                        value={button.Method}
                                        onChange={(e) => {
                                          const newValue = e.target.value;
                                          setConfigursButtonData(
                                            (prevState) => {
                                              const newData = [...prevState];
                                              newData[index].Method = newValue;
                                              return newData;
                                            }
                                          );
                                          configurButton(configursButtonData);
                                        }}
                                      >
                                        <option>Select Method</option>
                                        {apiMethods.map((data, optionIndex) => (
                                          <option
                                            key={optionIndex}
                                            value={data}
                                          >
                                            {data}
                                          </option>
                                        ))}
                                      </Form.Select>
                                    </div>
                                    <div className="form-group my-2">
                                      <label>Message</label>
                                      <input
                                        type="text"
                                        className="form-control my-2"
                                        value={button.Message}
                                        onChange={(e) => {
                                          const newValue = e.target.value;
                                          setConfigursButtonData(
                                            (prevState) => {
                                              const newData = [...prevState];
                                              newData[index].Message = newValue;
                                              return newData;
                                            }
                                          );
                                          configurButton(configursButtonData);
                                        }}
                                      />
                                    </div>
                                  </Form>
                                </Tab.Pane>
                              ))}
                            </Tab.Content>
                          </Tab.Container>
                        </div>
                        {configursButtonData.length > 0 && (
                          <div className="form-group my-2">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={saveConfigureButtonData}
                            >
                              Save
                            </button>
                            {/* <button type="reset" className="btn btn-light">Reset</button> */}
                          </div>
                        )}
                      </div>
                    )}
                    {configurationFlag === 'Salesforce_Setup' && (
                      <div>
                        <h6>Connect to an Org</h6>
                        <hr />
                        <form>
                          <div className="form-group">
                            <label>URL</label>
                            <input
                              type="text"
                              className="form-control"
                              value={configureConnectToOrg.endpointURL}
                              onChange={(e) =>
                                addConfigureConnectToOrg(e, 'endpointURL')
                              }
                            />
                          </div>
                          <div className="form-group">
                            <label>UserName</label>
                            <input
                              type="text"
                              className="form-control"
                              value={configureConnectToOrg.userName}
                              onChange={(e) =>
                                addConfigureConnectToOrg(e, 'userName')
                              }
                            />
                          </div>
                          <div className="form-group">
                            <label>Password</label>
                            <input
                              type="text"
                              className="form-control"
                              value={configureConnectToOrg.password}
                              onChange={(e) =>
                                addConfigureConnectToOrg(e, 'password')
                              }
                            />
                          </div>
                          <div className="form-group">
                            <label>Grant Type</label>
                            <input
                              type="text"
                              className="form-control"
                              value={configureConnectToOrg.grantType}
                              onChange={(e) =>
                                addConfigureConnectToOrg(e, 'grantType')
                              }
                            />
                            <small>
                              Your name may appear around here where you are
                              mentioned. You can change or remove it at any
                              time.
                            </small>
                          </div>
                          <div className="form-group">
                            <label>Client Id</label>
                            <textarea
                              className="form-control"
                              rows={4}
                              value={configureConnectToOrg.clientId}
                              onChange={(e) =>
                                addConfigureConnectToOrg(e, 'clientId')
                              }
                            ></textarea>
                          </div>
                          <div className="form-group">
                            <label>Client Secret</label>
                            <textarea
                              className="form-control"
                              rows={4}
                              value={configureConnectToOrg.clientSecret}
                              onChange={(e) =>
                                addConfigureConnectToOrg(e, 'clientSecret')
                              }
                            ></textarea>
                          </div>
                          <div className="form-group">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={saveConfigureConnectToOrg}
                            >
                              Save
                            </button>
                            <button type="reset" className="btn btn-light">
                              Reset
                            </button>
                          </div>
                        </form>
                      </div>
                    )}
                    {configurationFlag === 'Copilot_Setup' && (
                      <div>
                        <h6>GENAIE Configuration</h6>
                        <hr />
                        <form>
                          <div className="form-group">
                            <label>Copilot Instance ID</label>
                            <input
                              type="text"
                              className="form-control"
                              value={copilotInstanceId}
                              placeholder="Please Enter Copilot Instance Id"
                              onChange={changeCopilotInstanceId}
                            />
                          </div>
                          <hr />
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={saveCopilotInstanceId}
                          >
                            Save
                          </button>
                        </form>
                      </div>
                    )}
                    {configurationFlag === 'AI_Setup' && (
                      <div>
                        <h6>Extra Settings Setup</h6>
                        <hr />
                        <form>
                          <div>
                            <strong> Enable Suggestive Response </strong>
                            <div>
                              <input
                                type="checkbox"
                                checked={isSuggestiveResponseEnable}
                                onChange={handleSuggestiveReponse}
                              />
                            </div>
                          </div>
                          <div>
                            <strong> Enable Generate Logs </strong>
                            <div>
                              <input
                                type="checkbox"
                                id="generateLogs"
                                checked={generateLogs}
                                onChange={onGenerateLogs}
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Settings;
