import axios from 'axios';

const requestInterceptor = async (config: any) => {
  const token = sessionStorage.getItem('jwtToken');
  const username = sessionStorage.getItem('user');

  if (token && username) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

const responseInterceptor = async (error: {
  response: { status: any };
  message: any;
}) => {
  console.error('Error in Axios response:', error);

  if (error.response) {
    const { status } = error.response;
    if (status === 401 || status === 403) {
      window.location.href = '/403';
    } else {
      console.error('API Error:', error.response);
    }
  } else {
    console.error('Request Error:', error.message);
  }
  return Promise.reject(error);
};

axios.interceptors.request.use(requestInterceptor);
axios.interceptors.response.use(null, responseInterceptor);
