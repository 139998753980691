import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Badge, Form, Modal, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { SetStateFunction } from '../../types';
import './ActivityStatusDropDown.css';
import UserContext from '../../context/UserContext';
import { IoMdRefresh } from 'react-icons/io';
import {
  CHANGE_COMMUNICATOR,
  CODE_DOCUMENTATION,
  MEETING_SUMMARIZER,
  NOTICE_ANALYSIS_ENGINE,
  TEST_SCRIPT_GENERATOR,
  USER_STORY_GENERATOR,
  METADATA_GENERATOR,
} from '../../utils/constants';
type ActivityStatusDropDownProps = {
  activityResponse: any;
  setIsDisabled?: SetStateFunction<boolean>;
  activityType?: string;
  setMeetingSummarizerUserInput?: any;
  onActivitySelect?: any;
  setActiveActivityId?: SetStateFunction<string>;
  setOutputData?: SetStateFunction<string>;
  isAssistant?: any;
  isC2D?: boolean;
  isMeetingSummarizerOutput?: boolean;
};
const ActivityStatusDropDown: React.FC<ActivityStatusDropDownProps> = ({
  activityResponse,
  setIsDisabled,
  activityType,
  setMeetingSummarizerUserInput,
  onActivitySelect,
  setActiveActivityId,
  setOutputData,
  isAssistant,
  isMeetingSummarizerOutput,
  isC2D,
}) => {
  const [activityStatus, setActivityStatus] = useState<any>({});
  const [activeId, setActiveId] = useState<string>('');
  const [showStatus, setShowStatus] = useState<boolean>(false);
  const [selectActivity, setSelectActivity] = useState<string>('');
  const [newSelectedActivity, setNewSelectedActivity] = useState<string>('');
  const [listActivity, setListActivity] = useState<any>([]);
  const [downloadResponseLink, setDownloadResponseLink] = useState<string>('');
  const [isDownload, setIsDownload] = useState<boolean>(!isC2D ? true : false);
  const [showClearChatWarning, setShowClearChatWarning] =
    useState<boolean>(false);
  const { projectId } = useParams();
  const userContext = useContext(UserContext) || {
    userInput: '',
    setUserInput: () => {},
    isActivityActive: false,
    setIsActivityActive: () => {},
    setIsRefreshing: () => {},
    isRefreshing: true,
    setShowToast: () => {},
    llmCloud: '',
  };

  const {
    setUserInput,
    isActivityActive,
    setIsActivityActive,
    setIsRefreshing,
    setShowToast,
    isRefreshing,
    llmCloud,
  } = userContext;

  const fetchActivity = async () => {
    const queryParams = {
      project_id: projectId,
      type: activityType,
    };
    let api;
    const allActivityApi = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/activity/all`;
    const metadataActivityApi = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/visionocr/ocr-list-activities`;
    api =
      activityType === METADATA_GENERATOR
        ? metadataActivityApi
        : allActivityApi;
    const fetchresponse = await axios.get(api, {
      params: queryParams,
    });
    const data = await fetchresponse.data;
    if (activityType === METADATA_GENERATOR) {
      setListActivity(data);
      // const filteredEdges = listActivity?.filter(
      //   (data: any) =>
      //     data?.metaData &&
      //     Array.isArray(data?.metaData) &&
      //     data?.metaData !== null &&
      //     data?.metaData.some((meta: any) => Object.keys(meta).length > 0)
      // );
      const res = data.slice(-1);
      setSelectActivity(res[0]?.id);
      onActivitySelect(res[0]?.id);
    } else {
      setListActivity(data.response.edges);
      const filteredEdges = data.response?.edges?.filter(
        (data: any) =>
          data?.node?.metaData &&
          Array.isArray(data?.node?.metaData) &&
          data?.node?.metaData !== null &&
          data?.node?.metaData.some((meta: any) => Object.keys(meta).length > 0)
      );
      const res = filteredEdges.slice(-1);
      setSelectActivity(res[0]?.node?.id);
      onActivitySelect(res[0]?.node?.id);
    }
  };

  useEffect(() => {
    if (activityType) {
      fetchActivity();
    }
  }, [activityResponse]);

  useEffect(() => {
    if (selectActivity) {
      setIsRefreshing(true);
    }
  }, [selectActivity]);

  const handleCheckActivity = (value: string) => {
    if (activityType === MEETING_SUMMARIZER && isMeetingSummarizerOutput) {
      setNewSelectedActivity(value);
      setShowClearChatWarning(true);
    } else {
      setShowClearChatWarning(false);
      setSelectActivity(value);
      onActivitySelect(value);
      setShowStatus(false);
    }
  };

  useEffect(() => {
    if (selectActivity && listActivity.length > 0) {
      let selectedActId;
      if (activityType === METADATA_GENERATOR) {
        selectedActId = listActivity.find(
          (data: any) => data?.id === selectActivity
        );
      } else {
        selectedActId = listActivity.find(
          (data: any) => data?.node?.id === selectActivity
        );
      }
      if (selectedActId) {
        getActivityStatus(selectedActId);
      }
    }
  }, [selectActivity, isActivityActive]);

  const getActivityStatus = async (actId: any) => {
    try {
      if (setOutputData) {
        setOutputData('');
      }
      setIsRefreshing(true);
      setDownloadResponseLink('');
      if (setIsDisabled) {
        setIsDisabled(true);
      }
      setUserInput('');
      if (setMeetingSummarizerUserInput) {
        setMeetingSummarizerUserInput('');
      }
      let activityId;
      let taskId;
      if (activityType === METADATA_GENERATOR) {
        activityId = actId?.id;
        taskId = actId?.taskId;
      } else {
        activityId = actId?.node?.id;
        taskId = actId?.node?.taskId;
      }
      if (
        activityType === CODE_DOCUMENTATION ||
        activityType === METADATA_GENERATOR
      ) {
        setActiveId(activityId);
      } else setActiveId(taskId);
      const bulk_api = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/generation/bulk/process/task-by-activityid/${activityId}`;
      const notice_analysis_api = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/one-shot-chain/notice-analysis/check-activity-status?activity_id=${activityId}`;
      const meeting_summarizer_azure_api = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/submit/async/audio-file/status/${activityId}`;
      const meeting_summarizer_gcp_api = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/gcp/submit/async/audio-file/status/${activityId}`;
      const code_to_design_api = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/activity/${activityId}`;
      const metadata_api = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/visionocr/check-status-activity?activity_id=${activityId}`;
      let url = '';
      let formData: { [key: string]: any } = {};

      if (
        activityType === USER_STORY_GENERATOR ||
        activityType === TEST_SCRIPT_GENERATOR
      ) {
        url = bulk_api;
      } else if (activityType === MEETING_SUMMARIZER) {
        url =
          llmCloud === 'gcp'
            ? meeting_summarizer_gcp_api
            : meeting_summarizer_azure_api;
      } else if (
        activityType === NOTICE_ANALYSIS_ENGINE ||
        activityType === CHANGE_COMMUNICATOR
      ) {
        url = notice_analysis_api;
      } else if (activityType === CODE_DOCUMENTATION) {
        url = code_to_design_api;
      } else if (activityType === METADATA_GENERATOR) {
        url = metadata_api;
      }
      if (url) {
        let response;
        if (activityType === METADATA_GENERATOR) {
          response = await axios.post(url, {
            params: formData,
            headers: {
              'Content-Type': 'application/json',
            },
          });
        } else {
          response = await axios.get(url, {
            params: formData,
            headers: {
              'Content-Type': 'application/json',
            },
          });
        }

        if (response.status === 200) {
          setShowToast(false);
          setIsRefreshing(false);
          const newStatus = { ...activityStatus };
          if (
            activityType === CODE_DOCUMENTATION ||
            activityType === METADATA_GENERATOR
          ) {
            newStatus[activityId] = response.data?.response?.status;
            setActivityStatus(newStatus);
          } else {
            newStatus[taskId] = response.data.response.status;
          }
          let outputData;
          if (activityType === METADATA_GENERATOR) {
            outputData =
              response.data?.response?.meta_data?.[0]?.response?.response;
          } else {
            outputData = response.data?.response.output;
          }
          setDownloadResponseLink(response.data.response.download_link);
          if (activityType === CODE_DOCUMENTATION) {
            const sal_url = response.data?.response?.meta_data[0]?.sal_url;
            setDownloadResponseLink(sal_url);
          }
          if (setOutputData) {
            setOutputData(outputData);
            if (
              activityType === CHANGE_COMMUNICATOR &&
              newStatus[taskId] === 'Complete'
            ) {
              await handleDownloadProcess(actId);
            }
          }
          setActivityStatus(newStatus);
          if (setActiveActivityId) {
            setActiveActivityId(activityId);
          }
          setShowStatus(true);
          if (newStatus[taskId] === 'Complete') {
            setMeetingSummarizerUserInput &&
              setMeetingSummarizerUserInput(
                response?.data?.response?.rawTranscription
              );
            if (setIsDisabled) {
              setIsDisabled(false);
            }
          }
        }
      }
    } catch (error) {
      console.error('response after check activity', error);
      setShowStatus(false);
      if (setIsDisabled) {
        setIsDisabled(true);
      }
      setIsRefreshing(false);
    }
  };

  const handleDownloadProcess = async (actId: any) => {
    try {
      const activityId = actId?.node?.id;
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/one-shot-chain/download-response-change-comm?activity_id=${activityId}`
      );
      if (response.status === 200) {
        setShowToast(false);
        setDownloadResponseLink(response.data.response.download_link);
      } else {
        console.error('Failed to submit files');
        return null;
      }
    } catch (error) {
      console.error('response after check activity', error);
      setShowStatus(false);
      if (setIsDisabled) {
        setIsDisabled(true);
      }
      setIsRefreshing(false);
    }
  };

  const handleCancelClearChat = () => {
    setShowClearChatWarning(false);
  };

  const handleClearChat = () => {
    setShowClearChatWarning(false);
    setSelectActivity(newSelectedActivity);
    onActivitySelect(newSelectedActivity);
    setShowStatus(false);
  };

  return (
    <>
      <Form.Group className="user-input" controlId="exampleForm.instancePicker">
        {activityType === METADATA_GENERATOR ? (
          <Form.Label>Collection List</Form.Label>
        ) : (
          <Form.Label>Activity List</Form.Label>
        )}
        <Form.Select
          aria-label="Activity status"
          className="instance-picker"
          value={selectActivity}
          onChange={(evt) => {
            const selectedValue = evt.target.value;
            handleCheckActivity(selectedValue);
          }}
        >
          {listActivity && (
            <>
              {activityType === METADATA_GENERATOR
                ? listActivity
                    .slice()
                    .reverse()
                    .filter(
                      (data: any) =>
                        data?.metaData &&
                        Array.isArray(data?.metaData) &&
                        data?.metaData !== null &&
                        data?.metaData.some(
                          (meta: any) => Object.keys(meta).length > 0
                        )
                    )
                    ?.map((data: any, index: any) => {
                      const actId = data;
                      return (
                        <option key={index} value={actId?.id}>
                          {actId?.id} -{' '}
                          {actId?.metaData?.map((item: any, index: any) => (
                            <>
                              <div key={index}>{item.collection_name}</div>
                            </>
                          ))}
                        </option>
                      );
                    })
                : listActivity
                    .slice()
                    .reverse()
                    .filter(
                      (data: any) =>
                        data?.node?.metaData &&
                        Array.isArray(data?.node?.metaData) &&
                        data?.node?.metaData !== null &&
                        data?.node?.metaData.some(
                          (meta: any) =>
                            Object.keys(meta).length > 0 &&
                            meta.hasOwnProperty('file_name')
                        )
                    )
                    ?.map((data: any, index: any) => {
                      const actId = data?.node;
                      return (
                        <option key={index} value={actId?.id}>
                          {actId?.id} -{' '}
                          {actId?.metaData?.map((item: any, index: any) => (
                            <>
                              <div key={index}>{item.file_name}</div>
                              {activityType === CODE_DOCUMENTATION && (
                                <div> - {item.Language}</div>
                              )}
                            </>
                          ))}
                        </option>
                      );
                    })}
            </>
          )}
        </Form.Select>
        {showStatus && (
          <Form.Label style={{ marginTop: '1rem' }}>
            Status :{' '}
            {activityStatus[activeId] === 'Complete' ? (
              <Badge bg="success">{activityStatus[activeId]}</Badge>
            ) : (
              <>
                <Badge bg="warning" text="dark" style={{ marginRight: '8px' }}>
                  {activityStatus[activeId]}
                </Badge>
                {!isRefreshing ? (
                  <IoMdRefresh
                    onClick={() => setIsActivityActive(!isActivityActive)}
                  />
                ) : (
                  <div>Refreshing...</div>
                )}
              </>
            )}
          </Form.Label>
        )}
        {downloadResponseLink && (
          <a
            className="download-button-tag user-story-generator-submit-button"
            href={`${downloadResponseLink}`}
            target="_blank"
            rel="noreferrer"
          >
            Download File
          </a>
        )}
      </Form.Group>
      {showClearChatWarning && activityType === MEETING_SUMMARIZER && (
        <div className="clear-chat-warning-container">
          <Modal
            show={showClearChatWarning}
            onHide={handleCancelClearChat}
            backdrop="static"
            keyboard={true}
          >
            <Modal.Header closeButton>
              <Modal.Title>Clear Chat?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                This action will clear the chat history and create a new
                session. Are you sure you want to clear the chat?
              </p>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="danger" onClick={handleClearChat}>
                Clear
              </Button>
              <Button variant="secondary" onClick={handleCancelClearChat}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
};

export default ActivityStatusDropDown;
