// ClearChatModal.js

import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface ClearChatModalProps {
  show: boolean;
  onHide: () => void;
  onClear: () => void;
}

const ClearChatModal: React.FC<ClearChatModalProps> = ({
  show,
  onHide,
  onClear,
}) => {
  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={true}>
      <Modal.Header closeButton>
        <Modal.Title>Clear Response?</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          This action will clear the chat history and create a new session. Are
          you sure you want to clear the response?
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="danger" onClick={onClear}>
          Clear
        </Button>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ClearChatModal;
