import React, { useState, useEffect } from 'react';
import Login from './Login';
import Settings from './settings';
import './CopilotNoUIV2.css';
import ChatComponent from './Chat';
import noUIIText from '../../assets/icon-no-ui.svg';
import noUIIcon from '../../assets/noui-logo.svg';
import initialNouiLoader from '../../assets/loader-noui.svg';
import backgroundImage from '../../assets/waves-horizontal.svg';

interface configureButton {
  Label: string;
  Alignment: string;
  Endpoint: string;
  Method: string;
  Message: string;
}

interface configureAction {
  EngineName: string;
  Endpoint: string;
  Method: string;
}

interface configureConnectToOrg {
  endpointURL: string;
  userName: string;
  password: string;
  grantType: string;
  clientId: string;
  clientSecret: string;
}

const CopilotNoUIV2: React.FC = () => {
  //let suggestionStatus:boolean=false;
  const [copilotInstanceId, SetcopilotInstanceId] = useState('');
  const [suggestionStatus, setSuggestionsStatus] = useState(false);
  const [configursButtonData, setConfigursButtonData] = useState<
    configureButton[]
  >([]);
  const [configureAction, setConfigureAction] = useState<configureAction[]>([]);
  const [configureConnectToOrg, setConfigureConnectToOrg] =
    useState<configureConnectToOrg>({
      endpointURL: '',
      userName: '',
      password: '',
      grantType: '',
      clientId: '',
      clientSecret: '',
    });
  const [showLoader, setShowLoader] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [generateLogs, setGenerateLogs] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false); // Hide loader after 3 seconds
    }, 3000);
    return () => clearTimeout(timer);
  }, [isLoggedIn]);

  useEffect(() => {
    const storedInstanceId = sessionStorage.getItem('copilotInstanceId');
    if (storedInstanceId) {
      SetcopilotInstanceId(storedInstanceId);
    }
  }, []);

  useEffect(() => {
    const storedButtonConfig = sessionStorage.getItem('buttonConfig');
    if (storedButtonConfig) {
      setConfigursButtonData(JSON.parse(storedButtonConfig));
    }
  }, []);

  useEffect(() => {
    const storedActionConfig = sessionStorage.getItem('actionConfig');
    if (storedActionConfig) {
      setConfigureAction(JSON.parse(storedActionConfig));
    }
  }, []);

  useEffect(() => {
    const storedConnectToOrgConfig =
      sessionStorage.getItem('connectToOrgConfig');
    if (storedConnectToOrgConfig) {
      setConfigureConnectToOrg(JSON.parse(storedConnectToOrgConfig));
    }
  }, []);

  const handleChangeCopilotInstanceId = (id: string) => {
    SetcopilotInstanceId(id);
    sessionStorage.setItem('copilotInstanceId', id);
  };

  const handleSuggestionchange = (status: boolean) => {
    setSuggestionsStatus(status);
  };

  const handleConfigureButton = (data: any[]) => {
    setConfigursButtonData(data);
    sessionStorage.setItem('buttonConfig', JSON.stringify(data));
  };
  const handleLogin = () => {
    setShowLoader(true);
    setIsLoggedIn(true);
    setTimeout(() => {
      setShowLoader(false);
    }, 3000);
  };

  const handleConfigureAction = (data: any[]) => {
    setConfigureAction(data);
    sessionStorage.setItem('actionConfig', JSON.stringify(configureAction));
  };

  const handleConnectToOrgConfig = (data: any) => {
    setConfigureConnectToOrg(data);

    sessionStorage.setItem(
      'connectToOrgConfig',
      JSON.stringify(configureConnectToOrg)
    );
    //console.log("configure data>>", JSON.stringify(data));
  };

  const handleGenerateLogs = () => {
    setGenerateLogs(!generateLogs);
  };
  return (
    <div className="no-ui-application-container">
      {!isLoggedIn ? (
        <Login onLogin={handleLogin} />
      ) : (
        <>
          {showLoader ? (
            <div className="initial-loader-container">
              <img src={initialNouiLoader} alt="Loader" />
            </div>
          ) : (
            <div>
              <div className="header-container">
                {/* <div style={{position:'absolute',display:'contents',width:'100%', }}>
                            <img className="" src={backgroundImage}/>
                        </div> */}
                <div
                  className="header"
                  style={{ backgroundImage: `url(${backgroundImage})` }}
                >
                  <div className="logo-box">
                    {
                      <img
                        src={noUIIcon}
                        alt="DEMOCRATIZE.AI Logo"
                        className="logo-icon-noui"
                      />
                    }
                  </div>
                  <div className="text-box">
                    <img
                      src={noUIIText}
                      alt="DEMOCRATIZE.AI Text"
                      className="logo-text-noui"
                    />
                  </div>
                  {/*
                            <div className="sub-header">
                                <p className="sub-header-text">
                                    Crafting Your Personalized Experience, Piece by Piece
                                </p>
                            </div>
                            <div className="main-content">
                                <p className="main-content-text">
                                    This revolutionary generative AI application gives you an intuitive, conversational interface to access the full power of your enterprise platform
                                </p>
                    </div>*/}
                </div>
                <Settings
                  onInstanceIdChange={handleChangeCopilotInstanceId}
                  handleSuggestionEnable={handleSuggestionchange}
                  configurButton={handleConfigureButton}
                  configurAction={handleConfigureAction}
                  configurConnectToOrgData={handleConnectToOrgConfig}
                  className="gear-icon"
                  generateLogs={generateLogs}
                  onGenerateLogs={handleGenerateLogs}
                />
              </div>
              <div className="sub-container">
                <ChatComponent
                  copilotInstanceId={copilotInstanceId}
                  suggestionStatus={suggestionStatus}
                  configursButtonData={configursButtonData}
                  configurAction={configureAction}
                  configurConnectToOrgData={configureConnectToOrg}
                  generateLogs={generateLogs}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CopilotNoUIV2;
