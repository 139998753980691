import { Outlet, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Dropdown } from 'react-bootstrap';
import './App.css';
import './utils/api-Interceptors';
import { Button, Modal } from 'react-bootstrap';
import { CgProfile } from 'react-icons/cg';
import { removeAuthToken } from './auth';
import { useState } from 'react';

function App() {
  const [showLogoutPopup, setShowLogoutPopup] = useState<boolean>(false);

  const storedEmail = sessionStorage.getItem('user');
  const userEmail = storedEmail ? JSON.parse(storedEmail) : '';

  const handleLogout = () => {
    removeAuthToken();
    window.location.href = '/logout';
  };

  const cancelLogoutPopup = () => {
    setShowLogoutPopup(false);
  };

  return (
    <div className="app">
      <header>
        <h1>
          <Link to="/">
            <img src="/logo.png" alt="GenAIe Logo" className="genaie-logo" />
          </Link>
        </h1>
        <div className="btn-group">
          <Dropdown align="end">
            <Dropdown.Toggle
              variant="light"
              id="dropdown-basic"
              className="logout-button"
            >
              <CgProfile className="profile-icon" size={30} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {userEmail?.length > 0 && (
                <>
                  <Dropdown.ItemText>{userEmail}</Dropdown.ItemText>
                  <Dropdown.Divider />
                </>
              )}
              <Dropdown.Item
                className="logout-item"
                onClick={() => setShowLogoutPopup(true)}
              >
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header>
      <div className="main-container">
        <main>
          <Outlet />
        </main>
      </div>
      <footer>
        <div className="footer-container">
          <div>© 2024 GenAIe - Powered by SmartGPT</div>
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://cookienotice.deloitte.com"
            >
              Cookies
            </a>
          </div>
        </div>
      </footer>
      {showLogoutPopup && (
        <Modal
          show={showLogoutPopup}
          onHide={cancelLogoutPopup}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Logout Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="delete-warning">
              <p>Are you sure you want to log out?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={cancelLogoutPopup}>
              Cancel
            </Button>
            <Button onClick={handleLogout}>Logout</Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default App;
