import React, { useEffect, useState } from 'react';
import { Button, Table, Badge, Spinner, Modal } from 'react-bootstrap';
import './KnowledgeIdTable.css';
import { FaTrashAlt } from 'react-icons/fa';
import axios from 'axios';
import { ADMIN, PGVECTOR, SUPERADMIN } from '../../utils/constants';

interface KnowledgeTableProps {
  data: any[];
  expandedRows: Set<number>;
  toggleRowExpansion: (rowIndex: number) => void;
  selectedInstance?: string;
  getInstanceData?: any;
  showDelete?: boolean;
  cloudProvider?: any;
  vectorDb?: string;
}

const KnowledgeTable: React.FC<KnowledgeTableProps> = ({
  data,
  expandedRows,
  toggleRowExpansion,
  selectedInstance,
  getInstanceData,
  showDelete,
  cloudProvider,
  vectorDb,
}) => {
  const [isDeleteResponse, setIsDeleteResponse] = useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [knowledgePaylaod, setknowledgePaylaod] = useState<any>({});
  const userRole = JSON.parse(sessionStorage.getItem('role') || '{}');

  const handleKnowledgeDelete = async (payload: any) => {
    setIsDeleteLoading(true);
    let url = '';
    const weaviateUrl = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/weaviate/delete-knowledge`;
    const pgvectorUrl = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/pgvector/delete-knowledge`;
    url = vectorDb === PGVECTOR ? pgvectorUrl : weaviateUrl;
    try {
      const response = await axios.delete(url, {
        data: payload,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        setIsDeleteLoading(false);
        setIsDeleteResponse(true);
        setShowDeletePopup(false);
        const responseData = response.data;
        getInstanceData(selectedInstance);
      } else {
        console.error('Failed to delete file');
        setIsDeleteLoading(false);
      }
    } catch (error: any) {
      console.error('Error:', error);
      setIsDeleteLoading(false);
    }
  };
  useEffect(() => {
    const removeSuccessMessage = setTimeout(() => {
      setIsDeleteResponse(false);
    }, 2000);

    return () => clearTimeout(removeSuccessMessage);
  }, [isDeleteResponse]);

  const handleDelete = async (id: string, documentName: string) => {
    setIsDeleteResponse(false);
    const payload = {
      instance_id: selectedInstance,
      knowledge_id: id,
      class_name:
        cloudProvider === 'azure'
          ? 'Document'
          : cloudProvider === 'gcp'
            ? 'GCPDocument'
            : cloudProvider === 'aws'
              ? 'AWSDocument'
              : '',
      collection_name:
        cloudProvider === 'azure'
          ? 'AzureDocument'
          : cloudProvider === 'gcp'
            ? 'GCPDocument'
            : cloudProvider === 'aws'
              ? 'AWSDocument'
              : '',
      document_name: documentName,
    };
    // handleKnowledgeDelete(payload);
    setknowledgePaylaod(payload);
    setShowDeletePopup(true);
  };
  const canceldeletePopup = () => {
    setShowDeletePopup(false);
  };

  const deleteModal = () => {
    return (
      <div className="clear-chat-warning-container">
        <Modal
          show={showDeletePopup}
          onHide={canceldeletePopup}
          backdrop={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Knowledge Document(s)?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="delete-warning">
              <p>
                This action will delete the knowledge document(s){' '}
                {knowledgePaylaod?.document_name}. Are you sure you want to
                delete it?
              </p>
            </div>
          </Modal.Body>

          <Modal.Footer>
            { userRole && (userRole === ADMIN || userRole === SUPERADMIN) && (
            <Button
              variant="danger"
              onClick={() => handleKnowledgeDelete(knowledgePaylaod)}
            >
              Delete
            </Button>
            )
           }
            <Button variant="secondary" onClick={canceldeletePopup}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  return (
    <div className="table-responsive">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th className="id-column">ID</th>
            <th className="name-column">Name</th>
            <th className="documents-column">Document(s) Name</th>
            {showDelete &&  userRole && (userRole === ADMIN || userRole === SUPERADMIN) && <th className="delete-column">Actions</th>}
          </tr>
        </thead>
        <tbody>
          {data?.map((edge: any, rowIndex: number) => (
            <React.Fragment key={edge.id}>
              {edge.documentName?.map((doc: string, docIndex: number) => (
                <tr
                  key={`${edge.id}-${doc}`}
                  onClick={() => toggleRowExpansion(rowIndex)}
                  style={{ cursor: 'pointer' }}
                >
                  {docIndex === 0 && (
                    <>
                      <td rowSpan={edge.documentName.length}>{edge.id}</td>
                      <td rowSpan={edge.documentName.length}>{edge.name}</td>
                    </>
                  )}
                  <td>{doc}</td>
                  {showDelete && userRole && (userRole === ADMIN || userRole === SUPERADMIN) && (
                    <td>
                      <Button
                        className="knowledge-delete-button"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleDelete(edge.id, doc)}
                      >
                        <FaTrashAlt className="knowledge-icon-delete" />
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
      {showDeletePopup && deleteModal()}
      {isDeleteLoading && (
        <div className="upload-spinner">
          <Spinner animation="border" size="sm" /> Deleting..
        </div>
      )}
      {isDeleteResponse && (
        <div className="upload-msg">
          <Badge bg="danger">Delete Successful</Badge>
        </div>
      )}
    </div>
  );
};

export default KnowledgeTable;
