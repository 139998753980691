import React from 'react';
import PropTypes from 'prop-types';
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import '@webscopeio/react-textarea-autocomplete/style.css';

const CustomReactTextareaAutocomplete = ({
  className,
  rows,
  value,
  onChange,
  loadingComponent,
  trigger,
}) => (
  <ReactTextareaAutocomplete
    className={className}
    rows={rows}
    value={value}
    minChar={value}
    onChange={onChange}
    loadingComponent={loadingComponent}
    trigger={trigger}
  />
);

CustomReactTextareaAutocomplete.propTypes = {
  className: PropTypes.string,
  rows: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  loadingComponent: PropTypes.element,
  trigger: PropTypes.object,
  minChar: PropTypes.number,
};

export default CustomReactTextareaAutocomplete;
