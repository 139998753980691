import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../assets/arrows/back-arrow.svg';
import Loader from '../../components/Loader';
import './ActivityHistoryTable.css';
import { Button, Table } from 'react-bootstrap';
import Pagination from './Pagination';
import axios from 'axios';
import ContentLoader from '../../components/Loader/contentLoader';

const ActivityHistoryTable = () => {
  const [activityData, setActivityData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { projectId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());
  const itemsPerPage = 15;
  const [downloadResponseLink, setDownloadResponseLink] = useState<string>('');
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const fetchActivity = async () => {
    try {
      setIsLoading(true);
      const queryParams = {
        project_id: projectId,
      };
      const response = await axios(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/activity/all`,
        {
          params: queryParams,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const data = await response.data;
      setActivityData(data.response.edges);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchActivity();
  }, []);

  useEffect(() => {
    if (downloadResponseLink) {
      const downloadLink = downloadLinkRef?.current;
      if (downloadLink) {
        downloadLink.href = downloadResponseLink;
        downloadLink.click();
        setIsDownloading(false);
      }
    }
  }, [downloadResponseLink]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(activityData.length / itemsPerPage);
  const reversedDisplayedData = activityData.slice().reverse();
  const displayedData = reversedDisplayedData.slice(startIndex, endIndex);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const toggleRowExpansion = (rowIndex: number) => {
    setExpandedRows((prevExpandedRows) => {
      const newExpandedRows = new Set(prevExpandedRows);
      if (newExpandedRows.has(rowIndex)) {
        newExpandedRows.delete(rowIndex);
      } else {
        newExpandedRows.add(rowIndex);
      }
      return newExpandedRows;
    });
  };

  const handleActivityDownLoad = async () => {
    setDownloadResponseLink('');
    setIsDownloading(true);
    try {
      const queryParams = {
        project_id: projectId,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/activity/all/download`,
        {
          params: queryParams,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response && response.status === 200) {
        const fileResponse = response.data;
        if (fileResponse) {
          const fileUrl = fileResponse.download_url;
          setDownloadResponseLink(fileUrl);
        }
      } else {
        console.error('Failed to get response');
        setDownloadResponseLink('');
        setIsDownloading(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setDownloadResponseLink('');
      setIsDownloading(false);
    }
  };

  return (
    <div className="application-page-container">
      <div className="title">Activity History</div>
      <div className="activity-back-button-container">
        <Link to={`/project/${projectId}`}>
          <BackIcon /> Back{' '}
        </Link>
        {displayedData.length > 0 && (
          <div className="download-activity-button-tag">
            <a
              href="#"
              ref={downloadLinkRef}
              download
              style={{ display: 'none' }}
            />
            {isDownloading ? (
              <Button
                disabled={true}
                variant="success"
                className="download-btn-loading"
              >
                <ContentLoader />
              </Button>
            ) : (
              <Button
                as="input"
                type="submit"
                value="Download"
                onClick={handleActivityDownLoad}
                variant="success"
              />
            )}
          </div>
        )}
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="application-page-container">
          <div className="table-responsive">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th style={{ width: '4%' }}>ID</th>
                  <th style={{ width: '18%' }}>Application</th>
                  <th style={{ width: '18%' }}>Instance</th>
                  <th style={{ width: '23%' }}>Input Prompt </th>
                  <th style={{ width: '28%' }}>Response</th>
                  <th style={{ width: '10%' }}>Task ID</th>
                  <th>Status</th>
                  <th style={{ width: '5%' }}>Feedback Input</th>
                  <th style={{ width: '1%' }}>Feedback Comments</th>
                  <th style={{ width: '15%' }}>User</th>
                  <th>Llm Model</th>
                  <th>Input Token Usage</th>
                  <th>Output Token Usage</th>
                  <th>Total Price</th>
                  <th>Created Timestamp</th>
                  <th style={{ width: '25%' }}>View Details</th>
                </tr>
              </thead>
              <tbody>
                {displayedData.map((edge, rowIndex) => (
                  <React.Fragment key={edge.node.id}>
                    <tr
                      key={edge.node.id}
                      onClick={() => toggleRowExpansion(rowIndex)}
                      style={{ cursor: 'pointer' }}
                    >
                      <td>{edge.node.id}</td>
                      <td>{edge.node._type}</td>
                      <td>
                        {expandedRows.has(rowIndex)
                          ? edge.node.instanceName
                          : edge.node.instanceName
                            ? edge.node.instanceName.length > 10
                              ? edge.node.instanceName.substring(0, 10) + ' ...'
                              : edge.node.instanceName
                            : ''}
                      </td>
                      <td>
                        {expandedRows.has(rowIndex)
                          ? edge.node.inputText
                          : edge.node.inputText
                            ? edge.node.inputText.length > 25
                              ? edge.node.inputText.substring(0, 25) + ' ...'
                              : edge.node.inputText
                            : ''}
                      </td>
                      <td>
                        {expandedRows.has(rowIndex)
                          ? edge.node.outputText
                          : edge.node.outputText
                            ? edge.node.outputText.length > 35
                              ? edge.node.outputText.substring(0, 35) + ' ...'
                              : edge.node.outputText
                            : ''}
                      </td>
                      <td>
                        {expandedRows.has(rowIndex)
                          ? edge.node.taskId
                          : edge.node.taskId
                            ? edge.node.taskId.length > 10
                              ? edge.node.taskId.substring(0, 10) + ' ...'
                              : edge.node.taskId
                            : ''}
                      </td>
                      <td>{edge.node.status}</td>
                      <td>
                        {edge.node.feedback
                          ? edge.node.feedback['was it helpful']
                          : 'N/A'}
                      </td>
                      <td>
                        {edge.node.feedback
                          ? expandedRows.has(rowIndex)
                            ? edge.node.feedback.message
                            : edge.node.feedback.message
                              ? edge.node.feedback.message.length > 10
                                ? edge.node.feedback.message.substring(0, 10) +
                                  ' ...'
                                : edge.node.feedback.message
                              : ''
                          : 'No Feedback Provided'}
                      </td>
                      <td>
                        {expandedRows.has(rowIndex)
                          ? edge.node.username
                          : edge.node.username
                            ? edge.node.username.length > 11
                              ? edge.node.username.substring(0, 11) + ' ...'
                              : edge.node.username
                            : ''}
                      </td>
                      <td>{edge?.node?.llmModel}</td>
                      <td>{edge?.node?.inputTokenUsage}</td>
                      <td>{edge?.node?.outputTokenUsage}</td>
                      <td>
                        {edge?.node?.totalPricing
                          ? '$' + edge?.node?.totalPricing
                          : ''}
                      </td>
                      <td>
                        {expandedRows.has(rowIndex)
                          ? edge.node.createdAt
                          : edge.node.createdAt
                            ? edge.node.createdAt.length > 11
                              ? edge.node.createdAt.substring(0, 11) + ' ...'
                              : edge.node.createdAt
                            : ''}
                      </td>
                      <td>
                        <span
                          className={`view-details ${expandedRows.has(rowIndex) ? 'active' : ''}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleRowExpansion(rowIndex);
                          }}
                        >
                          {expandedRows.has(rowIndex)
                            ? 'Hide Details'
                            : 'View Details'}
                        </span>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="pagination">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivityHistoryTable;
