import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import axios from 'axios';

import './DownloadButton.css';
type downloadButtonProps = {
  instanceId?: any;
  outputData?: any;
  title?: string;
  activeActivityId?: any;
  activityType?: string;
};

const DownloadButton: React.FC<downloadButtonProps> = ({
  instanceId,
  outputData,
  title,
  activeActivityId,
  activityType,
}) => {
  const handleDownLoad = async (e: any) => {
    const fileType = e;
    let formData;
    if (activityType === 'Status Report Generator') {
      const instance_id = instanceId.toString();
      formData = {
        input_text: outputData,
        report_file_type: fileType,
        instance_id: instance_id,
      };
    } else {
      formData = {
        activity_id: activeActivityId,
        report_type: fileType,
      };
    }
    let response;
    try {
      if (activityType === 'Status Report Generator') {
        response = await axios.post(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/report/download`,
          formData,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/azure/one-shot-chain/notice-analysis/downloads-report-by-activity-id`,
          null,
          {
            params: formData,
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
      }
      if (response && response.status === 200) {
        const fileResponse = response.data.response;
        if (fileResponse) {
          const fileUrl = fileResponse.download_link;
          let downloadURL = '';
          if (activityType === 'Status Report Generator') {
            downloadURL = `${process.env.REACT_APP_BACKEND_DOMAIN}${fileUrl}`;
          } else {
            downloadURL = `${fileUrl}`;
          }
          const clickLink = document.createElement('a');
          clickLink.href = downloadURL;
          clickLink.click();
        }
      } else {
        console.error('Failed to get response');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const renderDropdownItems = () => {
    if (activityType === 'Status Report Generator') {
      return (
        <>
          <Dropdown.Item
            eventKey="pptx"
            onSelect={() => handleDownLoad('pptx')}
          >
            PPT
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="docx"
            onSelect={() => handleDownLoad('docx')}
          >
            Word file
          </Dropdown.Item>
        </>
      );
    } else if (activityType === 'Notice Analysis Engine') {
      return (
        <>
          <Dropdown.Item
            eventKey="xlsx"
            onSelect={() => handleDownLoad('xlsx')}
          >
            Excel File
          </Dropdown.Item>
          <Dropdown.Item eventKey="csv" onSelect={() => handleDownLoad('csv')}>
            CSV
          </Dropdown.Item>
        </>
      );
    }
    return null;
  };

  return (
    <DropdownButton
      id="dropdown-download-button"
      variant="success"
      title={title}
      onSelect={handleDownLoad}
    >
      {renderDropdownItems()}
    </DropdownButton>
  );
};

export default DownloadButton;
