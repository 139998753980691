import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import './TimelinesReport.css';
import { Form } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import ContentLoader from '../../components/Loader/contentLoader';
import Chart from 'react-apexcharts';

type ChartComponentProps = {
  title: string;
  options: any;
  series: any;
};

const TimelinesReport = () => {
  const [startDate, setStartDate] = useState('2024-07-01');
  const [endDate, setEndDate] = useState('');
  const [responseTimelinesData, setResponseTimelinesData] = useState<any>();
  const [loading, isloading] = useState(false);
  const [showDate, setShowDate] = useState({
    startDate: '',
    endDate: '',
  });
  const [validationError, setValidationError] = useState<string | null>(null);
  const [selectedChartType, setSelectedChartType] =
    useState<string>('CostPieChart');
  const [selectedFilterType, setSelectedFilterType] =
    useState<string>('Engine');
  const [selectedSortType, setSelectedSortType] = useState<string>('Week');
  const formatDate = (date: string) => {
    const [yyyy, mm, dd] = date.split('-');
    return `${dd}-${mm}-${yyyy}`;
  };
  const maxDate = new Date().toISOString().split('T')[0];
  const currentDate = formatDate(maxDate);
  const initialDate = '2024-07-01';
  const formatedInitialDate = formatDate(initialDate);

  const Timelineparams = {
    split_by: selectedFilterType?.toLowerCase(),
    frequency: selectedSortType?.toLowerCase(),
    project_id: undefined,
    activity_details:
      selectedChartType === 'CostPieChart' ? 'activity_cost' : 'activity_count',
    start_date: formatedInitialDate,
    end_date: currentDate,
  };

  const timelinesValues = async (params: {
    split_by: string;
    frequency: string;
    project_id: string | undefined;
    activity_details: string;
    start_date: string;
    end_date: string;
  }) => {
    isloading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/activity/dashboard/timeline/specific`,
        { params }
      );
      if (response.status === 200 || response.status === 201) {
        isloading(false);
        const data = response.data;
        let filteredResponseData = data?.response;
        // console.log('filteredResponseData', filteredResponseData);
        filteredResponseData =
          selectedChartType === 'ActivitiesPieChart'
            ? filteredResponseData?.sort(
                (a: any, b: any) => b.activity_count - a.activity_count
              )
            : filteredResponseData?.sort(
                (a: any, b: any) => b.totalCost - a.totalCost
              );
        setResponseTimelinesData(filteredResponseData);
        setShowDate({
          startDate: params.start_date,
          endDate: params.end_date,
        });
      } else {
        isloading(false);
      }
    } catch (error: any) {
      console.error(
        'Error fetching projects:',
        error?.response?.data || error?.message
      );
      const err = error?.response?.data?.error;
    }
  };
  const handleOptionChange = (event: any) => {
    setSelectedChartType(event?.target.value);
    timelinesValues({
      split_by: selectedFilterType?.toLowerCase(),
      frequency: selectedSortType?.toLowerCase(),
      project_id: undefined,
      activity_details:
        event?.target.value === 'CostPieChart'
          ? 'activity_cost'
          : 'activity_count',
      start_date: startDate ? formatDate(startDate) : formatedInitialDate,
      end_date: endDate ? formatDate(endDate) : currentDate,
    });
  };
  const handleFilterOptionChange = (event: any) => {
    setSelectedFilterType(event?.target.value);
    timelinesValues({
      split_by: event?.target.value?.toLowerCase(),
      frequency: selectedSortType?.toLowerCase(),
      project_id: undefined,
      activity_details:
        selectedChartType === 'CostPieChart'
          ? 'activity_cost'
          : 'activity_count',
      start_date: startDate ? formatDate(startDate) : formatedInitialDate,
      end_date: endDate ? formatDate(endDate) : currentDate,
    });
  };
  const handleSortOrderChange = (event: any) => {
    setSelectedSortType(event?.target.value);
    timelinesValues({
      split_by: selectedFilterType?.toLowerCase(),
      frequency: event?.target.value?.toLowerCase(),
      project_id: undefined,
      activity_details:
        selectedChartType === 'CostPieChart'
          ? 'activity_cost'
          : 'activity_count',
      start_date: startDate ? formatDate(startDate) : formatedInitialDate,
      end_date: endDate ? formatDate(endDate) : currentDate,
    });
  };
  useEffect(() => {
    timelinesValues(Timelineparams);
  }, []);

  const handleSubmit = () => {
    if (startDate && endDate) {
      if (new Date(startDate) > new Date(endDate)) {
        setValidationError('From Date should not be greater than To Date.');
        return;
      } else {
        setValidationError(null);
      }
    }

    timelinesValues({
      split_by: selectedFilterType?.toLowerCase(),
      frequency: selectedSortType?.toLowerCase(),
      project_id: undefined,
      activity_details:
        selectedChartType === 'CostPieChart'
          ? 'activity_cost'
          : 'activity_count',
      start_date: startDate ? formatDate(startDate) : formatedInitialDate,
      end_date: endDate ? formatDate(endDate) : currentDate,
    });
  };

  const transformData = (data: any[]) => {
    // console.log('transformData call');
    const groupedData: { [key: string]: { [key: string]: number } } = {};
    const itemType =
      selectedFilterType === 'User'
        ? 'email'
        : selectedFilterType === 'Project'
          ? 'projectname'
          : 'applicationName';
    const filteredData = data.filter((item) => item[itemType]);

    filteredData.forEach((item) => {
      const date = new Date(item.timeline).toLocaleDateString();
      if (!groupedData[date]) {
        groupedData[date] = {};
      }
      if (!groupedData[date][item[itemType]]) {
        groupedData[date][item[itemType]] = 0;
      }
      selectedChartType === 'ActivitiesPieChart'
        ? (groupedData[date][item[itemType]] += item.activity_count)
        : (groupedData[date][item[itemType]] += item.totalCost);
    });

    const categories = Object.keys(groupedData).sort((a, b) => {
      const dateA = new Date(a.split('/').reverse().join('-')).getTime();
      const dateB = new Date(b.split('/').reverse().join('-')).getTime();
      return dateA - dateB;
    });

    const seriesNames = Array.from(
      new Set(filteredData.map((item) => item[itemType]).filter((name) => name))
    );
    // console.log('categories', categories);
    // console.log('seriesNames', seriesNames);
    const series = seriesNames.map((item) => ({
      name: item,
      data: categories.map((date) => {
        const value = groupedData[date][item] || 0;
        return selectedChartType === 'CostPieChart'
          ? parseFloat(value.toFixed(2))
          : value;
      }),
    }));

    // console.log('Transformed Data:', { categories, series });
    return { categories, series };
  };

  useEffect(() => {
    // console.log('startDate in useEffect', startDate);
    handleSubmit();
  }, [startDate, endDate]);

  const { categories, series } = useMemo(() => {
    return responseTimelinesData && responseTimelinesData.length > 0
      ? transformData(responseTimelinesData)
      : { categories: [], series: [] };
  }, [responseTimelinesData, selectedChartType]);

  // console.log('categories', categories);
  // console.log('series', series);

  const chartOptions = {
    chart: {
      type: 'bar',
      stacked: true,
    },
    xaxis: {
      categories,
    },
    yaxis: {
      title: {
        text:
          selectedChartType === 'CostPieChart'
            ? 'Total Cost'
            : 'Activity Count',
      },
    },
    legend: {
      position: 'bottom',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: '13px',
              fontWeight: 900,
            },
            formatter: (val: any) =>
              selectedChartType === 'CostPieChart' ? '$' + val.toFixed(2) : val,
          },
        },
      },
    },
  };

  const ChartComponent: React.FC<ChartComponentProps> = ({
    title,
    options,
    series,
  }) => {
    if (!options || !series) {
      console.error('Invalid options or series:', { options, series });
      return null;
    }
    return (
      <>
        {/* <h4 className="margin-top">{title}</h4> */}
        <Chart
          options={options}
          series={series}
          type="bar"
          width={'100%'}
          height={600}
        />
      </>
    );
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setStartDate(e.target.value);
  };
  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setEndDate(e.target.value);
  };
  return (
    <>
      <div>
        <Form.Label className="Project-selector">
          <b>Date Range</b>
        </Form.Label>
        <div className="split-screen">
          <div>
            <div>From Date</div>
            <Form.Control
              type="date"
              name="datepic"
              placeholder="Select Date"
              value={startDate}
              className="date-picker"
              onChange={handleStartDateChange}
              min={initialDate}
              max={maxDate}
            />
          </div>
          <div>
            <div>To Date</div>
            <Form.Control
              type="date"
              name="datepic"
              placeholder="Select Date"
              value={endDate ? endDate : maxDate}
              className="date-picker"
              disabled={!startDate}
              onChange={handleEndDateChange}
              min={startDate}
              max={maxDate}
            />
          </div>
        </div>
        {validationError && (
          <div className="date-error-message">{validationError}</div>
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 20,
            position: 'relative',
          }}
        >
          <div className="filters-container">
            <div className="filter-show">
              <Form.Label>Data</Form.Label>
              <Form.Select
                value={selectedFilterType}
                onChange={handleFilterOptionChange}
              >
                <option value="User">User</option>
                <option value="Project">Project</option>
                <option value="Engine">Engine</option>
              </Form.Select>
            </div>
            <div className="filter-groupby">
              <Form.Label>Y-axis</Form.Label>
              <Form.Select
                value={selectedChartType}
                onChange={handleOptionChange}
              >
                <option value="CostPieChart">Cost</option>
                <option value="ActivitiesPieChart">Activity Count</option>
              </Form.Select>
            </div>
            <div className="filter-sortby">
              <Form.Label>Group By</Form.Label>
              <Form.Select
                value={selectedSortType}
                onChange={handleSortOrderChange}
              >
                <option value="Day">Day</option>
                <option value="Week">Week</option>
                <option value="Month">Month</option>
                <option value="Year">Year</option>
              </Form.Select>
            </div>
          </div>
        </div>

        {responseTimelinesData && responseTimelinesData.length > 0 && (
          <>
            <div className="margin-top all-record-message">
              Showing results from {showDate.startDate} to {showDate.endDate}:
            </div>
            <>
              {loading ? (
                <div className="loading-container">
                  <ContentLoader />
                  <span>Updating Chart...</span>
                </div>
              ) : (
                <ChartComponent
                  title="Cost Chart: Project"
                  options={chartOptions}
                  series={series}
                />
              )}
            </>
          </>
        )}
      </div>
    </>
  );
};
export default TimelinesReport;
