import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface ConfirmationBoxProps {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
  message: string;
}

const ConfirmationBox: React.FC<ConfirmationBoxProps> = ({
  show,
  onHide,
  onConfirm,
  message,
}) => {
  return (
    <>
      <div>
        <Modal show={show} onHide={onHide} centered animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h3> Confirmation</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="container">
              <h4>{message}</h4>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onConfirm}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default ConfirmationBox;
