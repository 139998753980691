import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  JsonView,
  collapseAllNested,
  defaultStyles,
} from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import { Chat } from '../../interfaces';
type DebugBoxProps = {
  activityId: string;
  isChatLoading: boolean;
  chatContents: Chat[];
};

const DebugBox = ({
  activityId,
  isChatLoading,
  chatContents,
}: DebugBoxProps) => {
  const [debugData, setDebugData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getDebugData = async () => {
    const activityApi = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/activity/${activityId}`;
    try {
      setIsLoading(true);
      const response = await axios.get(activityApi);
      const debug_data = response?.data?.response?.meta_data.map(
        (item: any) => item.semantic_search_output
      );
      setDebugData(debug_data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (activityId && !isChatLoading && chatContents.length > 0) {
      getDebugData();
    }
  }, [activityId, isChatLoading]);

  useEffect(() => {
    if (chatContents && chatContents.length === 0) {
      setDebugData([]);
    }
  }, [chatContents]);

  return (
    <div className="p-2">
      <div
        className="d-flex"
        style={{ justifyContent: 'space-between', marginBottom: '10px' }}
      >
        <h4>Debug Mode</h4>
      </div>
      {isLoading && <div>Updating...</div>}
      {debugData && (
        <JsonView
          data={
            debugData && debugData.length > 0
              ? debugData
              : { response: 'No data' }
          }
          shouldExpandNode={collapseAllNested}
          style={defaultStyles}
        />
      )}
    </div>
  );
};

export default DebugBox;
