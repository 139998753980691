/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { useEffect, useState, useRef } from 'react';
import { Form, Alert, Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import '../UserStoryGenerator/UserStoryGenerator.css';
import ContentLoader from '../../components/Loader/contentLoader';
import CustomFeedback from '../../components/Feedback';
import { ReactComponent as BackIcon } from '../../assets/arrows/back-arrow.svg';
import { ResultReason } from 'microsoft-cognitiveservices-speech-sdk';
import * as speechsdk from 'microsoft-cognitiveservices-speech-sdk';
import './CallCenter.css';
import stream from '../../utils/stream';
import llmGuardSubmit from '../../utils/llmGuardSubmit';
import { Appl } from '../../interfaces';
import { Llm_models } from '../../types';
import Selectinstance from '../../components/SelectInstance';
import UserContext from '../../context/UserContext';
import { SiGooglecloud, SiAzuredevops, SiAmazonaws } from 'react-icons/si';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {
  aws_llm_models_options,
  azure_llm_models_options,
  gcp_llm_models_options,
  // private_llm_models_options,
} from '../../utils/constants';
import ClearChat from '../../components/ClearContent';
import { FaRegStopCircle } from 'react-icons/fa';

const CallCenter = () => {
  const { projectId, appUrl } = useParams();
  const apps = JSON.parse(sessionStorage.getItem('apps') as string) as Appl[];
  const currentApp = apps.find((app) => app.url === appUrl);
  const [outputData, setOutputData] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [showFeedbackOptions, setShowFeedBackOptions] =
    useState<boolean>(false);
  const [activityId, setActivityId] = useState<string>('');
  const [showmic, setshowmic] = useState<boolean>(false);
  const speechConfig = useRef<speechsdk.SpeechConfig | null>(null);
  const audioConfig = useRef<speechsdk.AudioConfig | null>(null);
  const recognizer = useRef<speechsdk.SpeechRecognizer | null>(null);
  const [recognizedSpeech, setRecognizedSpeech] = useState<string>('');
  const [recognizingSpeech, setRecognizingSpeech] = useState<string>('');
  const [instanceData, setInstanceData] = useState<any>('');
  const abortControllerRef = useRef<AbortController | null>(null);
  const [sanitizedPrompt, setSanitizedPrompt] = useState<string>('');
  const [showSanitizedPrompt, setShowSanitizedPrompt] =
    useState<boolean>(false);

  const SPEECH_KEY: string | undefined = process.env.REACT_APP_SPEECH_KEY;
  const SPEECH_REGION: string | undefined = process.env.REACT_APP_SPEECH_REGION;

  const userContext = useContext(UserContext) || {
    selectedInstance: '',
    showErrMsg: '',
    llmCloud: '',
    llmGuardOption: '',
  };

  const { selectedInstance, showErrMsg, llmCloud, llmGuardOption } =
    userContext;
  const [selectedLlmModel, setSelectedLlmModel] = useState<Llm_models>(
    llmCloud === 'gcp'
      ? 'gemini-pro'
      : llmCloud === 'aws'
        ? 'anthropic.claude-v2:1'
        : 'gpt-4-turbo'
  );

  useEffect(() => {
    setSelectedLlmModel(
      llmCloud === 'gcp'
        ? 'gemini-pro'
        : llmCloud === 'aws'
          ? 'anthropic.claude-v2:1'
          : 'gpt-4-turbo'
    );
  }, [llmCloud]);

  useEffect(() => {
    if (!SPEECH_KEY || !SPEECH_REGION) {
      throw new Error('Speech key and region are required.');
    }
    speechConfig.current = speechsdk.SpeechConfig.fromSubscription(
      SPEECH_KEY,
      SPEECH_REGION
    );
    speechConfig.current.speechRecognitionLanguage = 'en-US';

    audioConfig.current = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
    recognizer.current = new speechsdk.SpeechRecognizer(
      speechConfig.current,
      audioConfig.current
    );

    const getRecognizedSpeech = (event: any) => {
      const result = event.result;

      if (result.reason === ResultReason.RecognizedSpeech) {
        const transcript = result.text;
        setRecognizedSpeech((prevState) => prevState + transcript);
      }
    };

    const getRecognizingSpeech = (event: any) => {
      const result = event.result;
      if (result.reason === ResultReason.RecognizingSpeech) {
        const transcript = result.text;
        setRecognizingSpeech(
          (prev) => `${prev}${transcript.replace(prev, '')}`
        );
        // const closecheck = transcript.toLowerCase().includes("stop recording");
        // if (closecheck) {
        //   stopListening();
        // }
      }
    };

    recognizer.current.recognized = (s, e) => getRecognizedSpeech(e);
    recognizer.current.recognizing = (s, e) => getRecognizingSpeech(e);
    // recognizer.current.startContinuousRecognitionAsync();
    return () => {
      recognizer?.current?.stopContinuousRecognitionAsync(() => {});
    };
  }, []);

  const resumeListening = () => {
    setOutputData('');
    setShowSanitizedPrompt(false);
    setSanitizedPrompt('');
    setShowToast(false);
    setRecognizedSpeech('');
    setRecognizingSpeech('');
    recognizer?.current?.startContinuousRecognitionAsync(() => {});
    // if (!isListening) {
    setshowmic(true);
    // }
  };

  const stopListening = () => {
    setshowmic(false);
    recognizer?.current?.stopContinuousRecognitionAsync(() => {});
  };

  const handlePromptSubmit = async (payload: any) => {
    setShowToast(false);
    setOutputData('');
    setIsLoading(true);
    setShowFeedBackOptions(false);
    abortControllerRef.current = new AbortController();
    stream({
      requestBody: payload,
      streamCallback: (value: string) => {
        setOutputData((prev) => `${prev}${value}`);
        // setIsLoading(false);
      },
      doneCallback: () => {
        setIsLoading(false);
        setShowFeedBackOptions(true);
        setShowToast(false);
      },
      setShowToast: setShowToast,
      setIsLoading: setIsLoading,
      llmCloud: llmCloud,
      selectedLlmModel: selectedLlmModel,
      activityId: activityId,
      setActivityId: setActivityId,
      signal: abortControllerRef.current.signal,
    });
  };

  const handleLlmGuardSubmit = async (payload: any) => {
    setOutputData('');
    setIsLoading(true);
    setShowFeedBackOptions(false);
    const response = await llmGuardSubmit({
      requestBody: payload,
      setIsLoading,
      setShowToast,
      setActivityId,
      llmCloud,
      setSanitizedPrompt,
    });
    if (response) {
      setOutputData((prev) => `${prev}${response}`);
      setShowFeedBackOptions(true);
      setShowToast(false);
    }
  };

  const submitPrompt = () => {
    setShowSanitizedPrompt(false);
    setSanitizedPrompt('');
    const payload = {
      input_text: recognizingSpeech,
      instance_id: selectedInstance,
      include_references: true,
    };

    if (llmGuardOption === 'Yes') {
      handleLlmGuardSubmit(payload);
    } else handlePromptSubmit(payload);
  };
  const handleAbortStream = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      setIsLoading(false);
    }
  };

  const handleLlmModelChange = (value: string) => {
    setSelectedLlmModel(value as Llm_models);
  };

  return (
    <div className="application-page-container">
      <div className="title">{currentApp?.name}</div>
      <div className="back-button-container">
        <Link to={`/project/${projectId}`}>
          <BackIcon /> Back
        </Link>
        {selectedInstance && (
          <div className="cloud-icons">
            {llmCloud === 'gcp' ? (
              <SiGooglecloud style={{ fontSize: '1.5rem' }} />
            ) : llmCloud === 'aws' ? (
              <SiAmazonaws style={{ fontSize: '1.8rem' }} />
            ) : (
              <SiAzuredevops />
            )}
            <div className="pipe"></div>
            <span className="cloud-texts">
              {llmCloud === 'gcp'
                ? 'GCP'
                : llmCloud === 'aws'
                  ? 'AWS'
                  : 'Azure'}
            </span>
          </div>
        )}
      </div>
      <div className="single-app-container">
        <Selectinstance
          projectId={projectId}
          currentApp={currentApp}
          outputData={outputData}
          setOutputData={setOutputData}
          setInstanceDetails={setInstanceData}
          instanceDetails={instanceData}
        />

        <Form.Group className="user-input" controlId="exampleForm.micAudio">
          <div style={{ position: 'relative' }}>
            {showmic ? (
              <>
                <div className="box">
                  <div className="object">
                    <div className="outline"></div>
                    <div className="outline" id="delayed"></div>
                    <div className="button"></div>
                    <div className="button" id="circlein">
                      <svg
                        className="mic-icon"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 1000 1000"
                        enableBackground="new 0 0 1000 1000"
                        xmlSpace="preserve"
                      >
                        <g>
                          <path d="M500,683.8c84.6,0,153.1-68.6,153.1-153.1V163.1C653.1,78.6,584.6,10,500,10c-84.6,0-153.1,68.6-153.1,153.1v367.5C346.9,615.2,415.4,683.8,500,683.8z M714.4,438.8v91.9C714.4,649,618.4,745,500,745c-118.4,0-214.4-96-214.4-214.4v-91.9h-61.3v91.9c0,141.9,107.2,258.7,245,273.9v124.2H346.9V990h306.3v-61.3H530.6V804.5c137.8-15.2,245-132.1,245-273.9v-91.9H714.4z" />
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="box">
                <div className="object">
                  <div className="button" id="circlein">
                    <svg
                      className="mic-icon"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 1000 1000"
                      enableBackground="new 0 0 1000 1000"
                      xmlSpace="preserve"
                    >
                      <g>
                        <path d="M500,683.8c84.6,0,153.1-68.6,153.1-153.1V163.1C653.1,78.6,584.6,10,500,10c-84.6,0-153.1,68.6-153.1,153.1v367.5C346.9,615.2,415.4,683.8,500,683.8z M714.4,438.8v91.9C714.4,649,618.4,745,500,745c-118.4,0-214.4-96-214.4-214.4v-91.9h-61.3v91.9c0,141.9,107.2,258.7,245,273.9v124.2H346.9V990h306.3v-61.3H530.6V804.5c137.8-15.2,245-132.1,245-273.9v-91.9H714.4z" />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Form.Group>
        <div className="user-story-generator-submit-container listening-mic-container">
          <Button
            className="mic-btn"
            as="input"
            type="submit"
            value="Start"
            variant="success"
            onClick={resumeListening}
            disabled={!selectedInstance}
          />
          <Button
            className="mic-btn"
            as="input"
            type="submit"
            value="Stop"
            variant="danger"
            onClick={stopListening}
          />
        </div>
        <div className="">
          <div
            style={{
              marginTop: '10px',
              color: '#000',
              padding: '1rem',
            }}
          >
            {/* {displayText} */}
            {/* {showmic ? */}
            {/* <div>recognizingSpeech : {recognizingSpeech}</div> */}
            <div>
              Recognized Speech:
              <br /> {recognizedSpeech}
            </div>
          </div>
        </div>

        <div className="user-story-generator-submit-container">
          {isLoading ? (
            <Button
              className="user-story-generator-submit send-btn"
              disabled={true}
            >
              <ContentLoader />
            </Button>
          ) : (
            <Button
              className="user-story-generator-submit"
              as="input"
              type="submit"
              value="Submit"
              onClick={submitPrompt}
              disabled={!recognizedSpeech || !selectedInstance?.length}
            />
          )}
          {outputData.length > 0 && !isLoading ? (
            <ClearChat
              setOutputData={setOutputData}
              setShowRawTranscript={setShowSanitizedPrompt}
              setRecognizedSpeech={setRecognizedSpeech}
              setRecognizingSpeech={setRecognizingSpeech}
            />
          ) : (
            outputData && (
              <Button
                className="abort-btn"
                variant="danger"
                type="button"
                onClick={handleAbortStream}
                style={{ marginLeft: '16px' }}
                disabled={!outputData}
              >
                <FaRegStopCircle size="1.25rem" />
              </Button>
            )
          )}
        </div>
        {showToast && (
          <div style={{ paddingTop: '80px' }}>
            <Alert
              variant="danger"
              onClose={() => setShowToast(false)}
              dismissible
            >
              <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
              <p>
                {showErrMsg
                  ? showErrMsg
                  : `Something went wrong. Please try again`}
              </p>
            </Alert>
          </div>
        )}
      </div>
      {outputData && (
        <>
          {instanceData.renderMarkdown ? (
            <Form.Group controlId="outputTextArea">
              <div className="output-container markdown-table">
                <Markdown remarkPlugins={[remarkGfm]}>{outputData}</Markdown>
              </div>
            </Form.Group>
          ) : (
            <div className="call-centre-output output-container ">
              {outputData
                .toString()
                .split(/\n/)
                .map((text, i) => {
                  return text.match(/\[.*\]\(.*\)/g) ? (
                    <Markdown key={i}>{text}</Markdown>
                  ) : (
                    <p key={i}>{text}</p>
                  );
                })}
            </div>
          )}
          {llmGuardOption === 'Yes' && sanitizedPrompt && (
            <Form.Group className="transcript-toggle-container">
              <Form.Check
                type="switch"
                id="transcript-view-switch"
                label=" View Sanitized Prompt"
                onChange={(evt) => setShowSanitizedPrompt(evt.target.checked)}
              />
            </Form.Group>
          )}
          {showSanitizedPrompt && (
            <Form.Group controlId="outputTextArea">
              <Form.Control
                as="textarea"
                rows={10}
                className="output-container"
                value={`Sanitized Prompt:\n${sanitizedPrompt}`}
                disabled
              />
            </Form.Group>
          )}
        </>
      )}
      {outputData && showFeedbackOptions && (
        <CustomFeedback
          url={`${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/activity/feedback/update`}
          activityId={activityId}
        />
      )}
    </div>
  );
};

export default CallCenter;
