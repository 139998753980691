import React, { FC, useEffect } from 'react';
import './ErrorPage.css';
import { isTokenExpired, authenticateUser, removeAuthToken } from '../../auth';
interface ErrorPageProps {
  error: number;
}

const ErrorPage: FC<ErrorPageProps> = ({ error }) => {
  useEffect(() => {
    if (isTokenExpired()) {
      removeAuthToken();
      authenticateUser();
    }
  }, []);

  return (
    <div className="error-page-container">
      {error === 404 ? (
        <>
          <h1>Page not found</h1>
          <p>Sorry, the page you are looking for does not exist.</p>
        </>
      ) : null}
      {error === 403 ? (
        <>
          <h1>You do not have access to these GPS GenAI engines.</h1>
          <p>
            Please reach out to your sector’s Nerve Center for more information
            on gaining access.
          </p>
        </>
      ) : null}
    </div>
  );
};

export default ErrorPage;
