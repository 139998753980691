import React, { useState } from 'react';
import './Accordian.css'; // Import your CSS file
import DOMPurify from 'dompurify';

interface JsonData {
  [key: string]: {
    data: string;
    type: string;
    picklistValues?: string[];
  };
}

interface DynamicTabsAndFormsProps {
  jsonData?: JsonData[];
  updateJsonData: (data: any, updatedIndex: number) => void;
  index: number;
  entity: string;
}

const DynamicTabsAndForms: React.FC<DynamicTabsAndFormsProps> = ({
  jsonData,
  updateJsonData,
  index,
  entity,
}) => {
  const initialTab = jsonData ? Object.keys(jsonData)[0] : '';
  const initialCollapsedTabs: Record<string, boolean> = {};
  // Initialize collapsedTabs with boolean values for each tab
  if (jsonData) {
    Object.keys(jsonData).forEach((key) => {
      initialCollapsedTabs[key] = key !== initialTab;
    });
  }

  const [activeTab, setActiveTab] = useState<string>(initialTab);
  const [activeSubTab, setActiveSubTab] = useState<number>(0);
  const [collapsedTabs, setCollapsedTabs] =
    useState<Record<string, boolean>>(initialCollapsedTabs);

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
    setActiveSubTab(0); // Reset sub-tab when switching tabs

    // Toggle the collapse state for the clicked tab
    const updatedCollapsedTabs: Record<string, boolean> = {};
    if (jsonData) {
      Object.keys(collapsedTabs).forEach((key) => {
        updatedCollapsedTabs[key] =
          key === tabName ? !collapsedTabs[key] : true;
      });
      setCollapsedTabs(updatedCollapsedTabs);
    }
  };

  const handleSubTabClick = (index: number) => {
    setActiveSubTab(index);
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
    let day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    return year + '-' + month + '-' + day;
  };

  const handleInputChange = (
    tabIndex: number,
    tabName: string,
    fieldName: string,
    newValue: string
  ) => {
    const updatedJson: any = { ...jsonData };
    if (tabIndex === -1) {
      updatedJson[tabName][fieldName].data = newValue;
    } else {
      updatedJson[tabName][tabIndex][fieldName].data = newValue;
    }
    updateJsonData(updatedJson, index);
  };

  const handleCheckboxChange = (
    tabIndex: number,
    tabName: string,
    fieldName: string,
    isChecked: boolean
  ) => {
    const updatedJson: any = { ...jsonData };
    updatedJson[tabName][fieldName].data = isChecked;
    if (tabIndex === -1) {
      updatedJson[tabName][fieldName].data = isChecked;
    } else {
      updatedJson[tabName][tabIndex][fieldName].data = isChecked;
    }
    updateJsonData(updatedJson, index);
  };

  const handleDateChange = (
    tabIndex: number,
    tabName: string,
    fieldName: string,
    newDate: string
  ) => {
    const updatedJson: any = { ...jsonData };
    if (tabIndex === -1) {
      updatedJson[tabName][fieldName].data = newDate;
    } else {
      updatedJson[tabName][tabIndex][fieldName].data = newDate;
    }
    updateJsonData(updatedJson, index);
  };

  return (
    <>
      <div className="accordion-container">
        <div className="scrollbars-container">
          <div className="accordion" id="accordionExample">
            {jsonData &&
              Object.entries(jsonData).map(([tabName, tabData], tabIndex) => (
                <div key={tabName} className="accordion-item">
                  <h2 className="accordion-header" id={`heading${tabIndex}`}>
                    <button
                      className={`accordion-button ${activeTab === tabName && !collapsedTabs[tabName] ? '' : 'collapsed'}`}
                      type="button"
                      onClick={() => handleTabClick(tabName)}
                      aria-expanded={!collapsedTabs[tabName]}
                      aria-controls={`collapse${tabIndex}`}
                    >
                      {tabName}
                    </button>
                  </h2>
                  <div
                    id={`collapse${tabIndex}`}
                    className={`accordion-collapse collapse ${!collapsedTabs[tabName] ? 'show' : ''}`}
                    aria-labelledby={`heading${tabIndex}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {Array.isArray(tabData) ? (
                        <>
                          {tabData.length > 1 && (
                            <div className="sub-tabs-row">
                              {tabData.map(
                                (subTabData: JsonData, subTabIndex: number) => (
                                  <div
                                    key={subTabIndex}
                                    onClick={() =>
                                      handleSubTabClick(subTabIndex)
                                    }
                                    className={`subtab-margin subtab-text ${activeSubTab === subTabIndex ? 'active' : ''}`}
                                  >
                                    {Object.values(subTabData)[0].data}
                                  </div>
                                )
                              )}
                            </div>
                          )}
                          {tabData.length > 1 && <hr className="subtab-line" />}
                          {activeSubTab !== null && tabData[activeSubTab] && (
                            <div className="sub-tab-content">
                              <form>
                                {Object.entries<JsonData>(
                                  tabData[activeSubTab]
                                ).map(([key, value]) => (
                                  <div key={key} className="form-field">
                                    <label>{key}:</label>
                                    {typeof value.type === 'string' &&
                                      value.data === null && (
                                        <input
                                          type="text"
                                          value={
                                            value.data === null
                                              ? ''
                                              : value.data
                                          }
                                          className="form-control"
                                          onChange={(e) =>
                                            handleInputChange(
                                              activeSubTab,
                                              tabName,
                                              key,
                                              e.target.value
                                            )
                                          }
                                        />
                                      )}
                                    {typeof value.type === 'string' &&
                                      value.data != null &&
                                      value.type === 'Picklist' &&
                                      value.picklistValues && (
                                        <select
                                          value={value.data.toString()}
                                          className="form-control picklist-control"
                                          onChange={(e) =>
                                            handleInputChange(
                                              activeSubTab,
                                              tabName,
                                              key,
                                              e.target.value
                                            )
                                          }
                                        >
                                          {JSON.parse(
                                            value.picklistValues.toString()
                                          ).map((option: any) => (
                                            <option key={option} value={option}>
                                              {option}
                                            </option>
                                          ))}
                                        </select>
                                      )}
                                    {typeof value.type === 'string' &&
                                      value.type === 'boolean' && (
                                        <input
                                          type="checkbox"
                                          checked={
                                            value.data.toString() === 'Yes'
                                          }
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              activeSubTab,
                                              tabName,
                                              key,
                                              e.target.checked
                                            )
                                          }
                                        />
                                      )}
                                    {typeof value.type === 'string' &&
                                      (value.type === 'date' ||
                                        value.type === 'Date/Time') && (
                                        <input
                                          type="date"
                                          value={formatDate(
                                            value.data.toString()
                                          )}
                                          className="form-control"
                                          onChange={(e) =>
                                            handleDateChange(
                                              activeSubTab,
                                              tabName,
                                              key,
                                              e.target.value
                                            )
                                          }
                                        />
                                      )}
                                    {typeof value.type === 'string' &&
                                      value.data != null &&
                                      value.type !== 'date' &&
                                      value.type !== 'Date/Time' &&
                                      value.type !== 'boolean' &&
                                      value.type !== 'Picklist' && (
                                        <input
                                          type="text"
                                          value={value.data.toString()}
                                          className="form-control"
                                          onChange={(e) =>
                                            handleDateChange(
                                              activeSubTab,
                                              tabName,
                                              key,
                                              e.target.value
                                            )
                                          }
                                        />
                                      )}
                                  </div>
                                ))}
                              </form>
                            </div>
                          )}
                        </>
                      ) : (
                        Object.entries(tabData).map(
                          (
                            [fieldName, fieldData]: [
                              string,
                              {
                                data: string;
                                type: string;
                                picklistValues?: string[];
                              },
                            ],
                            fieldIndex: number
                          ) => (
                            <div key={fieldIndex} className="form-field">
                              <label>{fieldName}:</label>
                              {fieldData.data === null && (
                                <input
                                  type="text"
                                  value={
                                    fieldData.data === null
                                      ? ''
                                      : fieldData.data
                                  }
                                  className="form-control"
                                  onChange={(e) =>
                                    handleInputChange(
                                      -1,
                                      tabName,
                                      fieldName,
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                              {fieldData.data != null &&
                                fieldData.type !== 'Picklist' &&
                                fieldData.type !== 'boolean' &&
                                fieldData.type !== 'date' && (
                                  <input
                                    type="text"
                                    value={fieldData.data}
                                    className="form-control"
                                    onChange={(e) =>
                                      handleInputChange(
                                        -1,
                                        tabName,
                                        fieldName,
                                        e.target.value
                                      )
                                    }
                                  />
                                )}
                              {fieldData.data != null &&
                                fieldData.type === 'Picklist' &&
                                fieldData.picklistValues && (
                                  <select
                                    value={fieldData.data.toString()}
                                    className="form-control picklist-control"
                                    onChange={(e) =>
                                      handleInputChange(
                                        -1,
                                        tabName,
                                        fieldName,
                                        e.target.value
                                      )
                                    }
                                  >
                                    {JSON.parse(
                                      fieldData.picklistValues.toString()
                                    ).map((option: any) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>
                                )}
                              {fieldData.type === 'boolean' && (
                                <input
                                  type="checkbox"
                                  checked={fieldData.data === 'Yes'}
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      -1,
                                      tabName,
                                      fieldName,
                                      e.target.checked
                                    )
                                  }
                                />
                              )}
                              {(fieldData.type === 'date' ||
                                fieldData.type === 'Date/Time') && (
                                <input
                                  type="date"
                                  value={formatDate(fieldData.data)}
                                  className="form-control"
                                  onChange={(e) =>
                                    handleDateChange(
                                      -1,
                                      tabName,
                                      fieldName,
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                            </div>
                          )
                        )
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default DynamicTabsAndForms;
