




import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Appl, ChatboxInterface } from '../../interfaces';
import {
  Badge,
  Button,
  ButtonGroup,
  Col,
  Form,
  FormGroup,
} from 'react-bootstrap';
import {
  Feed_Back_Message,
  THUMBS_DOWN_ICON,
  THUMBS_UP_ICON,
} from '../../utils/constants';
import './VisionQaFeedback.css';
import axios from 'axios';
type feedbackprops = {
  feedBackProperties?: any;
};

const VisionQaFeedback: React.FC<feedbackprops> = ({ feedBackProperties }) => {
  const { projectId, appUrl } = useParams();
  const apps = JSON.parse(sessionStorage.getItem('apps') as string) as Appl[];
  const currentApp = apps.find((app) => app.url === appUrl);
  const [feedback, setFeedback] = useState<string | null>(null);
  const [showFeedback, setShowFeedback] = useState<boolean>(true);
  const [feedbackText, setFeedbackText] = useState<string>('');
  const [activeButton, setActiveButton] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const [showFeedBackBadge, setShowFeedBackBadge] = useState(false);
  const onThumbsDownButton = () => {
    setShowFeedBackBadge(true);
  };

  //To send feedback

  const onThumbsUpButton = async () => {
    setIsDisabled(true);
    const params: Record<string, string | number> = {
      corpus_name: feedBackProperties[0].corpusname,
    };
    if (feedBackProperties) {
      params.project_id = Number(feedBackProperties[0].projectId);
      params.application_id = Number(feedBackProperties[0].applicationId);
      params.recent_response = feedBackProperties[0].query;
    }
    const queryparam = new URLSearchParams(
      params as Record<string, string>
    ).toString();
    const vision='/api/v1/ui/visionqa/update-corpusfb'
    const video='/api/v1/ui/video-qa/update-corpusfb'
    let Api=''
    if(currentApp?.id === '26'){
      Api=video
    }
    else{
      Api=vision
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN+Api}?${queryparam}`
      );
      if (response.status === 201 || response.status === 200) {
        setIsDisabled(false);
        if (response.data.status === 'success') {
          setShowFeedBackBadge(true);
        }
      }
    } catch (error) {
      setIsDisabled(false);
      console.error(error);
    }
  };
  return (
    <div className="">
      <p>{feedbackText}</p>
      {showFeedback && (
        <div>
          <div className="mb-3">
            <FormGroup>
              <Col sm={12}>
                {!showFeedBackBadge ? (
                  <React.Fragment>
                    <Form.Label htmlFor="feedbackMessage">
                      Was this helpful?
                    </Form.Label>
                    <ButtonGroup>
                      <Button
                        className={activeButton === 'yes' ? 'active' : ''}
                        variant={
                          feedback === 'up' ? 'success' : 'outline-success'
                        }
                        disabled={isDisabled}
                        onClick={onThumbsUpButton}
                      >
                        {THUMBS_UP_ICON}
                      </Button>
                      <Button
                        className={activeButton === 'no' ? 'active' : ''}
                        variant={
                          feedback === 'down' ? 'danger' : 'outline-danger'
                        }
                        disabled={isDisabled}
                        onClick={onThumbsDownButton}
                      >
                        {THUMBS_DOWN_ICON}
                      </Button>
                    </ButtonGroup>
                  </React.Fragment>
                ) : (
                  showFeedBackBadge && (
                    <Badge bg="success" className="badge-style">
                      {Feed_Back_Message}
                    </Badge>
                  )
                )}
              </Col>
            </FormGroup>
          </div>
        </div>
      )}
    </div>
  );
};

export default VisionQaFeedback;

