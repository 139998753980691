import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface GettingStartedModalProps {
  show: boolean;
  handleClose: () => void;
}

const GettingStartedModal: React.FC<GettingStartedModalProps> = ({
  show,
  handleClose,
}) => {
  return (
    <div
      className="modal show"
      style={{ display: 'block', position: 'initial' }}
    >
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>
            Thank you for your interest in our GPS GenAI Enterprise Engines.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            These engines were built for Deloitte users and/or potential client
            users to help with productivity. Learn more about these engines and
            others developed by GPS teams by visiting{' '}
            <a
              href="https://portal.cfg.deloitte.com"
              target="_blank"
              rel="noreferrer"
            >
              portal.cfg.deloitte.com
            </a>
            , or click on &gt;Get Started&lt; to access your project and start
            using GenAIe engines.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Get Started
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GettingStartedModal;
