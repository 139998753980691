import React from 'react';
import { Container, Row, Card } from 'react-bootstrap';
import './CardDetailsSummary.css';

interface CardDetailsProps {
  onClickCard: (task: string) => void;
}
const CardDetailsPage: React.FC<CardDetailsProps> = ({ onClickCard }) => {
  const handleCardClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    // alert('message>>>>'+JSON.stringify(message))
    // setMessage(message);
    // setShowCardSummary(true);

    const taskname = (event.target as HTMLDivElement).querySelector(
      'strong'
    )?.textContent;
    onClickCard(String(taskname));
  };

  return (
    <>
      <Container className="outer-container-card">
        <Row className="">
          <Card className="text-center">
            <Card.Body onClick={handleCardClick}>
              <div className="card-text shine">
                <p className="ctitle">
                  <strong>Show me my task list</strong>
                </p>
                <p className="csubtitle">
                  Assistance, Eligibility, Case Management
                </p>
              </div>
            </Card.Body>
          </Card>

          <Card className="text-center">
            <Card.Body onClick={handleCardClick}>
              <div className="card-text shine">
                <p className="ctitle">
                  <strong>What does my day look like?</strong>
                </p>
                <p className="csubtitle">Schedule, Appointments, Events</p>
              </div>
            </Card.Body>
          </Card>
          <Card className="text-center">
            <Card.Body onClick={handleCardClick}>
              <div className="card-text shine">
                <p className="ctitle">
                  <strong>Go to a case</strong>
                </p>
                <p className="csubtitle">Access, View, Update</p>
              </div>
            </Card.Body>
          </Card>
          <Card className="text-center">
            <Card.Body onClick={handleCardClick}>
              <div className="card-text shine">
                <p className="ctitle">
                  <strong>Take an action</strong>
                </p>
                <p className="csubtitle">Act, Assist, Advance</p>
              </div>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </>
  );
};
export default CardDetailsPage;
