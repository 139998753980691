import React from 'react';
import './Stepper.css';

interface StepperProps {
  currentStep?: number | undefined;
  totalSteps?: number | undefined;
}

const Stepper: React.FC<StepperProps> = ({ currentStep, totalSteps }) => {
  const stepsArray =
    totalSteps && Array.from({ length: totalSteps }, (_, i) => i + 1);
  const renderSteps = () => {
    return (
      currentStep &&
      stepsArray &&
      stepsArray.map((step, index) => {
        const isActive = index + 1 === currentStep;
        const isCompleted = index + 1 < currentStep;

        const circleStyle: React.CSSProperties = {
          backgroundColor: isActive
            ? 'white'
            : isCompleted
              ? '#2781f2'
              : '#ddd',
          color: isCompleted ? 'white' : 'black',
          border: isActive || isCompleted ? '2px solid' : 'none',
          borderColor: isActive || isCompleted ? '#2781f2' : '#ddd',
        };

        return (
          <div key={index} className="step">
            <div className="step-circle" style={circleStyle}>
              {index + 1}
            </div>
          </div>
        );
      })
    );
  };

  return (
    <div className="stepper-container">
      <div className="steps">{renderSteps()}</div>
    </div>
  );
};

export default Stepper;
