/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { useContext, useState, useEffect } from 'react';
import {
  Form,
  Button,
  Alert,
  Badge,
  Spinner,
  Tabs,
  Tab,
} from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import CustomFeedback from '../../components/Feedback';
import { ReactComponent as BackIcon } from '../../assets/arrows/back-arrow.svg';
import { Appl } from '../../interfaces';
import ContentLoader from '../../components/Loader/contentLoader';
import SelectInstance from '../../components/SelectInstance';
import UserContext from '../../context/UserContext';
import './NoticeAnalysisEngine.css';
import ActivityStatusDropDown from '../../components/ActivityStatusDropDown';
import DownloadButton from '../../components/DownloadButton';
import * as XLSX from 'xlsx';
import { Llm_models } from '../../types';
import { azure_llm_models_options } from '../../utils/constants';
interface HeaderData {
  colName: string;
  description: string;
}

const NoticeAnalysisEngine = () => {
  const { projectId, appUrl } = useParams();
  const apps = JSON.parse(sessionStorage.getItem('apps') as string) as Appl[];
  const currentApp = apps.find((app) => app.url === appUrl);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showFeedbackOptions, setShowFeedBackOptions] =
    useState<boolean>(false);
  const [activityResponse, setActivityResponse] = useState<any>([]);
  const [activeTab, setActiveTab] = useState('Upload');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [showErrMsg, setShowErrMsg] = useState<string>('');
  const [activeActivityId, setActiveActivityId] = useState<string>('');
  const [outputData, setOutputData] = useState<any>('');
  const [isUploadResponse, setIsUploadResponse] = useState<boolean>(false);
  const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false);
  const [headers, setHeaders] = useState<string[]>([]);
  const [selectedHeaders, setSelectedHeaders] = useState<string[]>([]);
  const [inputData, setInputData] = useState<HeaderData[]>([]);
  const [selectedLlmModel, setSelectedLlmModel] =
    useState<Llm_models>('gpt-4-turbo');
  const [groupbyColumn, setGroupbyColumn] = useState<string>('');
  const [projectDetails, setProjectDetails] = useState<string>('');
  const [instanceData, setInstanceData] = useState<any>('');

  const NOTICE_ANALYSIS_ENGINE = 'Notice Analysis Engine';
  const CHANGE_COMMUNICATOR = 'Change Communicator';

  const userContext = useContext(UserContext) || {
    selectedInstance: '',
    userInput: '',
    setUserInput: () => {},
    chatContents: [],
    setChatContents: () => [],
    showToast: false,
    setShowToast: () => {},
    setIsActivityActive: () => {},
    isActivityActive: false,
    filesSelectedForUploadRef: null,
  };

  const {
    selectedInstance,
    showToast,
    setShowToast,
    setIsActivityActive,
    isActivityActive,
    filesSelectedForUploadRef,
  } = userContext;

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsUploadResponse(false);
    const files = event.target.files;
    if (files?.length) {
      let selectedFile = Array.from(files);
      setUploadedFiles(Array.from(files));
      if (currentApp?.name === NOTICE_ANALYSIS_ENGINE) {
        uploadFile(selectedFile);
      }
      setOutputData(false);
      setIsDisabled(false);
      if (
        currentApp?.name === CHANGE_COMMUNICATOR &&
        files &&
        files.length > 0
      ) {
        const file = files[0];
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const headerValues: any = [];
          let headerRow = -1;
          for (let row = 0; ; row++) {
            let rowEmpty = true;
            for (let col = 0; ; col++) {
              const cell = XLSX.utils.encode_cell({ r: row, c: col });

              if (!sheet[cell]) {
                break;
              }

              if (sheet[cell].v && sheet[cell].v.trim() !== '') {
                rowEmpty = false;
                break;
              }
            }
            if (!rowEmpty) {
              headerRow = row;
              break;
            }
          }
          if (headerRow !== -1) {
            for (let col = 0; ; col++) {
              const cell = XLSX.utils.encode_cell({ r: headerRow, c: col });

              if (!sheet[cell]) {
                break;
              }
              headerValues.push(sheet[cell].v);
            }
          }
          setHeaders(headerValues);
        };
        reader.readAsArrayBuffer(file);
      }
    } else {
      setIsUploadLoading(false);
      setIsUploadResponse(false);
      setIsDisabled(false);
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    let updatedSelectedHeaders = [...selectedHeaders];
    if (checked && !selectedHeaders.includes(value)) {
      updatedSelectedHeaders.push(value);
      setInputData([...inputData, { colName: value, description: '' }]);
    } else {
      updatedSelectedHeaders = selectedHeaders.filter(
        (header: string) => header !== value
      );
      setInputData(inputData.filter((input) => input.colName !== value));
    }
    setSelectedHeaders(updatedSelectedHeaders);
  };

  const handleSelectedInputChange = (e: any, colName: string) => {
    const { value } = e.target;
    setInputData((prevInputData) =>
      prevInputData.map((input) =>
        input.colName === colName
          ? { ...input, description: value?.trim() || colName }
          : input
      )
    );
  };

  const handleJsonSubmit = () => {
    const jsonData = inputData.map((input) => ({
      colName: input.colName,
      description: input.description?.trim() || input.colName,
    }));
    uploadFile(uploadedFiles, jsonData);
  };
  useEffect(() => {
    if (
      filesSelectedForUploadRef &&
      filesSelectedForUploadRef.current?.value === ''
    ) {
      setUploadedFiles([]);
      setIsUploadLoading(false);
      setIsUploadResponse(false);
    }
  }, [filesSelectedForUploadRef, selectedInstance]);


  const uploadFile = async (
    selectedFile: any,
    jsonData?: any,
    isAssistant?: any
  ) => {
    setShowToast(false);
    // setShowDownload(false);
    const formData = new FormData();
    selectedFile.forEach((file: any) => {
      if (currentApp?.name === NOTICE_ANALYSIS_ENGINE) {
        formData.append('pdf_file', file);
      } else formData.append('files', file);
    });

    try {
      let queryParams: any = {
        instance_id: selectedInstance,
        project_details: projectDetails,
        group_by: groupbyColumn,
        batch_size: 10,
        model: selectedLlmModel,
      };
      if (jsonData) {
        const jsonDataString = JSON.stringify(jsonData);
        queryParams = {
          ...queryParams,
          column_desc: jsonDataString,
        };
      }
      setShowToast(false);
      setShowFeedBackOptions(false);
      setIsLoading(true);
      setIsUploadLoading(true);
      setIsUploadResponse(false);

      const noticeAnalysisURl =
        '/api/v1/ui/azure/one-shot-chain/notice-analysis/upload-query-notice';
      const changeCommunatorURL =
        '/api/v1/ui/azure/one-shot-chain/change-communicator/multi-llm-call/async';
      const submitURL =
        currentApp?.name === NOTICE_ANALYSIS_ENGINE
          ? noticeAnalysisURl
          : changeCommunatorURL;
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}${submitURL}`,
        formData,
        {
          params: queryParams,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        setShowFeedBackOptions(true);
        setIsUploadLoading(false);
        setIsUploadResponse(true);
        setIsLoading(false);
        setUploadedFiles([]);
        const responseData = response.data?.response;
        // setShowDownload(true);
        setActivityResponse((prev: any) => [...prev, responseData]);
        setSelectedHeaders([]);
        setHeaders([]);
        setInputData([]);
      } else {
        console.error('Failed to upload files');
        setIsLoading(false);
        setShowToast(true);
        setIsUploadLoading(false);
        // setShowDownload(false);
      }
    } catch (error: any) {
      console.error('Error:', error);
      const err = error?.response?.data?.error;
      setIsLoading(false);
      setIsUploadLoading(false);
      setShowToast(true);
      if (err && err.errorMessage) {
        setShowErrMsg(err.errorMessage);
      } else {
        console.error('Error message not available');
      }
    }
  };

  // const clearSelectedFile = () => {
  //   if (filesSelectedForUploadRef.current) {
  //     filesSelectedForUploadRef.current.value = '';
  //   }
  // };

  const handleActivitySelect = (selectedActivity: any) => {};

  const handleProjectDetails = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setProjectDetails(event.target.value);
  };

  return (
    <>
      <div className="title">{currentApp?.name}</div>
      <div className="back-button-container">
        <Link to={`/project/${projectId}`}>
          <BackIcon /> Back
        </Link>
      </div>
      <div className="single-app-container">
        <SelectInstance
          projectId={projectId}
          currentApp={currentApp}
          step2={currentApp?.name === NOTICE_ANALYSIS_ENGINE ? true : false}
          step3={currentApp?.name === NOTICE_ANALYSIS_ENGINE ? true : false}
          step4={false}
          setOutputData={setOutputData}
          setInstanceDetails={setInstanceData}
          instanceDetails={instanceData}
          setisUploadingProps={setIsUploadLoading}
          setIsUploadResponseProps={setIsUploadResponse}
        />
        <Tabs
          defaultActiveKey="Upload"
          id="justify-tab-example"
          className="mb-3"
          justify
          // activeKey={activeTab}
          onSelect={(key: string | null) => {
            if (key !== null) {
              setActiveTab(key);
              setIsActivityActive(!isActivityActive);
            }
          }}
        >
          <Tab eventKey="Upload" title="Upload">
            <Form.Group
              className="file-input"
              controlId="exampleForm.fileInput"
            >
              <Form.Label>
                {currentApp?.name === NOTICE_ANALYSIS_ENGINE
                  ? 'Upload Document(s)'
                  : 'Upload Document'}
              </Form.Label>
              <Form.Control
                type="file"
                multiple={currentApp?.name === NOTICE_ANALYSIS_ENGINE}
                accept={
                  currentApp?.name === NOTICE_ANALYSIS_ENGINE
                    ? '.pdf'
                    : '.xlsx, .csv'
                }
                onChange={handleFileChange}
                ref={filesSelectedForUploadRef}
                disabled={!selectedInstance}
              />
              {isUploadLoading && (
                <div className="upload-spinner">
                  <Spinner animation="border" size="sm" /> Uploading
                </div>
              )}
              {uploadedFiles && isUploadResponse && (
                <div className="upload-msg">
                  <Badge bg="success">Upload Successful</Badge>
                </div>
              )}
              <div>
                <Form.Text>
                  Formats supported:{' '}
                  {currentApp?.name === NOTICE_ANALYSIS_ENGINE
                    ? 'pdf'
                    : 'xlsx and csv'}
                </Form.Text>
              </div>
              {currentApp?.name === CHANGE_COMMUNICATOR && (
                <div>
                  <Form.Text>
                    Maximum of 300 rows and 7 columns are only supported in the
                    uploaded document.
                  </Form.Text>
                </div>
              )}
            </Form.Group>
            {currentApp?.name === CHANGE_COMMUNICATOR && (
              <>
                {uploadedFiles && uploadedFiles.length > 0 && (
                  <div className="mb-3">
                    <Form.Group
                      className="user-input"
                      controlId="exampleForm.userInput"
                    >
                      <Form.Label>Enter your Project Details</Form.Label>
                      <Form.Control
                        placeholder="Release Note - Project Name - Version - Date"
                        as="textarea"
                        rows={2}
                        value={projectDetails}
                        onChange={handleProjectDetails}
                      />
                    </Form.Group>
                  </div>
                )}
                {uploadedFiles && uploadedFiles.length > 0 && (
                  <div className="mb-3">
                    <Form>
                      <Form.Label>Choose the relevant Columns</Form.Label>
                      {headers.map((header, index) => (
                        <div key={index}>
                          <Form.Check
                            type="checkbox"
                            id={`checkbox-${index}`}
                            label={header}
                            checked={selectedHeaders.includes(header)}
                            onChange={handleCheckboxChange}
                            value={header}
                          />
                          {selectedHeaders.includes(header) && (
                            <div className="mt-1 mb-3">
                              <Form.Control
                                type="text"
                                placeholder="Description"
                                onChange={(e) =>
                                  handleSelectedInputChange(e, header)
                                }
                              />
                            </div>
                          )}
                        </div>
                      ))}
                    </Form>
                  </div>
                )}
                {selectedHeaders?.length > 0 && (
                  <div className="mb-3" style={{ marginTop: '28px' }}>
                    <Form.Group
                      className="user-input"
                      controlId="exampleForm.llmPicker"
                    >
                      <Form.Label>Choose the Column for Grouping</Form.Label>
                      <Form.Select
                        aria-label="Choose the column for grouping"
                        className="instance-picker"
                        value={groupbyColumn}
                        onChange={(e) => setGroupbyColumn(e.target.value)}
                      >
                        {selectedHeaders.map((header, index) => (
                          <option key={index} value={header}>
                            {header}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                )}
                <div className="with-download-button">
                  <Button
                    className="user-story-generator-submit"
                    as="input"
                    type="submit"
                    value="Submit"
                    onClick={handleJsonSubmit}
                    disabled={selectedHeaders.length > 0 ? false : true}
                    style={{ margin: '0 auto', display: 'flex' }}
                  />
                </div>
              </>
            )}
          </Tab>
          <Tab eventKey="Activity" title="Activity">
            <ActivityStatusDropDown
              activityResponse={activityResponse}
              setIsDisabled={setIsDisabled}
              onActivitySelect={handleActivitySelect}
              activityType={
                currentApp?.name === NOTICE_ANALYSIS_ENGINE
                  ? NOTICE_ANALYSIS_ENGINE
                  : CHANGE_COMMUNICATOR
              }
              setActiveActivityId={setActiveActivityId}
              setOutputData={setOutputData}
            />
          </Tab>
        </Tabs>
      </div>
      <div
        className="user-story-generator-submit-container"
        style={{ marginBottom: '1.5rem', marginTop: '1.5rem' }}
      ></div>
      {activeTab === 'Activity' && (
        <div className="user-story-generator-submit-container">
          {isLoading ? (
            <Button
              className="user-story-generator-submit-button"
              disabled={true}
              style={{ width: '104px', height: '36px' }}
            >
              <ContentLoader />
            </Button>
          ) : (
            <div className="clear-button">
              {/* { outputData &&(
                <ClearChat disabled={ outputData.length > 0 ? false : true} 
                setUploadedFiles={setUploadedFiles} 
                setOutputData={setOutputData} 
                clearSelectedFile={clearSelectedFile}
                setShowDownload={setShowDownload}
              />
              )} */}
              {outputData && currentApp?.name === NOTICE_ANALYSIS_ENGINE && (
                <div style={{ marginLeft: '20px' }}>
                  <DownloadButton
                    activeActivityId={activeActivityId}
                    title="Download As"
                    activityType={NOTICE_ANALYSIS_ENGINE}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {showToast && activeTab === 'Activity' && outputData && (
        <div style={{ paddingTop: '80px', width: '50vw', margin: '0 auto' }}>
          <Alert
            variant="danger"
            onClose={() => setShowToast(false)}
            dismissible
          >
            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
            <p>
              {showErrMsg
                ? showErrMsg
                : `Something went wrong. Please try again`}
            </p>
          </Alert>
        </div>
      )}
      {outputData && activeTab === 'Activity' && (
        <>
          <Form.Control
            as="textarea"
            rows={18}
            className="output-container"
            value={outputData}
            disabled
          />
        </>
      )}
      {activeTab === 'Activity' && showFeedbackOptions && outputData && (
        <CustomFeedback
          url={`${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/ui/activity/feedback/update`}
          activityId={activeActivityId}
        />
      )}
    </>
  );
};

export default NoticeAnalysisEngine;
